import { EMAIL_FIELD, USERNAME_FIELD } from "./userDetails.constants";
import { CYRILLIC_REGEX } from "../../../constants/regex";
import {
  emailAsyncValidation,
  isEmailValid,
  isUserNameValid,
  passwordValidation,
  usernameAsyncValidation,
} from "../../../utils/commonValidation";
import {
  ENTER_VALID_LOGIN,
  PLEASE_ENTER_FORENAME,
  PLEASE_ENTER_LASTNAME,
  PLEASE_ENTER_VALID_EMAIL,
  USERNAME_CANNOT_BE_EMPTY,
} from "../../auth/auth.constants";

export const validate = ({
  uid,
  forename = "",
  lastname = "",
  email = "",
  username = "",
  useEmailAsLogin,
  useDefaultPassword,
  newPassword,
  reEnterPassword,
  isAdminAccess = false,
}) => {
  const errors = {};
  let passwordErrors = {};

  const shouldValidatePassword = !useDefaultPassword && !useEmailAsLogin;

  if (!forename || !forename.trim()) {
    errors.forename = PLEASE_ENTER_FORENAME;
  }

  if (!lastname || !lastname.trim()) {
    errors.lastname = PLEASE_ENTER_LASTNAME;
  }

  // If 'Use this email address as the login' is enabled, non Latin are forbidden
  if (
    ((useEmailAsLogin || isAdminAccess || email) && !isEmailValid(email)) ||
    ((useEmailAsLogin || isAdminAccess) && CYRILLIC_REGEX.test(email))
  ) {
    errors.email = PLEASE_ENTER_VALID_EMAIL;
  }

  if (!username && !isAdminAccess && !useEmailAsLogin) {
    errors.username = USERNAME_CANNOT_BE_EMPTY;
  } else if (!uid && !useEmailAsLogin && !isUserNameValid(username)) {
    errors.username = ENTER_VALID_LOGIN;
  }

  if (
    (uid && newPassword && shouldValidatePassword) ||
    (!uid && shouldValidatePassword)
  ) {
    passwordErrors = passwordValidation({ reEnterPassword, newPassword });
  }

  return { ...errors, ...passwordErrors };
};

export const asyncValidate = async (
  formValues,
  dispatch,
  props,
  blurredField
) => {
  // Do not validate username or email on edit user
  if (formValues.uid) {
    return;
  }

  const { username, email, useEmailAsLogin, isAdminAccess } = formValues;
  // In case if "isAdminAccess" has been disabled - "useEmailAsLogin" will be turned off too.
  // But validation will not be triggered for "useEmailAsLogin" field.
  const isAdminAccessHasBeenTriggered = blurredField === "isAdminAccess";

  // If user is Admin - validate email
  // If user is not Admin - validate username
  const field =
    (!isAdminAccessHasBeenTriggered && useEmailAsLogin) || isAdminAccess
      ? EMAIL_FIELD
      : USERNAME_FIELD;

  if (field === USERNAME_FIELD && username) {
    return usernameAsyncValidation({ username });
  }

  if (field === EMAIL_FIELD && isEmailValid(email)) {
    return emailAsyncValidation({ email });
  }
};
