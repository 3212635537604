import { get } from "lodash";

import { COUNTRY_CODES, GB } from "../../../constants/strings";
import { isDpdLocalDomain } from "../../app/app.modes";
import {
  ACCOUNT_ID_FIELD,
  ADDLINE_1_FIELD,
  ADDLINE_2_FIELD,
  ADDLINE_3_FIELD,
  ADDLINE_4_FIELD,
  BUSINESS_ID_FIELD,
  COMPANY_FIELD,
  COUNTRY_FIELD,
  CUSTOMER_ID_FIELD,
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
  EOD_MASTER_AVAILABLE_FIELD,
  EOD_MASTER_USER_FIELD,
  NOTIFICATION_EMAIL_FIELD,
  NOTIFICATION_NUMBER_FIELD,
  OVERRIDE_DETAILS_FIELD,
  POSTCODE_FIELD,
} from "./accountDetails.constants";

export const getAccountDetailsInitialValues = () => ({
  [BUSINESS_ID_FIELD]: isDpdLocalDomain()
    ? DPD_LOCAL_BUSINESS_ID
    : DPD_BUSINESS_ID,
  [ACCOUNT_ID_FIELD]: "",
  [COUNTRY_FIELD]: GB,
  [OVERRIDE_DETAILS_FIELD]: false,
  [POSTCODE_FIELD]: "",
  [ADDLINE_1_FIELD]: "",
  [ADDLINE_3_FIELD]: "",
  [ADDLINE_2_FIELD]: "",
  [ADDLINE_4_FIELD]: "",
  [COMPANY_FIELD]: "",
  [NOTIFICATION_EMAIL_FIELD]: "",
  [NOTIFICATION_NUMBER_FIELD]: "",
  [EOD_MASTER_AVAILABLE_FIELD]: false,
  [EOD_MASTER_USER_FIELD]: null,
  [CUSTOMER_ID_FIELD]: null,
});

export const mapAddressFromLocations = ({
  street,
  town,
  county,
  property,
  locality,
}) => ({
  [ADDLINE_1_FIELD]: property ? `${property}, ${street}` : street,
  [ADDLINE_4_FIELD]: county,
  [ADDLINE_3_FIELD]: town,
  [ADDLINE_2_FIELD]: locality,
});

export const formatCountry = (country) =>
  !country || !COUNTRY_CODES.includes(country) ? GB : country;

export const getCountryValue = (country) =>
  !country || !COUNTRY_CODES.includes(country) ? "" : country;

export const formatItem = ({ street, city, county, property, postcode }) =>
  [property, street, city, county, postcode].filter(Boolean).join(", ");

export const mapCustomerDetailsData = ({
  account,
  addressline1,
  addressline2,
  addressline3,
  addressline4,
  postcode,
  notifyemail,
  notifysms,
  customername,
  country,
}) => ({
  [COUNTRY_FIELD]: getCountryValue(country),
  [POSTCODE_FIELD]: postcode,
  [ACCOUNT_ID_FIELD]: account,
  [ADDLINE_1_FIELD]: addressline1,
  [ADDLINE_2_FIELD]: addressline2,
  [ADDLINE_3_FIELD]: addressline3,
  [ADDLINE_4_FIELD]: addressline4,
  [NOTIFICATION_EMAIL_FIELD]: `${notifyemail}`,
  [NOTIFICATION_NUMBER_FIELD]: `${notifysms}`,
  [COMPANY_FIELD]: customername,
});

export const mapCustomerData = (customer) => ({
  ...mapCustomerDetailsData(customer),
  overrideDetails: false,
});

export const mapMyDpdUserData = (customer) => ({
  ...mapCustomerDetailsData(customer),
  businessId: customer.business,
  overrideDetails: true,
});

export const prepareAccountDetails = ({
  account,
  businessId,
  overrideDetails,
  ...details
}) => {
  const accountDetails = { account, businessId, customerAddress: {} };

  if (!overrideDetails) {
    return accountDetails;
  }

  const customerAddress = {
    country: get(details, "country"),
    postcode: get(details, "postcode"),
    [NOTIFICATION_EMAIL_FIELD]: get(details, "notifyEmail"),
    [NOTIFICATION_NUMBER_FIELD]: get(details, "notifyTel"),
    [ADDLINE_1_FIELD]: get(details, "addressline1", "").slice(0, 35),
    [ADDLINE_2_FIELD]: get(details, "addressline2", "").slice(0, 35),
    [ADDLINE_3_FIELD]: get(details, "addressline3", "").slice(0, 35),
    [ADDLINE_4_FIELD]: get(details, "addressline4", "").slice(0, 35),
  };

  return { ...accountDetails, customerAddress };
};
