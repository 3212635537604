import { ACTIONS } from "./modal.actions";

export const initialState = {
  isModalOpened: false,
  modalProps: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SHOW_MODAL:
      return {
        ...state,
        isModalOpened: true,
        modalProps: action.payload,
      };
    case ACTIONS.HIDE_MODAL:
      return {
        ...state,
        isModalOpened: false,
      };
    default:
      return state;
  }
};
