import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { SLIDS_FORM } from "../../constants/forms";
import { prepareSequencesData } from "./sequences.model";

export const getSequencesFormValue = getFormValues(SLIDS_FORM);

export const getSequences = createSelector(
  getSequencesFormValue,
  prepareSequencesData
);
