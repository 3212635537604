import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import { READ_ONLY_PRODUCT_BOOK } from "../../constants/strings";
import { READ_ONLY_PRODUCT_BOOK_FIELD } from "../../features/userDetailsForm/advancedCustomerConfiguration/advancedCustomerConfiguration.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

const ProductBook = ({ onProductBookChange }) => (
  <Grid container justify="space-between">
    <Field
      name={READ_ONLY_PRODUCT_BOOK_FIELD}
      component={CustomSwitchWithTitle}
      type="checkbox"
      label={READ_ONLY_PRODUCT_BOOK}
      onChange={(event, value) => {
        onProductBookChange(READ_ONLY_PRODUCT_BOOK_FIELD, value);
      }}
    />
  </Grid>
);

ProductBook.propTypes = {
  onProductBookChange: PropTypes.func,
};

ProductBook.defaultProps = {
  onProductBookChange: () => {},
};

export default ProductBook;
