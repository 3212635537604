import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

import styles from "./MenuItem.module.scss";

const MenuItem = ({ text, to, onClick, location: { pathname } }) => {
  const handleClick = (e) => {
    if (pathname === to) e.preventDefault();
    return onClick(text);
  };
  return (
    <ListItem
      button
      component={NavLink}
      to={to}
      onClick={handleClick}
      activeClassName="selected"
      className={classNames(styles["list-item"], "menu-item")}
    >
      <ListItemText primary={text} className={styles["list-text"]} />
    </ListItem>
  );
};

MenuItem.defaultProps = {
  onClick: () => null,
};

MenuItem.propTypes = {
  text: PropTypes.string,
  location: PropTypes.object,
  to: PropTypes.any,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withRouter(MenuItem);
