import React, { useState } from "react";
import InputWithTitle from "../InputWithTitle/InputWithTitle";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/outlinedEye.svg";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import styles from "./KeyInput.module.scss";

const KeyInput = ({
  showEyeIcon,
  showDeleteIcon,
  onDeleteIconClick,
  title,
  ...inputProps
}) => {
  const [showKey, setShowKey] = useState(false);

  return (
    <Grid container spacing={3}>
      {title && (
        <Grid item xs={12}>
          <Typography className={styles.title}>{title}</Typography>
        </Grid>
      )}
      <Grid item xs={7}>
        <InputWithTitle type={showKey ? "text" : "password"} {...inputProps} />
      </Grid>
      {showEyeIcon && (
        <Grid item xs={1}>
          <IconButton onClick={() => setShowKey(!showKey)}>
            <EyeIcon />
          </IconButton>
        </Grid>
      )}
      {showDeleteIcon && (
        <Grid item xs={1}>
          <IconButton onClick={onDeleteIconClick}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

KeyInput.defaultProps = {
  showDeleteIcon: true,
  showEyeIcon: true,
  onDeleteIconClick: () => null,
};

KeyInput.propTypes = {
  onDeleteIconClick: PropTypes.func.isRequired,
  showDeleteIcon: PropTypes.bool,
  showEyeIcon: PropTypes.bool,
  title: PropTypes.string,
};

export default KeyInput;
