import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";

import { NODE_ENV } from "../constants/common";

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_RELEASE, REACT_APP_SENTRY_ENV } =
  process.env;

Sentry.init({
  debug: process.env.NODE_ENV === NODE_ENV.DEVELOPMENT,
  enabled: process.env.NODE_ENV === NODE_ENV.PRODUCTION,
  release: REACT_APP_SENTRY_RELEASE,
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
  maxBreadcrumbs: 50,
  whitelistUrls: [`${window.location.origin}/static/js/`, "https://unpkg.com/"],
  ignoreUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

export const sentryMiddleware = createSentryMiddleware(Sentry, {
  filterBreadcrumbActions: (action) => !action.type.startsWith("@@redux-form"),
});

export const setSentryUser = (user) => Sentry.setUser(user);

export default Sentry;
