import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { ReactComponent as Phone } from "../../../../assets/icons/phone.svg";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  COMPANY,
  COUNTRY,
  COUNTY,
  LOCALITY,
  POSTCODE,
  STREET,
  TEL_NO,
  TOWN_CITY,
} from "../../../../constants/strings";
import {
  CITY_FIELD,
  COMPANY_FIELD,
  COUNTRY_NAME_FIELD,
  COUNTY_FIELD,
  POSTCODE_FIELD,
  STREET_FIELD,
  SUBURB_FIELD,
  TELEPHONE_FIELD,
} from "../../../../features/profile/profile.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);
const DecoratedSearchWithError = withErrorTooltip(InputWithTitle);

export const ProfileSenderAddress = ({ onTextEntry }) => (
  <Box width="100%">
    <Box display="flex" flexDirection="row">
      <Box width={2 / 3} p={1}>
        <Field
          name={COMPANY_FIELD}
          label={COMPANY}
          component={DecoratedFieldWithError}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(COMPANY_FIELD, value);
          }}
        />
      </Box>
      <Box width={1 / 3} p={1}>
        <Field
          name={POSTCODE_FIELD}
          component={DecoratedSearchWithError}
          label={POSTCODE}
          inputProps={{ maxLength: 8 }}
          onBlur={(e, value) => {
            onTextEntry(POSTCODE_FIELD, value);
          }}
        />
      </Box>
    </Box>
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          name={STREET_FIELD}
          label={STREET}
          component={DecoratedFieldWithError}
          inputProps={{ maxLength: 35 }}
          onBlur={(e, value) => {
            onTextEntry(STREET_FIELD, value);
          }}
        />
      </Box>
      <Box width={1 / 2} p={1}>
        <Field
          name={SUBURB_FIELD}
          component={DecoratedSearchWithError}
          label={LOCALITY}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(SUBURB_FIELD, value);
          }}
        />
      </Box>
    </Box>
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          name={CITY_FIELD}
          label={TOWN_CITY}
          component={DecoratedFieldWithError}
          inputProps={{ maxLength: 35 }}
          onBlur={(e, value) => {
            onTextEntry(CITY_FIELD, value);
          }}
        />
      </Box>
      <Box width={1 / 2} p={1}>
        <Field
          name={COUNTY_FIELD}
          component={DecoratedSearchWithError}
          label={COUNTY}
          inputProps={{ maxLength: 35 }}
          onBlur={(e, value) => {
            onTextEntry(COUNTY_FIELD, value);
          }}
        />
      </Box>
    </Box>
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          name={COUNTRY_NAME_FIELD}
          component={DecoratedSearchWithError}
          label={COUNTRY}
          inputProps={{ maxLength: 35 }}
          onBlur={(e, value) => {
            onTextEntry(COUNTRY_NAME_FIELD, value);
          }}
        />
      </Box>
      <Box width={1 / 2} p={1}>
        <Field
          name={TELEPHONE_FIELD}
          component={DecoratedFieldWithError}
          label={TEL_NO}
          inputProps={{ maxLength: 15 }}
          icon={<Phone />}
          onBlur={(e, value) => {
            onTextEntry(TELEPHONE_FIELD, value);
          }}
        />
      </Box>
    </Box>
  </Box>
);

ProfileSenderAddress.propTypes = {
  onTextEntry: PropTypes.func,
};

export default compose(
  withTrackProps(() => ({
    onTextEntry: (field, value) =>
      createLogEntryPayload(PROFILE_ANALYTICS.TEXT_ENTRY, {
        field,
        value,
      }),
  }))
)(ProfileSenderAddress);
