import { connect } from "react-redux";
import { compose } from "recompose";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import LabelSettings from "../../../../components/LabelSettings/LabelSettings";
import {
  ANALYTICS_FOR_USER,
  CREATE_EDIT_CUSTOMER_USER_ANALYTICS,
} from "../../../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../../../constants/forms";
import { resetLabelSettings } from "../../../../features/userDetailsForm/userDetails/userDetails.actions";
import {
  HOMECALL_REFERENCE,
  INCLUDE_CUSTOMER_CODE_FIELD,
  INCLUDE_REFERENCE_AS_BARCODE,
  INCLUDE_REFERENCE_CUSTOMER_ZONE,
  INCLUDE_TRACKING_BARCODE_FIELD,
  USE_6_INCH_LABEL_FIELD,
} from "../../../../features/userDetailsForm/userDetails/userDetails.constants";
import { shouldShowLabelSettings } from "../../../../features/userDetailsForm/userDetails/userDetails.selectors";

const mapStateToProps = (state) => ({
  showLabelSettings: shouldShowLabelSettings(state),
});

export default compose(
  connect(mapStateToProps, { resetLabelSettings }),
  reduxForm({
    form: USER_DETAILS_FORMS.DPD_USER_DETAILS,
  }),
  withTrackProps(() => ({
    onInputChange: (field, value) => {
      let id = "";
      switch (field) {
        case INCLUDE_CUSTOMER_CODE_FIELD:
        case INCLUDE_TRACKING_BARCODE_FIELD:
        case USE_6_INCH_LABEL_FIELD:
          id = CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_TOGGLE;
          break;
        case INCLUDE_REFERENCE_CUSTOMER_ZONE:
          id = ANALYTICS_FOR_USER.CLICK_LABEL_SETTINGS_DROPDOWN1;
          break;
        case INCLUDE_REFERENCE_AS_BARCODE:
          id = ANALYTICS_FOR_USER.CLICK_LABEL_SETTINGS_DROPDOWN2;
          break;
        case HOMECALL_REFERENCE:
          id = ANALYTICS_FOR_USER.CLICK_LABEL_SETTINGS_DROPDOWN3;
          break;
      }

      return createLogEntryPayload(id, {
        field,
        value,
      });
    },
  }))
)(LabelSettings);
