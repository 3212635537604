import PropTypes from "prop-types";
import { Field } from "redux-form";

import { SHOW_EXPIRATION_DATE_ON_LABEL } from "../../constants/strings";
import { EXPIRATION_DATE_ON_LABEL_FIELD } from "../../features/userDetailsForm/shippingSettings/shippingSettings.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

const GroupDispatch = ({ onInputChange }) => (
  <Field
    name={EXPIRATION_DATE_ON_LABEL_FIELD}
    component={CustomSwitchWithTitle}
    type="checkbox"
    label={SHOW_EXPIRATION_DATE_ON_LABEL}
    onChange={(event, value) => {
      onInputChange(EXPIRATION_DATE_ON_LABEL_FIELD, value);
    }}
  />
);

export default GroupDispatch;

GroupDispatch.defaultProps = {
  onInputChange: () => null,
};

GroupDispatch.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};
