import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { reduxForm } from "redux-form";

import AddressBook from "../../../components/AdvancedCustomerConfiguration/AddressBook";
import styles from "../../../components/AdvancedCustomerConfiguration/AdvancedCustomerConfiguration.module.scss";
import Collections from "../../../components/AdvancedCustomerConfiguration/Collections";
import CreateShipment from "../../../components/AdvancedCustomerConfiguration/CreateShipment";
import ProductBook from "../../../components/AdvancedCustomerConfiguration/ProductBook";
import CustomTabs from "../../../components/CustomTabs/CustomTabs.js";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { CUSTOMER_ADVANCED_CONFIGURATION_TABS } from "../../../constants/strings";
import { onReference1Change } from "../../../features/userDetailsForm/advancedCustomerConfiguration/advancedCustomerConfiguration.actions";

const AdvancedCustomerConfiguration = ({ onToggleChange, onReference1 }) => (
  <div className={styles.wrapper}>
    <CustomTabs tabs={CUSTOMER_ADVANCED_CONFIGURATION_TABS}>
      <AddressBook onAddressBookChange={onToggleChange} />
      <ProductBook onProductBookChange={onToggleChange} />
      <CreateShipment onChangeToggle={onReference1} />
      <Collections onChangeToggle={onToggleChange} />
    </CustomTabs>
  </div>
);

AdvancedCustomerConfiguration.propTypes = {
  onToggleChange: PropTypes.func.isRequired,
  onReference1: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
  initialValues: {},
});

const mapDispatchToProps = {
  onAddressBookChangeAction: onReference1Change,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps((props) => ({
    onReference1: (field, value) => {
      props.onAddressBookChangeAction();
      props.onToggleChange(field, value);
    },
  })),
  reduxForm({
    form: USER_DETAILS_FORMS.ADVANCED_CUSTOMER_CONFIGURATION,
  })
)(AdvancedCustomerConfiguration);
