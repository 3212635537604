import { Box, Grid, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { renderSelectItems } from "./DefaultsDpd";
import styles from "./DefaultsDpd.module.scss";
import HeaderWithStar from "../../../../components/HeaderWithStar/HeaderWithStar";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  DOMESTIC_OUTBOUND_SERVICE,
  OFFSHORE_OUTBOUND_SERVICE,
  PLEASE_SELECT_SERVICE,
} from "../../../../constants/strings";
import { getDpdLocalOutboundServices } from "../../../../features/config/config.selectors";
import {
  DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD,
  DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD,
} from "../../../../features/profile/profile.constants";
import { isLocalDpdAccount } from "../../../../features/profile/profile.models";
import { getAccountNumber } from "../../../../features/profile/profile.selectors";

const DefaultsDpdLocal = ({
  onDropdownClick,
  dpdLocalServices,
  accountNumber,
}) => (
  <Box width="80%" className={styles.wrapper}>
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.spacing}
    >
      <HeaderWithStar
        text={DOMESTIC_OUTBOUND_SERVICE}
        required={!!isLocalDpdAccount(accountNumber)}
      />
      <Grid item className={styles.select}>
        <Field
          name={DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD}
          component={SelectWithTitle}
          displayEmpty
          showMetaErrors
          onClick={() => {
            onDropdownClick(DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD);
          }}
        >
          <MenuItem value={""}>{PLEASE_SELECT_SERVICE}</MenuItem>
          {renderSelectItems(dpdLocalServices)}
        </Field>
      </Grid>
    </Grid>
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.spacing}
    >
      <HeaderWithStar
        text={OFFSHORE_OUTBOUND_SERVICE}
        required={!!isLocalDpdAccount(accountNumber)}
      />
      <Grid item className={styles.select}>
        <Field
          name={DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD}
          component={SelectWithTitle}
          displayEmpty
          showMetaErrors
          onClick={() => {
            onDropdownClick(DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD);
          }}
        >
          <MenuItem value={""}>{PLEASE_SELECT_SERVICE}</MenuItem>
          {renderSelectItems(dpdLocalServices)}
        </Field>
      </Grid>
    </Grid>
  </Box>
);

DefaultsDpdLocal.propTypes = {
  onDropdownClick: PropTypes.func,
  dpdLocalServices: PropTypes.array,
  accountNumber: PropTypes.string,
};

const mapStateToProps = (state) => ({
  dpdLocalServices: getDpdLocalOutboundServices(state),
  accountNumber: getAccountNumber(state),
});

export default compose(
  connect(mapStateToProps),
  withTrackProps(() => ({
    onDropdownClick: (field) =>
      createLogEntryPayload(PROFILE_ANALYTICS.CLICK_DROPDOWN_LIST, {
        field,
      }),
  }))
)(DefaultsDpdLocal);
