import { connect } from "react-redux";
import { compose } from "recompose";
import { formValueSelector } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import FailedImport from "../../../components/ShippingSettings/FailedImport";
import { CREATE_EDIT_USER_ANALYTICS } from "../../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { changeShippingFormFieldValue } from "../../../features/userDetailsForm/shippingSettings/shippingSettings.actions";
import {
  USE_BAD_FILE_FIELD,
  USE_SCREEN_FIELD,
} from "../../../features/userDetailsForm/shippingSettings/shippingSettings.constants";

const mapStateToProps = (state) => ({
  useFields: formValueSelector(USER_DETAILS_FORMS.SHIPPING_SETTINGS)(
    state,
    USE_BAD_FILE_FIELD,
    USE_SCREEN_FIELD
  ),
});

const mapDispatchToProps = {
  changeShippingFormFieldValue,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps((props) => ({
    toggleCheckBox: (field, value, clickField) => {
      props.changeShippingFormFieldValue(field, !value);
      return createLogEntryPayload(CREATE_EDIT_USER_ANALYTICS.CLICK_TOGGLE, {
        field: clickField,
        value,
      });
    },
  }))
)(FailedImport);
