import { USER_ROLES } from "../../../constants/common";
import { isDefaultPassword } from "../../../utils/commonValidation";
import { preparePassword } from "../../auth/auth.model";
import {
  ACCOUNT_EXPIRY_DATE_FIELD,
  EMAIL_FIELD,
  NEW_PASSWORD_FIELD,
  PASSWORD_FIELD,
  RE_ENTER_PASSWORD_FIELD,
  USE_EMAIL_AS_LOGIN_FIELD,
} from "../../myDpdUserForm/userDetails/userDetails.constants";
import {
  DEFAULT_PASSWORD,
  FORENAME_FIELD,
  HOMECALL_REFERENCE,
  INCLUDE_CUSTOMER_CODE_FIELD,
  INCLUDE_REFERENCE_AS_BARCODE,
  INCLUDE_REFERENCE_CUSTOMER_ZONE,
  INCLUDE_TRACKING_BARCODE_FIELD,
  LASTNAME_FIELD,
  PASSWORD_EXPIRES_FIELD,
  PASSWORD_LOCK_FIELD,
  TITLE_FIELD,
  USE_6_INCH_LABEL_FIELD,
  USE_ROAD_FIELD,
  USERNAME_FIELD,
} from "./userDetails.constants";

export const isShowPasswordMeter = (newPassword) =>
  newPassword && !isDefaultPassword(newPassword);

export const labelSettingsInitialValues = () => ({
  [INCLUDE_REFERENCE_CUSTOMER_ZONE]: "0",
  [INCLUDE_REFERENCE_AS_BARCODE]: "0",
  [INCLUDE_TRACKING_BARCODE_FIELD]: false,
  [INCLUDE_CUSTOMER_CODE_FIELD]: false,
  [HOMECALL_REFERENCE]: "0",
  [USE_6_INCH_LABEL_FIELD]: false,
});

const commonUserDetailsInitialValues = () => ({
  [FORENAME_FIELD]: "",
  [LASTNAME_FIELD]: "",
  [USERNAME_FIELD]: "",
  [EMAIL_FIELD]: "",
  [TITLE_FIELD]: "Mr",
  [PASSWORD_LOCK_FIELD]: false,
  [USE_ROAD_FIELD]: 0,
});

export const getMyDpdUserDetailsInitialValues = () => ({
  ...commonUserDetailsInitialValues(),
  ...labelSettingsInitialValues(),
  [USE_EMAIL_AS_LOGIN_FIELD]: false,
  [NEW_PASSWORD_FIELD]: "",
  [RE_ENTER_PASSWORD_FIELD]: "",
});

export const getUserDetailsInitialValues = () => ({
  ...commonUserDetailsInitialValues(),
  ...labelSettingsInitialValues(),
  [NEW_PASSWORD_FIELD]: DEFAULT_PASSWORD,
  [RE_ENTER_PASSWORD_FIELD]: DEFAULT_PASSWORD,
});

export const prepareAccounts = (account) => (account ? [account] : []);

export const prepareUserDetails = (
  { username, newPassword, isAdminAccess, email, ...rest },
  { account }
) => {
  const userDetails = {
    [USERNAME_FIELD]: username.toLowerCase(),
    [EMAIL_FIELD]: email.toLowerCase(),
    userRoles: [USER_ROLES.CUSTOMER_USER],
    accounts: prepareAccounts(account),
    customerAdminAccounts: [],
  };

  if (isAdminAccess) {
    userDetails.userRoles.push(USER_ROLES.CUSTOMER_ADMIN);
  }

  if (newPassword) {
    userDetails[PASSWORD_FIELD] = preparePassword(newPassword, username);
  }

  if (rest[PASSWORD_LOCK_FIELD]) {
    userDetails[PASSWORD_EXPIRES_FIELD] = false;
  } else if (newPassword) {
    userDetails[PASSWORD_EXPIRES_FIELD] = true;
  }

  return Object.entries(rest).reduce((acc, [key, value]) => {
    switch (key) {
      case ACCOUNT_EXPIRY_DATE_FIELD:
        acc[key] = value || null;
        break;
      case PASSWORD_LOCK_FIELD:
      case USE_ROAD_FIELD:
      case USE_6_INCH_LABEL_FIELD:
      case INCLUDE_TRACKING_BARCODE_FIELD:
      case INCLUDE_CUSTOMER_CODE_FIELD:
        acc[key] = +value;
        break;
      default:
        acc[key] = value;
    }

    return acc;
  }, userDetails);
};
