import colors from "./colors";
import dpdLogoLocal from "../assets/icons/dpd-logo-local.svg";
import dpdLogo from "../assets/icons/dpd-logo.svg";
import dpdSpinner from "../assets/images/dpd-logo.gif";
import dpdLocalSpinner from "../assets/images/dpdLocal-logo.gif";
import { Pluto } from "../assets/stylesheets/modules/fonts.scss";

export const globalThemeProps = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 756,
      lg: 1024,
      xl: 1200,
    },
  },
  typography: {
    button: {
      fontFamily: Pluto,
      fontWeight: "lighter",
    },
  },
  props: {
    MuiButton: {
      variant: "contained",
      color: "primary",
    },
    MuiTextField: {
      variant: "outlined",
    },
  },
};

export const redThemeProps = {
  images: {
    loader: dpdSpinner,
    logo: dpdLogo,
  },
  palette: {
    primary: {
      // main: colors.uiPrimary,
      main: "#d43331",
      dark: colors.uiPrimaryDark,
      light: colors.uiPrimaryLight,
      background: colors.buttonGradient,
      gradientColors: ["#b00738", "#c00538", "#d80237", "#e10137"],
    },
    secondary: {
      // main: colors.uiSecondary,
      main: "#bebebe",
      dark: colors.uiSecondaryDark,
      light: colors.uiSecondaryLight,
      background: colors.buttonGradient,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        background: colors.buttonGradient,
      },
      containedSecondary: {
        background: colors.buttonSecondaryGradient,
        color: colors.white,
      },
    },
    MuiFab: {
      root: {
        "&.Mui-disabled": {
          backgroundColor: colors.uiPrimary,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        "&.Mui-expanded": {
          background: colors.buttonGradient,
        },
      },
    },
    MuiList: {
      root: {
        "li:hover": {
          background: colors.uiPrimary,
        },
      },
    },
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: colors.uiPrimaryLight,
        },
        "&.Filter_active": {
          borderColor: colors.uiPrimary,
        },
      },
      button: {
        "&.menu-item&:hover": {
          background: colors.buttonGradient,
        },
        "&:hover": {
          backgroundColor: colors.uiPrimaryLight,
        },
        "&.selected": {
          background: colors.buttonGradient,
        },
      },
    },
    MuiTableHead: {
      root: {
        background: colors.buttonGradient,
      },
    },
  },
  favicon: "dpdFavicon.ico",
};

export const blueThemeProps = {
  images: {
    loader: dpdLocalSpinner,
    logo: dpdLogoLocal,
  },
  palette: {
    primary: {
      main: colors.uiLocalPrimary,
      dark: colors.uiLocalPrimaryDark,
      light: colors.uiLocalPrimaryLight,
      background: colors.buttonLocalGradient,
      gradientColors: ["#0067aa", "#0082c1", "#009ad5", "#00a3dd"],
    },
    secondary: {
      main: colors.uiSecondary,
      dark: colors.uiSecondaryDark,
      light: colors.uiLocalSecondaryLight,
      background: colors.buttonSecondaryGradient,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        background: colors.buttonLocalGradient,
      },
      containedSecondary: {
        background: colors.buttonSecondaryGradient,
        color: colors.white,
      },
    },
    MuiFab: {
      root: {
        "&.Mui-disabled": {
          backgroundColor: colors.uiLocalPrimary,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        "&.Mui-expanded": {
          background: colors.buttonLocalGradient,
        },
      },
    },
    MuiList: {
      root: {
        "li:hover": {
          background: colors.uiLocalPrimary,
        },
      },
    },
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: colors.uiLocalPrimaryLight,
        },
        "&.Filter_active": {
          borderColor: colors.uiLocalPrimary,
        },
      },
      button: {
        "&.menu-item&:hover": {
          background: colors.buttonLocalGradient,
        },
        "&:hover": {
          backgroundColor: colors.uiLocalPrimaryLight,
        },
        "&.selected": {
          background: colors.buttonLocalGradient,
        },
      },
    },
    MuiTableHead: {
      root: {
        background: colors.buttonLocalGradient,
      },
    },
  },
  favicon: "localFavicon.svg",
};
