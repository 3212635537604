import { Component } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme, InputAdornment, withTheme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import get from "lodash/get";
import PropTypes from "prop-types";

import styles from "./DatePicker.module.scss";
import { ReactComponent as Clear } from "../../assets/icons/clear.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/expiry-date.svg";
import colors from "../../constants/colors";

class CustomDatePicker extends Component {
  state = { isOpen: false };

  getTheme = () => {
    const primaryColor = get(this.props, "theme.palette.primary.main");

    return createMuiTheme({
      overrides: {
        Mui: {
          "&:disabled": {
            cursorPointer: "none",
          },
        },
        MuiPopover: {
          paper: {
            overflowX: "auto",
          },
        },
        MuiPickersInlineWrapper: {
          popoverPaper: {
            width: 255,
            paddingBottom: 0,
          },
        },
        MuiSvgIcon: {
          root: {
            height: 20,
            width: 20,
          },
        },
        MuiTypography: {
          body1: {
            fontSize: 13,
            fontWeight: "bold",
            color: primaryColor,
          },
          caption: {
            fontSize: 12,
            fontWeight: "bold",
          },
          body2: {
            color: colors.black,
            fontSize: 12,
            fontWeight: 500,
          },
        },
        MuiPickersBasePicker: {
          pickerView: {
            maxWidth: 255,
            minWidth: 255,
            minHeight: 208,
          },
        },
        MuiPickersCalendar: {
          transitionContainer: {
            marginTop: 0,
            minHeight: 172,
          },
        },
        MuiPickersCalendarHeader: {
          dayLabel: {
            width: 23,
            marginRight: 7,
            fontSize: 9,
            color: colors.paginationDisabled,
            textTransform: "uppercase",
          },
          transitionContainer: {
            height: 20,
          },
          switchHeader: {
            marginTop: 13,
            marginBottom: 13,
            marginRight: 20,
            marginLeft: 20,
          },
          iconButton: {
            padding: 0,
          },
        },
        MuiPickersDay: {
          day: {
            width: 23,
            height: 23,
            marginRight: 6,
            marginTop: 5,
          },
          daySelected: {
            backgroundColor: primaryColor,
            "&:hover": {
              backgroundColor: primaryColor,
            },
          },
          dayDisabled: {
            color: colors.uiSecondary,
          },
          current: { color: primaryColor },
        },
      },
    });
  };

  openPicker = () => {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
    this.setState({ isOpen: true });
  };

  closePicker = () => this.setState({ isOpen: false });

  onChange = (date) => {
    const onChange =
      get(this.props.input, "onChange") || get(this.props, "onChange");
    onChange && onChange(date);
    this.closePicker();
  };

  resetDate = () => this.onChange(null);

  render() {
    const {
      input: { value, ...inputProps },
      disabled,
      labelFunc,
      emptyLabel,
      shouldDisableDate,
      ...otherProps
    } = this.props;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={this.getTheme()}>
          <div className={styles.wrapper}>
            <DatePicker
              open={this.state.isOpen}
              {...inputProps}
              {...otherProps}
              disableToolbar
              shouldDisableDate={shouldDisableDate}
              variant="inline"
              value={value || null}
              inputVariant="standard"
              onChange={this.onChange}
              onOpen={this.openPicker}
              onClose={this.closePicker}
              emptyLabel={emptyLabel}
              labelFunc={labelFunc}
              maxDateMessage={<span />}
              minDateMessage={<span />}
              invalidDateMessage={<span />}
              className={value ? styles.selectedDate : ""}
              InputProps={{
                classes: { root: styles.button, input: styles.input },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    classes={{ positionStart: styles.icon }}
                  >
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
              disabled={disabled}
              PopoverProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              }}
            />

            {value && (
              <div className={styles.controls}>
                <IconButton
                  onClick={this.openPicker}
                  className={styles["date-button"]}
                >
                  <Edit />
                </IconButton>

                <IconButton
                  onClick={this.resetDate}
                  className={styles["date-button"]}
                >
                  <Clear />
                </IconButton>
              </div>
            )}
          </div>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

CustomDatePicker.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  labelFunc: PropTypes.func,
  onOpen: PropTypes.func,
  emptyLabel: PropTypes.string,
  shouldDisableDate: PropTypes.func,
};

CustomDatePicker.defaultProps = {
  emptyLabel: "",
  shouldDisableDate: null,
  labelFunc: null,
  input: {},
};

export default withTheme(CustomDatePicker);
