import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { MenuItem } from "@material-ui/core";
import { Field } from "redux-form";

import {
  STATUS,
  NO_APP_VERSIONS_AVAILABLE,
} from "../../../../../../constants/strings";
import SelectItem from "../../../../../../components/SelectItem/SelectItem";
import {
  StyledHeaderCell,
  StyledTableCell,
} from "../../../../../../components/Table/Table";
import SelectWithTitle from "../../../../../../components/SelectWithTitle/SelectWithTitle";
import { STATUSES } from "../../../../../../features/appManagement/appManagement.constants";
import { formatDateTime } from "../../../../../../features/appManagement/appManagement.models";
import styles from "./AppVersionTable.module.scss";

const COLUMNS = [
  {
    label: "VERSION",
    key: "version",
  },
  {
    label: "STATUS",
    key: "status",
  },
  {
    label: "CREATED AT",
    key: "createdAt",
  },
  {
    label: "USED TOTAL",
    key: "usedTotal",
  },
  {
    label: "EXPECTED TOTAL",
    key: "expectedTotal",
  },
];

const renderSelectItems = (mainField) =>
  STATUSES.map((item) => (
    <MenuItem key={`${mainField}_${item}`} value={item}>
      <SelectItem title={item} containerStyle={styles["select-item"]} />
    </MenuItem>
  ));

const renderHeaderRows = () =>
  COLUMNS.map((column) => (
    <StyledHeaderCell
      key={column.key}
      className={styles[`field_${column.key}`]}
    >
      {column.label}
    </StyledHeaderCell>
  ));

const renderRows = ({ fields }) => {
  return fields.map((fieldName, index) => {
    const data = fields.get(index);

    return (
      <TableRow key={fieldName}>
        <StyledTableCell
          key={`${data.version}_${fieldName}.version`}
          cellStyles={styles.field_version}
        >
          {data.version}
        </StyledTableCell>
        <StyledTableCell
          key={`${data.version}_${fieldName}.status`}
          cellStyles={styles.field_status}
        >
          <Field
            component={SelectWithTitle}
            name={`${fieldName}.status`}
            label={STATUS}
          >
            {renderSelectItems(`${fieldName}.status`)}
          </Field>
        </StyledTableCell>
        <StyledTableCell
          key={`${data.version}_${fieldName}.createdAt`}
          cellStyles={styles.field_createdAt}
        >
          {formatDateTime(data.createdAt)}
        </StyledTableCell>
        <StyledTableCell
          key={`${data.version}_${fieldName}.usedTotal`}
          cellStyles={styles.field_usedTotal}
        >
          {data.usedTotal}
        </StyledTableCell>
        <StyledTableCell
          key={`${data.version}_${fieldName}.expectedTotal`}
          cellStyles={styles.field_expectedTotal}
        >
          {data.expectedTotal}
        </StyledTableCell>
      </TableRow>
    );
  });
};

const renderNoValue = ({ fields }) =>
  !fields.length && (
    <TableRow>
      <StyledTableCell colSpan={3}>{NO_APP_VERSIONS_AVAILABLE}</StyledTableCell>
    </TableRow>
  );

const AppVersionTable = ({ fields }) => {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>{renderHeaderRows()}</TableRow>
      </TableHead>
      <TableBody>
        {renderRows({ fields })}
        {renderNoValue({ fields })}
      </TableBody>
    </Table>
  );
};

export default AppVersionTable;
