import FetchClient from "@dpdgroupuk/fetch-client";

import { getAppDomain } from "../features/app/app.modes";
import { refreshToken } from "../features/auth/auth.service";
import { LOGIN_PAGE } from "../router/constants";
import { navigateTo } from "../router/navigation";

const baseUrl = getAppDomain() || "http://localhost:8082";

const headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: -1,
};

export const api = new FetchClient({
  credentials: "include",
  baseUrl,
  headers,
});

export const webApi = new FetchClient({
  credentials: "include",
  baseUrl: `${baseUrl}/web`,
  headers,
  async onRetryError(retry, err) {
    if (err.statusCode === 401) {
      return refreshToken()
        .then(() => retry())
        .catch((e) => {
          if (!window.location.href.includes(LOGIN_PAGE)) {
            navigateTo(LOGIN_PAGE);
          }
          throw e;
        });
    }
    throw err;
  },
});

export default api;
