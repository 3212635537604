import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import styles from "./AddIpRangeSection.module.scss";
import InputWithTopTitle from "./components/InputWithTopTitle";
import SimulateTabTitle from "../../../../components/CustomTabs/SimulateTabTitle";
import StyledSearchIcon from "../../../../components/StyledSearchIcon/StyledButtonIcon";
import {
  END_IP,
  IP_RANGE,
  IP_RANGE_NAME,
  START_IP,
} from "../../../../constants/strings";
import {
  END_IP_FIELD,
  IP_RANGE_FIELD,
  IP_RANGE_NAME_FIELD,
  START_IP_FIELD,
} from "../../../../features/teamSettings/teamSettings.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip/withErrorTooltip.hoc";
import { withoutSpaces } from "../../../../utils/normalize";

const IpRangeComponent = ({ input, onBlurFields }) => (
  <Box className={styles.rangeContainer} {...input}>
    <div className={styles.inputContainer}>
      <Field
        topTitle={START_IP}
        name={START_IP_FIELD}
        component={DecoratedInputWithError}
        normalize={withoutSpaces}
        onBlur={onBlurFields}
      />
    </div>
    <div className={styles.hyphen} />
    <div className={styles.inputContainer}>
      <Field
        topTitle={END_IP}
        name={END_IP_FIELD}
        component={DecoratedInputWithError}
        normalize={withoutSpaces}
        onBlur={onBlurFields}
      />
    </div>
  </Box>
);

IpRangeComponent.propTypes = {
  input: PropTypes.object,
  onBlurFields: PropTypes.func,
};

const DecoratedInputWithError = withErrorTooltip(InputWithTopTitle);
const DecoratedRangeComponentWithError = withErrorTooltip(IpRangeComponent);

const AddIpRangeSection = ({
  addIpRestriction,
  handleSubmit,
  isAddButtonDisabled,
  onBlurFields,
}) => (
  <div className={styles.container}>
    <SimulateTabTitle title={IP_RANGE} />

    <Field
      name={IP_RANGE_FIELD}
      component={DecoratedRangeComponentWithError}
      onBlurFields={onBlurFields}
    />

    <Box className={styles.nameContainer}>
      <div className={styles.nameInput}>
        <Field
          topTitle={IP_RANGE_NAME}
          name={IP_RANGE_NAME_FIELD}
          component={DecoratedInputWithError}
          inputProps={{ maxLength: 50 }}
          onBlur={onBlurFields}
        />
      </div>
      <div className={styles.button}>
        <StyledSearchIcon
          onClick={handleSubmit(addIpRestriction)}
          disabled={isAddButtonDisabled}
        />
      </div>
    </Box>
  </div>
);

export default AddIpRangeSection;

AddIpRangeSection.propTypes = {
  addIpRestriction: PropTypes.func,
  handleSubmit: PropTypes.func,
  isAddButtonDisabled: PropTypes.bool,
  onBlurFields: PropTypes.func,
};
