import { API_ENDPOINTS } from "../../constants/common";
import { webApi } from "../../utils/api";

export const getUserByUsernameAndAccount = ({
  account = "",
  username = "",
  ...extraParams
}) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.USER}/findUsers`,
      query: {
        ...extraParams,
        account: encodeURIComponent(account),
        username: encodeURIComponent(username),
      },
    })
    .then((res) => res.data);

export const isUsernameUniq = (username) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.USER}/username/${encodeURIComponent(username)}`,
    })
    .then((res) => res.data);

export const validateLink = (link) =>
  webApi
    .post({
      path: `${API_ENDPOINTS.USER}/apigee/validateLink`,
      body: {
        link,
      },
    })
    .then((res) => res.data);

export const getGeneratedUsername = (account) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.USER}/usernames/${account}`,
    })
    .then((res) => res.data);

export const deleteTempUser = (uid) =>
  webApi
    .delete({
      path: `${API_ENDPOINTS.USER}/temp/${uid}`,
    })
    .then((res) => res.data);

export const fetchAccountUsers = ({ accountNumber, ...extraParams }) =>
  webApi
    .get({
      path: API_ENDPOINTS.USER,
      query: {
        ...extraParams,
        account: accountNumber,
      },
    })
    .then((res) => res.data);

export const fetchUser = (uid) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.USER}/${uid}`,
    })
    .then((res) => res.data);

export const createUser = (body) =>
  webApi
    .post({
      path: `${API_ENDPOINTS.USER}/temp/create`,
      body,
    })
    .then((res) => res.data);

export const createMasterUser = (body) =>
  webApi
    .post({
      path: `${API_ENDPOINTS.USER}/masterUser/create`,
      body,
    })
    .then((res) => res.data);

export const multipleUsersUpdate = (uids, values) =>
  webApi
    .put({
      path: `${API_ENDPOINTS.USER}/bulkUpdate`,
      body: {
        uids,
        values,
      },
    })
    .then((res) => res.data);

export const multipleTempUsersDelete = (body) =>
  webApi
    .post({
      path: `${API_ENDPOINTS.USER}/temp/bulkDelete`,
      body,
    })
    .then((res) => res.data);

export const customerUserUpdate = (uid, body) =>
  webApi
    .put({
      path: `${API_ENDPOINTS.USER}/${uid}`,
      body,
    })
    .then(({ data }) => data);

export const userFirstLogin = (uid) =>
  webApi
    .put({
      path: `${API_ENDPOINTS.USER}/firstLogin/${uid}`,
    })
    .then(({ data }) => data);

export const deleteUser = (uid, body) =>
  webApi
    .delete({
      path: `${API_ENDPOINTS.USER}/${uid}`,
      body,
    })
    .then(({ data }) => data);

export const suspendUser = (uid, body) =>
  webApi
    .post({
      path: `${API_ENDPOINTS.USER}/suspend/${uid}`,
      body,
    })
    .then(({ data }) => data);

export const fetchCurrentUser = () =>
  webApi.get(`${API_ENDPOINTS.USER}/me`).then(({ data }) => data);

export const resendEmail = (uid) =>
  webApi
    .get(`${API_ENDPOINTS.USER}/resendEmail/${uid}`)
    .then(({ data }) => data);

export const requestResetPassword = (uid) =>
  webApi
    .get(`${API_ENDPOINTS.USER}/resetPassword/${uid}`)
    .then(({ data }) => data);

export const fetchApiUsersKeys = () =>
  webApi
    .get({
      path: `${API_ENDPOINTS.USER}/apigee/keys`,
    })
    .then(({ data }) => data);

export const verifyApiUserEmail = (body) =>
  webApi
    .post({
      path: `${API_ENDPOINTS.USER}/apigee/verify`,
      body,
    })
    .then(({ data }) => data);

export const createApiUserKey = (body) =>
  webApi
    .post({
      path: `${API_ENDPOINTS.USER}/apigee/keys`,
      body,
    })
    .then(({ data }) => data);

export const deleteApiUserKey = (system, key) =>
  webApi
    .delete({
      path: `${API_ENDPOINTS.USER}/apigee/keys/${system}/${key}`,
    })
    .then(({ data }) => data);

export const deleteApiUser = () =>
  webApi
    .delete({
      path: `${API_ENDPOINTS.USER}/apigee/user`,
    })
    .then(({ data }) => data);
