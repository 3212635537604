import { getFormSyncErrors, isDirty } from "redux-form";
import { createSelector } from "reselect";

import { getCustomerValidationModalError } from "./accountDetails.models";
import { USER_FORMS } from "../../../constants/forms";
import { ENSURE_ALL_FIELDS_POPULATED } from "../../../constants/strings";
import { getUserCustomerAccounts } from "../../customer/customer.selectors";

export const getModalError = createSelector(
  getFormSyncErrors(USER_FORMS.ACCOUNT_DETAILS),
  getCustomerValidationModalError
);

export const getModalErrorMessage = createSelector(
  isDirty(USER_FORMS.ACCOUNT_DETAILS),
  getModalError,
  (dirty, error) => {
    if (error) {
      return !dirty ? ENSURE_ALL_FIELDS_POPULATED : error;
    }
    return "";
  }
);

export const getCurrentAccountData = (account) =>
  createSelector(getUserCustomerAccounts, (accounts) => accounts[account]);
