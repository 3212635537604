export const onlyNumbers = (value) => value && value.replace(/[^\d]/g, "");

export const withoutSpaces = (value) => value && value.trim();

export const uppercase = (value) => value && value.toUpperCase();

export const notMathChar = (value) => value && value.replace(/[/+ || /-]/g, "");

export const noSpecialCharacters = (value) => value.replace(/[^\w]/gi, "");

export const nonEditable = (value, previousValue) => previousValue;
