import get from "lodash/get";
import { getFormValues, isValid } from "redux-form";
import { createSelector } from "reselect";

import { ADD_KEY_FORM, USER_DETAILS_FORMS } from "../../../constants/forms";
import {
  API_SETTING_FIELDS,
  EMAIL,
  ENTER_EMAIL,
  RESEND_$,
  VERIFIED_$,
  VERIFY_$,
} from "./apiSetting.constants";

const emptyArray = [];

export const getApiSettingFormValues = getFormValues(
  USER_DETAILS_FORMS.API_SETTING
);

export const getAddKeyFormValues = getFormValues(ADD_KEY_FORM);

export const shouldShowApiSettings = createSelector(
  getApiSettingFormValues,
  (formValues) => get(formValues, API_SETTING_FIELDS.API_ACCESS, false)
);

export const shouldDisableModalConfirmButton = (form) =>
  createSelector(isValid(form), (isFormValid) => !isFormValid);

export const getUserForm = (state) => state.userForm;

export const getApiUsersKeys = createSelector(
  getUserForm,
  ({ apiUsersKeys }) => apiUsersKeys
);
export const getSandboxKeys = createSelector(
  getApiUsersKeys,
  (apiUsersKeys) => get(apiUsersKeys, "sandboxKeys.keys") || emptyArray
);
export const getLiveKeys = createSelector(
  getApiUsersKeys,
  (apiUsersKeys) => get(apiUsersKeys, "liveKeys.keys") || emptyArray
);

export const getVerifyEmailsData = createSelector(
  getApiUsersKeys,
  shouldShowApiSettings,
  (apiUsersKeys, showApiSettings) => {
    const title = get(apiUsersKeys, "verified", false)
      ? VERIFIED_$(EMAIL)
      : ENTER_EMAIL;
    const buttonName = get(apiUsersKeys, API_SETTING_FIELDS.EMAIL, "")
      ? RESEND_$(EMAIL)
      : VERIFY_$(EMAIL);
    const showKeys = showApiSettings && get(apiUsersKeys, "verified", false);

    return { title, buttonName, showKeys };
  }
);

export const isEmailSent = createSelector(
  getUserForm,
  ({ isEmailSent }) => isEmailSent
);
