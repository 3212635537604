import { Box } from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "./PageContainer.module.scss";

export const PageContainer = ({ children }) => (
  <Box className={styles.container}>{children}</Box>
);

export default PageContainer;

PageContainer.propTypes = {
  children: PropTypes.node,
};
