import PropTypes from "prop-types";

import SimulateTabTitle from "../../../../components/CustomTabs/SimulateTabTitle";
import { SENDER_ADDRESS } from "../../../../constants/strings";
import ProfileSenderAddress from "./ProfileSenderAddress";

const ProfileAddress = ({ activeTabIndex }) => (
  <>
    <SimulateTabTitle title={SENDER_ADDRESS} activeTabIndex={activeTabIndex} />
    <ProfileSenderAddress activeTabIndex={activeTabIndex} />
  </>
);

ProfileAddress.propTypes = {
  activeTabIndex: PropTypes.number,
};

export default ProfileAddress;
