import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { USER_DETAILS_FORMS } from "../../constants/forms";
import {
  USE_ROAD_SERVICE,
  USE_ROAD_SERVICE_INFO,
} from "../../constants/strings";
import { USE_ROAD_FIELD } from "../../features/userDetailsForm/userDetails/userDetails.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

const InternationalShipments = ({ onInputChange, onInfoMouseOver }) => (
  <Field
    name={USE_ROAD_FIELD}
    component={CustomSwitchWithTitle}
    type="checkbox"
    onInfoMouseOver={onInfoMouseOver}
    label={USE_ROAD_SERVICE}
    infoLabel={USE_ROAD_SERVICE_INFO}
    onChange={(event, value) => {
      onInputChange(USE_ROAD_FIELD, value);
    }}
  />
);

InternationalShipments.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onInfoMouseOver: PropTypes.func.isRequired,
};

export default reduxForm({
  form: USER_DETAILS_FORMS.DPD_USER_DETAILS,
})(InternationalShipments);
