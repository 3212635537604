import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { NODE_ENV } from "../constants/common";
import { rootReducer } from "../features/app/app.reducers";
import {
  errorHandlerMiddleware,
  showAccessDeniedModalMiddleware,
} from "../utils/middleware";
import { sentryMiddleware } from "../utils/sentry";

const middlewares = [
  thunk,
  errorHandlerMiddleware,
  showAccessDeniedModalMiddleware,
  sentryMiddleware,
];

if (process.env.NODE_ENV !== NODE_ENV.PRODUCTION) {
  middlewares.push(require("redux-logger").logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
