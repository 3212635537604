import { SUSPENDED_DAYS_30 } from "../../constants/strings";
import {
  checkIpBelongToIpRange,
  checkStartIpComesBeforeEndIp,
} from "../../utils/ip";
import {
  AUTO_SUSPEND_ACCOUNTS_FIELD,
  END_IP_FIELD,
  IP_ADDRESS_FIELD,
  IP_ADDRESS_NAME_FIELD,
  IP_RANGE_NAME_FIELD,
  PERMITTED_IP_FIELD,
  START_IP_FIELD,
  SUSPEND_USER_ACCOUNT_DAYS_FIELD,
} from "./teamSettings.constants";

export const addIpAddressFormInitialValues = () => ({
  [IP_ADDRESS_FIELD]: "",
  [IP_ADDRESS_NAME_FIELD]: "",
  [START_IP_FIELD]: "",
  [END_IP_FIELD]: "",
  [IP_RANGE_NAME_FIELD]: "",
});

export const teamSettingsFormInitialValues = () => ({
  [AUTO_SUSPEND_ACCOUNTS_FIELD]: false,
  [SUSPEND_USER_ACCOUNT_DAYS_FIELD]: SUSPENDED_DAYS_30,
  [PERMITTED_IP_FIELD]: [],
});

export const formatIpAddress = (ipAddress, name) =>
  name ? `${ipAddress[0]}  -  ${name}` : `${ipAddress[0]}`;

export const formatIpRange = (ipAddress, name) => {
  const ipRangeString = `${ipAddress[0]} - ${ipAddress[1]}`;
  return (name && `${ipRangeString} - ${name}`) || ipRangeString;
};

export const formatIpRestriction = ({ ipAddress, name = "" }) =>
  ipAddress.length > 1 && ipAddress[1]
    ? formatIpRange(ipAddress, name)
    : formatIpAddress(ipAddress, name);

export const prepareIpAddress = (ipAddress, name = "") => ({
  ipAddress: [ipAddress, null],
  name,
});

export const prepareIpRange = (
  rangeStart,
  rangeEnd = null,
  rangeName = ""
) => ({
  ipAddress: [rangeStart, rangeEnd],
  name: rangeName,
});

export const prepareTeamSettingsData = ({
  permittedIp,
  autoSuspendUserAccount,
  autoSuspendAccounts,
}) => {
  if (!autoSuspendAccounts) {
    return { permittedIp, autoSuspendUserAccount: "" };
  }
  return {
    permittedIp: permittedIp.map(formatPermittedIp),
    autoSuspendUserAccount,
  };
};

const formatPermittedIp = (ip) => ({
  ...ip,
  ipAddress: [ip.ipAddress[0], ip.ipAddress[1] || null],
});

export const checkIpAlreadyExistInIpRange = (permittedIp, ip) => {
  const ipRanges = permittedIp.filter(
    (item) => item.ipAddress.length > 1 && item.ipAddress[1]
  );

  return ipRanges.some(({ ipAddress }) =>
    checkIpBelongToIpRange(ipAddress[0], ipAddress[1], ip)
  );
};

export const checkIpAlreadyExist = (permittedIp, ipAddress) =>
  permittedIp.find((data) => data.ipAddress[0] === ipAddress);

export const checkIsIpAddressUnique = (permittedIp, ipAddress) => {
  const ip = ipAddress[0];
  const isSingleIpExist = checkIpAlreadyExist(permittedIp, ip);
  const isIpExistInRange = checkIpAlreadyExistInIpRange(permittedIp, ip);
  return isSingleIpExist || isIpExistInRange;
};

export const checkIpRangeValid = (rangeStart, rangeEnd) =>
  checkStartIpComesBeforeEndIp(rangeStart, rangeEnd);
