import {
  SEARCH_PARAM_FIELD,
  SEARCH_PARAMS,
  SEARCH_VALUE_FIELD,
} from "./search.constants";
import { ACCOUNT_REGEX } from "../../constants/regex";
import { PLEASE_CHECK_YOUR_INPUT } from "../../constants/strings";

const ACCOUNTS = {
  DPD_LOCAL: 7,
  DPD: 6,
  ADMIN: 1,
};

const possibleAccountsLength = [
  ACCOUNTS.DPD,
  ACCOUNTS.DPD_LOCAL,
  ACCOUNTS.ADMIN,
];

export const validate = (values) => {
  const errors = {};

  if (
    values[SEARCH_PARAM_FIELD] === SEARCH_PARAMS.ACCOUNT &&
    values[SEARCH_VALUE_FIELD] &&
    !possibleAccountsLength.includes(values[SEARCH_VALUE_FIELD].length)
  ) {
    errors[SEARCH_VALUE_FIELD] = PLEASE_CHECK_YOUR_INPUT;
  } else if (
    values[SEARCH_PARAM_FIELD] === SEARCH_PARAMS.ACCOUNT &&
    values[SEARCH_VALUE_FIELD] &&
    isNaN(parseInt(values[SEARCH_VALUE_FIELD]))
  ) {
    errors[SEARCH_VALUE_FIELD] = PLEASE_CHECK_YOUR_INPUT;
  } else if (
    values[SEARCH_PARAM_FIELD] === SEARCH_PARAMS.ACCOUNTS &&
    values[SEARCH_VALUE_FIELD] &&
    !ACCOUNT_REGEX.test(values[SEARCH_VALUE_FIELD])
  ) {
    errors[SEARCH_VALUE_FIELD] = PLEASE_CHECK_YOUR_INPUT;
  }
  return errors;
};
