import { ACTIONS } from "./customer.actions";

export const initialState = {
  userCustomerAccounts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_USER_CUSTOMER_ACCOUNTS.SUCCESS:
      return {
        ...state,
        userCustomerAccounts: action.payload,
      };
    case ACTIONS.FETCH_CUSTOMER_PERMITTED_IP.SUCCESS:
      return {
        ...state,
        userCustomerAccounts: {
          ...state.userCustomerAccounts,
          [action.payload.account]: {
            ...state.userCustomerAccounts[action.payload.account],
            permittedIp: action.payload.permittedIp,
          },
        },
      };
    default:
      return state;
  }
};
