import isEmpty from "lodash/isEmpty";

import { formatMessage } from "../../../utils/string";
import { FIELD_CANT_BE_EMPTY } from "../../auth/auth.constants";

export const validate = ({ allocated = [] }) => {
  const errors = {};

  if (isEmpty(allocated)) {
    errors.allocated = formatMessage(FIELD_CANT_BE_EMPTY, "allocated");
  }

  return errors;
};
