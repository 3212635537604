export const ENABLE_API_ACCESS = "Enable API Access";
export const EMAIL = "Email";
export const SANDBOX = "Sandbox";
export const LIVE = "Live";
export const ADD_NEW_KEY = "add new key";
export const DELETE_KEY = "delete key";
export const DISABLE_API_ACCESS = "disable api access";
export const SECRET = "Secret";
export const KEY_$ = (number) => `Key ${number}`;
export const VERIFY_$ = (what) => `Verify ${what}`;
export const RESEND_$ = (what) => `Resend ${what}`;
export const VERIFIED_$ = (what) => `Verified ${what}`;
export const ENTER_EMAIL =
  "Please enter the user’s email address below to send verification email.";
export const ENTER_VALID_DISABLE = "Please enter DISABLE API ACCESS";
export const ENTER_VALID_DELETE = "Please enter DELETE";
export const DELETE = "Delete";
export const INVALID_EMAIL = "Invalid email address";

export const API_SETTING_FIELDS = {
  API_ACCESS: "apiAccess",
  EMAIL: "email",
  FIRST_SANDBOX_KEY: "firstSandboxKey",
  SECOND_SANDBOX_KEY: "secondSandboxKey",
  FIRST_LIVE_KEY: "firstLiveKey",
  SECOND_LIVE_KEY: "secondLiveKey",
  DELETE_KEY: "deleteKey",
  KEY: "key",
  SECRET: "secret",
  DISABLE: "disable",
};

export const SANDBOX_FIELDS = [
  API_SETTING_FIELDS.FIRST_SANDBOX_KEY,
  API_SETTING_FIELDS.SECOND_SANDBOX_KEY,
];
export const LIVE_FIELDS = [
  API_SETTING_FIELDS.FIRST_LIVE_KEY,
  API_SETTING_FIELDS.SECOND_LIVE_KEY,
];
export const DELETE_KEYS_SYSTEM = { SANDBOX: "SANDBOX", LIVE: "LIVE" };
export const UNABLE_TO_$ = (what) => `Unable to ${what}`;
export const EMAIL_ALREADY_VERIFIED = "Email already verified";
export const PLEASE_REFRESH_PAGE = "Please refresh page";
export const VERIFIED_EMAIL_ERROR = `${EMAIL_ALREADY_VERIFIED}. ${PLEASE_REFRESH_PAGE}.`;
export const EMAIL_SENT = `${EMAIL} sent`;
export const CANNOT_SENT = "cannot be sent";
export const EMAIL_CANNOT_SENT = `${EMAIL} can't be sent. Please try again later.`;
