import { differenceWith, get, isEqual, map } from "lodash";

export const prepareToSaveUserProfiles = (formValues) => ({
  userProfile: get(formValues, "allocated", []).map(({ code }) => code),
});

export const preparedUserProfiles = (profiles) =>
  map(profiles, ({ profileCode, profileName, dpdDomesticOutboundSlid }) => ({
    code: profileCode,
    name: profileName,
    slid: dpdDomesticOutboundSlid,
  }));

export const getDiffServices = (services, selectedServices) =>
  differenceWith(services, selectedServices, isEqual);
