import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import {
  ENABLE_BULK_REVERSE,
  ENABLE_FOUR_INCH_REVERSE,
} from "../../constants/strings";
import {
  ENABLE_BULK_REV_FIELD,
  REVERSE_FOUR_INCH_FIELD,
} from "../../features/userDetailsForm/shippingSettings/shippingSettings.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

const BulkReverseIt = ({ onInputChange }) => (
  <Grid container spacing={4}>
    <Grid item>
      <Field
        name={ENABLE_BULK_REV_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={ENABLE_BULK_REVERSE}
        onChange={(event, value) => {
          onInputChange(ENABLE_BULK_REV_FIELD, value);
        }}
      />
    </Grid>
    <Grid item>
      <Field
        name={REVERSE_FOUR_INCH_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={ENABLE_FOUR_INCH_REVERSE}
        onChange={(event, value) => {
          onInputChange(REVERSE_FOUR_INCH_FIELD, value);
        }}
      />
    </Grid>
  </Grid>
);

export default BulkReverseIt;

BulkReverseIt.defaultProps = {
  onInputChange: () => null,
};

BulkReverseIt.propTypes = {
  onInputChange: PropTypes.func,
};
