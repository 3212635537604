import get from "lodash/get";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { MASTER_PASSWORD_FIELD } from "./createMasterUser.constants";
import { CREATE_MASTER_USER_FORM } from "../../constants/forms";
import { passwordStrength } from "../../utils/commonValidation";

export const getCreateMasterUserValues = getFormValues(CREATE_MASTER_USER_FORM);

export const getPassword = createSelector(
  getCreateMasterUserValues,
  (formValues) => get(formValues, MASTER_PASSWORD_FIELD, "")
);

export const getPasswordStrengthScore = createSelector(
  getPassword,
  (password) => passwordStrength(password)
);
