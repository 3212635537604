import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";

import { ReactComponent as CheckboxIcon } from "../../assets/icons/checkbox.svg";
import { ReactComponent as CheckBoxWithTick } from "../../assets/icons/ticked-box.svg";

export const CheckBox = ({
  input,
  label,
  icon,
  checkedIcon,
  labelClasses,
  ...props
}) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={!!input.value}
        onChange={input.onChange}
        onBlur={input.onBlur}
        icon={icon}
        checkedIcon={checkedIcon}
        {...props}
      />
    }
    label={label}
    classes={{ ...labelClasses }}
  />
);

CheckBox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  icon: PropTypes.node,
  checkedIcon: PropTypes.node,
  labelClasses: PropTypes.object,
};

CheckBox.defaultProps = {
  icon: <CheckboxIcon />,
  checkedIcon: <CheckBoxWithTick />,
};
