import { Badge } from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "./MissingFieldBadge.module.scss";

export const MissingFieldBadge = ({ children, badgeStyle, ...props }) => (
  <Badge
    overlap="rectangle"
    classes={{
      root: styles.container,
      badge: `${styles.badge} ${badgeStyle}`,
    }}
    {...props}
  >
    {children}
  </Badge>
);

MissingFieldBadge.defaultProps = {
  badgeContent: "!",
};

MissingFieldBadge.propTypes = {
  children: PropTypes.node,
  badgeStyle: PropTypes.string,
};

export default MissingFieldBadge;
