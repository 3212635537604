import { get } from "lodash";

export const hasAnyRole = (checkRoles) => (user) => {
  const userRoles = get(user, "userRoles", []);

  return userRoles.some((userRole) => checkRoles.includes(userRole));
};

export const hasAllRoles = (checkRoles) => (user) => {
  const userRoles = get(user, "userRoles", []);

  return checkRoles.every((checkRole) => userRoles.includes(checkRole));
};
