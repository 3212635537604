import { PureComponent } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { TrackerProvider } from "@dpdgroupuk/react-event-tracker";

import ErrorBoundary from "./components/ErrorBoundary";
import ErrorSnackbar from "./components/ErrorSnackbar";
import Loader from "./components/Loader";
import Modal from "./components/Modal/Modal";
import SomethingWentWrong from "./components/SomethingWentWrong";
import { launchApp } from "./features/app/app.actions";
import { Routes } from "./router";
import tracker from "./utils/eventTracker";
import Sentry from "./utils/sentry";
import ThemeProvider from "./utils/ThemeProvider";

class App extends PureComponent {
  constructor(props) {
    super(props);

    this.props.launchApp();
  }

  render() {
    return (
      <ErrorBoundary component={SomethingWentWrong} sentry={Sentry}>
        <TrackerProvider tracker={tracker}>
          <ThemeProvider>
            <Modal />
            <ErrorSnackbar />
            <Routes />
            <Loader />
          </ThemeProvider>
        </TrackerProvider>
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  launchApp: PropTypes.func,
};

const mapDispatchToProps = {
  launchApp,
};

export default connect(null, mapDispatchToProps)(App);
