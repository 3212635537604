import { Box, Divider, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import DefaultsDpd from "./DefaultsDpd";
import DefaultsDpdLocal from "./DefaultsDpdLocal";
import DefaultsDpdLocalInternational from "./DefaultsDpdLocalInternational";
import DefaultsInternational from "./DefaultsInternational";
import styles from "./ProfileDefaults.module.scss";
import DpdIconLocal from "../../../../assets/icons/dpd-local.svg";
import DpdLogo from "../../../../assets/icons/dpd.svg";
import { CheckBox } from "../../../../components/CheckBox/CheckBox";
import SimpleTabs from "../../../../components/CustomTabs/CustomTabs";
import TabTitleWithIcon from "../../../../components/CustomTabs/TabTitleWithIcon";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import SvgIconComponent from "../../../../components/SvgIcon/svgIcon";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  DEFAULT_INFORMATION,
  DO_NOT_USE_MULTI_BUSINESS_UNIT,
  DPD,
  DPD_LOCAL,
  DPD_LOCAL_INTERNATIONAL,
  INTERNATIONAL,
  USE_MY_DPD_SHIPPING_DEFAULTS,
  WEIGHT,
} from "../../../../constants/strings";
import { resetDefaultsFormValues } from "../../../../features/profile/profile.actions";
import {
  DEFAULT_INFORMATION_FIELD,
  DEFAULT_WEIGHT_FIELD,
  DEFAULTS_TAB_FIELDS,
  USE_MY_DPD_ACCOUNT_SETTINGS_FIELD,
} from "../../../../features/profile/profile.constants";
import { isLocalDpdAccount } from "../../../../features/profile/profile.models";
import {
  getAccountNumber,
  getInternationalDpdValue,
  getUseMyDpdAccountSettings,
  shouldShowDefaultsDpdTabBadge,
  shouldShowDefaultsLocalDpdTabBadge,
  shouldShowInternationalTabBadge,
  shouldShowLocalInternationalTabBadge,
} from "../../../../features/profile/profile.selectors";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import { notMathChar } from "../../../../utils/normalize";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const getActiveTab = (accountNumber, internationalDpdValue) => {
  if (isLocalDpdAccount(accountNumber) || !internationalDpdValue) {
    return 2;
  }
  return 0;
};

export const getContent = (useMyDpdAccountSettings) => {
  const content = [
    <DefaultsDpd key={DPD} />,
    <DefaultsInternational key={INTERNATIONAL} />,
    <DefaultsDpdLocal key={DPD_LOCAL} />,
    <DefaultsDpdLocalInternational key={DPD_LOCAL_INTERNATIONAL} />,
  ];
  if (useMyDpdAccountSettings) {
    return [];
  }
  return content;
};

const ProfileDefaults = ({
  onTextEntry,
  onChangeCheckbox,
  useMyDpdAccountSettings,
  internationalDpdValue,
  getDefaultsTabs,
  resetDefaultsTab,
  accountNumber,
}) => (
  <>
    <Grid container alignItems="center" className={styles.spacing}>
      <Field
        name={USE_MY_DPD_ACCOUNT_SETTINGS_FIELD}
        component={CheckBox}
        label={USE_MY_DPD_SHIPPING_DEFAULTS}
        labelClasses={{ root: styles.checkbox, label: styles.label }}
        onChange={(event, checked) => {
          checked && resetDefaultsTab(DEFAULTS_TAB_FIELDS);
          onChangeCheckbox(checked);
        }}
      />
      <Typography className={styles.warning}>
        {DO_NOT_USE_MULTI_BUSINESS_UNIT}
      </Typography>
    </Grid>
    <Grid container className={styles.spacing}>
      <Grid item className={styles.weight}>
        <Field
          name={DEFAULT_WEIGHT_FIELD}
          component={DecoratedFieldWithError}
          label={WEIGHT}
          disabled={!!useMyDpdAccountSettings}
          icon={<SvgIconComponent name="weight" />}
          onBlur={(e, value) => {
            onTextEntry(DEFAULT_WEIGHT_FIELD, value);
          }}
          inputProps={{ maxLength: 6 }}
          normalize={notMathChar}
        />
      </Grid>
    </Grid>
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.default}
    >
      <Typography className={styles["default-text"]}>
        {DEFAULT_INFORMATION}
      </Typography>
      <Grid item className={styles["default-information"]}>
        <Field
          name={DEFAULT_INFORMATION_FIELD}
          component={InputWithTitle}
          multiline
          rows="3"
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(DEFAULT_INFORMATION_FIELD, value);
          }}
          disabled={!!useMyDpdAccountSettings}
        />
      </Grid>
    </Grid>
    <Divider className={styles.divider} />
    <Box className={classNames(styles["tabs-section"])}>
      <SimpleTabs
        tabs={getDefaultsTabs(internationalDpdValue)}
        disableAll={!!useMyDpdAccountSettings}
        activeTab={getActiveTab(accountNumber, internationalDpdValue)}
      >
        {getContent(useMyDpdAccountSettings, internationalDpdValue)}
      </SimpleTabs>
    </Box>
  </>
);

ProfileDefaults.propTypes = {
  onTextEntry: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  showBadgeDpdTab: PropTypes.bool,
  showBadgeInternationalTab: PropTypes.bool,
  internationalDpdValue: PropTypes.bool,
  useMyDpdAccountSettings: PropTypes.bool,
  getDefaultsTabs: PropTypes.func,
  resetDefaultsTab: PropTypes.func,
  accountNumber: PropTypes.string,
};

const mapStateToProps = (state) => ({
  useMyDpdAccountSettings: getUseMyDpdAccountSettings(state),
  internationalDpdValue: getInternationalDpdValue(state),
  showBadgeDpdTab: shouldShowDefaultsDpdTabBadge(state),
  showBadgeInternationalTab: shouldShowInternationalTabBadge(state),
  showBadgeLocalInternationalTab: shouldShowLocalInternationalTabBadge(state),
  showBadgeLocalDpdTab: shouldShowDefaultsLocalDpdTabBadge(state),
  accountNumber: getAccountNumber(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetDefaultsTab: (fieldValues) =>
    dispatch(resetDefaultsFormValues(fieldValues)),
});

export default compose(
  withTrackProps(() => ({
    onTextEntry: (field, value) =>
      createLogEntryPayload(PROFILE_ANALYTICS.TEXT_ENTRY, {
        field,
        value,
      }),
    onChangeCheckbox: () =>
      createLogEntryPayload(PROFILE_ANALYTICS.CLICK_CHECKBOX, {
        field: USE_MY_DPD_ACCOUNT_SETTINGS_FIELD,
      }),
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    getDefaultsTabs: (ownerProps) => (internationalDpdValue) =>
      [
        {
          label: <TabTitleWithIcon icon={DpdLogo} title={DPD} key={DPD} />,
          analyticsId: PROFILE_ANALYTICS.CLICK_DEFAULTS_DPD_TAB,
          withBadge: ownerProps.showBadgeDpdTab,
        },
        {
          label: INTERNATIONAL,
          disabled: !internationalDpdValue,
          analyticsId: PROFILE_ANALYTICS.CLICK_DEFAULTS_INTERNATIONAL_TAB,
          withBadge:
            ownerProps.showBadgeInternationalTab && !!internationalDpdValue,
        },
        {
          label: (
            <TabTitleWithIcon
              icon={DpdIconLocal}
              title={DPD_LOCAL}
              key={DPD_LOCAL}
            />
          ),
          analyticsId: PROFILE_ANALYTICS.CLICK_DEFAULTS_DPD_LOCAL_TAB,
          withBadge: ownerProps.showBadgeLocalDpdTab,
        },
        {
          label: DPD_LOCAL_INTERNATIONAL,
          disabled: internationalDpdValue,
          analyticsId:
            PROFILE_ANALYTICS.CLICK_DEFAULTS_DPD_LOCAL_INTERNATIONAL_TAB,
          withBadge: ownerProps.showBadgeLocalInternationalTab,
        },
      ],
  })
)(ProfileDefaults);
