import omit from "lodash/omit";
import {
  change,
  getFormValues,
  touch,
  untouch,
  updateSyncErrors,
} from "redux-form";

import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { INVALID_ACCOUNT_NUMBER } from "../../customer/customer.constants";
import { getCustomersStartsWith } from "../../customer/customer.service";
import {
  clearUsernames,
  fetchUsernames,
  onChangeGeneratedUserName,
  resetUsername,
} from "../../user/user.actions";
import { getUserBusinessId } from "../../user/user.selectors";
import { clearAdditionalAccounts } from "../additionalAccounts/additionalAccounts.actions";
import { selectDisableNotificationOnPrint } from "../advancedCustomerConfiguration/advancedCustomerConfiguration.actions";
import { enableMiscellaneousEodFields } from "../miscellaneousConfiguration/miscellaneousConfiguration.actions";
import { resetServiceXrefMapping } from "../serviceXrefMappings/serviceXrefMappings.actions";
import { selectEndOfDay } from "../shippingSettings/shippingSettings.actions";
import {
  clearUserDetailsForm,
  onAccountChange,
  setAccountToAdminAccess,
} from "../userDetailsForm.actions";
import { getAdminAccess } from "../userDetailsForm.selector";
import {
  getAccountProfiles,
  populateAccountProfiles,
  resetUserProfilesForm,
} from "../userProfiles/userProfiles.actions";
import { CUSTOMER_DETAILS_SECTION_FIELDS } from "./accountDetails.constants";
import {
  getAccountDetailsInitialValues,
  mapAddressFromLocations,
  mapCustomerData,
} from "./accountDetails.models";
import { getCustomerData } from "./accountDetails.selectors";

export const populateAccountDetails =
  (newFormValues, formName = USER_DETAILS_FORMS) =>
  (dispatch) =>
    Object.entries(newFormValues).map(([field, value]) => {
      dispatch(change(formName.ACCOUNT_DETAILS, field, value));
      return dispatch(untouch(formName.ACCOUNT_DETAILS, field));
    });

export const onPostcodeItemClick = (address) => async (dispatch) => {
  const newFormValues = mapAddressFromLocations(address);
  dispatch(populateAccountDetails(newFormValues));
};

export const resetAccountDetails =
  (fields = {}) =>
  (dispatch) => {
    const resetValues = omit(
      getAccountDetailsInitialValues(),
      Object.keys(fields)
    );

    dispatch(clearUserDetailsForm());
    dispatch(populateAccountDetails(resetValues));
  };

export const onAccountClick = (item) => async (dispatch, getState) => {
  const newFormValues = mapCustomerData(item);
  const hasAdminAccess = getAdminAccess(getState());
  dispatch(fetchUsernames(item.account));
  dispatch(onAccountChange(item));
  hasAdminAccess && dispatch(setAccountToAdminAccess(item));
  dispatch(populateAccountDetails(newFormValues));

  if (item.eodMasterUser) {
    dispatch(enableMiscellaneousEodFields());
    dispatch(selectEndOfDay());
    dispatch(selectDisableNotificationOnPrint(true));
  }

  const mappedProfiles = await dispatch(getAccountProfiles(item.account));
  dispatch(populateAccountProfiles(mappedProfiles));
};

export const onChangeBusinessId = (businessId) => (dispatch, getState) => {
  const hasAdminAccess = getAdminAccess(getState());

  hasAdminAccess && dispatch(setAccountToAdminAccess(""));
  dispatch(resetAccountDetails({ businessId }));
  dispatch(resetServiceXrefMapping());
  dispatch(clearUsernames());
  dispatch(onChangeGeneratedUserName());
  dispatch(resetUsername());
  dispatch(resetUserProfilesForm());
  dispatch(clearAdditionalAccounts());
};

export const fetchCustomerAccount = (accountNumber) => async (dispatch) => {
  const customers = await dispatch(
    getCustomersByAccountNumberBusinessId(accountNumber)
  );
  if (!customers.length) {
    dispatch(
      updateSyncErrors(USER_DETAILS_FORMS.ACCOUNT_DETAILS, {
        account: INVALID_ACCOUNT_NUMBER,
      })
    );
  }
  return customers;
};

export const getCustomersByAccountNumberBusinessId =
  (accountNumber) => async (dispatch, getState) => {
    const formValues = getFormValues(USER_DETAILS_FORMS.ACCOUNT_DETAILS)(
      getState()
    );
    const businessId =
      (formValues && formValues.businessId) || getUserBusinessId(getState());

    let customers = [];
    try {
      customers = await getCustomersStartsWith(accountNumber);
    } catch (e) {
      return customers;
    }

    return customers.filter((customer) => customer.business === businessId);
  };

export const resetCustomerDetailsSection = () => (dispatch, getState) => {
  const getOriginalCustomerDetails = getCustomerData(getState());
  const preparedOriginalCustomerData = mapCustomerData(
    getOriginalCustomerDetails
  );

  CUSTOMER_DETAILS_SECTION_FIELDS.map((fieldName) => {
    dispatch(
      change(
        USER_DETAILS_FORMS.ACCOUNT_DETAILS,
        fieldName,
        preparedOriginalCustomerData[fieldName] || ""
      )
    );
    return dispatch(
      touch(
        USER_DETAILS_FORMS.ACCOUNT_DETAILS,
        fieldName,
        preparedOriginalCustomerData[fieldName]
      )
    );
  });
};
