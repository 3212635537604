import {
  ENTER_VALID_LOGIN,
  PLEASE_ENTER_PASSWORD,
} from "../../../../features/auth/auth.constants";

export const validate = ({ email, password }) => {
  const errors = {};

  if (!email) {
    errors.email = ENTER_VALID_LOGIN;
  }

  if (!password) {
    errors.password = PLEASE_ENTER_PASSWORD;
  }
  return errors;
};
