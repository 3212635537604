import React from "react";
import BaseModal from "../BaseModal";
import {
  ABOUT_TO_DELETE_KEY,
  DELETE_KEY,
  ENTER_DELETE,
} from "../../../constants/strings";
import { Field, propTypes, reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import { API_SETTING_FIELDS } from "../../../features/userDetailsForm/apiSetting/apiSetting.constants";
import { compose } from "recompose";
import { DELETE_KEY_FORM } from "../../../constants/forms";
import Typography from "@material-ui/core/Typography";
import InputWithTitle from "../../InputWithTitle/InputWithTitle";
import WarningText from "../../WarningText/WarningText";
import styles from "./DeleteKeyModal.module.scss";
import PropTypes from "prop-types";
import { nonEditable, uppercase } from "../../../utils/normalize";
import { shouldDisableModalConfirmButton } from "../../../features/userDetailsForm/apiSetting/apiSettingSelectors";
import { deleteKeyValidator } from "../../../features/userDetailsForm/apiSetting/apiSetting.validate";
import { connect } from "react-redux";

const DeleteKeyModal = ({
  isFormValid,
  isFormDirty,
  keyLabel,
  ...otherProps
}) => {
  return (
    <BaseModal title={DELETE_KEY} {...otherProps}>
      <Grid container spacing={3} className={styles.container}>
        <Grid item xs={12}>
          <Typography className={styles.text}>{ABOUT_TO_DELETE_KEY}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            component={InputWithTitle}
            name={API_SETTING_FIELDS.KEY}
            label={keyLabel}
            normalize={nonEditable}
            onBlur={() => null} // TODO: add analytics
          />
        </Grid>
        <Grid item xs={12}>
          <WarningText text={ENTER_DELETE} />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={InputWithTitle}
            name={API_SETTING_FIELDS.DELETE_KEY}
            normalize={uppercase}
            onBlur={() => null} // TODO: add analytics
          />
        </Grid>
      </Grid>
    </BaseModal>
  );
};

DeleteKeyModal.propTypes = {
  ...propTypes,
  keyLabel: PropTypes.string,
  disabledConfirmButton: PropTypes.bool,
};

const mapStateToProps = (state, { initialValues }) => ({
  initialValues,
  disabledConfirmButton:
    shouldDisableModalConfirmButton(DELETE_KEY_FORM)(state),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: DELETE_KEY_FORM,
    validate: (values) => deleteKeyValidator(values),
  })
)(DeleteKeyModal);
