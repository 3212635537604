import get from "lodash/get";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { USER_FORMS } from "../../../constants/forms";
import { getUserCustomerAccounts } from "../../customer/customer.selectors";
import { getCurrentUser } from "../../user/user.selectors";
import { ACCOUNTS_FIELD } from "../userDetails/userDetails.constants";
import { getUserId } from "../userDetails/userDetails.selectors";
import { getCustomerAdditionalAccounts } from "../userDetailsForm.selector";

export const getCustomerAccountsInitialValues = (accountNumber) => (state) => {
  const accounts = getUserCustomerAccounts(state);
  return { accounts: accounts ? [accounts[accountNumber]] : [] };
};

export const isFirstAdditionalAccount = (accountNumber) => (state) => {
  const formValues = getCustomerAdditionalAccounts(state);
  return formValues[0] && formValues[0].account === accountNumber;
};

const getAdditionalAccountsFormValues = getFormValues(
  USER_FORMS.ADDITIONAL_ACCOUNTS
);

export const getAdditionalAccountsCount = createSelector(
  getAdditionalAccountsFormValues,
  (formValues) => get(formValues, ACCOUNTS_FIELD, []).length
);

export const isOwnUser = createSelector(
  getCurrentUser,
  getUserId,
  (currentUser, uid) => get(currentUser, "uid") === uid
);
