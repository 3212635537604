import {
  _CONNECTION_ERROR,
  _INVALID_CREDENTIALS,
  _NO_USER_ACCOUNT,
  ACCOUNT_EXPIRED,
  ACCOUNT_SUSPENDED,
  TOO_MANY_REQUESTS,
  UNABLE_TO_AUTHORISE_CONTACT_SUPPORT,
} from "./auth.constants";

export const isConnectionError = (error) =>
  error &&
  error.code &&
  ["unavailable", "auth/network-request-failed"].includes(error.code);

export const getSignInWithPasswordError = (error) => {
  if (isConnectionError(error)) {
    return _CONNECTION_ERROR;
  }

  if (
    error &&
    [
      _NO_USER_ACCOUNT,
      ACCOUNT_SUSPENDED,
      ACCOUNT_EXPIRED,
      UNABLE_TO_AUTHORISE_CONTACT_SUPPORT,
    ].includes(error.message)
  ) {
    return error.message;
  }
  if (error.statusCode === 429) {
    return TOO_MANY_REQUESTS;
  }
  return _INVALID_CREDENTIALS;
};
