import { ACTIONS } from "./snackbar.actions";

export const initialState = {
  open: false,
  props: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SHOW_SNACKBAR:
      return {
        ...state,
        open: true,
        props: action.payload,
      };
    case ACTIONS.HIDE_SNACKBAR:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
