export const ROOT = "/";

export const LOGIN_PAGE = "/login";
export const LOGIN_FIRST_PAGE = "/login/first/:id";
export const LOGIN_PROVIDER_PAGE = "/login/provider";
export const RESET_PASSWORD = "/login/reset-password";
export const SET_PASSWORD = "/login/set-password";
export const FORGOT_PASSWORD_PAGE = "/login/forgot-password";
export const INTERNAL_LINK = "/internal";
export const PAGE_404 = "/not-found";

// customer routes
export const CUSTOMER = "/customer";
export const CURRENT_CUSTOMER = "/customer/:customerId";
export const CUSTOMER_USERS_PAGE = "/customer/:customerId/users";
export const CUSTOMER_EDIT_USER_PAGE = "/customer/:customerId/users/:id";
export const CUSTOMER_VERIFY_EMAIL_PAGE =
  "/customer/:customerId/users/:id/:email";
export const CUSTOMER_CREATE_USER_PAGE = "/customer/:customerId/users/new";
export const CUSTOMER_TEAM_SETTINGS_PAGE =
  "/customer/:customerId/team-settings";
export const CUSTOMER_ACTIVITY_MONITOR =
  "/customer/:customerId/activity-monitor";

export const DPD = "/dpd";
export const DPD_CREATE_USER_PAGE = "/dpd/user/new";
export const DPD_CREATE_CUSTOMER_USER_PAGE = "/dpd/customer-user/new";
export const DPD_SEARCH_PAGE = "/dpd/search";
export const DPD_UPDATE_FOUND_USER = "/dpd/search/user/:id";
export const DPD_PROFILES_PAGE = "/dpd/profiles";
export const DPD_MIGRATIONS_PAGE = "/dpd/migrations";
export const DPD_APP = "/dpd/app/config";
export const DPD_CREATE_PROFILE = "/dpd/profiles/new";
export const DPD_UPDATE_PROFILE = "/dpd/profiles/:id";

// filters
export const ALL_USERS_FILTER = "all";
export const SUSPENDED_USERS_FILTER = "suspended";
export const INACTIVE_USERS_FILTER = "inactive";
export const INVITED_USERS_FILTER = "invited";
