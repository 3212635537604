export const MINIMUM_VERSION_FIELD = "minVersion";
export const LATEST_VERSION_FIELD = "latestVersion";
export const ALLOW_DRAFT_FIELD = "allowDraft";
export const ALLOW_PRERELEASE_FIELD = "allowPrerelease";
export const ALLOW_DEPRECATED_FIELD = "allowDeprecated";
export const DRAFT = "DRAFT";
export const PRERELEASE = "PRERELEASE";
export const RELEASE = "RELEASE";
export const DEPRECATED = "DEPRECATED";
export const STATUSES = [DRAFT, PRERELEASE, RELEASE, DEPRECATED];
export const LATEST_VERSION_FIELD_SHOULD_BE_HIGHER =
  "Latest version should be higher or the same than Min version";
export const UPDATE_CONFIG_ERROR_MESSAGE = "Cannot update DPD App config";
export const UPDATE_VERSIONS_ERROR_MESSAGE = "Cannot update DPD App versions";
