import { useEffect, useState } from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { hasSubmitFailed, isValid } from "redux-form";

import backIcon from "../../assets/icons/down-arrow.svg";
import { clearTabsState } from "../../features/tabs/tabs.actions";
import {
  COLLAPSE_ALL_STATE,
  EXPAND_ALL_STATE,
  INITIAL_STATE,
} from "../../features/tabs/tabs.constants";
import { getTabsState } from "../../features/tabs/tabs.selectors";
import MissingFieldBadge from "../MissingFieldBadge/MissingFieldBadge";
import {
  arrowDown,
  badge,
  collapsedContainer,
  content,
  details,
  expandedTab,
  expandIcon,
  header,
  panel,
  panelExpanded,
  root,
} from "./CustomExpansionPanel.module.scss";

export default function CustomExpansionPanel({
  title,
  children,
  contentPadding,
  defaultExpanded,
  onChange,
  formName,
  ...props
}) {
  const dispatch = useDispatch();
  const tabsState = useSelector(getTabsState);
  const clearTabs = () => dispatch(clearTabsState());
  const [expanded, setExpanded] = useState(defaultExpanded || false);

  const isFormSubmitFailed = useSelector(hasSubmitFailed(formName));
  const withBadge = !useSelector(isValid(formName)) && isFormSubmitFailed;

  const Summary = () => (
    <ExpansionPanelSummary
      expandIcon={<img src={backIcon} alt="arrow" className={arrowDown} />}
      classes={{ root, expanded: expandedTab, expandIcon, content }}
    >
      <div className={header}>{title}</div>
    </ExpansionPanelSummary>
  );

  const SummaryWithBadge = () => (
    <MissingFieldBadge
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      badgeStyle={badge}
    >
      <Summary />
    </MissingFieldBadge>
  );

  useEffect(() => {
    switch (tabsState) {
      case EXPAND_ALL_STATE:
        setExpanded(true);
        break;
      case COLLAPSE_ALL_STATE:
        setExpanded(false);
        break;
      case INITIAL_STATE:
        setExpanded(defaultExpanded);
    }
  }, [tabsState]);

  return (
    <ExpansionPanel
      classes={{ root: panel, expanded: panelExpanded }}
      TransitionProps={{
        classes: { container: collapsedContainer },
      }}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
        tabsState && clearTabs();
        onChange(title);
      }}
      {...props}
    >
      {withBadge ? <SummaryWithBadge /> : <Summary />}
      <ExpansionPanelDetails
        classes={{ root: details }}
        className={contentPadding}
      >
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

CustomExpansionPanel.defaultProps = {
  children: [],
  onChange: () => null,
};

CustomExpansionPanel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  containerStyles: PropTypes.any,
  onChange: PropTypes.func,
  contentPadding: PropTypes.any,
  defaultExpanded: PropTypes.bool,
  formName: PropTypes.string,
};
