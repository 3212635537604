import { Box, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { Field, reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import DpdIconLocal from "../../../../assets/icons/dpd-local.svg";
import DpdIcon from "../../../../assets/icons/dpd.svg";
import { CheckBox } from "../../../../components/CheckBox/CheckBox";
import FieldWithLabel from "../../../../components/FieldWithLabel/FieldWithLabel";
import HashIcon from "../../../../components/HashIcon/HashIcon";
import SearchFieldWithList from "../../../../components/SearchFieldWithList/SearchFieldWithList";
import SelectItem from "../../../../components/SelectItem/SelectItem";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import {
  CREATE_CUSTOMER_USER,
  CREATE_EDIT_CUSTOMER_USER_ANALYTICS,
} from "../../../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../../../constants/forms";
import {
  ACCOUNT_NUMBER,
  BUSINESS_ID,
  COMPANY,
  DPD,
  DPD_LOCAL,
  OVERRIDE_ACCOUNT_ADDRESS,
} from "../../../../constants/strings";
import { getAddressByPostcode } from "../../../../features/config/config.service";
import { formatCustomerItem } from "../../../../features/customer/customer.model";
import {
  ACCOUNT_ID_FIELD,
  BUSINESS_ID_FIELD,
  CITY_FIELD,
  COMPANY_FIELD,
  COUNTRY_FIELD,
  COUNTY_FIELD,
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
  NOTIFICATION_EMAIL_FIELD,
  NOTIFICATION_NUMBER_FIELD,
  OVERRIDE_DETAILS_FIELD,
  POSTCODE_FIELD,
  POSTCODE_FIELD_CLICK,
  STREET_FIELD,
  SUBURB_FIELD,
} from "../../../../features/userDetailsForm/accountDetails/accountDetails.constants";
import { formatItem } from "../../../../features/userDetailsForm/accountDetails/accountDetails.models";
import {
  getBusinessId,
  getOverrideDetails,
} from "../../../../features/userDetailsForm/accountDetails/accountDetails.selectors";
import { validate } from "../../../../features/userDetailsForm/accountDetails/accountDetails.validate";
import {
  fetchCustomerAccount,
  onAccountClick,
  onChangeBusinessId,
  onPostcodeItemClick,
  resetCustomerDetailsSection,
} from "../../../../features/userDetailsForm/accountDetails/actionsDetails.actions";
import { deselectEodMaster } from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.actions";
import {
  isEodMasterEnabled,
  isEodMasterSelected,
} from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.selectors";
import { getIsOverrideEnabled } from "../../../../features/userDetailsForm/userDetailsForm.selector";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import withRef from "../../../../hocs/withRef.js";
import { withoutSpaces } from "../../../../utils/normalize";
import CustomerDetails from "../CustomerDetails/CustomerDetails";

const DecoratedSearchWithError = withErrorTooltip(SearchFieldWithList);

const AccountDetails = ({
  onItemClick,
  onInputChange,
  fetchFunction,
  userId,
  onBlurFields,
  onAccountNameClick,
  uncheckOverrideAddressCheckbox,
  eodMasterSelected,
  isOverrideAccountEnabled,
  isEodMasterDisabled,
  onDeselectEodMaster,
  disabled,
  onBusinessIdChange,
}) => (
  <Box width="100%">
    <Box display="flex" flexDirection="row">
      <Box width={1 / 3} p={1}>
        <Field
          component={SelectWithTitle}
          name={BUSINESS_ID_FIELD}
          label={BUSINESS_ID}
          onChange={onBusinessIdChange}
          props={{
            disabled: !!userId,
          }}
        >
          <MenuItem value={DPD_BUSINESS_ID} className="dropdownItem">
            <SelectItem icon={DpdIcon} title={DPD} />
          </MenuItem>
          <MenuItem value={DPD_LOCAL_BUSINESS_ID} className="dropdownItem">
            <SelectItem icon={DpdIconLocal} title={DPD_LOCAL} />
          </MenuItem>
        </Field>
      </Box>
      <Box width={2 / 3} p={1} position="relative">
        <Field
          name={ACCOUNT_ID_FIELD}
          component={DecoratedSearchWithError}
          label={ACCOUNT_NUMBER}
          icon={<HashIcon />}
          required
          fetchFunction={fetchFunction}
          formatItem={formatCustomerItem}
          onBlur={() => onBlurFields(ACCOUNT_ID_FIELD)}
          onClick={(item) => {
            onAccountNameClick(item);
            if (eodMasterSelected && isEodMasterDisabled) {
              onDeselectEodMaster();
            }
          }}
          onClearClick={!userId && onBusinessIdChange}
          disabled={isOverrideAccountEnabled || !!userId}
          normalize={withoutSpaces}
        />
      </Box>
    </Box>

    <Box width={1} p={1}>
      <Field name={COMPANY_FIELD} label={COMPANY} component={FieldWithLabel} />
    </Box>
    <CustomerDetails
      fetchFunction={getAddressByPostcode}
      formatItem={formatItem}
      onItemClick={onItemClick}
      onBlurFields={onBlurFields}
      disabled={disabled}
      onInputChange={onInputChange}
    />
    <Box p={2}>
      <Field
        name={OVERRIDE_DETAILS_FIELD}
        component={CheckBox}
        onChange={(e, value) => {
          if (!value) {
            uncheckOverrideAddressCheckbox();
          }
          onInputChange(OVERRIDE_DETAILS_FIELD, value);
        }}
        label={OVERRIDE_ACCOUNT_ADDRESS}
        disabled={!isOverrideAccountEnabled}
      />
    </Box>
  </Box>
);

AccountDetails.propTypes = {
  onInputChange: PropTypes.func,
  fetchFunction: PropTypes.func,
  onItemClick: PropTypes.func,
  uncheckOverrideAddressCheckbox: PropTypes.func,
  onAccountNameClick: PropTypes.func,
  onChangeBusiness: PropTypes.func,
  onBlurFields: PropTypes.func,
  onDeselectEodMaster: PropTypes.func,
  onBusinessIdChange: PropTypes.func,
  onSelectAccountNumber: PropTypes.func,
  userId: PropTypes.string,
  isOverrideAccountEnabled: PropTypes.bool,
  isEodMasterDisabled: PropTypes.bool,
  eodMasterSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  businessId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isOverrideAccountEnabled: getIsOverrideEnabled(state),
  isEodMasterDisabled: !isEodMasterEnabled(state),
  eodMasterSelected: !!isEodMasterSelected(state),
  businessId: getBusinessId(state),
  disabled: !getOverrideDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchFunction: (accountNumber) =>
    dispatch(fetchCustomerAccount(accountNumber)),
  onItemClick: (itemDetails) => dispatch(onPostcodeItemClick(itemDetails)),
  onAccountNameClick: (item) => dispatch(onAccountClick(item)),
  uncheckOverrideAddressCheckbox: () => dispatch(resetCustomerDetailsSection()),
  onChangeBusiness: (value) => dispatch(onChangeBusinessId(value)),
  onDeselectEodMaster: () => dispatch(deselectEodMaster()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: USER_DETAILS_FORMS.ACCOUNT_DETAILS,
    validate,
  }),
  withHandlers({
    onBusinessIdChange: (ownerProps) => (e, value) => {
      if (value === ownerProps.businessId) {
        return;
      }
      ownerProps.onChangeBusiness(value);
    },
  }),
  withTrackProps(() => ({
    onBusinessIdChange: () =>
      CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_BUSINESS_ID_DROPDOWN_LIST,
    onAccountNameClick: () => CREATE_CUSTOMER_USER.SELECT_ACCOUNT_NUMBER,
    onBlurFields: (field) => {
      switch (field) {
        case CITY_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_CITY;
        case COUNTY_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_COUNTY;
        case SUBURB_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_SUBURB;
        case COUNTRY_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_COUNTRY;
        case NOTIFICATION_EMAIL_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_NOTIFICATION_EMAIL;
        case NOTIFICATION_NUMBER_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_NOTIFICATION_PHONE;
        case STREET_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_STREET;
        case ACCOUNT_ID_FIELD:
          return CREATE_CUSTOMER_USER.ACCOUNT_NUMBER_ENTRY;
      }
    },
    onInputChange: (field, value) => {
      let id = "";
      switch (field) {
        case OVERRIDE_DETAILS_FIELD:
          id = CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ONOVERRIDECHECKBOX;
          break;
        case POSTCODE_FIELD:
          id = CREATE_EDIT_CUSTOMER_USER_ANALYTICS.POSTCODE_LOOKUP;
          break;
        case POSTCODE_FIELD_CLICK:
          id =
            CREATE_EDIT_CUSTOMER_USER_ANALYTICS.POSTCODE_LOOKUP_ADDRESS_CLICKED;
          break;
      }
      return createLogEntryPayload(id, {
        field,
        value,
      });
    },
  })),
  withRef
)(AccountDetails);
