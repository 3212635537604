import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";

import colors from "../../constants/colors";

const customizeSwitchStyle = (theme) => ({
  root: {
    width: 36,
    height: 23,
    padding: 0,
    marginRight: 11,
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(14px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&$disabled": {
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: colors.tableBorder,
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 34,
    backgroundColor: colors.tableBorder,
    opacity: 1,
  },
  disabled: {},
  checked: {},
  focusVisible: {},
});

const CustomSwitch = ({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
      disabled: classes.disabled,
    }}
    {...props}
  />
);

CustomSwitch.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(customizeSwitchStyle)(CustomSwitch);
