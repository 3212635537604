import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import styles from "./NoResultsWavingBox.module.scss";
import box from "../../assets/images/greetingBox.gif";

export const NoResultsWavingBox = ({ title, subtitle }) => (
  <Box className={styles.container}>
    <Box className={styles.textContainer}>
      <Typography align="center" variant="h4" classes={{ h4: styles.header }}>
        {title}
      </Typography>

      {subtitle && (
        <Typography align="center" classes={{ body1: styles.subtitle }}>
          {subtitle}
        </Typography>
      )}
    </Box>
    <img src={box} alt="Greeting Box" className={styles.image} />
  </Box>
);

export default NoResultsWavingBox;

NoResultsWavingBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
