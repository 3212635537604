import { change } from "redux-form";

import {
  DISABLE_NOTIFICATION_ON_PRINT_FIELD,
  EXTENDED_HOLD_POLICY,
} from "./advancedCustomerConfiguration.constants";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { onReferenceOneChange } from "../shippingSettings/shippingSettings.actions";
import { SENDERS_REF1_FIELD } from "../shippingSettings/shippingSettings.constants";
import { shouldShowEndOfDay } from "../shippingSettings/shippingSettings.selectors";

export const selectDisableNotificationOnPrint = (value) => (dispatch) =>
  dispatch(
    change(
      USER_DETAILS_FORMS.ADVANCED_CUSTOMER_CONFIGURATION,
      DISABLE_NOTIFICATION_ON_PRINT_FIELD,
      value
    )
  );

export const deselectSixMonthsToggle = () => (dispatch) =>
  dispatch(
    change(
      USER_DETAILS_FORMS.ADVANCED_CUSTOMER_CONFIGURATION,
      EXTENDED_HOLD_POLICY,
      false
    )
  );

export const onReference1Change = () => (dispatch) => {
  dispatch(
    change(USER_DETAILS_FORMS.SHIPPING_SETTINGS, SENDERS_REF1_FIELD, false)
  );
  dispatch(onReferenceOneChange());
};

export const onForwardDateOver7DaysChange = (value) => (dispatch, getState) => {
  const eodToggleSelected = shouldShowEndOfDay(getState());
  dispatch(selectDisableNotificationOnPrint(eodToggleSelected || value));
};
