import PropTypes from "prop-types";

const OOPS_SOMETHING_WENT_WRONG = "Oops something went wrong";

const SomethingWentWrong = ({ errorMessage }) => (
  <>
    <div>{OOPS_SOMETHING_WENT_WRONG}</div>
    <div>{errorMessage}</div>
  </>
);

SomethingWentWrong.propTypes = {
  errorMessage: PropTypes.any,
};

export default SomethingWentWrong;
