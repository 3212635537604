export const USERNAME_FIELD = "username";
export const ACCOUNT_FIELD = "account";
export const SEARCH_RESULT = "searchResults";
export const BUSINESS_ID_FIELD = "businessId";
export const SEARCH_ERRORS = {
  username: "No users with this logon ID found",
  account: "No users exist for this account",
  enter: "Please enter logon ID or account",
};
export const PAGE_SIZE = 25;
