import { Box, Grid, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import styles from "./DefaultsDpd.module.scss";
import HeaderWithStar from "../../../../components/HeaderWithStar/HeaderWithStar";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  EXCHANGE_OUTBOUND_SERVICE,
  EXCHANGE_RETURN_SERVICE,
  OFFSHORE_EXCHANGE_SERVICE,
  OFFSHORE_OUTBOUND_SERVICE,
  OUTBOUND_SERVICE,
  PLEASE_SELECT_SERVICE,
} from "../../../../constants/strings";
import {
  getExchangeDpdServices,
  getOutboundDpdServices,
} from "../../../../features/config/config.selectors";
import {
  DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD,
} from "../../../../features/profile/profile.constants";
import { isLocalDpdAccount } from "../../../../features/profile/profile.models";
import { getAccountNumber } from "../../../../features/profile/profile.selectors";

export const renderSelectItems = (selectItems) =>
  selectItems.map((service) => (
    <MenuItem key={service.code} value={service.code}>
      {service.name.toUpperCase()}
    </MenuItem>
  ));

const DefaultsDpd = ({
  onDropdownClick,
  outboundServices,
  exchangeServices,
  isDpdAccount,
}) => (
  <Box width="80%" className={styles.wrapper}>
    <Grid
      container
      justify="space-between"
      alignItems="center"
      wrap="wrap"
      className={styles.spacing}
    >
      <HeaderWithStar
        text={OUTBOUND_SERVICE}
        required={isDpdAccount}
        headerStyles={styles.label}
      />
      <Grid item className={styles.select}>
        <Field
          name={DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD}
          component={SelectWithTitle}
          displayEmpty
          onClick={() =>
            onDropdownClick(DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD)
          }
          showMetaErrors
        >
          <MenuItem value={""}>{PLEASE_SELECT_SERVICE}</MenuItem>
          {renderSelectItems(outboundServices)}
        </Field>
      </Grid>
    </Grid>

    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.spacing}
    >
      <HeaderWithStar
        text={EXCHANGE_OUTBOUND_SERVICE}
        required={isDpdAccount}
        headerStyles={styles.label}
      />
      <Grid item className={styles.select}>
        <Field
          name={DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD}
          component={SelectWithTitle}
          displayEmpty
          onClick={() =>
            onDropdownClick(DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD)
          }
          showMetaErrors
        >
          <MenuItem value={""}>{PLEASE_SELECT_SERVICE}</MenuItem>
          {renderSelectItems(exchangeServices)}
        </Field>
      </Grid>
    </Grid>

    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.spacing}
    >
      <HeaderWithStar
        text={EXCHANGE_RETURN_SERVICE}
        required={isDpdAccount}
        headerStyles={styles.label}
      />
      <Grid item className={styles.select}>
        <Field
          name={DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD}
          component={SelectWithTitle}
          displayEmpty
          onClick={() =>
            onDropdownClick(DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD)
          }
          showMetaErrors
        >
          <MenuItem value={""}>{PLEASE_SELECT_SERVICE}</MenuItem>
          {renderSelectItems(exchangeServices)}
        </Field>
      </Grid>
    </Grid>

    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.spacing}
    >
      <HeaderWithStar
        text={OFFSHORE_OUTBOUND_SERVICE}
        required={isDpdAccount}
        headerStyles={styles.label}
      />
      <Grid item className={styles.select}>
        <Field
          name={DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD}
          component={SelectWithTitle}
          displayEmpty
          onClick={() =>
            onDropdownClick(DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD)
          }
          showMetaErrors
        >
          <MenuItem value={""}>{PLEASE_SELECT_SERVICE}</MenuItem>
          {renderSelectItems(outboundServices)}
        </Field>
      </Grid>
    </Grid>

    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.spacing}
    >
      <HeaderWithStar
        text={OFFSHORE_EXCHANGE_SERVICE}
        required={isDpdAccount}
        headerStyles={styles.label}
      />
      <Grid item className={styles.select}>
        <Field
          name={DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD}
          component={SelectWithTitle}
          displayEmpty
          onClick={() =>
            onDropdownClick(DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD)
          }
          showMetaErrors
        >
          <MenuItem value={""}>{PLEASE_SELECT_SERVICE}</MenuItem>
          {renderSelectItems(exchangeServices)}
        </Field>
      </Grid>
    </Grid>
  </Box>
);

DefaultsDpd.propTypes = {
  onDropdownClick: PropTypes.func,
  outboundServices: PropTypes.array,
  exchangeServices: PropTypes.array,
  isDpdAccount: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  outboundServices: getOutboundDpdServices(state),
  exchangeServices: getExchangeDpdServices(state),
  isDpdAccount: !isLocalDpdAccount(getAccountNumber(state)),
});

export default compose(
  connect(mapStateToProps, null),
  withTrackProps(() => ({
    onDropdownClick: (field) =>
      createLogEntryPayload(PROFILE_ANALYTICS.CLICK_DROPDOWN_LIST, {
        field,
      }),
  }))
)(DefaultsDpd);
