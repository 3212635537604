import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import styles from "./LoginInput.module.scss";
import InputWithTitle from "../InputWithTitle/InputWithTitle";

const useStyles = makeStyles((theme) => ({
  themeBorder: {
    borderColor: theme.palette.primary.main,
  },
}));

export default function LoginInput(props) {
  const classes = useStyles();

  return (
    <InputWithTitle
      inputStyles={styles.input}
      adornmentStyles={{
        adornmentWrapper: styles["adornment-wrapper"],
        adornmentIcon: classNames(styles["login-icon"], classes.themeBorder),
      }}
      {...props}
    />
  );
}
