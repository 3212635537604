import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";

import {
  actions,
  button,
  field,
  fields,
  form,
  formTitle,
  passwordMeter,
} from "./SetPasswordForm.module.scss";
import PasswordField from "../../../../components/PasswordField";
import PasswordStrengthMeter from "../../../../components/PasswordStrengthMeter/PasswordStrengthMeter";
import { SET_PASSWORD_FORM } from "../../../../constants/forms";
import { CREATE_NEW_PASSWORD } from "../../../../constants/strings";
import {
  getFirstLoginEmail,
  getNewPassword,
  getNewPasswordStrengthScore,
} from "../../../../features/auth/auth.selectors";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import { LOGIN_PAGE } from "../../../../router";
import { navigateTo } from "../../../../router/navigation";
import LoginSubmitButton from "../LoginSubmitButton";

const SUBMIT = "Submit";
const NEW_PASSWORD = "New password";
const RE_ENTER_PASSWORD = "Re-enter new password";

const PasswordFieldWithError = withErrorTooltip(PasswordField);

const SetPasswordForm = ({ handleSubmit, onSubmit, firstLogin }) => {
  const newPassword = useSelector(getNewPassword);
  const email = useSelector(getFirstLoginEmail);
  const passwordStrength = useSelector(getNewPasswordStrengthScore);
  if (!email && firstLogin) {
    navigateTo(LOGIN_PAGE);
  }
  return (
    <>
      <h3 className={formTitle}>{CREATE_NEW_PASSWORD}</h3>
      <form onSubmit={handleSubmit(onSubmit)} className={form}>
        <div className={fields}>
          <div className={field}>
            <Field
              name="newPassword"
              component={PasswordFieldWithError}
              id="newPassword"
              placeholder={NEW_PASSWORD}
              inputProps={{ maxLength: 100 }}
            />
            <div className={passwordMeter}>
              {newPassword && (
                <PasswordStrengthMeter
                  passwordStrengthScore={passwordStrength}
                />
              )}
            </div>
          </div>
          <div className={field}>
            <Field
              name="reEnterPassword"
              component={PasswordFieldWithError}
              id="reEnterPassword"
              placeholder={RE_ENTER_PASSWORD}
              inputProps={{ maxLength: 100 }}
            />
          </div>
        </div>

        <Grid container className={actions}>
          <Grid item sm={5} className={button}>
            <LoginSubmitButton>{SUBMIT}</LoginSubmitButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

SetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  firstLogin: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
};

export default reduxForm({
  initialValues: {
    newPassword: "",
    reEnterPassword: "",
  },
  form: SET_PASSWORD_FORM,
})(SetPasswordForm);
