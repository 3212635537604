import { useTheme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

const getSvgIcons = (name, fillColor, gradientColors = []) => {
  const svgIcons = {
    email: {
      svg: (
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Manage-individual-Expiry-date"
            transform="translate(-376.000000, -214.000000)"
          >
            <g
              id="icon/bordered-circle-icon-copy-13"
              transform="translate(375.000000, 211.000000)"
            >
              <g id="email">
                <polygon id="bounding-box" points="0 0 20 0 20 20 0 20" />
                <path
                  d="M16.72,3.2 L3.28,3.2 C2.356,3.2 1.6084,3.965 1.6084,4.9 L1.6,15.1 C1.6,16.035 2.356,16.8 3.28,16.8 L16.72,16.8 C17.644,16.8 18.4,16.035 18.4,15.1 L18.4,4.9 C18.4,3.965 17.644,3.2 16.72,3.2 Z M16.72,15.1 L3.28,15.1 L3.28,6.6 L10,10.85 L16.72,6.6 L16.72,15.1 Z M10,9.15 L3.28,4.9 L16.72,4.9 L10,9.15 Z"
                  id="shape"
                  fill={fillColor}
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      ),
      width: "18px",
      height: "14px",
      viewBox: "0 0 18 14",
    },
    description: {
      svg: (
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="User-Details"
            transform="translate(-369.000000, -454.000000)"
            fill={fillColor}
          >
            <g id="Group-2" transform="translate(369.000000, 454.000000)">
              <rect id="Rectangle" x="0" y="0" width="13" height="2" rx="1" />
              <rect
                id="Rectangle-Copy-4"
                x="0"
                y="8"
                width="17"
                height="2"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-2"
                x="1"
                y="4"
                width="19"
                height="2"
                rx="1"
              />
            </g>
          </g>
        </g>
      ),

      width: "20px",
      height: "10px",
      viewBox: "0 0 20 10",
    },
    profile: {
      svg: (
        <path
          fill={fillColor}
          id="Shape-Copy-4"
          d="M7.49,2.09A1.83,1.83,0,1,1,5.66,3.92,1.83,1.83,0,0,1,7.49,2.09Zm0,8.22c2.46,0,5.29,1.17,5.47,1.82v.92H2v-.92C2.19,11.48,5,10.31,7.49,10.31Zm0-10a3.65,3.65,0,1,0,2.58,1.07A3.66,3.66,0,0,0,7.49.26Zm0,8.22C5.05,8.48.18,9.7.18,12.13v2.74H14.79V12.13C14.79,9.7,9.92,8.48,7.49,8.48Z"
        />
      ),
      width: "15px",
      height: "15px",
      viewBox: "0 0 15 15",
    },
    password: {
      svg: (
        <path
          fill={fillColor}
          d="M6.72,18.69A6.07,6.07,0,1,1,9,7l6.9-6.9,3.33,3.33L17.93,4.75l2.62,2.62L17.22,10.7,14.59,8.08l-2.25,2.26a6.07,6.07,0,0,1-5.62,8.35Zm0-11.13a5.07,5.07,0,1,0,4.57,2.88l-.15-.32,3.45-3.45,2.63,2.62,1.92-1.92L16.51,4.75l1.31-1.31L15.91,1.52,9.22,8.21,8.9,8.05A5.06,5.06,0,0,0,6.72,7.56Zm0,7.42a2.36,2.36,0,1,1,1.67-.69A2.34,2.34,0,0,1,6.72,15Zm0-3.71a1.38,1.38,0,0,0-1,.39,1.36,1.36,0,1,0,1.92,1.92,1.31,1.31,0,0,0,.4-1,1.35,1.35,0,0,0-1.36-1.35Z"
        />
      ),
      width: "21px",
      height: "19px",
      viewBox: "0 0 21 19",
    },
    phone: {
      svg: (
        <path
          fill={fillColor}
          d="M6.47,4.91a12.48,12.48,0,0,0,.46,2.62L5.71,8.75a15,15,0,0,1-.77-3.84Zm10,12.18a12.92,12.92,0,0,0,2.63.46v1.51a15.63,15.63,0,0,1-3.85-.76Zm-9-14.21H3.89a1,1,0,0,0-1,1A17.23,17.23,0,0,0,20.11,21.12a1,1,0,0,0,1-1V16.57a1,1,0,0,0-1-1A11.56,11.56,0,0,1,16.49,15a.85.85,0,0,0-.31-.05,1,1,0,0,0-.72.29l-2.23,2.23a15.35,15.35,0,0,1-6.68-6.68L8.78,8.54a1,1,0,0,0,.25-1,11.51,11.51,0,0,1-.58-3.62A1,1,0,0,0,7.44,2.88Z"
        />
      ),
      width: "24px",
      height: "24px",
      viewBox: "0 0 24 24",
    },
    position: {
      svg: (
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group" transform="translate(1.000000, 0.000000)">
            <path
              d="M7,7.875 C7.75939153,7.875 8.375,8.49060847 8.375,9.25 C8.375,10.0093915 7.75939153,10.625 7,10.625 C6.24060847,10.625 5.625,10.0093915 5.625,9.25 C5.625,8.49060847 6.24060847,7.875 7,7.875 Z M7,14.0625 C8.85625,14.0625 10.9875,14.949375 11.125,15.4375 L11.125,16.125 L2.875,16.125 L2.875,15.4375 C3.0125,14.949375 5.14375,14.0625 7,14.0625 Z M7,6.5 C5.48121694,6.5 4.25,7.73121694 4.25,9.25 C4.25,10.7687831 5.48121694,12 7,12 C8.51878306,12 9.75,10.7687831 9.75,9.25 C9.75,8.52065465 9.46026869,7.82118139 8.94454365,7.30545635 C8.42881861,6.78973131 7.72934535,6.5 7,6.5 Z M7,12.6875 C5.164375,12.6875 1.5,13.60875 1.5,15.4375 L1.5,17.5 L12.5,17.5 L12.5,15.4375 C12.5,13.60875 8.835625,12.6875 7,12.6875 Z"
              id="Shape-Copy-5"
              fill={fillColor}
              fillRule="nonzero"
            />
            <rect
              id="Rectangle"
              x="0"
              y="3"
              width="14"
              height="17"
              stroke={fillColor}
            />
            <rect
              id="Rectangle"
              fill={fillColor}
              x="6"
              y="0"
              width="2"
              height="5"
            />
          </g>
        </g>
      ),
      width: "16px",
      height: "21px",
      viewBox: "0 0 16 21",
    },
    next: {
      svg: (
        <>
          <defs>
            <polygon
              id="path-1"
              points="1.175 6 5 2.29149798 8.825 6 10 4.8582996 5 0 0 4.8582996"
            />
          </defs>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Users-dashboard-single-selected"
              transform="translate(-368.000000, -770.000000)"
            >
              <g
                id="Group-13-Copy"
                transform="translate(334.000000, 770.000000)"
              >
                <g id="Group-25-Copy">
                  <g id="Group-21">
                    <g id="Group-28">
                      <g id="Group-20">
                        <g
                          id="Group-19-Copy"
                          transform="translate(45.000000, 11.000000) scale(-1, 1) translate(-45.000000, -11.000000) translate(34.000000, 0.000000)"
                        >
                          <circle
                            id="Oval"
                            fill={fillColor}
                            cx="11"
                            cy="11"
                            r="11"
                          />
                          <g
                            id="Icon/Arrow-Right"
                            transform="translate(1.222222, 1.222222)"
                          >
                            <g
                              id="Arrow-right"
                              transform="translate(10.000000, 10.000000) rotate(-90.000000) translate(-10.000000, -10.000000) "
                            >
                              <polygon id="Path" points="0 0 20 0 20 20 0 20" />
                              <g
                                id="Colour/Blue"
                                transform="translate(5.000000, 6.666667)"
                              >
                                <mask id="mask-2" fill="white">
                                  <use xlinkHref="#path-1" />
                                </mask>
                                <use
                                  id="Mask"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  xlinkHref="#path-1"
                                />
                                <g
                                  id="Colour/White"
                                  mask="url(#mask-2)"
                                  fill="#FFFFFF"
                                  fillRule="nonzero"
                                >
                                  <g
                                    transform="translate(-5.333333, -6.666667)"
                                    id="Rectangle"
                                  >
                                    <rect x="0" y="0" width="20" height="20" />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </>
      ),
      width: "22px",
      height: "22px",
      viewBox: "0 0 22 22",
    },
    resend: {
      svg: (
        <>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Invited-users"
              transform="translate(-1109.000000, -355.000000)"
            >
              <g id="Group-3" transform="translate(1109.000000, 355.000000)">
                <g id="redo-(2)" fill={fillColor} fillRule="nonzero">
                  <path
                    d="M26.4790421,0.0640640391 C26.1640778,-0.0652999746 25.8007049,0.0055618418 25.5598984,0.247398363 L22.522522,3.28498079 C20.0182572,1.21721648 16.8051681,0 13.5,0 C6.05580142,0 0,6.05580142 0,13.5 C0,20.9441986 6.05580142,27 13.5,27 C17.1034469,27 16.5025635,24.7499313 13.5,24.7499313 C7.29670718,24.7499313 2.25006868,19.7032928 2.25006868,13.5 C2.25006868,7.29670718 7.29670718,2.25006868 13.5,2.25006868 C16.2112884,2.25006868 18.8436813,3.22544858 20.9238052,4.88246157 L18.2475357,7.55996704 C18.0056991,7.80180356 17.9348373,8.16414641 18.0642013,8.47911069 C18.1945954,8.79407503 18.5017319,9.00006868 18.8436813,9.00006868 L26.15625,9.00006868 C26.6220016,9.00006868 27,8.62207031 27,8.15631868 L27,0.84375 C27,0.501800537 26.7942123,0.194663988 26.4790421,0.0640640391 Z"
                    id="Path"
                  />
                </g>
                <g
                  id="icon/bordered-circle-icon-copy-13"
                  transform="translate(10.000000, 7.500000)"
                >
                  <g id="email" transform="translate(0.500000, 0.500000)">
                    <polygon id="bounding-box" points="0 0 18 0 18 18 0 18" />
                    <path
                      d="M15.048,2.88 L2.952,2.88 C2.1204,2.88 1.44756,3.5685 1.44756,4.41 L1.44,13.59 C1.44,14.4315 2.1204,15.12 2.952,15.12 L15.048,15.12 C15.8796,15.12 16.56,14.4315 16.56,13.59 L16.56,4.41 C16.56,3.5685 15.8796,2.88 15.048,2.88 Z M15.048,13.59 L2.952,13.59 L2.952,5.94 L9,9.765 L15.048,5.94 L15.048,13.59 Z M9,8.235 L2.952,4.41 L15.048,4.41 L9,8.235 Z"
                      id="shape"
                      fill={fillColor}
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </>
      ),
      width: "28px",
      height: "27px",
      viewBox: "0 0 28 27",
    },
    dpd: {
      svg: (
        <>
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="368.44"
              y1="1086.6"
              x2="737.96"
              y2="776.53"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor={gradientColors[0] || fillColor} />
              <stop offset="0.25" stopColor={gradientColors[1] || fillColor} />
              <stop offset="0.71" stopColor={gradientColors[2] || fillColor} />
              <stop offset="1" stopColor={gradientColors[3] || fillColor} />
            </linearGradient>
          </defs>
          <path
            style={{ fill: "url(#linear-gradient)" }}
            d="M871.9,744.43a12.86,12.86,0,0,1-12.18-.23L840,732.47a12.92,12.92,0,0,1-4.2-4.51l-.2-.34a13.12,13.12,0,0,1-2.05-6.22l-.5-23a12.85,12.85,0,0,1,5.86-10.62l237.37-138.29L743.16,367.93a30.95,30.95,0,0,0-26.71,0L383.35,549.48,756.51,766.71a12.69,12.69,0,0,1,6.09,10.43V1094a12.89,12.89,0,0,1-6.33,10.41l-20.09,11.15a12.93,12.93,0,0,1-6,1.37h-.38a13,13,0,0,1-6.41-1.37l-20.15-11.15A12.85,12.85,0,0,1,697,1094V811.54a7.92,7.92,0,0,0-3.36-5.54L364.9,614.62V989.47c0,8.39,5.91,18.74,13.16,23l338.6,199.69a28.94,28.94,0,0,0,26.3,0l338.64-199.69c7.22-4.29,13.12-14.58,13.11-23V614.62Z"
          />
        </>
      ),
      viewBox: "0 0 1580.2 1580.2",
    },
    weight: {
      svg: (
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Create-New-Profile---Defaults---DPD-Domestic"
            transform="translate(-371.000000, -416.000000)"
            fill={fillColor}
            fillRule="nonzero"
            stroke={fillColor}
            strokeWidth="0.3"
          >
            <g id="business" transform="translate(372.000000, 417.000000)">
              <g id="Group-6">
                <path
                  d="M15.4333419,5.06644441 L11.3063429,5.06644441 C11.7411643,4.53038787 12.0033817,3.84111021 12.0033817,3.0899353 C12.0033817,1.38609312 10.6560834,0 9.00007046,0 C7.34391658,0 5.99661838,1.38609312 5.99661838,3.0899353 C5.99661838,3.84111021 6.25883569,4.53038787 6.69365708,5.06644441 L2.56665806,5.06644441 L0,19 L18,19 L15.4333419,5.06644441 Z M9.00007046,1.11328125 C10.0593664,1.11328125 10.9212597,1.99999236 10.9212597,3.0899353 C10.9212597,4.17973326 10.0593664,5.06644441 9.00007046,5.06644441 C7.94063359,5.06644441 7.07874035,4.17973326 7.07874035,3.0899353 C7.07874035,1.99999236 7.94063359,1.11328125 9.00007046,1.11328125 L9.00007046,1.11328125 Z M3.46293121,6.17972566 L14.5370688,6.17972566 L16.6935632,17.8867188 L1.30643684,17.8867188 L3.46293121,6.17972566 Z"
                  id="Shape"
                />
                <polygon
                  id="Path"
                  points="9 10 7.7165783 10 5.92226148 11.9826013 5.92226148 10 5 10 5 15 5.92226148 15 5.92226148 13.2910856 6.38736746 12.7893262 7.79299174 15 8.98351001 15 7.0459364 12.0881663"
                />
                <path
                  d="M10.9755074,13.1797359 L12.1339398,13.1797359 L12.1339398,13.7114558 C12.0408677,13.7880703 11.9140658,13.863263 11.7557733,13.9354543 C11.5631911,14.0234425 11.3630511,14.0679894 11.1612316,14.0679894 C10.930161,14.0679894 10.7090273,14.0096992 10.5037089,13.8946986 C10.3058083,13.7839631 10.1588523,13.6184127 10.0547236,13.3884115 C9.94723583,13.1513016 9.89279217,12.8472134 9.89279217,12.4846771 C9.89279217,12.1910148 9.9389783,11.9156767 10.0295311,11.6671932 C10.0807558,11.5289713 10.1536739,11.4002275 10.2463261,11.2841211 C10.3346396,11.1735435 10.4548635,11.0819221 10.603359,11.0119424 C10.7546536,10.9406989 10.9395382,10.9045242 11.1531141,10.9045242 C11.3270819,10.9045242 11.486494,10.9384873 11.6268719,11.0054657 C11.7603919,11.0691268 11.8628412,11.1526918 11.9312806,11.2536333 C12.0054584,11.362947 12.0696991,11.520599 12.1223233,11.722324 L12.1715885,11.9110956 L12.9787264,11.6611904 L12.9351994,11.4702073 C12.8611617,11.1447934 12.7497551,10.8748262 12.6039188,10.6674144 C12.4543037,10.4546316 12.2475857,10.2875016 11.9892232,10.1706053 C11.7389783,10.0573424 11.456823,10 11.1504549,10 C10.7303009,10 10.3521344,10.0998357 10.026452,10.2966637 C9.69307208,10.4982307 9.43442967,10.8095855 9.25822252,11.2220397 C9.08677397,11.6229622 9,12.0599014 9,12.5211677 C9,12.9881208 9.08789363,13.4181095 9.26158152,13.79897 C9.43918826,14.1888348 9.70552833,14.4907115 10.053464,14.6963857 C10.3939818,14.8977948 10.776627,15 11.1907628,15 C11.4976907,15 11.8009797,14.9369708 12.0922323,14.8124921 C12.3806858,14.6894351 12.6635409,14.5039808 12.9329601,14.2615001 L13,14.2013143 L13,12.2535701 L10.9755074,12.2572033 L10.9755074,13.1797359 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      ),
      viewBox: "0 0 20 21",
    },
  };

  return svgIcons[name];
};

const SvgIconComponent = (props) => {
  const { palette } = useTheme();
  const icon = getSvgIcons(
    props.name,
    props.color || palette.primary.main,
    props.gradientColors || palette.primary.gradientColors
  );
  const width = props.width || icon.width;
  const height = props.height || icon.height;

  return (
    <SvgIcon width={width} height={height} viewBox={icon.viewBox} {...props}>
      {icon.svg}
    </SvgIcon>
  );
};
SvgIconComponent.propTypes = {
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  gradientColors: PropTypes.array,
};
export default SvgIconComponent;
