import { IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./SimpleArrayField.module.scss";
import { ReactComponent as ClearIcon } from "../../assets/icons/clear.svg";

export const SimpleArrayField = ({
  fields,
  formatItem,
  disableRemoveFirst,
  disabled,
  icon,
  disableGutters,
  containerStyles,
  textItemStyles,
  onRemoveCallback,
  onRemove,
}) => {
  const fieldsValues = fields.getAll() || [];
  return (
    <List className={classNames(containerStyles)}>
      {fieldsValues.map((item, index) => {
        const formattedItem = formatItem ? formatItem(item) : item;
        let showDeleteIcon = !disabled;
        if (index === 0 && disableRemoveFirst) {
          showDeleteIcon = false;
        }

        return (
          <ListItem
            key={item.uid || formattedItem}
            alignItems="center"
            disableGutters={disableGutters}
          >
            <ListItemText
              primary={formattedItem}
              className={classNames(textItemStyles, styles.itemText)}
            />
            <ListItemSecondaryAction>
              {showDeleteIcon && (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    const removeItem = () => fields.remove(index);
                    onRemoveCallback
                      ? onRemoveCallback(removeItem)
                      : removeItem();
                    onRemove(index);
                  }}
                >
                  {icon}
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

SimpleArrayField.propTypes = {
  fields: PropTypes.object,
  formatItem: PropTypes.func,
  disableRemoveFirst: PropTypes.bool,
  icon: PropTypes.node,
  disableGutters: PropTypes.bool,
  disabled: PropTypes.bool,
  containerStyles: PropTypes.object,
  textItemStyles: PropTypes.object,
  onRemoveCallback: PropTypes.func,
  onRemove: PropTypes.func,
};

SimpleArrayField.defaultProps = {
  icon: <ClearIcon />,
  onRemoveCallback: null,
  disableGutters: false,
  containerStyles: {},
  textItemStyles: {},
  onRemove: () => null,
};
