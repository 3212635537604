import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, FieldArray, reduxForm } from "redux-form";

import styles from "./AdditionalAccounts.module.scss";
import SearchFieldWithList from "../../components/SearchFieldWithList/SearchFieldWithList";
import { SimpleArrayField } from "../../components/SimpleArrayField/SimpleArrayField";
import { ACCOUNT_NUMBER } from "../../constants/strings";
import { formatCustomerItem } from "../../features/customer/customer.model";
import { ADDITIONAL_ACCOUNTS_FIELD } from "../../features/userDetailsForm/additionalAccounts/additionalAccounts.constant";
import { validate } from "../../features/userDetailsForm/additionalAccounts/additionalAccounts.validate";
import withRef from "../../hocs/withRef";
import { withoutSpaces } from "../../utils/normalize";

const AdditionalAccounts = ({
  disableRemoveFirst,
  onItemClick,
  fetchFunction,
  onRemove,
  disabled,
  onBlurFields = () => null,
}) => (
  <Box width="100%">
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box width={3 / 5} className={styles.container}>
        <Field
          name={ADDITIONAL_ACCOUNTS_FIELD}
          component={SearchFieldWithList}
          label={ACCOUNT_NUMBER}
          fetchFunction={fetchFunction}
          formatItem={formatCustomerItem}
          onClick={onItemClick}
          disabled={disabled}
          onBlur={() => onBlurFields(ADDITIONAL_ACCOUNTS_FIELD)}
          normalize={withoutSpaces}
          inputProps={{ maxLength: 200 }}
        />
      </Box>
      <Box width={1 / 3} className={styles.container}>
        <FieldArray
          name="accounts"
          onRemove={onRemove}
          disabled={disabled}
          formatItem={formatCustomerItem}
          component={SimpleArrayField}
          disableRemoveFirst={disableRemoveFirst}
        />
      </Box>
    </Box>
  </Box>
);

AdditionalAccounts.propTypes = {
  disableRemoveFirst: PropTypes.bool,
  disabled: PropTypes.bool,
  onItemClick: PropTypes.func,
  onFetch: PropTypes.func,
  fetchFunction: PropTypes.func,
  onRemove: PropTypes.func,
  onBlurFields: PropTypes.func,
};

export default compose(
  reduxForm({
    validate,
  }),
  withRef
)(AdditionalAccounts);
