import { Fade } from "@material-ui/core";
import MuiTooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";

import {
  popper,
  tooltip,
  tooltipPlacementBottom,
  tooltipPlacementTop,
} from "./Tooltip.module.scss";

const Tooltip = ({ tooltipClasses, ...props }) => (
  <MuiTooltip
    placement="top"
    classes={{
      popper,
      tooltip,
      tooltipPlacementBottom,
      tooltipPlacementTop,
      ...tooltipClasses,
    }}
    TransitionComponent={Fade}
    TransitionProps={{
      timeout: {
        exit: 0,
      },
    }}
    PopperProps={{
      disablePortal: true,
      modifiers: {
        flip: {
          enabled: false,
        },
      },
    }}
    {...props}
  />
);

Tooltip.propTypes = {
  tooltipClasses: PropTypes.object,
};

export default Tooltip;
