import { addDays, format } from "date-fns";
import { get } from "lodash";

import {
  ACCOUNT_ID_FIELD,
  BUSINESS_ID_FIELD,
} from "../userDetailsForm/accountDetails/accountDetails.constants";
import {
  MASTER_PASSWORD_FIELD,
  MASTER_PASSWORD_LOCK_FIELD,
  MASTER_USERNAME_FIELD,
} from "./createMasterUser.constants";

export const buildUsername = (
  currentUserUsername = "",
  editUserUsername = "",
  businessId
) => {
  const name2 = currentUserUsername.split("@")[0].slice(0, 23);
  const name1 = editUserUsername.split("@")[0].slice(0, 23);
  return `${name1}-${name2}-${businessId}`;
};

export const getExpiryDate = () => {
  const date = addDays(new Date(), 1);
  return format(date, "do 'of' MMMM");
};

export const prepareMasterUser = (values, user) => ({
  overrideUserUid: get(user, "uid"),
  businessId: get(user, BUSINESS_ID_FIELD),
  username: get(values, MASTER_USERNAME_FIELD),
  account: get(user, ACCOUNT_ID_FIELD),
  password: get(values, MASTER_PASSWORD_FIELD),
  passwordLock: get(values, MASTER_PASSWORD_LOCK_FIELD),
});
