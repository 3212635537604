import get from "lodash/get";
import { getFormSyncErrors, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import * as serviceRestrictionModels from "./serviceRestrictions.model";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import {
  getServiceRestrictionHelper,
  getServiceRestrictions,
  getZeroRestrictionItems,
} from "../../config/config.selectors";

export const getServiceRestrictionsData = getFormValues(
  USER_DETAILS_FORMS.SERVICE_RESTRICTIONS
);

export const getRestrictionMappingValue = createSelector(
  getServiceRestrictionsData,
  (formData) => get(formData, "mapping")
);

export const getRestrictionServiceRestrictedValue = createSelector(
  getServiceRestrictionsData,
  (formData) => get(formData, "serviceRestricted", [])
);

export const getRestrictionsFromErrors = getFormSyncErrors(
  USER_DETAILS_FORMS.SERVICE_RESTRICTIONS
);

export const isRestrictionMappingValid = createSelector(
  getRestrictionMappingValue,
  getRestrictionsFromErrors,
  (mappingValue = "", { mapping } = {}) => !mappingValue.trim() || !!mapping
);

export const getPostcodeRestrictions = createSelector(
  getServiceRestrictionHelper,
  getServiceRestrictions,
  getZeroRestrictionItems,
  getRestrictionMappingValue,
  serviceRestrictionModels.getSectorSelectValue
);

export const get2DayRestrictionCode = createSelector(
  getServiceRestrictions,
  serviceRestrictionModels.get2DayRestrictionCode
);
