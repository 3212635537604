import { isWeakPassword, passwordStrength } from "../../utils/commonValidation";
import {
  PASSWORD_DONT_MATCH,
  PLEASE_ENTER_PASSWORD,
  WEAK_PASSWORD,
} from "../auth/auth.constants";

const passwordValidation = ({ reEnterPassword, newPassword = "" }) => {
  const errors = {};
  if (!newPassword) {
    errors.newPassword = PLEASE_ENTER_PASSWORD;
  } else if (isWeakPassword(passwordStrength(newPassword))) {
    errors.newPassword = WEAK_PASSWORD;
  } else if (reEnterPassword !== newPassword) {
    errors.reEnterPassword = PASSWORD_DONT_MATCH;
  }

  return errors;
};

export const validate = ({ newPassword, reEnterPassword }, { syncErrors }) => {
  const errors = {};

  if (syncErrors.username) {
    errors.username = syncErrors.username;
  }
  const passwordErrors = passwordValidation({
    reEnterPassword,
    newPassword,
  });

  return { ...errors, ...passwordErrors };
};
