import { get, intersectionWith, isEqual, xorWith } from "lodash";

import { PERMITTED_IP_FIELD } from "./ipRestrictions.constants";
import * as TEAM_SETTINGS from "../../teamSettings/teamSettings.constants";

export const getIpRestrictionsInitialValue = ({ userInfo, customer }) => ({
  [PERMITTED_IP_FIELD]: intersectionWith(
    get(customer, TEAM_SETTINGS.PERMITTED_IP_FIELD, []),
    get(userInfo, PERMITTED_IP_FIELD, []),
    (row1, row2) => isEqual(row1.ipAddress, row2.ipAddress)
  ),
});

export const getIpAddressForDropdown = (customerIp, userIp) =>
  xorWith(customerIp, userIp, isEqual);
