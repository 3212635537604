import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./Tools.module.scss";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import { ReactComponent as Edit } from "../../../../assets/icons/edit.svg";
import { ReactComponent as Pause } from "../../../../assets/icons/pause.svg";
import { ReactComponent as Play } from "../../../../assets/icons/play.svg";
import { CUSTOMER_EDIT_USER_PAGE } from "../../../../router";
import { getPageLink } from "../../../../router/navigation";

const Tools = ({
  id,
  onSuspendPress,
  onDeletePress,
  accountSuspended,
  customerId,
  onEditUser,
  showEditButton,
  showDeleteButton = true,
  showSuspendButton = true,
  user,
}) => {
  const IconComponent = accountSuspended ? Play : Pause;
  return (
    <section className={styles["tools-container"]}>
      {showEditButton && (
        <IconButton
          component={Link}
          onClick={() => {
            onEditUser && onEditUser();
          }}
          to={getPageLink(CUSTOMER_EDIT_USER_PAGE, { id, customerId })}
        >
          <Edit />
        </IconButton>
      )}

      {showSuspendButton && (
        <IconButton
          onClick={() => {
            onSuspendPress(id, accountSuspended);
          }}
        >
          <IconComponent />
        </IconButton>
      )}
      {showDeleteButton && (
        <IconButton
          onClick={() => {
            onDeletePress(id, user);
          }}
        >
          <Delete />
        </IconButton>
      )}
    </section>
  );
};

Tools.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSuspendPress: PropTypes.func,
  onDeletePress: PropTypes.func,
  onEditUser: PropTypes.func,
  accountSuspended: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showSuspendButton: PropTypes.bool,
  customerId: PropTypes.string,
  showEditButton: PropTypes.bool,
  user: PropTypes.object,
};

Tools.defaultProps = {
  showEditButton: true,
};

export default Tools;
