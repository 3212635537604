import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import styles from "./SecuritySettings.module.scss";
import { USER_DETAILS_FORMS } from "../../constants/forms";
import {
  DISABLE_ADD_EXTRA_LABEL,
  DISABLE_ADHOC_EDIT,
  DISABLE_EXPORT_FILE_SETUP,
  DISABLE_EXTENDED_LIABILITY,
  DISABLE_IMPORT_FILE_SETUP,
  SECURITY_SETTINGS_WARNING_ONE,
  SECURITY_SETTINGS_WARNING_TWO,
} from "../../constants/strings";
import {
  DISABLE_ADD_EXTRA_LABEL_FIELD,
  DISABLE_ADHOC_EDIT_SHIPMENT_FIELD,
  DISABLE_EXPORT_FILE_SETUP_FIELD,
  DISABLE_EXTENDED_LIABILITY_FIELD,
  DISABLE_IMPORT_FILE_SETUP_FIELD,
} from "../../features/userDetailsForm/securitySettings/securitySettings.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";
import WarningText from "../WarningText/WarningText";

const SecuritySettings = ({ onToggleChange }) => (
  <Grid container direction="column">
    <Grid item container>
      <WarningText text={SECURITY_SETTINGS_WARNING_ONE} />
      <WarningText text={SECURITY_SETTINGS_WARNING_TWO} />
    </Grid>
    <Grid item container justify="space-between">
      <Field
        name={DISABLE_ADHOC_EDIT_SHIPMENT_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={DISABLE_ADHOC_EDIT}
        onChange={(event, value) => {
          onToggleChange(DISABLE_ADHOC_EDIT_SHIPMENT_FIELD, value);
        }}
      />

      <Field
        name={DISABLE_ADD_EXTRA_LABEL_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={DISABLE_ADD_EXTRA_LABEL}
        onChange={(event, value) => {
          onToggleChange(DISABLE_ADD_EXTRA_LABEL_FIELD, value);
        }}
      />
      <Field
        name={DISABLE_IMPORT_FILE_SETUP_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={DISABLE_IMPORT_FILE_SETUP}
        onChange={(event, value) => {
          onToggleChange(DISABLE_IMPORT_FILE_SETUP_FIELD, value);
        }}
      />
    </Grid>
    <Grid item container justify="space-between" className={styles.container}>
      <Field
        name={DISABLE_EXPORT_FILE_SETUP_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={DISABLE_EXPORT_FILE_SETUP}
        onChange={(event, value) => {
          onToggleChange(DISABLE_EXPORT_FILE_SETUP_FIELD, value);
        }}
      />

      <Field
        name={DISABLE_EXTENDED_LIABILITY_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={DISABLE_EXTENDED_LIABILITY}
        onChange={(event, value) => {
          onToggleChange(DISABLE_EXTENDED_LIABILITY_FIELD, value);
        }}
      />
    </Grid>
  </Grid>
);

SecuritySettings.propTypes = {
  onToggleChange: PropTypes.func.isRequired,
};

export default reduxForm({
  form: USER_DETAILS_FORMS.SECURITY_SETTINGS,
  initialValues: {},
})(SecuritySettings);
