import { Box } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./InputWithTopTitle.module.scss";
import InputWithTitle from "../../../../../components/InputWithTitle/InputWithTitle";

const InputWithTopTitle = ({ topTitle, ...other }) => (
  <Box>
    <div className={classNames(styles.inputTitle, styles.inputTitleMargin)}>
      {topTitle}
    </div>
    <InputWithTitle {...other} inputStyles={styles.inputStyles} />
  </Box>
);

export default InputWithTopTitle;

InputWithTopTitle.propTypes = {
  topTitle: PropTypes.string,
};
