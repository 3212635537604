import { change } from "redux-form";

import { USER_DETAILS_FORMS } from "../../../constants/forms";
import {
  NEW_PASSWORD_FIELD,
  PASSWORD_LOCK_FIELD,
  RE_ENTER_PASSWORD_FIELD,
} from "./userDetails.constants";
import { labelSettingsInitialValues } from "./userDetails.models";

export const changeUserDetailsForm = (field, value) =>
  change(USER_DETAILS_FORMS.DPD_USER_DETAILS, field, value);

export const clearPasswords = () => (dispatch) => {
  dispatch(changeUserDetailsForm(NEW_PASSWORD_FIELD, ""));
  dispatch(changeUserDetailsForm(RE_ENTER_PASSWORD_FIELD, ""));
};

export const deselectPasswordLock = () => (dispatch) =>
  dispatch(changeUserDetailsForm(PASSWORD_LOCK_FIELD, false));

export const clearPasswordSection = () => (dispatch) => {
  dispatch(clearPasswords());
  dispatch(deselectPasswordLock());
};

export const resetLabelSettings = () => (dispatch) => {
  const initialValues = labelSettingsInitialValues();

  Object.entries(initialValues).map(([key, value]) =>
    dispatch(changeUserDetailsForm(key, value))
  );
};
