import {
  EOD_DAY_FIELD,
  EOD_TIME_FIELD,
  MIN_AUTO_EOD,
  NO_TIME_SELECTED,
  SELECT_THREE_AUTO_EOD,
} from "./shippingSettings.constants";

export const validate = ({
  [EOD_TIME_FIELD]: autoEodTime = "",
  [EOD_DAY_FIELD]: weekdays = [],
}) => {
  const errors = {};

  if (!autoEodTime) {
    errors[EOD_TIME_FIELD] = NO_TIME_SELECTED;
  }

  if (weekdays.length < MIN_AUTO_EOD) {
    errors[EOD_DAY_FIELD] = { _error: SELECT_THREE_AUTO_EOD };
  }

  return errors;
};
