import {
  ACCOUNT_ID_FIELD,
  NOTIFICATION_NUMBER_FIELD,
  REQUIRED_VALIDATION_FIELD_LABELS,
} from "./accountDetails.constants";
import {
  GB_POSTCODE_PATTERN,
  IE_POSTCODE_PATTERN,
  PHONE_NUMBER_REGEX,
} from "../../../constants/regex";
import { INVALID_PHONE_NOT_A_VALID_PHONE_NUMBER } from "../../../constants/strings";
import { isEmailValid } from "../../../utils/commonValidation";
import { formatMessage } from "../../../utils/string";
import {
  FIELD_CANT_BE_EMPTY,
  INVALID_POSTCODE,
  PLEASE_ENTER_VALID_EMAIL,
} from "../../auth/auth.constants";
import { INVALID_ACCOUNT_NUMBER } from "../../customer/customer.constants";
import { isAdminAccount } from "../../customer/customer.model";

export const validateRequiredCustomerFields = ({
  postcode = "",
  addressline1 = "",
  addressline3 = "",
  country = "",
}) => {
  const errors = {};

  const validateFields = { postcode, addressline1, addressline3, country };

  if (country === "GB" && !GB_POSTCODE_PATTERN.test(postcode)) {
    errors.postcode = INVALID_POSTCODE;
  }

  if (country === "IE" && !IE_POSTCODE_PATTERN.test(postcode)) {
    errors.postcode = INVALID_POSTCODE;
  }

  return Object.keys(validateFields).reduce((acc, key) => {
    if (!validateFields[key].trim()) {
      acc[key] = formatMessage(
        FIELD_CANT_BE_EMPTY,
        REQUIRED_VALIDATION_FIELD_LABELS[key]
      );
    }

    return acc;
  }, errors);
};

export const validateCustomerDetails = ({
  postcode = "",
  addressline1 = "",
  addressline3 = "",
  country = "",
  notifyTel = "",
  notifyEmail = "",
}) => {
  const errors = {};

  const addressValidationErrors = validateRequiredCustomerFields({
    postcode,
    addressline1,
    addressline3,
    country,
  });

  if (notifyTel && !PHONE_NUMBER_REGEX.test(notifyTel)) {
    errors.notifyTel = INVALID_PHONE_NOT_A_VALID_PHONE_NUMBER;
  }

  if (notifyEmail && notifyEmail.trim() && !isEmailValid(notifyEmail)) {
    errors.notifyEmail = PLEASE_ENTER_VALID_EMAIL;
  }

  return { ...errors, ...addressValidationErrors };
};

export const validate = ({
  postcode = "",
  addressline1 = "",
  addressline3 = "",
  country = "",
  account = "",
  notifyTel = "",
  notifyEmail = "",
}) => {
  const errors = {};

  const customerDetailsErrors = validateCustomerDetails({
    postcode,
    addressline1,
    addressline3,
    country,
    notifyTel,
    notifyEmail,
  });

  if (notifyTel && !PHONE_NUMBER_REGEX.test(notifyTel)) {
    errors[NOTIFICATION_NUMBER_FIELD] = INVALID_PHONE_NOT_A_VALID_PHONE_NUMBER;
  }

  if (!account.trim()) {
    errors.account = formatMessage(
      FIELD_CANT_BE_EMPTY,
      REQUIRED_VALIDATION_FIELD_LABELS[ACCOUNT_ID_FIELD]
    );
  }

  if (account.trim() && account.length < 3 && !isAdminAccount(account)) {
    errors.account = INVALID_ACCOUNT_NUMBER;
  }

  return { ...customerDetailsErrors, ...errors };
};
