import {
  ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD,
  INCLUDE_SHIPPING_BY_OTHERS_FIELD,
  SHARE_SHIPMENTS_BY_PROFILE_FIELD,
} from "../miscellaneousConfiguration/miscellaneousConfiguration.constants";
import {
  EOD_DAY_FIELD,
  EOD_DISABLE_PRINT_MANIFEST_FIELD,
  EOD_TIME_FIELD,
  EOD_USER_FIELD,
  ON_SCREEN_IMPORT_FIX_FIELD,
  USE_BAD_FILE_FIELD,
  USE_SCREEN_FIELD,
  WEEKDAYS_DEFAULT,
} from "./shippingSettings.constants";

export const prepareShippingSetting = (fieldValues) => {
  const shippingSettings = Object.entries(fieldValues).reduce(
    (acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      switch (key) {
        case USE_BAD_FILE_FIELD:
          acc[ON_SCREEN_IMPORT_FIX_FIELD] = 0;
          break;
        case USE_SCREEN_FIELD:
          acc[ON_SCREEN_IMPORT_FIX_FIELD] = 1;
          break;
        case EOD_DAY_FIELD:
          if (fieldValues[EOD_USER_FIELD]) {
            acc[EOD_DAY_FIELD] = value.sort().join();
          }
          break;
        case EOD_TIME_FIELD:
          acc[EOD_TIME_FIELD] = value; // todo timestamp
          break;
        default:
          acc[key] = 1;
      }

      return acc;
    },
    {}
  );

  return { shippingSettings };
};

export const isEodMasterDefaultsSelected = (
  endOfDayValues,
  miscellaneousValues
) => {
  const eodSelected = endOfDayValues && endOfDayValues[EOD_USER_FIELD];
  const shareShipmentsByProfile =
    miscellaneousValues &&
    miscellaneousValues[SHARE_SHIPMENTS_BY_PROFILE_FIELD];
  const allowEditShipments =
    miscellaneousValues &&
    miscellaneousValues[ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD];

  return !!(eodSelected && shareShipmentsByProfile && allowEditShipments);
};

export const isEodMasterEnabled = (endOfDayValues, miscellaneousValues) => {
  const isDefaultsEnabled = isEodMasterDefaultsSelected(
    endOfDayValues,
    miscellaneousValues
  );
  const disablePrintManifest =
    endOfDayValues && endOfDayValues[EOD_DISABLE_PRINT_MANIFEST_FIELD];
  const includeRaisedShipments =
    miscellaneousValues &&
    miscellaneousValues[INCLUDE_SHIPPING_BY_OTHERS_FIELD];

  return (
    isDefaultsEnabled && !!(disablePrintManifest && includeRaisedShipments)
  );
};

export const getShippingInitialValues = () => ({
  [USE_SCREEN_FIELD]: true,
  ...getEndOfDayInitialValues(),
});

export const getEndOfDayInitialValues = () => ({
  [EOD_DAY_FIELD]: WEEKDAYS_DEFAULT,
  [EOD_TIME_FIELD]: "",
});
