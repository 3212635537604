import { createSelector } from "reselect";

import { getConfig } from "../config/config.selectors";

export const customerActivityLogs = (state) =>
  state.customerAudit.userCustomerActivityLogs;

export const getConfigForDescriptions = createSelector(
  getConfig,
  ({ dpdLocalServices, dpdServices, serviceRestrictions }) => ({
    services: [...dpdLocalServices, ...dpdServices],
    restrictions: serviceRestrictions,
  })
);
