import { USER_DETAILS_FORMS } from "../../constants/forms";

export const VALIDATE_USER_CUSTOMER_DETAILS_FORMS = [
  USER_DETAILS_FORMS.DPD_USER_DETAILS,
  USER_DETAILS_FORMS.SYSTEM_ACCESS,
  USER_DETAILS_FORMS.MISCELLANEOUS_CONFIGURATION,
  USER_DETAILS_FORMS.ADVANCED_CUSTOMER_CONFIGURATION,
  USER_DETAILS_FORMS.SHIPPING_SETTINGS,
  USER_DETAILS_FORMS.SECURITY_SETTINGS,
  USER_DETAILS_FORMS.XREF_MAPPINGS,
  USER_DETAILS_FORMS.SERVICE_EXCLUSIONS,
  USER_DETAILS_FORMS.SERVICE_RESTRICTIONS,
  USER_DETAILS_FORMS.USER_PROFILES,
];

export const VALIDATE_USER_DETAILS_FORMS = [
  USER_DETAILS_FORMS.ACCOUNT_DETAILS,
  ...VALIDATE_USER_CUSTOMER_DETAILS_FORMS,
];
