import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { PLEASE_NOTE } from "../../constants/strings";
import { hideModal } from "../../features/modal/modal.actions";
import { WIDTH_TYPE } from "../../features/modal/modal.constants";
import styles from "./Modal.module.scss";

const BaseModal = ({
  title = "",
  titleStyles,
  notificationType,
  onCloseClick,
  onConfirmClick,
  cancelButtonText = "cancel",
  confirmButtonText = "confirm",
  widthType = WIDTH_TYPE.EXTRA_SMALL,
  alignButton = "",
  showCancelButton = true,
  showConfirmButton = true,
  contentText,
  disabledConfirmButton = false,
  note = "",
  children,
  footerWarningMessage = "",
  showFooterWarning,
}) => {
  const classes = { paper: styles[widthType] };

  return (
    <Dialog open classes={classes} disableScrollLock>
      <div className={classNames(styles.container)}>
        {!!title && (
          <MuiDialogTitle
            disableTypography
            className={classNames(
              styles.title,
              titleStyles && titleStyles,
              notificationType && styles[notificationType]
            )}
          >
            {title}
          </MuiDialogTitle>
        )}
        {contentText ? (
          <DialogContent className={classNames(styles.contentText)}>
            <DialogContentText id="alert-dialog-description">
              {contentText}
            </DialogContentText>
            {note && (
              <div className={styles.noteContainer}>
                <p className={styles.noteContent}>
                  <span className={styles.noteTitle}>{PLEASE_NOTE}</span>
                  {note}
                </p>
              </div>
            )}
          </DialogContent>
        ) : (
          children
        )}
        <DialogActions
          className={classNames(
            styles["button-container"],
            alignButton && styles[alignButton]
          )}
        >
          {!!showCancelButton && (
            <Button onClick={onCloseClick} color="secondary">
              {cancelButtonText}
            </Button>
          )}
          {!!showConfirmButton && (
            <Button
              className={classNames(
                styles.button,
                disabledConfirmButton && styles["disabled-button"]
              )}
              onClick={onConfirmClick}
              disabled={disabledConfirmButton}
            >
              {confirmButtonText}
            </Button>
          )}
        </DialogActions>
        {showFooterWarning && (
          <div className={styles.footerWarningContainer}>
            {footerWarningMessage && (
              <Typography className={styles.footerWarning}>
                {footerWarningMessage}
              </Typography>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

BaseModal.propTypes = {
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  title: PropTypes.string,
  widthType: PropTypes.string,
  titleStyles: PropTypes.string,
  notificationType: PropTypes.string,
  onCloseClick: PropTypes.func,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  alignButton: PropTypes.string,
  showCancelButton: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
  contentText: PropTypes.string,
  disabledConfirmButton: PropTypes.bool,
  children: PropTypes.object,
  cancelActionId: PropTypes.string,
  confirmActionId: PropTypes.string,
  note: PropTypes.string,
  footerWarningMessage: PropTypes.string,
  showFooterWarning: PropTypes.bool,
};

export default compose(
  connect(null, {
    closeModal: hideModal,
  }),
  withTrackProps(({ closeModal, cancelActionId, confirmActionId }) => ({
    onCloseClick: () => {
      closeModal();
      return cancelActionId;
    },
    onConfirmClick: () => {
      closeModal();
      return confirmActionId;
    },
  }))
)(BaseModal);
