import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import * as auditService from "./audit.service";

const ACTION_NAMESPACE = "AUDIT";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  FETCH_AUDIT_LOGS: createAsyncActionTypes("FETCH_AUDIT_LOGS"),
});

export const getAuditsByAccount = createAsyncAction(
  (account, query) => () => auditService.getAuditByAccount(account, query),
  ACTIONS.FETCH_AUDIT_LOGS
);
