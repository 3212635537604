import {
  createAction,
  createActionTypes,
  createAsyncAction,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

const ACTION_NAMESPACE = "SNACKBAR";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  HIDE_SNACKBAR: "HIDE_SNACKBAR",
});

export const showSnackbar = (props) =>
  createPayloadAction(ACTIONS.SHOW_SNACKBAR, props);

export const hideSnackbar = () => createAction(ACTIONS.HIDE_SNACKBAR);

export const createAsyncErrorAction =
  (...actionParams) =>
  (...args) =>
  async (dispatch) => {
    try {
      return dispatch(createAsyncAction(...actionParams)(...args));
    } catch (e) {
      dispatch(showSnackbar({ message: e.message || e }));
      return e;
    }
  };

export const createSnackbarErrorAction =
  (errorMessage, ...actionParams) =>
  (...args) =>
  async (dispatch) => {
    try {
      return await dispatch(createAsyncAction(...actionParams)(...args));
    } catch (e) {
      dispatch(showSnackbar({ message: errorMessage || e.message || e }));
      return e;
    }
  };
