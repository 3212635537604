import { Typography } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./HeaderWithStar.module.scss";
const STAR = "*";
const HeaderWithStar = ({ text, required, headerStyles }) => (
  <Typography className={classNames(styles.text, headerStyles)}>
    {text} {required && <span className={styles.required}>{STAR}</span>}
  </Typography>
);

HeaderWithStar.propTypes = {
  text: PropTypes.string,
  required: PropTypes.bool,
  headerStyles: PropTypes.string,
};

export default HeaderWithStar;
