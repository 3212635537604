import { useState } from "react";

import PropTypes from "prop-types";

import CustomTabs from "../../components/CustomTabs/CustomTabs.js";

const ShippingSettings = ({
  shippingSettingsTabs,
  onInputChange,
  isSendersRef1Disabled,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <CustomTabs
      tabs={shippingSettingsTabs}
      onChange={(activeTabIndex) => {
        setActiveTabIndex(activeTabIndex);
      }}
    >
      {shippingSettingsTabs.map((tabItem) => (
        <tabItem.component
          key={tabItem.label}
          activeTabIndex={activeTabIndex}
          onInputChange={onInputChange}
          isSendersRef1Disabled={isSendersRef1Disabled}
        />
      ))}
    </CustomTabs>
  );
};

export default ShippingSettings;

ShippingSettings.propTypes = {
  shippingSettingsTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onInputChange: PropTypes.func,
  isSendersRef1Disabled: PropTypes.bool,
};
