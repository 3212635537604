import { change, initialize, registerField } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { preparedUserProfiles } from "./userProfiles.models";
import { NO_PROFILE_WARNING_MODAL } from "../../../constants/analytics";
import { USER_DETAILS_FORMS, USER_FORMS } from "../../../constants/forms";
import {
  CLOSE,
  USER_PROFILES_NO_PROFILE_LINKED,
  WARNING,
} from "../../../constants/strings";
import { showModal } from "../../modal/modal.actions";
import { ALIGN_BUTTONS } from "../../modal/modal.constants";
import { getProfilesByAccount } from "../../profile/profile.service";

const ACTION_NAMESPACE = "USER_PROFILES";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  FETCH_USER_PROFILES: createAsyncActionTypes("FETCH_USER_PROFILES"),
});

export const onChangeUserProfiles = (profiles, type) =>
  change(USER_DETAILS_FORMS.USER_PROFILES, type, profiles);

export const onInitUserProfiles = (profiles, type) =>
  initialize(USER_FORMS.USER_PROFILES, { [type]: profiles });

export const resetUserProfilesForm = () => (dispatch) => {
  dispatch(changeUnallocatedProfiles([]));
  dispatch(changeAllocatedProfiles([]));
};

export const changeUnallocatedProfiles = (profiles) => (dispatch) => {
  dispatch(onChangeUserProfiles(profiles, "unallocated"));
};

export const changeAllocatedProfiles = (profiles) => (dispatch) => {
  dispatch(onChangeUserProfiles(profiles, "allocated"));
};

export const showNoProfilesModal = () => (dispatch) =>
  dispatch(
    showModal({
      interfaceId: NO_PROFILE_WARNING_MODAL.INTERFACE_ID,
      loadId: NO_PROFILE_WARNING_MODAL.LOAD,
      confirmActionId: NO_PROFILE_WARNING_MODAL.CLOSE,
      contentText: USER_PROFILES_NO_PROFILE_LINKED,
      notificationType: WARNING,
      title: WARNING,
      confirmButtonText: CLOSE,
      showCancelButton: false,
      alignButton: ALIGN_BUTTONS.CENTER,
    })
  );

export const populateCustomerAccountProfiles =
  (mappedProfiles) => (dispatch) => {
    if (!mappedProfiles.length) {
      dispatch(showNoProfilesModal());
    }
    dispatch(onInitUserProfiles([], "allocated"));
    dispatch(onInitUserProfiles(mappedProfiles, "unallocated"));
  };

export const populateAccountProfiles = (mappedProfiles) => (dispatch) => {
  if (!mappedProfiles.length) {
    dispatch(showNoProfilesModal());
  }
  dispatch(changeAllocatedProfiles([]));
  dispatch(changeUnallocatedProfiles(mappedProfiles));
};

export const getAccountProfiles = createAsyncAction(
  (accountId) => async () => {
    const profiles = await getProfilesByAccount(accountId);
    return preparedUserProfiles(profiles);
  },
  ACTIONS.FETCH_USER_PROFILES
);

export const prepareProfilesForm = (accountId) => async (dispatch) => {
  const profiles = await dispatch(getAccountProfiles(accountId));
  dispatch(populateCustomerAccountProfiles(profiles));
};

export const registerAllocatedProfileField = () =>
  registerField(USER_DETAILS_FORMS.USER_PROFILES, "allocated", "Field");
