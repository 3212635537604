import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import isFunction from "lodash/isFunction";
import PropTypes from "prop-types";

import styles from "./InputWithTitle.module.scss";
import InputTitleAdornment from "../InputTitleAdornment/InputTitleAdornment";
import InputTitleEndAdornment from "../InputTitleAdornment/InputTitleEndAdornment";

const onBlurFunc = (input, blurNormalize, onBlur) => (e) => {
  const {
    target: { value },
  } = e;
  let newValue = value;

  if (isFunction(blurNormalize)) {
    newValue = blurNormalize(value);
    input.onChange(newValue);
  }

  onBlur && onBlur(e, value);
  input.onBlur(newValue);
};

const InputWithTitle = ({
  label,
  icon,
  avatar,
  input,
  meta,
  showMetaErrors,
  required,
  inputProps,
  withUpperCase,
  onClearClick,
  type,
  disabled,
  inputStyles,
  adornmentStyles,
  InputProps,
  blurNormalize,
  onBlur,
  ...props
}) => {
  const displayMetaError =
    showMetaErrors &&
    !meta.valid &&
    (meta.dirty || meta.touched) &&
    !meta.active;
  return (
    <TextField
      InputProps={{
        classes: {
          adornedEnd: styles.adornedEnd,
          adornedStart: styles.adornedStart,
        },
        startAdornment: (label || icon || avatar) && (
          <InputTitleAdornment
            label={label}
            icon={icon}
            avatar={avatar}
            required={required}
            meta={meta}
            adornmentStyles={adornmentStyles}
            showMetaErrors={displayMetaError}
          />
        ),
        endAdornment:
          onClearClick && disabled ? (
            <InputTitleEndAdornment onClearClick={onClearClick} />
          ) : null,
        inputProps,
        ...InputProps,
        onBlur: onBlurFunc(input, blurNormalize, onBlur),
      }}
      className={classNames(
        styles.input,
        withUpperCase && styles.withUpperCase,
        disabled && styles.disabled,
        inputStyles && inputStyles,
        displayMetaError && styles["meta-error"]
      )}
      type={type}
      disabled={disabled}
      fullWidth
      {...input}
      {...props}
      onDrop={(e) => e.preventDefault()}
    />
  );
};

InputWithTitle.propTypes = {
  classes: PropTypes.any,
  InputProps: PropTypes.any,
  label: PropTypes.string,
  input: PropTypes.object,
  required: PropTypes.bool,
  withUpperCase: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  icon: PropTypes.node,
  avatar: PropTypes.string,
  type: PropTypes.string,
  onClearClick: PropTypes.func,
  inputStyles: PropTypes.any,
  meta: PropTypes.object,
  showMetaErrors: PropTypes.bool,
  adornmentStyles: PropTypes.object,
  updateFocus: PropTypes.func,
  blurNormalize: PropTypes.func,
  onBlur: PropTypes.func,
};

InputWithTitle.defaultProps = {
  required: false,
  type: "text",
  showMetaErrors: false,
  adornmentStyles: {},
};

export default InputWithTitle;
