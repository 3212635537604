import { useEffect } from "react";

import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { Field, isValid, reduxForm } from "redux-form";

import { USER_FORMS } from "../../../constants/forms";
import {
  ACCOUNT_DETAILS,
  ACCOUNT_NUMBER,
  COMPANY,
} from "../../../constants/strings";
import { getAddressByPostcode } from "../../../features/config/config.service";
import { WIDTH_TYPE } from "../../../features/modal/modal.constants";
import { checkRequiredFields } from "../../../features/myDpdUserForm/accountDetails/accountDetails.actions";
import { getModalErrorMessage } from "../../../features/myDpdUserForm/accountDetails/accountDetails.selectors";
import {
  ACCOUNT_ID_FIELD,
  COMPANY_FIELD,
} from "../../../features/userDetailsForm/accountDetails/accountDetails.constants";
import { formatItem } from "../../../features/userDetailsForm/accountDetails/accountDetails.models";
import { validateCustomerDetails } from "../../../features/userDetailsForm/accountDetails/accountDetails.validate";
import { onPostcodeItemClick } from "../../../features/userDetailsForm/accountDetails/actionsDetails.actions";
import CustomerDetails from "../../../pages/dpd-user/components/CustomerDetails/CustomerDetails";
import FieldWithLabel from "../../FieldWithLabel/FieldWithLabel";
import GradientHeader from "../../GradientHeader/GradientHeader";
import HashIcon from "../../HashIcon/HashIcon";
import BaseModal from "../BaseModal";
import styles from "./CustomerDetailsModal.module.scss";

const CustomerDetailsModal = (props) => {
  const dispatch = useDispatch();

  const isCustomerValidationFormValid = useSelector(
    isValid(USER_FORMS.ACCOUNT_DETAILS)
  );

  const modalErrorMessage = useSelector(getModalErrorMessage);

  const showValidationErrors = () => dispatch(checkRequiredFields());
  const onItemClick = (itemDetails) =>
    dispatch(onPostcodeItemClick(itemDetails, USER_FORMS));

  useEffect(() => {
    showValidationErrors();
  }, [showValidationErrors]);

  return (
    <BaseModal
      widthType={WIDTH_TYPE.EXTRA_LARGE}
      alignButton="right"
      footerWarningMessage={modalErrorMessage}
      showFooterWarning
      disabledConfirmButton={!isCustomerValidationFormValid}
      {...props}
    >
      <GradientHeader title={ACCOUNT_DETAILS} />

      <Box className={styles.contentWrapper}>
        <Box className={styles.companyDetails}>
          <Box className={styles.detailAccount}>
            <Field
              name={ACCOUNT_ID_FIELD}
              label={ACCOUNT_NUMBER}
              component={FieldWithLabel}
              icon={<HashIcon />}
              classNameValue={styles.name}
            />
          </Box>
          <Box className={styles.detailName}>
            <Field
              name={COMPANY_FIELD}
              label={COMPANY}
              component={FieldWithLabel}
              classNameValue={styles.name}
            />
          </Box>
        </Box>
        <CustomerDetails
          fetchFunction={getAddressByPostcode}
          formatItem={formatItem}
          showMetaErrors
          onItemClick={onItemClick}
        />
      </Box>
    </BaseModal>
  );
};

export default reduxForm({
  form: USER_FORMS.ACCOUNT_DETAILS,
  validate: validateCustomerDetails,
})(CustomerDetailsModal);
