import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD } from "./miscellaneousConfiguration.constants";
import { getEodMasterAccountDefaults } from "./miscellaneousConfiguration.models";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { isMasterEodExist } from "../userDetailsForm.selector";

export const getMiscellaneousConfigurationFormValues = getFormValues(
  USER_DETAILS_FORMS.MISCELLANEOUS_CONFIGURATION
);

export const allowEditShipmentsByProfile = createSelector(
  getMiscellaneousConfigurationFormValues,
  ({ [ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD]: allowEditByProfile }) =>
    !!allowEditByProfile
);

export const getMiscellaneousConfigurationInitialValues = createSelector(
  isMasterEodExist,
  (isMasterEodExist) => (isMasterEodExist ? getEodMasterAccountDefaults() : {})
);
