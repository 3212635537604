import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import styles from "./ActivityItem.module.scss";
import ActivityListItem from "./ActivityListItem";
import ActivityMonitorPagination from "./ActivityMonitorPagination";
import { formatDate } from "../../../../utils/timeFormater";

export const ActivityItem = ({ activities = [], total = 0 }) => (
  <Box className={styles.container}>
    <Box classes={{ root: styles.itemsContainer }}>
      {activities.map((item, index) => (
        <>
          {(index === 0 ||
            formatDate(activities[index - 1].date) !==
              formatDate(activities[index].date)) && (
            <Typography classes={{ root: styles.date }}>
              {formatDate(activities[index].date)}
            </Typography>
          )}
          <ActivityListItem auditLog={item} key={index + item.date} />
        </>
      ))}
    </Box>
    <ActivityMonitorPagination totalItems={total} />
  </Box>
);

ActivityItem.propTypes = {
  date: PropTypes.any,
  activities: PropTypes.array,
  total: PropTypes.number,
};
