import { initialize, reset, getFormValues } from "redux-form";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import {
  DPD_APP_CONFIG_FORM,
  DPD_APP_VERSION_DETAILS_FORM,
} from "../../constants/forms";
import { createLoadingAction } from "../loader/loader.actions";
import { showInfoModal } from "../modal/modal.actions";

import * as dpdAppConfigService from "./appManagement.service";
import {
  MINIMUM_VERSION_FIELD,
  LATEST_VERSION_FIELD,
  ALLOW_DRAFT_FIELD,
  ALLOW_PRERELEASE_FIELD,
  ALLOW_DEPRECATED_FIELD,
  UPDATE_CONFIG_ERROR_MESSAGE,
  UPDATE_VERSIONS_ERROR_MESSAGE,
} from "./appManagement.constants";
import * as DPDAppConfigSelector from "./appManagement.selectors";
import {
  findAppVersionsChanges,
  getUpdateVersionsDetails,
} from "./appManagement.models";

const ACTION_NAMESPACE = "DPD_APP_CONFIG";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  LOAD_DPD_APP_CONFIG_DATA: createAsyncActionTypes("LOAD_DPD_APP_CONFIG_DATA"),
  LOAD_DPD_APP_VERSIONS_DETAILS: createAsyncActionTypes(
    "LOAD_DPD_APP_VERSIONS_DETAILS"
  ),
  FETCH_DPD_APP_CONFIG: createAsyncActionTypes("FETCH_DPD_APP_CONFIG"),
  FETCH_DPD_APP_VERSION: createAsyncActionTypes("FETCH_DPD_APP_CONFIG_DATA"),
  FETCH_DPD_APP_VERSION_DETAILS: createAsyncActionTypes(
    "FETCH_DPD_APP_VERSION_DETAILS"
  ),
  UPDATE_DPD_APP_CONFIG: createAsyncActionTypes("UPDATE_DPD_APP_CONFIG"),
  UPDATE_DPD_APP_VERSION_DETAILS: "UPDATE_DPD_APP_VERSION_DETAILS",
});

export const updateVersionsDetails = (versions) =>
  createPayloadAction(ACTIONS.UPDATE_DPD_APP_VERSION_DETAILS, versions);

export const loadDPDAppConfig = createAsyncAction(
  () => dpdAppConfigService.getDPDAppConfig,
  ACTIONS.FETCH_DPD_APP_CONFIG
);

export const loadDPDAppVersionsDetailsData = createAsyncAction(
  () => dpdAppConfigService.getDPDAppVersionsDetails,
  ACTIONS.FETCH_DPD_APP_VERSION_DETAILS
);

export const loadDPDAppVersions = createAsyncAction(
  () => dpdAppConfigService.getDPDAppVersions,
  ACTIONS.FETCH_DPD_APP_VERSION
);

export const updateDPDAppConfig = createAsyncAction(
  (params) => () => dpdAppConfigService.updateDPDAppConfig(params),
  ACTIONS.UPDATE_DPD_APP_CONFIG
);

export const loadDPDAppVersionsDetails = createLoadingAction(
  createAsyncAction(
    () => async (dispatch, getState) => {
      await dispatch(loadDPDAppVersionsDetailsData());
      const versions = DPDAppConfigSelector.getAppVersionsDetails(getState());

      dispatch(
        initialize(DPD_APP_VERSION_DETAILS_FORM, {
          ["versions"]: versions,
        })
      );
    },
    ACTIONS.LOAD_DPD_APP_VERSIONS_DETAILS
  )
);

export const onCancelVersions = () => (dispatch) => {
  dispatch(reset(DPD_APP_VERSION_DETAILS_FORM));
};

export const onUpdateVersions = createLoadingAction(
  () => async (dispatch, getState) => {
    const { versions } = getFormValues(DPD_APP_VERSION_DETAILS_FORM)(
      getState()
    );
    const originVersions = DPDAppConfigSelector.getAppVersionsDetails(
      getState()
    );
    const versionsChanged = findAppVersionsChanges(versions, originVersions);

    if (versionsChanged.length) {
      const versionsToUpdate = getUpdateVersionsDetails(versionsChanged);

      try {
        await dpdAppConfigService.updateDPDAppVersionsDetails(versionsToUpdate);
        await dispatch(loadDPDAppVersions());
        dispatch(updateVersionsDetails(versions));
        dispatch(
          initialize(DPD_APP_VERSION_DETAILS_FORM, {
            ["versions"]: versions,
          })
        );
      } catch (err) {
        console.error(err);
        dispatch(showInfoModal(UPDATE_VERSIONS_ERROR_MESSAGE));
      }
    }
  }
);

export const loadDPDAppConfigData = createLoadingAction(
  createAsyncAction(
    () => async (dispatch, getState) => {
      await dispatch(loadDPDAppConfig());
      await dispatch(loadDPDAppVersions());

      const state = getState();
      const config = DPDAppConfigSelector.getDPDAppConfig(state);

      await dispatch(
        initialize(DPD_APP_CONFIG_FORM, {
          [MINIMUM_VERSION_FIELD]: config.minVersion,
          [LATEST_VERSION_FIELD]: config.latestVersion,
          [ALLOW_DRAFT_FIELD]: config.allowDraft,
          [ALLOW_PRERELEASE_FIELD]: config.allowPrerelease,
          [ALLOW_DEPRECATED_FIELD]: config.allowDeprecated,
        })
      );
    },
    ACTIONS.LOAD_DPD_APP_CONFIG_DATA
  )
);

export const onCancelConfig = () => (dispatch) => {
  dispatch(reset(DPD_APP_CONFIG_FORM));
};

export const onUpdateConfig = createLoadingAction(
  () => async (dispatch, getState) => {
    const values = getFormValues(DPD_APP_CONFIG_FORM)(getState());
    try {
      await dispatch(updateDPDAppConfig(values));
      await dispatch(
        initialize(DPD_APP_CONFIG_FORM, {
          [MINIMUM_VERSION_FIELD]: values.minVersion,
          [LATEST_VERSION_FIELD]: values.latestVersion,
          [ALLOW_DRAFT_FIELD]: values.allowDraft,
          [ALLOW_PRERELEASE_FIELD]: values.allowPrerelease,
          [ALLOW_DEPRECATED_FIELD]: values.allowDeprecated,
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(showInfoModal(UPDATE_CONFIG_ERROR_MESSAGE));
    }
  }
);
