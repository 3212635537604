import { IP_REGEXP } from "../../constants/regex";
import {
  INVALID_END_IP,
  INVALID_IP,
  INVALID_START_IP,
} from "../../constants/strings";
import { checkIpValid } from "../../utils/ip";
import {
  END_IP_FIELD,
  IP_ADDRESS_FIELD,
  START_IP_FIELD,
} from "./teamSettings.constants";

export const validate = (props) => {
  const errors = {};

  const fields = [IP_ADDRESS_FIELD, START_IP_FIELD, END_IP_FIELD];

  const fieldsErrors = {
    [IP_ADDRESS_FIELD]: INVALID_IP,
    [START_IP_FIELD]: INVALID_START_IP,
    [END_IP_FIELD]: INVALID_END_IP,
  };

  // eslint-disable-next-line array-callback-return
  fields.map((field) => {
    if (
      props[field] &&
      (!IP_REGEXP.test(props[field]) || !checkIpValid(props[field]))
    ) {
      return (errors[field] = fieldsErrors[field]);
    }
  });

  return errors;
};
