import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import styles from "./MappingTable.module.scss";
import { CLEAR_ALL } from "../../constants/strings";
import withErrorTooltip from "../../hocs/withErrorTooltip";
import InputWithActionButton from "../InputWithActionButton/InputWithActionButton";
import ServiceTable from "../ServiceTable/ServiceTable";

const DecoratedInputWithError = withErrorTooltip(InputWithActionButton);
const ServiceTableWithError = withErrorTooltip(ServiceTable);

export const MappingTable = ({
  fieldsNaming,
  inputPlaceholder,
  headerRows,
  fieldArrayName,
  normalize,
  disableButton,
  onClearAllClick,
  onAddMappingClick,
  onSelectValue,
  onRemoveItem,
}) => (
  <Grid container spacing={4}>
    <Grid item container className={styles.add}>
      <Grid item className={styles.input}>
        <Field
          name="mapping"
          component={DecoratedInputWithError}
          placeholder={inputPlaceholder}
          smallInput
          onButtonClick={() => onAddMappingClick()}
          disableActionButton={disableButton}
          normalize={normalize}
        />
      </Grid>
      <Button
        variant="text"
        onClick={onClearAllClick}
        classes={{
          label: styles["button-label"],
          textPrimary: styles.button,
        }}
        disableRipple
      >
        {CLEAR_ALL}
      </Button>
    </Grid>

    <Grid item container>
      <Field
        showErrorsOnRender
        component={ServiceTableWithError}
        name={fieldArrayName}
        headerRows={headerRows}
        fieldsNaming={fieldsNaming}
        onSelectValue={onSelectValue}
        onRemoveItem={onRemoveItem}
      />
    </Grid>
  </Grid>
);

MappingTable.propTypes = {
  onClearAllClick: PropTypes.func,
  headerRows: PropTypes.array,
  fieldsNaming: PropTypes.object,
  normalize: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  fieldArrayName: PropTypes.string,
  onAddMappingClick: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onSelectValue: PropTypes.func,
  disableButton: PropTypes.bool,
};

export default MappingTable;
