import { useMemo } from "react";

import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import styles from "./CustomerDetails.module.scss";
import { ReactComponent as Phone } from "../../../../assets/icons/phone.svg";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import SearchFieldWithList from "../../../../components/SearchFieldWithList/SearchFieldWithList";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import SvgIconComponent from "../../../../components/SvgIcon/svgIcon";
import {
  CITY,
  COUNTRY,
  COUNTY,
  GB,
  IE,
  NOTIFICATION_EMAIL,
  NOTIFICATION_TEL,
  POSTCODE,
  RIA,
  STREET,
  SUBURB,
  UK,
} from "../../../../constants/strings";
import {
  CITY_FIELD,
  COUNTRY_FIELD,
  COUNTY_FIELD,
  NOTIFICATION_EMAIL_FIELD,
  NOTIFICATION_NUMBER_FIELD,
  POSTCODE_FIELD,
  POSTCODE_FIELD_CLICK,
  STREET_FIELD,
  SUBURB_FIELD,
} from "../../../../features/userDetailsForm/accountDetails/accountDetails.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import { uppercase, withoutSpaces } from "../../../../utils/normalize";

const getInputComponentWithError = (showMetaErrors, Component) =>
  showMetaErrors ? Component : withErrorTooltip(Component);

const CustomerDetails = ({
  onInputChange,
  onItemClick,
  formatItem,
  disabled,
  fetchFunction,
  showMetaErrors,
  onBlurFields,
}) => {
  const InputComponent = useMemo(
    () => getInputComponentWithError(showMetaErrors, InputWithTitle),
    [showMetaErrors]
  );
  const SearchFieldComponent = useMemo(
    () => getInputComponentWithError(showMetaErrors, SearchFieldWithList),
    [showMetaErrors]
  );

  const SelectComponent = useMemo(
    () => getInputComponentWithError(showMetaErrors, SelectWithTitle),
    [showMetaErrors]
  );

  return (
    <Box width="100%">
      <Box display="flex" flexDirection="row">
        <Box width={1 / 4} p={1}>
          <Field
            name={POSTCODE_FIELD}
            component={SearchFieldComponent}
            label={POSTCODE}
            onBlur={() => onBlurFields(POSTCODE_FIELD)}
            fetchFunction={fetchFunction}
            onClick={(item) => {
              onInputChange && onInputChange(POSTCODE_FIELD_CLICK);
              onItemClick(item);
            }}
            formatItem={formatItem}
            withUpperCase
            onChange={() => onInputChange && onInputChange(POSTCODE_FIELD)}
            required
            props={{
              disabled,
            }}
            inputProps={{ maxLength: 8 }}
            inputStyles={styles.postcodeInput}
            showMetaErrors={showMetaErrors}
            normalize={uppercase}
          />
        </Box>
        <Box width={3 / 8} p={1}>
          <Field
            name={STREET_FIELD}
            onBlur={() => onBlurFields(STREET_FIELD)}
            required
            label={STREET}
            component={InputComponent}
            props={{
              disabled,
            }}
            inputProps={{ maxLength: 35 }}
            showMetaErrors={showMetaErrors}
          />
        </Box>
        <Box width={3 / 8} p={1}>
          <Field
            name={SUBURB_FIELD}
            onBlur={() => onBlurFields(SUBURB_FIELD)}
            component={InputWithTitle}
            label={SUBURB}
            props={{
              disabled,
            }}
            inputProps={{ maxLength: 35 }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box width={1 / 3} p={1}>
          <Field
            name={CITY_FIELD}
            onBlur={() => onBlurFields(CITY_FIELD)}
            required
            label={CITY}
            component={InputComponent}
            props={{
              disabled,
            }}
            inputProps={{ maxLength: 35 }}
            showMetaErrors={showMetaErrors}
          />
        </Box>
        <Box width={1 / 3} p={1}>
          <Field
            name={COUNTY_FIELD}
            onBlur={() => onBlurFields(COUNTY_FIELD)}
            label={COUNTY}
            component={InputWithTitle}
            props={{
              disabled,
            }}
            inputProps={{ maxLength: 35 }}
          />
        </Box>
        <Box width={1 / 3} p={1}>
          <Field
            component={SelectComponent}
            name={COUNTRY_FIELD}
            onChange={() => onBlurFields(COUNTRY_FIELD)}
            label={COUNTRY}
            required
            props={{
              disabled,
            }}
            showMetaErrors={showMetaErrors}
          >
            <MenuItem value={GB}>
              <Typography align="center" className={styles.country}>
                {UK}
              </Typography>
            </MenuItem>
            <MenuItem value={IE}>
              <Typography align="center" className={styles.country}>
                {RIA}
              </Typography>
            </MenuItem>
          </Field>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box width={1 / 2} p={1}>
          <Field
            name={NOTIFICATION_EMAIL_FIELD}
            onBlur={() => onBlurFields(NOTIFICATION_EMAIL_FIELD)}
            component={InputComponent}
            label={NOTIFICATION_EMAIL}
            icon={<SvgIconComponent name="email" />}
            props={{
              disabled,
            }}
            normalize={withoutSpaces}
            inputProps={{ maxLength: 100 }}
            showMetaErrors={showMetaErrors}
          />
        </Box>
        <Box width={1 / 2} p={1}>
          <Field
            name={NOTIFICATION_NUMBER_FIELD}
            onBlur={() => onBlurFields(NOTIFICATION_NUMBER_FIELD)}
            component={InputComponent}
            label={NOTIFICATION_TEL}
            icon={<Phone />}
            props={{
              disabled,
            }}
            inputProps={{ maxLength: 15 }}
            showMetaErrors={showMetaErrors}
          />
        </Box>
      </Box>
    </Box>
  );
};

CustomerDetails.propTypes = {
  onInputChange: PropTypes.func,
  onItemClick: PropTypes.func,
  formatItem: PropTypes.func,
  disabled: PropTypes.bool,
  fetchFunction: PropTypes.func,
  onBlurFields: PropTypes.func,
  showMetaErrors: PropTypes.bool,
};

CustomerDetails.defaultProps = {
  disabled: false,
  onInputChange: null,
  showMetaErrors: false,
  onBlurFields: () => null,
};

export default CustomerDetails;
