import {
  blueThemeProps,
  globalThemeProps,
  redThemeProps,
} from "../../constants/themes";
import { getLocationHostname } from "../../router/navigation";
import * as localStorageUtils from "../../utils/localStorage";
import {
  DPD_EMAIL,
  DPD_LOCAL_DOMAIN,
  DPD_LOCAL_EMAIL,
} from "../auth/auth.constants";
import { isDpdLocalBusinessId } from "../user/user.model";
import {
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
} from "../userDetailsForm/accountDetails/accountDetails.constants";

const {
  REACT_APP_DPD_DOMAIN,
  REACT_APP_DPD_LOCAL_DOMAIN,
  REACT_APP_API_DOMAIN,
  REACT_APP_API_LOCAL_DOMAIN,
} = process.env;

export const getAppTheme = (businessId) => {
  const savedBusinessId = localStorageUtils.getItem("userTheme");
  const domainBusinessId = getDomainBusinessId();
  const theme = isDpdLocalBusinessId(
    savedBusinessId || businessId || domainBusinessId
  )
    ? blueThemeProps
    : redThemeProps;

  return { ...globalThemeProps, ...theme };
};

export const isDpdLocalDomain = () =>
  getLocationHostname().includes(DPD_LOCAL_DOMAIN);

export const getEmailDomain = () =>
  isDpdLocalDomain() ? DPD_LOCAL_EMAIL : DPD_EMAIL;

export const getDomainBusinessId = () =>
  isDpdLocalDomain() ? DPD_LOCAL_BUSINESS_ID : DPD_BUSINESS_ID;

export const getMyDpdDomain = () =>
  isDpdLocalDomain() ? REACT_APP_DPD_LOCAL_DOMAIN : REACT_APP_DPD_DOMAIN;

export const getAppDomain = () =>
  isDpdLocalDomain()
    ? REACT_APP_API_LOCAL_DOMAIN || REACT_APP_API_DOMAIN
    : REACT_APP_API_DOMAIN;
