import PropTypes from "prop-types";
import { compose } from "recompose";

import { withTrack } from "@dpdgroupuk/react-event-tracker";

import GradientHeader from "../../../../components/GradientHeader/GradientHeader";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import { HOME_PAGE_ANALYTICS } from "../../../../constants/analytics";
import { SEARCH } from "../../../../constants/strings";
import DPDSearchForm from "../../components/SearchForm";

const Search = () => (
  <PageContainer>
    <GradientHeader title={SEARCH} />
    <DPDSearchForm />
  </PageContainer>
);

Search.propTypes = {
  history: PropTypes.object.isRequired,
};

export default compose(
  withTrack({
    loadId: HOME_PAGE_ANALYTICS.LOAD,
    interfaceId: HOME_PAGE_ANALYTICS.INTERFACE_ID,
  })
)(Search);
