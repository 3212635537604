export const DEFAULT_PASSWORD = "PASSWORD";

export const USERNAME_FIELD = "username";
export const EMAIL_FIELD = "email";
export const USE_EMAIL_AS_LOGIN_FIELD = "useEmailAsLogin";
export const PASSWORD_LOCK_FIELD = "passwordLock";
export const NEW_PASSWORD_FIELD = "newPassword";
export const RE_ENTER_PASSWORD_FIELD = "reEnterPassword";
export const ACCOUNT_EXPIRY_DATE_FIELD = "accountExpiry";
export const PASSWORD_FIELD = "password";
