import omit from "lodash/omit";
import { blur, change, SubmissionError, untouch } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import {
  ACCOUNT_FIELD,
  SEARCH_ERRORS,
  SEARCH_RESULT,
  USERNAME_FIELD,
  PAGE_SIZE,
} from "./search.constants";
import {
  getInitialSearchFormValues,
  prepareSearchTableData,
  updateSingleUser,
} from "./search.models";
import { getSearchResults } from "./search.selectors";
import { getAccountUsers } from "../user/user.selectors";
import { DPD_SEARCH_FORM } from "../../constants/forms";
import { NO, YES } from "../../constants/strings";
import { formatMessage } from "../../utils/string";
import { createLoadingAction } from "../loader/loader.actions";
import { onToggleResendInviteClick } from "../user/user.actions";
import { showModal } from "../modal/modal.actions";
import { SUSPEND, SUSPEND_THIS_USER, UNSUSPEND } from "../user/user.constants";
import { getAnalyticIds } from "../user/user.model";
import * as userService from "../user/user.service";

const ACTION_NAMESPACE = "SEARCH_USER";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SEARCH_USER: createAsyncActionTypes("SEARCH_USER"),
  CHECK_EXIST_INVITED_USERS: createAsyncActionTypes(
    "CHECK_EXIST_INVITED_USERS"
  ),
  DELETE_USER: createAsyncActionTypes("DELETE_USER"),
  DELETE_TEMP_USER: createAsyncActionTypes("DELETE_TEMP_USER"),
  SUSPEND_USER: createAsyncActionTypes("SUSPEND_USER"),
});

export const showSuspendUserModal =
  (updateUser) => (uid, accountSuspended) => (dispatch) => {
    const message = accountSuspended ? UNSUSPEND : SUSPEND;
    const contentText = formatMessage(SUSPEND_THIS_USER, message);

    dispatch(
      showModal({
        ...getAnalyticIds([uid], accountSuspended),
        contentText,
        cancelButtonText: NO,
        confirmButtonText: YES,
        onConfirmClick: () =>
          dispatch(updateUser(uid, { accountSuspended: !accountSuspended })),
      })
    );
  };

export const suspendUser = createLoadingAction(
  createAsyncAction(
    (uid, values) => (dispatch) =>
      userService.suspendUser(uid, values).then(() => {
        dispatch(suspendLocalUser(uid, values));
      }),
    ACTIONS.SUSPEND_USER
  )
);

export const onSuspendUnsuspendUserClick = showSuspendUserModal(suspendUser);

export const suspendLocalUser = (uid, values) => (dispatch, getState) => {
  const users = getSearchResults(getState());
  const updatedUsers = updateSingleUser(users, uid, values);

  dispatch(change(DPD_SEARCH_FORM, SEARCH_RESULT, updatedUsers));
};

export const checkExistInvitedUsers = createAsyncAction(
  createLoadingAction(
    ({ account, username, businessId }) =>
      async (dispatch) => {
        let exist = false;

        if (account) {
          try {
            const data = await userService.getUserByUsernameAndAccount({
              account: account.toLowerCase(),
              username: username.toLowerCase(),
              limit: 1,
              businessId,
              isTemp: true,
            });

            exist = !!data.results.length;
          } catch (e) {
            exist = false;
          }
        }

        dispatch(change(DPD_SEARCH_FORM, "isExistInvitedUsers", exist));
      }
  ),
  ACTIONS.CHECK_EXIST_INVITED_USERS
);

export const onSearch = createLoadingAction(
  createAsyncAction(
    (
        { username, account, businessId },
        { withTempUsers = false, page = 0, pageSize = PAGE_SIZE } = {}
      ) =>
      async (dispatch) => {
        dispatch(checkExistInvitedUsers({ username, account, businessId }));

        const { results, totalResults } =
          await userService.getUserByUsernameAndAccount({
            account: account.toLowerCase(),
            username: username.toLowerCase(),
            businessId,
            isTemp: withTempUsers,
            offset: page * pageSize,
            limit: pageSize,
          });

        const formattedData = prepareSearchTableData(results);

        dispatch(change(DPD_SEARCH_FORM, "searchResults", formattedData));
        dispatch(change(DPD_SEARCH_FORM, "totalResults", totalResults));
        dispatch(change(DPD_SEARCH_FORM, "isTempUsers", withTempUsers));

        if (!totalResults) {
          const field = username ? USERNAME_FIELD : ACCOUNT_FIELD;

          throw new SubmissionError({ [field]: SEARCH_ERRORS[field] });
        }
        return { results, totalResults };
      },
    ACTIONS.SEARCH_USER
  )
);

export const resetUserSearchForm =
  (fields = {}) =>
  (dispatch) => {
    const resetValues = omit(getInitialSearchFormValues(), Object.keys(fields));

    Object.entries(resetValues).forEach(([field, value]) => {
      dispatch(change(DPD_SEARCH_FORM, field, value));
      dispatch(untouch(DPD_SEARCH_FORM, field));
    });
  };

export const clearSearchField = (fieldName) => (dispatch) =>
  dispatch(change(DPD_SEARCH_FORM, fieldName, ""));

export const onSearchUsersClick =
  ({ username, account, businessId }, { withTempUsers, page, pageSize } = {}) =>
  (dispatch) => {
    const field = username ? USERNAME_FIELD : ACCOUNT_FIELD;

    dispatch(blur(DPD_SEARCH_FORM, field));
    return dispatch(
      onSearch(
        { username, account, businessId },
        { withTempUsers, page, pageSize }
      )
    );
  };

export const onToggleUserResendInviteClick =
  (uid) => async (dispatch, getState) => {
    await dispatch(onToggleResendInviteClick(uid));
    const users = getAccountUsers(getState());
    const formattedUsers = prepareSearchTableData(users);

    dispatch(change(DPD_SEARCH_FORM, "searchResults", formattedUsers));
  };
