import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { SET_FIRST_LOGIN_PASSWORD_ANALYTICS } from "../../../../constants/analytics";
import { onSetFirstPasswordSubmit } from "../../../../features/auth/auth.actions";
import { resetPasswordValidation as validate } from "../../../../utils/commonValidation";
import SetPasswordForm from "../../components/SetPasswordForm";

const LoginSetPasswordPage = ({ onSubmit }) => (
  <SetPasswordForm onSubmit={onSubmit} validate={validate} firstLogin />
);

LoginSetPasswordPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: ({ newPassword }) =>
    dispatch(onSetFirstPasswordSubmit(newPassword)),
});

export default compose(
  connect(null, mapDispatchToProps),
  withTrack({
    loadId: SET_FIRST_LOGIN_PASSWORD_ANALYTICS.LOAD,
    interfaceId: SET_FIRST_LOGIN_PASSWORD_ANALYTICS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onSubmit: () => SET_FIRST_LOGIN_PASSWORD_ANALYTICS.CONTINUE,
  }))
)(LoginSetPasswordPage);
