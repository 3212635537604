import { createSelector } from "reselect";

export const getDPDAppConfigState = (state) => state.appManagement;

export const getDPDAppConfig = createSelector(
  getDPDAppConfigState,
  (config) => config.appConfig
);

export const getAppVersions = createSelector(
  getDPDAppConfigState,
  (config) => config.appVersions
);

export const getAppVersionsDetails = createSelector(
  getDPDAppConfigState,
  (config) => config.appVersionsDetails
);
