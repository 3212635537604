import Box from "@material-ui/core/Box";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import styles from "./AddIpAddressSection.module.scss";
import InputWithTopTitle from "./components/InputWithTopTitle";
import SimulateTabTitle from "../../../../components/CustomTabs/SimulateTabTitle";
import StyledSearchIcon from "../../../../components/StyledSearchIcon/StyledButtonIcon";
import {
  ENTER_IP_ADDRESS,
  IP_ACCESS,
  IP_ADDRESS_NAME,
} from "../../../../constants/strings";
import {
  IP_ADDRESS_FIELD,
  IP_ADDRESS_NAME_FIELD,
} from "../../../../features/teamSettings/teamSettings.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip/withErrorTooltip.hoc";
import { withoutSpaces } from "../../../../utils/normalize";

const DecoratedInputWithError = withErrorTooltip(InputWithTopTitle);

export const AddIpAddressSection = ({
  onAddIpAddress,
  onBlurFields,
  isAddIpButtonDisabled,
  handleSubmit,
}) => (
  <Box className={styles.addIpContainer}>
    <SimulateTabTitle title={IP_ACCESS} />
    <div className={styles.inputWrapper}>
      <Field
        topTitle={ENTER_IP_ADDRESS}
        name={IP_ADDRESS_FIELD}
        component={DecoratedInputWithError}
        normalize={withoutSpaces}
        onBlur={onBlurFields}
      />
    </div>
    <div className={classNames(styles.nameContainer)}>
      <div className={styles.nameInput}>
        <Field
          name={IP_ADDRESS_NAME_FIELD}
          topTitle={IP_ADDRESS_NAME}
          component={InputWithTopTitle}
          inputProps={{ maxLength: 50 }}
          onBlur={onBlurFields}
        />
      </div>
      <div className={styles.addButton}>
        <StyledSearchIcon
          onClick={handleSubmit(onAddIpAddress)}
          disabled={isAddIpButtonDisabled}
        />
      </div>
    </div>
  </Box>
);

AddIpAddressSection.propTypes = {
  onAddIpAddress: PropTypes.func,
  onBlurFields: PropTypes.func,
  isAddIpButtonDisabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default AddIpAddressSection;
