import get from "lodash/get";
import { getFormInitialValues, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import {
  EMAIL_FIELD,
  NEW_PASSWORD_FIELD,
  USE_EMAIL_AS_LOGIN_FIELD,
} from "./userDetails.constants";
import { USER_FORMS } from "../../../constants/forms";
import {
  isEmailValid,
  passwordStrength,
} from "../../../utils/commonValidation";
import { RE_ENTER_PASSWORD_FIELD } from "../../userDetailsForm/userDetails/userDetails.constants";

export const getUserDetailFormValues = getFormValues(
  USER_FORMS.DPD_USER_DETAILS
);

export const getInitialUserDetailFormValues = getFormInitialValues(
  USER_FORMS.DPD_USER_DETAILS
);

export const getUseEmailAddressAsLogin = createSelector(
  getUserDetailFormValues,
  (formValues) => get(formValues, USE_EMAIL_AS_LOGIN_FIELD, false)
);

export const getInitialUseEmailAddressAsLogin = createSelector(
  getInitialUserDetailFormValues,
  (formValues) => get(formValues, USE_EMAIL_AS_LOGIN_FIELD, false)
);

export const getEmailAddress = createSelector(
  getUserDetailFormValues,
  (formValues) => get(formValues, EMAIL_FIELD, "")
);

export const getNewPassword = createSelector(
  getUserDetailFormValues,
  (formValues) => get(formValues, NEW_PASSWORD_FIELD, "")
);

export const getConfirmPassword = createSelector(
  getUserDetailFormValues,
  (formValues) => get(formValues, RE_ENTER_PASSWORD_FIELD, "")
);

export const getPasswordStrengthScore = createSelector(
  getNewPassword,
  (password) => passwordStrength(password)
);

export const isValidUserEmail = createSelector(getEmailAddress, (email) =>
  isEmailValid(email)
);
