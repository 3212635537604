import { isEmpty, isNaN, isUndefined, omitBy, startCase } from "lodash";
import qs from "query-string";

import { PAGINATION } from "../../constants/activityMonitor";
import {
  COUNTRY_CODES_DESCRIPTION,
  HOMECALL_LABEL_PREFERENCE_ITEMS,
  MAPPING,
  REFERENCE_IN_CUSTOMER_ZONE_BARCODE_ITEMS,
  REFERENCE_IN_CUSTOMER_ZONE_ITEMS,
  SECTOR,
} from "../../constants/strings";
import { SUSPEND_USER_ACCOUNT_DAYS_FIELD } from "../teamSettings/teamSettings.constants";
import { COUNTRY_FIELD } from "../userDetailsForm/accountDetails/accountDetails.constants";
import {
  HOMECALL_REFERENCE,
  INCLUDE_REFERENCE_AS_BARCODE,
  INCLUDE_REFERENCE_CUSTOMER_ZONE,
} from "../userDetailsForm/userDetails/userDetails.constants";

const serviceExclusionsMapper = (key, { services }) => {
  const serviceData = services.find((service) => service.code === key);
  return (serviceData && serviceData.name.toUpperCase()) || key;
};

const serviceRestrictionsMapper = (key, { restrictions }) => {
  const keyValues = key.split("-");
  const restrictionData = restrictions.find(
    (service) => Number(service.restrictionCode) === Number(keyValues[1])
  );
  const descriptionValue =
    restrictionData && restrictionData.restrictionName
      ? `${SECTOR} ${
          keyValues[0]
        }: ${restrictionData.restrictionName.toUpperCase()}`
      : `${SECTOR} ${keyValues[0]}: ${keyValues[1]}`;
  return descriptionValue;
};

const serviceXrefMappingsMapper = (key, { services }) => {
  const keyValues = key.split("-");
  const serviceData = services.find((service) => service.code === keyValues[1]);
  const descriptionValue =
    serviceData && serviceData.name
      ? `${MAPPING} ${keyValues[0]}: ${serviceData.name.toUpperCase()}`
      : `${MAPPING} ${keyValues[0]}: ${keyValues[1]}`;
  return descriptionValue;
};

const ipRestrictionsMapper = (key) => {
  const keyValues = key.split(",");
  const descriptionValue = keyValues[1]
    ? `${keyValues[0]}, ${keyValues[1]}`
    : keyValues[0];
  return descriptionValue;
};

const labelSettingsValueMapper = (key, value) => {
  let decodedValue = "";
  switch (key) {
    case INCLUDE_REFERENCE_CUSTOMER_ZONE:
      decodedValue = REFERENCE_IN_CUSTOMER_ZONE_ITEMS.find(
        (item) => item.value === value
      );
      break;
    case INCLUDE_REFERENCE_AS_BARCODE:
      decodedValue = REFERENCE_IN_CUSTOMER_ZONE_BARCODE_ITEMS.find(
        (item) => item.value === value
      );
      break;
    case HOMECALL_REFERENCE:
      decodedValue = HOMECALL_LABEL_PREFERENCE_ITEMS.find(
        (item) => item.value === value
      );
  }

  return decodedValue ? decodedValue.title : value;
};

const teamSettingsSuspendAccountsValueMapper = (key, value) => {
  if (key === SUSPEND_USER_ACCOUNT_DAYS_FIELD) {
    return `inactive for ${value} days`;
  }
  return value;
};

const customerAddressMapper = (key, value) =>
  key === COUNTRY_FIELD ? COUNTRY_CODES_DESCRIPTION[value] || value : value;

const normalizeSectionKeyMappers = {
  serviceExclusions: serviceExclusionsMapper,
  serviceRestrictions: serviceRestrictionsMapper,
  serviceXrefMappings: serviceXrefMappingsMapper,
  ipRestrictions: ipRestrictionsMapper,
  ipAccess: ipRestrictionsMapper,
};

const normalizeSectionValueMappers = {
  labelSettings: labelSettingsValueMapper,
  suspendAccounts: teamSettingsSuspendAccountsValueMapper,
  customerAddress: customerAddressMapper,
};

export const normalizeKeyString = (key, sectionName, config) =>
  normalizeSectionKeyMappers[sectionName]
    ? normalizeSectionKeyMappers[sectionName](key, config)
    : startCase(key);

export const normalizeValueString = (key, value, sectionName) =>
  normalizeSectionValueMappers[sectionName]
    ? normalizeSectionValueMappers[sectionName](key, value)
    : value;

const capitalizeFirstLetter = (string) =>
  string && string.charAt(0).toUpperCase() + string.slice(1);

const getSurname = (firstname, lastname, username) =>
  firstname || lastname
    ? `${capitalizeFirstLetter(firstname)} ${capitalizeFirstLetter(lastname)}`
    : username || "";

const getObjectUserDescription = (
  objectFirstname,
  objectLastname,
  objectUsername
) => {
  if (objectFirstname || objectLastname) {
    const firstname = objectFirstname
      ? capitalizeFirstLetter(objectFirstname)
      : "";
    const description = objectLastname
      ? `${firstname} ${capitalizeFirstLetter(
          objectLastname
        )}, username: ${objectUsername}`
      : `${firstname}, username: ${objectUsername}`;
    return description.trim();
  }
  return objectUsername;
};

const shortLogDescription = ({
  firstname = "",
  lastname = "",
  context = "",
  objectFirstname = "",
  objectLastname = "",
  username = "",
  objectUsername = "",
}) => {
  const userData = `${getSurname(firstname, lastname, username)}`;
  const objectUser = getObjectUserDescription(
    objectFirstname,
    objectLastname,
    objectUsername
  );
  return objectUser
    ? `${userData} ${context} ${objectUser}`
    : `${userData} ${context}`;
};

const descriptionWithObjectUser = ({
  firstname = "",
  lastname = "",
  context = "",
  objectFirstname = "",
  objectLastname = "",
  username = "",
  objectUsername = "",
}) => {
  const userData =
    firstname || lastname
      ? `${capitalizeFirstLetter(firstname)} ${capitalizeFirstLetter(
          lastname
        )} using username: ${username}`
      : username;
  const objectUser = getObjectUserDescription(
    objectFirstname,
    objectLastname,
    objectUsername
  );

  return `${userData} ${context} ${objectUser}`;
};

const defaultDescription = ({
  firstname = "",
  lastname = "",
  context = "",
  action = "",
  username = "",
  platform,
  ip,
}) => {
  const usernameString = username ? `and username ${username}` : "";
  const description = `${getSurname(
    firstname,
    lastname,
    username
  )} ${context}, ${action} in platform: ${platform}, using ip: ${Buffer.from(
    ip
  ).toString("utf8")} ${usernameString}`;
  return description.trim();
};

export const getAuditLogDescription = (auditData) => {
  if (auditData.userSettings && !isEmpty(auditData.userSettings)) {
    return shortLogDescription(auditData);
  } else if (auditData.objectUsername) {
    return descriptionWithObjectUser(auditData);
  }
  return defaultDescription(auditData);
};

export const getQueryStringParams = (query) => {
  const { search, date, page: queryPage } = qs.parse(query);
  const { page, limit } = getSelectedPage(queryPage);

  return omitBy(
    {
      search,
      date,
      offset: (page - 1) * limit,
      limit,
    },
    isUndefined
  );
};

export const getSelectedPage = (value) => {
  const page = Number(value);

  return {
    page: !isNaN(page) && page >= 1 ? page : PAGINATION.defaultValues.page,
    limit: PAGINATION.defaultValues.limit,
  };
};
