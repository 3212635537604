import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./WarningText.module.scss";

const WarningText = ({ text, customClass }) => (
  <Typography className={classNames(styles.warn, customClass && customClass)}>
    {text}
  </Typography>
);

WarningText.propTypes = {
  text: PropTypes.string,
  customClass: PropTypes.any,
};

export default WarningText;
