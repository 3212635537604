import { SUSPEND_DELETE_USER_COLUMN } from "../../constants/strings";
import { isDpdLocalDomain } from "../app/app.modes";
import {
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
} from "../userDetailsForm/accountDetails/accountDetails.constants";

export const createData = ({ uid, values, fields }) => {
  const tableFields = Object.keys(fields).reduce((acc, key) => {
    acc[`field${key}`] = fields[key];
    return acc;
  }, {});

  return {
    id: uid,
    uid,
    values,
    fields: tableFields,
  };
};

export const prepareSearchTableData = (list) =>
  list.map((data) =>
    createData({
      uid: data.uid,
      values: data,
      fields: {
        username: data.username,
        account: data.account,
        forename: data.forename,
        lastname: data.lastname,
        SUSPEND_DELETE_USER_COLUMN,
      },
    })
  );

export const getInitialSearchFormValues = () => ({
  businessId: isDpdLocalDomain() ? DPD_LOCAL_BUSINESS_ID : DPD_BUSINESS_ID,
  searchResults: [],
  totalResults: 0,
  isExistInvitedUsers: false,
  username: "",
  account: "",
  isTempUsers: null,
});

export const updateSingleUser = (accountUsers, uid, values) =>
  accountUsers.map((item) => {
    if (item.id === uid) {
      return { ...item, values: { ...item.values, ...values } };
    }
    return item;
  });
