import { isFunction } from "lodash";
import { lifecycle } from "recompose";

export default lifecycle({
  componentDidMount() {
    isFunction(this.props.withReference) && this.props.withReference(this);
  },
  componentWillUnmount() {
    isFunction(this.props.withReference) && this.props.withReference();
  },
});
