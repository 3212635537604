import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { isAuthCheckedOnce } from "../auth.selectors";

const AuthGate = ({ children }) =>
  useSelector(isAuthCheckedOnce) ? children : null;

AuthGate.propTypes = {
  children: PropTypes.node,
};

export default AuthGate;
