import { useMemo } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

import SimpleTable from "../../../../../../components/Table/Table";
import styles from "./CustomerMigrationTable.module.scss";

const prepareItem = (data, columns) => ({
  id: data.uid,
  uid: data.uid,
  values: data,
  fields: columns.map(({ dataKey }) => data[dataKey]),
});

const prepareTableData = (list, columns) =>
  list.map((item) => prepareItem(item, columns));

const MigrationTable = ({ input, columns }) => {
  const data = useMemo(
    () => prepareTableData(input.value, columns),
    [input.value, columns]
  );
  const headers = useMemo(
    () => columns.map((column) => column.label),
    [columns]
  );

  return (
    <Box className={styles.customerTable}>
      <SimpleTable
        headerRows={headers}
        cellStyles={styles["table-cell"]}
        input={{
          value: data,
        }}
        renderItem={(value, columnPos) => (
          <Box width={columns[columnPos].width}>{value}</Box>
        )}
      />
    </Box>
  );
};

MigrationTable.propTypes = {
  input: PropTypes.object,
  column: PropTypes.array,
};

export default MigrationTable;
