import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import SimulateTabTitle from "../../../../components/CustomTabs/SimulateTabTitle";
import HeaderWithStar from "../../../../components/HeaderWithStar/HeaderWithStar";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import SelectItem from "../../../../components/SelectItem/SelectItem";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  COMMERCIAL,
  CREATE_OWN_INVOICE,
  EXPORT_INVOICE_REQUIRED,
  GENERATE_INVOICE_ONLINE,
  INVOICE_TYPE,
  PAGE_HEADER,
  PROFORMA,
  SELECT_EACH_TIME_INVOICE,
  SHIPPERS_DETAILS,
  WHEN_NOT_USING_LETTERHEAD_PAPER,
} from "../../../../constants/strings";
import {
  COMMERCIAL_VALUE,
  CREATE_OWN_INVOICE_VALUE,
  GENERATE_INVOICE_ONLINE_VALUE,
  INV_EXPORT_INVOICE_REQUIRED_FIELD,
  INV_INVOICE_TYPE_FIELD,
  INV_PAGE_HEADER,
  PROFORMA_VALUE,
  SELECT_EACH_TIME_INV_TYPE_VALUE,
  SELECT_EACH_TIME_VALUE,
} from "../../../../features/profile/profile.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import styles from "./ProfileIntInvoice.module.scss";
import ProfileIntInvoiceShippersDetails from "./ProfileIntInvoiceShippersDetails";

const SelectWithError = withErrorTooltip(SelectWithTitle);

const ProfileIntInvoice = ({
  onTextEntry,
  onDropdownClick,
  webEntryCurrency,
  activeTabIndex,
}) => (
  <>
    <Box width="100%">
      <Box display="flex" flexDirection="row">
        <Box width={1 / 3} p={1}>
          <HeaderWithStar text={EXPORT_INVOICE_REQUIRED} />
        </Box>
        <Box width={2 / 4} p={1}>
          <Field
            component={SelectWithError}
            displayEmpty
            name={INV_EXPORT_INVOICE_REQUIRED_FIELD}
            updateFocus={() => null}
            onClick={() => {
              onDropdownClick(INV_EXPORT_INVOICE_REQUIRED_FIELD);
            }}
          >
            <MenuItem value={SELECT_EACH_TIME_VALUE}>
              <SelectItem title={SELECT_EACH_TIME_INVOICE} />
            </MenuItem>
            <MenuItem value={GENERATE_INVOICE_ONLINE_VALUE}>
              <SelectItem title={GENERATE_INVOICE_ONLINE} />
            </MenuItem>
            <MenuItem value={CREATE_OWN_INVOICE_VALUE}>
              <SelectItem title={CREATE_OWN_INVOICE} />
            </MenuItem>
          </Field>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box width={1 / 3} p={1}>
          <HeaderWithStar text={INVOICE_TYPE} />
        </Box>
        <Box width={2 / 4} p={1}>
          <Field
            component={SelectWithError}
            displayEmpty
            name={INV_INVOICE_TYPE_FIELD}
            updateFocus={() => null}
            onClick={() => {
              onDropdownClick(INV_INVOICE_TYPE_FIELD);
            }}
          >
            <MenuItem value={SELECT_EACH_TIME_INV_TYPE_VALUE}>
              <SelectItem title={SELECT_EACH_TIME_INVOICE} />
            </MenuItem>
            <MenuItem value={COMMERCIAL_VALUE}>
              <SelectItem title={COMMERCIAL} />
            </MenuItem>
            <MenuItem value={PROFORMA_VALUE}>
              <SelectItem title={PROFORMA} />
            </MenuItem>
          </Field>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" className={styles.container}>
        <Box width={1 / 3} p={1}>
          <HeaderWithStar
            text={PAGE_HEADER}
            headerStyles={styles["header-styles"]}
          />
          <p className={styles.text}>{WHEN_NOT_USING_LETTERHEAD_PAPER}</p>
        </Box>
        <Box width={2 / 3} p={1}>
          <Field
            className={styles.textArea}
            name={INV_PAGE_HEADER}
            multiline
            rows="3"
            component={InputWithTitle}
            inputProps={{ maxLength: 255 }}
            onBlur={(e, value) => {
              onTextEntry(INV_PAGE_HEADER, value);
            }}
          />
        </Box>
      </Box>
    </Box>
    <SimulateTabTitle
      title={SHIPPERS_DETAILS}
      activeTabIndex={activeTabIndex}
    />
    <ProfileIntInvoiceShippersDetails
      webEntryCurrency={webEntryCurrency}
      activeTabIndex={activeTabIndex}
    />
  </>
);

ProfileIntInvoice.propTypes = {
  onTextEntry: PropTypes.func,
  onDropdownClick: PropTypes.func,
  activeTabIndex: PropTypes.number,
  webEntryCurrency: PropTypes.array,
};

export default compose(
  withTrackProps(() => ({
    onTextEntry: (field, value) =>
      createLogEntryPayload(PROFILE_ANALYTICS.TEXT_ENTRY, {
        field,
        value,
      }),
    onDropdownClick: (field) =>
      createLogEntryPayload(PROFILE_ANALYTICS.CLICK_DROPDOWN_LIST, {
        field,
      }),
  }))
)(ProfileIntInvoice);
