import { useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import {
  badge,
  boxRoot,
  colorDefault,
  container,
  root,
  scrollButtons,
  wrapper,
} from "./CustomTabs.module.scss";
import MissingFieldBadge from "../../components/MissingFieldBadge/MissingFieldBadge";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const id = `simple-tabpanel-${index}`;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={id}
      {...other}
    >
      <Box classes={{ root: boxRoot }} my={3}>
        {children}
      </Box>
    </Typography>
  );
}

const LabelWithBadge = ({ label }) => (
  <MissingFieldBadge
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    badgeStyle={badge}
  >
    {label}
  </MissingFieldBadge>
);

LabelWithBadge.propTypes = {
  label: PropTypes.node,
};

function CustomTab({ label, index, withBadge, ...props }) {
  const id = `simple-tab-${index}`;

  const tabLabel = withBadge ? <LabelWithBadge label={label} /> : label;

  return (
    <Tab classes={{ root, wrapper }} label={tabLabel} id={id} {...props} />
  );
}

CustomTab.propTypes = {
  label: PropTypes.node,
  index: PropTypes.number,
  withBadge: PropTypes.bool,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SimpleTabs = ({
  tabs,
  children,
  tabsStyles,
  onChange,
  disableAll,
  activeTab,
}) => {
  const [value, setValue] = useState(activeTab);

  useEffect(() => {
    setValue(activeTab);
  }, [activeTab]);

  return (
    <div className={container}>
      <AppBar
        position="static"
        color="default"
        classes={{ colorDefault }}
        elevation={0}
      >
        <Tabs
          value={value}
          onChange={(_, newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          indicatorColor="primary"
          className={tabsStyles}
          variant="scrollable"
          scrollButtons="auto"
          classes={{ scrollButtons }}
        >
          {tabs.map((tabName, index) => (
            <CustomTab
              key={index}
              label={tabName.label}
              index={index}
              disabled={disableAll || tabName.disabled}
              withBadge={tabName.withBadge}
            />
          ))}
        </Tabs>
      </AppBar>
      {children.map((component, index) => (
        <TabPanel key={index} value={value} index={index}>
          {component}
        </TabPanel>
      ))}
    </div>
  );
};

SimpleTabs.defaultProps = {
  children: [],
  analyticsData: {},
  onChange: () => {},
  activeTab: 0,
};

SimpleTabs.propTypes = {
  tabs: PropTypes.array,
  children: PropTypes.node,
  analyticsData: PropTypes.object,
  tabsStyles: PropTypes.string,
  onChange: PropTypes.func,
  disableAll: PropTypes.bool,
  activeTab: PropTypes.number,
};

export default compose(
  withTrackProps(({ tabs }) => ({
    onChange: (activeTab) => tabs[activeTab].analyticsId,
  }))
)(SimpleTabs);
