import classNames from "classnames";
import PropTypes from "prop-types";

import {
  PASSWORD_STRENGTH,
  PASSWORD_STRENGTH_LABELS,
} from "../../constants/strings.js";
import styles from "./PasswordStrengthMeter.module.scss";

const passwordStrengthLabel = (passwordStrength) => {
  if (passwordStrength < PASSWORD_STRENGTH.GOOD_PASSWORD) {
    return PASSWORD_STRENGTH_LABELS.WEAK_PASSWORD;
  } else if (passwordStrength === PASSWORD_STRENGTH.GOOD_PASSWORD) {
    return PASSWORD_STRENGTH_LABELS.GOOD_PASSWORD;
  } else if (passwordStrength === PASSWORD_STRENGTH.STRONG_PASSWORD) {
    return PASSWORD_STRENGTH_LABELS.STRONG_PASSWORD;
  }
};

export const PasswordStrengthMeter = ({ passwordStrengthScore }) => {
  const showWeakPassword =
    passwordStrengthScore > PASSWORD_STRENGTH.EMPTY_PASSWORD;

  const showGoodPassword =
    passwordStrengthScore > PASSWORD_STRENGTH.UNSAFE_PASSWORD;

  const showStrongPassword =
    passwordStrengthScore === PASSWORD_STRENGTH.STRONG_PASSWORD;

  return (
    <div className={styles.container}>
      <section className={styles.progressContainer}>
        <div
          className={classNames(
            styles.progressBar,
            showWeakPassword && styles.weakPassword
          )}
        />
        <div
          className={classNames(
            styles.progressBar,
            showGoodPassword && styles.goodPassword
          )}
        />
        <div
          className={classNames(
            styles.progressBar,
            showStrongPassword && styles.strongPassword
          )}
        />
      </section>
      <span className={styles.label}>
        {passwordStrengthLabel(passwordStrengthScore)}
      </span>
    </div>
  );
};

PasswordStrengthMeter.propTypes = {
  passwordStrengthScore: PropTypes.number,
};

export default PasswordStrengthMeter;
