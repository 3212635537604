import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import styles from "./CreateShipment.module.scss";
import {
  NO_MANUAL_ADDRESS_ENTRY,
  NO_MANUAL_CONTACT_DETAILS_ENTRY,
  REFERENCE_ONE_READ_ONLY,
} from "../../constants/strings";
import {
  NO_MANUAL_ADDRESS_ENTRY_FIELD,
  NO_MANUAL_CONTACT_DETAILS_ENTRY_FIELD,
  REFERENCE_1_READ_ONLY,
} from "../../features/userDetailsForm/advancedCustomerConfiguration/advancedCustomerConfiguration.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

const CreateShipment = ({ onChangeToggle }) => (
  <Grid container justify="space-between" className={styles.container}>
    <Field
      name={NO_MANUAL_ADDRESS_ENTRY_FIELD}
      component={CustomSwitchWithTitle}
      type="checkbox"
      label={NO_MANUAL_ADDRESS_ENTRY}
      onChange={(event, value) => {
        onChangeToggle(NO_MANUAL_ADDRESS_ENTRY_FIELD, value);
      }}
    />
    <Field
      name={NO_MANUAL_CONTACT_DETAILS_ENTRY_FIELD}
      component={CustomSwitchWithTitle}
      type="checkbox"
      label={NO_MANUAL_CONTACT_DETAILS_ENTRY}
      onChange={(event, value) => {
        onChangeToggle(NO_MANUAL_CONTACT_DETAILS_ENTRY_FIELD, value);
      }}
    />
    <Grid item container>
      <Field
        name={REFERENCE_1_READ_ONLY}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={REFERENCE_ONE_READ_ONLY}
        onChange={(event, value) => {
          onChangeToggle(REFERENCE_1_READ_ONLY, value);
        }}
      />
    </Grid>
  </Grid>
);

CreateShipment.propTypes = {
  onChangeToggle: PropTypes.func,
};

CreateShipment.defaultProps = {
  onChangeToggle: () => {},
};

export default CreateShipment;
