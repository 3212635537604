import { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/password.svg";
import LoginInput from "../LoginInput";
import {
  icon,
  keyIcon,
  root,
  visiblePassword,
} from "./PasswordField.module.scss";

const PasswordField = ({ ...props }) => {
  const [{ showPassword }, setValues] = useState({
    showPassword: false,
  });
  return (
    <LoginInput
      icon={<PasswordIcon className={keyIcon} />}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            className={showPassword ? visiblePassword : ""}
            classes={{ root }}
          >
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => setValues({ showPassword: !showPassword })}
            >
              <EyeIcon className={icon} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordField;
