import { change, getFormValues, initialize } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import {
  SEARCH_PARAM_FIELD,
  SEARCH_PARAMS,
  SEARCH_RESULTS_FIELD,
  SEARCH_RESULTS_TOTAL_FIELD,
  SEARCH_VALUE_FIELD,
  SEARCH_PAGE_FIELD,
  VERSION_FIELD,
  PAGE_SIZE,
} from "./search.constants";
import * as searchService from "./search.service";
import { CUSTOMER_MIGRATION_SEARCH_FORM } from "../../constants/forms";
import { DPD, DPD_LOCAL } from "../../constants/strings";
import { createLoadingAction } from "../loader/loader.actions";
import { getInitialSearchFormValues } from "./search.models";

const ACTION_NAMESPACE = "MIGRATION_SEARCH";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SEARCH_USERS: createAsyncActionTypes("SEARCH_USERS"),
  SYNC_USER_VERSION: createAsyncActionTypes("SYNC_USER_VERSION"),
  SEARCH_CUSTOMERS_BY_NAME: createAsyncActionTypes("SEARCH_CUSTOMERS_BY_NAME"),
});

export const clearSearchField = () => async (dispatch, getState) => {
  const state = getState();
  const values = getFormValues(CUSTOMER_MIGRATION_SEARCH_FORM)(state);
  return dispatch(
    initialize(CUSTOMER_MIGRATION_SEARCH_FORM, {
      ...getInitialSearchFormValues,
      [SEARCH_PARAM_FIELD]: values[SEARCH_PARAM_FIELD]
        ? values[SEARCH_PARAM_FIELD]
        : SEARCH_PARAMS.ACCOUNT,
    })
  );
};

const onSearchByParams = createAsyncAction(
  createLoadingAction((params) => async (dispatch, getState) => {
    const state = getState();
    const migrationData = await searchService.findByParams(params);

    const getBusinessUnitTitle = (businessId) =>
      businessId === 1 ? DPD.toUpperCase() : DPD_LOCAL.toUpperCase();

    const [results, resultsTotal] =
      params.account || params.username
        ? [
            migrationData.userAccounts.map((data) => ({
              ...data,
              businessUnit: getBusinessUnitTitle(data.businessId),
            })),
            migrationData.userAccountsTotal,
          ]
        : [
            migrationData.customerAccounts.map((data) => ({
              ...data,
              businessUnit: getBusinessUnitTitle(data.business),
            })),
            migrationData.customerAccountsTotal,
          ];

    const appVersions = state.config.appVersions; // todo get version by selector //
    const latestVersion = appVersions.length
      ? appVersions[appVersions.length - 1].version
      : null;

    dispatch(
      change(CUSTOMER_MIGRATION_SEARCH_FORM, VERSION_FIELD, latestVersion)
    );
    dispatch(
      change(CUSTOMER_MIGRATION_SEARCH_FORM, SEARCH_RESULTS_FIELD, results)
    );
    dispatch(
      change(
        CUSTOMER_MIGRATION_SEARCH_FORM,
        SEARCH_RESULTS_TOTAL_FIELD,
        resultsTotal
      )
    );

    return results;
  }),
  ACTIONS.SEARCH_USERS
);

export const onSearch = (values) => (dispatch) => {
  const fieldName = values[SEARCH_PARAM_FIELD];
  const value = values[SEARCH_VALUE_FIELD];
  const page = values[SEARCH_PAGE_FIELD];

  const search = {
    [fieldName]: value,
    offset: page * PAGE_SIZE,
    limit: PAGE_SIZE,
  };

  if (fieldName === SEARCH_PARAMS.ACCOUNTS) {
    search.accounts = search.accounts.split(",");
  }

  dispatch(onSearchByParams(search));
};

const prepareParams = (values) => {
  const fieldName = values[SEARCH_PARAM_FIELD];

  switch (fieldName) {
    case SEARCH_PARAMS.ACCOUNT:
      return {
        accounts: [values[SEARCH_VALUE_FIELD]],
      };
    case SEARCH_PARAMS.ACCOUNTS:
      return {
        accounts: values[SEARCH_VALUE_FIELD].split(",").map((accountCode) =>
          accountCode.split("*")[0].trim()
        ),
      };
    case SEARCH_PARAMS.COMPANY_NAME:
      return {
        accounts: values[SEARCH_RESULTS_FIELD].map(
          (customer) => customer.account
        ),
      };
    case SEARCH_PARAMS.USERNAME:
      return {
        usersId: values[SEARCH_RESULTS_FIELD].map((user) => user.userId),
      };
  }
};

export const onSyncVersion = createAsyncAction(
  createLoadingAction(() => async (dispatch, getState) => {
    const values = getFormValues(CUSTOMER_MIGRATION_SEARCH_FORM)(getState());
    const params = prepareParams(values);
    await searchService.syncAppVersion({
      ...params,
      updateVersion: values[VERSION_FIELD],
    });

    dispatch(clearSearchField());
  }),
  ACTIONS.SYNC_USER_VERSION
);
