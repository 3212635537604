import get from "lodash/get";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { getDiffServices } from "./userProfiles.models";
import { USER_DETAILS_FORMS } from "../../../constants/forms";

const getUserProfilesFormValues = getFormValues(
  USER_DETAILS_FORMS.USER_PROFILES
);

export const getUnallocatedProfiles = createSelector(
  getUserProfilesFormValues,
  (profiles) => get(profiles, "unallocated", [])
);

export const getUserProfilesAllocatedFormValues = createSelector(
  getUserProfilesFormValues,
  (profiles) => get(profiles, "allocated", [])
);

export const getAvailableToSelectProfiles = createSelector(
  getUnallocatedProfiles,
  getUserProfilesAllocatedFormValues,
  getDiffServices
);
