export const NODE_ENV = {
  PRODUCTION: "production",
  DEVELOPMENT: "development",
};

export const APP_ENV = {
  DEVELOPMENT: "d1",
};

export const EXTENDENT_LIABILITY = {
  DEFAULT: "Default",
  YES: "Y",
  NO: "N",
};

export const USER_ROLES = {
  DPD_ADMIN: "dpdAdmin",
  CREATE_STAFF_OVERRIDE_USER: "createStaffOverrideUser",
  ADD_DPD_USER: "addDPDUser",
  CUSTOMER_ADMIN: "customerAdmin",
  CUSTOMER_USER: "myDPD",
  APP_MANAGEMENT: "myDPDAppManagement",
};

export const API_ENDPOINTS = {
  AUTH: "/auth",
  AUDIT: "/audit",
  CONFIG: "/config",
  REFERENCE: "/reference",
  CUSTOMER: "/customer",
  ESG: "/esg",
  USER: "/user",
  PROFILE: "/profile",
  SEQUENCE: "/sequence",
  activityMonitor: "/activityMonitor",
};
