import { Box } from "@material-ui/core";
import PropTypes from "prop-types";

import { CheckBox } from "../CheckBox/CheckBox";

export const CheckBoxGroup = ({ fields, checkBoxData, onChange }) => {
  const fieldValues = fields.getAll();

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      {checkBoxData.map(({ label, value }, index) => {
        const itemIndex = fieldValues.indexOf(value);
        return (
          <CheckBox
            key={label + index}
            label={label}
            name={label}
            onChange={(event) => {
              onChange && onChange(event);
              if (event.target.checked) {
                return fields.push(value);
              }

              fields.remove(itemIndex);
            }}
            input={{ value: itemIndex > -1 }}
          />
        );
      })}
    </Box>
  );
};

CheckBoxGroup.propTypes = {
  fields: PropTypes.object,
  formatItem: PropTypes.func,
  onChange: PropTypes.func,
  checkBoxData: PropTypes.array,
};
