import { IconButton, InputAdornment } from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "./InputTitleEndAdornment.module.scss";
import { ReactComponent as Remove } from "../../assets/icons/clear.svg";

const InputTitleEndAdornment = ({ onClearClick }) => (
  <InputAdornment position="end" className={styles.container}>
    <IconButton classes={{ root: styles.button }} onClick={onClearClick}>
      <Remove className={styles.icon} />
    </IconButton>
  </InputAdornment>
);

InputTitleEndAdornment.propTypes = {
  onClearClick: PropTypes.func,
};

export default InputTitleEndAdornment;
