import {
  SEARCH_PARAM_FIELD,
  SEARCH_PARAMS,
  SEARCH_RESULTS_TOTAL_FIELD,
  SEARCH_RESULTS_FIELD,
  SEARCH_PAGE_FIELD,
} from "./search.constants";

export const getInitialSearchFormValues = {
  [SEARCH_PARAM_FIELD]: SEARCH_PARAMS.ACCOUNT,
  [SEARCH_RESULTS_TOTAL_FIELD]: 0,
  [SEARCH_RESULTS_FIELD]: [],
  [SEARCH_PAGE_FIELD]: 0,
};

export const updateSingleUser = (accountUsers, uid, values) =>
  accountUsers.map((item) => {
    if (item.id === uid) {
      return { ...item, ...values };
    }
    return item;
  });
