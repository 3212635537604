import { useEffect, useState } from "react";

import { Button, Grid } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { reduxForm } from "redux-form";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import SimpleTabs from "../../../../components/CustomTabs/CustomTabs";
import GradientHeader from "../../../../components/GradientHeader/GradientHeader";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import Prompt from "../../../../components/Prompt/Prompt";
import {
  DELETE_PROFILE_MODAL,
  NAVIGATE_AWAY_MODAL,
  PROFILE_ANALYTICS,
} from "../../../../constants/analytics";
import { CREATE_PROFILE_FORM } from "../../../../constants/forms";
import {
  ACCOUNT,
  ADDRESS,
  CANCEL,
  CREATE_NEW_PROFILE,
  DEFAULTS,
  DELETE_PROFILE,
  DUPLICATE_PROFILE,
  EDIT_PROFILE,
  GO_AWAY_MESSAGE,
  INT_INVOICE,
  NO,
  NO_EDIT_PROFILE,
  PREFERENCES,
  SAFE_PROFILE,
  YES_DELETE_IT,
} from "../../../../constants/strings";
import { getFilteredCurrencies } from "../../../../features/config/config.selectors";
import {
  duplicateProfile,
  enableNavigateModal,
  fetchProfileInfo,
  onCreateOrUpdateProfile,
  showDeleteModal,
} from "../../../../features/profile/profile.actions";
import {
  ACCOUNT_NUMBER_FIELD,
  CREATE_ACCOUNT_NUMBER_FIELD,
} from "../../../../features/profile/profile.constants";
import { getInitialValues } from "../../../../features/profile/profile.models";
import {
  getAccountNumber,
  getProfileCode,
  shouldShowAccountTabBadge,
  shouldShowAddressTabBadge,
  shouldShowDefaultsTabBadge,
  shouldShowIntInvoiceTabBadge,
  shouldShowPromptOnCreate,
} from "../../../../features/profile/profile.selectors";
import {
  asyncValidate,
  validate,
} from "../../../../features/profile/profile.validate";
import { CUSTOMER_ID_FIELD } from "../../../../features/userDetailsForm/accountDetails/accountDetails.constants";
import ProfileAccount from "../../components/ProfileAccount/ProfileAccount";
import ProfileAddress from "../../components/ProfileAddress/ProfileAddress";
import ProfileDefaults from "../../components/ProfileDefaults/ProfileDefaults";
import ProfileGeneralInfo from "../../components/ProfileGeneralInfo/ProfileGeneralInfo";
import ProfileIntInvoice from "../../components/ProfileIntInvoice/ProfileIntInvoice";
import ProfilePreferences from "../../components/ProfilePreferences/ProfilePreferences";
import styles from "./Profile.module.scss";

const Profile = ({
  handleSubmit,
  shouldShowPrompt,
  onClickSave,
  profileCode,
  accountNumber,
  onClickCancel,
  onDelete,
  onDuplicate,
  enableModal,
  createProfileTabs,
  fetchProfile,
  match: {
    params: { id },
  },
  webEntryCurrency,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const cancelButtonText = id ? NO_EDIT_PROFILE : NO;

  useEffect(() => {
    id && fetchProfile(accountNumber, false);
    enableModal();
  }, [id, accountNumber]);

  return (
    <PageContainer>
      <Prompt
        when={shouldShowPrompt}
        message={GO_AWAY_MESSAGE}
        cancelButtonText={cancelButtonText}
        interfaceId={NAVIGATE_AWAY_MODAL.INTERFACE_ID}
        cancelActionId={
          id ? NAVIGATE_AWAY_MODAL.NO_EDIT_PROFILE : NAVIGATE_AWAY_MODAL.NO
        }
        key={id}
      />
      <GradientHeader
        title={!id ? CREATE_NEW_PROFILE : `${EDIT_PROFILE} - ${profileCode}`}
      />
      <Grid className={styles.content}>
        <Grid className={classNames(id && styles.sectionContainer)}>
          <ProfileGeneralInfo isEdit={!!id} />
          {!!id && (
            <Grid className={styles.buttonContainer}>
              <Grid item>
                <Button
                  className={styles.sectionButton}
                  color="secondary"
                  disabled={shouldShowPrompt}
                  onClick={() =>
                    onDuplicate({
                      profileCode,
                      accountNumber,
                    })
                  }
                >
                  {DUPLICATE_PROFILE}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={styles.sectionButton}
                  onClick={() =>
                    onDelete({
                      profileCode,
                      accountNumber,
                    })
                  }
                >
                  {DELETE_PROFILE}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className={styles.tabs}>
          <SimpleTabs
            onChange={(activeTabIndex) => {
              setActiveTabIndex(activeTabIndex);
            }}
            tabs={createProfileTabs}
          >
            <ProfileAccount activeTabIndex={activeTabIndex} />
            <ProfilePreferences activeTabIndex={activeTabIndex} />
            <ProfileDefaults activeTabIndex={activeTabIndex} />
            <ProfileIntInvoice
              activeTabIndex={activeTabIndex}
              webEntryCurrency={webEntryCurrency}
            />
            <ProfileAddress activeTabIndex={activeTabIndex} />
          </SimpleTabs>
        </Grid>
        <Grid
          container
          justify="space-between"
          className={styles.buttonsContainer}
        >
          <Grid item>
            <Button
              className={styles.button}
              color="secondary"
              onClick={onClickCancel}
            >
              {CANCEL}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={styles.button}
              onClick={handleSubmit(onClickSave)}
            >
              {SAFE_PROFILE}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

Profile.propTypes = {
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showAccountTabBadge: PropTypes.bool,
  shouldShowPrompt: PropTypes.bool,
  showDefaultsTabBadge: PropTypes.bool,
  onClickSave: PropTypes.func,
  onClickCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  fetchProfile: PropTypes.func,
  enableModal: PropTypes.func,
  profileCode: PropTypes.string,
  accountNumber: PropTypes.string,
  createProfileTabs: PropTypes.array,
  match: PropTypes.object.isRequired,
  webEntryCurrency: PropTypes.array,
};

const mapStateToProps = (state) => ({
  showAccountTabBadge: shouldShowAccountTabBadge(state),
  shouldShowPrompt: shouldShowPromptOnCreate(state),
  showDefaultsTabBadge: shouldShowDefaultsTabBadge(state),
  showIntInvoiceTabBadge: shouldShowIntInvoiceTabBadge(state),
  showAddressTabBadge: shouldShowAddressTabBadge(state),
  profileCode: getProfileCode(state),
  accountNumber: getAccountNumber(state),
  webEntryCurrency: getFilteredCurrencies(state),
});

const mapDispatchToProps = (dispatch, props) => {
  const id = props.match.params.id;

  return {
    fetchProfile: (account) => dispatch(fetchProfileInfo(id, account, false)),
    enableModal: () => dispatch(enableNavigateModal()),
    onClickSave: () => dispatch(onCreateOrUpdateProfile(id)),
    onClickCancel: () => props.history.goBack(),
    onDelete: (originalProfileInfo) =>
      dispatch(
        showDeleteModal(id, originalProfileInfo, YES_DELETE_IT, {
          loadId: DELETE_PROFILE_MODAL.LOAD,
          interfaceId: DELETE_PROFILE_MODAL.INTERFACE_ID,
          confirmActionId: DELETE_PROFILE_MODAL.YES,
          cancelActionId: DELETE_PROFILE_MODAL.NO,
        })
      ),
    onDuplicate: (originalProfileInfo) =>
      dispatch(duplicateProfile(id, originalProfileInfo)),
  };
};

const BaseProfile = compose(
  reduxForm({
    form: CREATE_PROFILE_FORM,
    initialValues: getInitialValues(),
    validate,
    asyncValidate,
    enableReinitialize: true,
    asyncChangeFields: [
      ACCOUNT_NUMBER_FIELD,
      CUSTOMER_ID_FIELD,
      CREATE_ACCOUNT_NUMBER_FIELD,
    ],
    keepDirtyOnReinitialize: true,
  }),
  withProps((props) => ({
    createProfileTabs: [
      {
        label: ACCOUNT,
        analyticsId: PROFILE_ANALYTICS.CLICK_ACCOUNT_TAB,
        withBadge: props.showAccountTabBadge,
      },
      {
        label: PREFERENCES,
        analyticsId: PROFILE_ANALYTICS.CLICK_PREFERENCES_TAB,
      },
      {
        label: DEFAULTS,
        analyticsId: PROFILE_ANALYTICS.CLICK_DEFAULTS_TAB,
        withBadge: props.showDefaultsTabBadge,
      },
      {
        label: INT_INVOICE,
        analyticsId: PROFILE_ANALYTICS.CLICK_INT_INVOICE_TAB,
        withBadge: props.showIntInvoiceTabBadge,
      },
      {
        label: ADDRESS,
        analyticsId: PROFILE_ANALYTICS.CLICK_ADDRESS_TAB,
        withBadge: props.showAddressTabBadge,
      },
    ],
  }))
)(Profile);

export const CreateProfile = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTrack({
    loadId: PROFILE_ANALYTICS.CREATE_LOAD,
    interfaceId: PROFILE_ANALYTICS.CREATE_INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onClickSave: () => PROFILE_ANALYTICS.CLICK_SAVE_CREATE_PROFILE,
    onClickCancel: () => PROFILE_ANALYTICS.CLICK_CANCEL_CREATE_PROFILE,
  }))
)(BaseProfile);

export const EditProfile = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTrack({
    loadId: PROFILE_ANALYTICS.UPDATE_LOAD,
    interfaceId: PROFILE_ANALYTICS.EDIT_INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onClickSave: () => PROFILE_ANALYTICS.CLICK_SAVE_UPDATE_PROFILE,
    onClickCancel: () => PROFILE_ANALYTICS.CLICK_CANCEL_UPDATE_PROFILE,
    onDuplicate: () => PROFILE_ANALYTICS.CLICK_DUPLICATE_PROFILE,
    onDelete: () => PROFILE_ANALYTICS.CLICK_DELETE_PROFILE,
  }))
)(BaseProfile);
