import { useSelector } from "react-redux";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import BaseModal from "./BaseModal";
import CreateMasterUserModal from "./CreateMasterUser/CreateMasterUserModal";
import CustomerDetailsModal from "./CustomerDetailsModal/CustomerDetailsModal";
import ChangeMessageModal from "../../components/Modal/ChangeMessageModal/ChangeMessageModal";
import SlidsModal from "../../components/Modal/SlidsModal/SlidsModal";
import {
  CHANGE_MESSAGE,
  CREATE_MASTER_USER,
  CUSTOMER_VALIDATION,
  SLIDS,
} from "../../features/modal/modal.constants";
import {
  getModalProps,
  isModalOpened,
} from "../../features/modal/modal.selectors";
import {
  ADD_KEY,
  DELETE_KEY,
  DISABLE_API_ACCESS,
} from "../../constants/strings";
import DeleteKeyModal from "./DeleteKeyModal";
import AddKeyModal from "./AddKeyModal";
import DisableApiAccessModal from "./DisableApiAccessModal";

const Modal = () => {
  const isDialogOpen = useSelector(isModalOpened) || false;
  const { loadId, interfaceId, customComponent, ...otherProps } =
    useSelector(getModalProps);

  let Component = BaseModal;
  switch (customComponent) {
    case SLIDS:
      Component = SlidsModal;
      break;
    case CHANGE_MESSAGE:
      Component = ChangeMessageModal;
      break;
    case CREATE_MASTER_USER:
      Component = CreateMasterUserModal;
      break;
    case CUSTOMER_VALIDATION:
      Component = CustomerDetailsModal;
      break;
    case DELETE_KEY:
      Component = DeleteKeyModal;
      break;
    case ADD_KEY:
      Component = AddKeyModal;
      break;
    case DISABLE_API_ACCESS:
      Component = DisableApiAccessModal;
      break;
  }

  return (
    isDialogOpen && (
      <Trackable loadId={loadId} interfaceId={interfaceId} modal>
        <Component {...otherProps} isDialogOpen={isDialogOpen} />
      </Trackable>
    )
  );
};

export default Modal;
