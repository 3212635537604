import {
  CITY_FIELD,
  COUNTRY_FIELD,
  NOTIFICATION_EMAIL_FIELD,
  NOTIFICATION_NUMBER_FIELD,
  POSTCODE_FIELD,
  STREET_FIELD,
} from "../../userDetailsForm/accountDetails/accountDetails.constants";

export const REQUIRED_VALIDATION_MODAL_FIELDS = [
  POSTCODE_FIELD,
  STREET_FIELD,
  CITY_FIELD,
  COUNTRY_FIELD,
];

export const FIELDS_FOR_VALIDATION_CHECK = [
  ...REQUIRED_VALIDATION_MODAL_FIELDS,
  NOTIFICATION_EMAIL_FIELD,
  NOTIFICATION_NUMBER_FIELD,
];
