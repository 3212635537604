import React from "react";
import BaseModal from "../BaseModal";
import {
  ADD_KEY,
  SECRET_SHOWN_ONCE,
  VIEW_KEY_INFORMATION,
} from "../../../constants/strings";
import { Field, propTypes, reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import {
  API_SETTING_FIELDS,
  SECRET,
} from "../../../features/userDetailsForm/apiSetting/apiSetting.constants";
import { compose, withHandlers } from "recompose";
import { ADD_KEY_FORM } from "../../../constants/forms";
import Typography from "@material-ui/core/Typography";
import InputWithTitle from "../../InputWithTitle/InputWithTitle";
import WarningText from "../../WarningText/WarningText";
import styles from "./AddKeyModal.module.scss";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { ReactComponent as CopyIcon } from "../../../assets/icons/content_copy.svg";
import { connect } from "react-redux";
import { getAddKeyFormValues } from "../../../features/userDetailsForm/apiSetting/apiSettingSelectors";
import { get } from "lodash";
import { nonEditable } from "../../../utils/normalize";

const AddKeyModal = ({ keyLabel, onCopyIconClick, ...otherProps }) => {
  return (
    <BaseModal title={ADD_KEY} {...otherProps}>
      <Grid container spacing={3} className={styles.container}>
        <Grid item xs={12}>
          <Typography className={styles.text}>
            {VIEW_KEY_INFORMATION}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            component={InputWithTitle}
            name={API_SETTING_FIELDS.KEY}
            label={keyLabel}
            normalize={nonEditable}
            onBlur={() => null} // TODO: add analytics
          />
        </Grid>
        <Grid item xs={10}>
          <Field
            component={InputWithTitle}
            name={API_SETTING_FIELDS.SECRET}
            label={SECRET}
            normalize={nonEditable}
            onBlur={() => null} // TODO: add analytics
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={onCopyIconClick}>
            <CopyIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <WarningText text={SECRET_SHOWN_ONCE} />
        </Grid>
      </Grid>
    </BaseModal>
  );
};

AddKeyModal.propTypes = {
  ...propTypes,
  keyLabel: PropTypes.string,
  onCopyIconClick: PropTypes.func,
};

export default compose(
  connect((state) => ({
    formValues: getAddKeyFormValues(state),
  })),
  withHandlers({
    onCopyIconClick:
      ({ formValues }) =>
      () =>
        navigator.clipboard.writeText(
          get(formValues, API_SETTING_FIELDS.SECRET, "")
        ),
  }),
  reduxForm({
    form: ADD_KEY_FORM,
  })
)(AddKeyModal);
