import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { CREATE_MY_DPD_USER_ANALYTICS } from "../../../../constants/analytics";
import { getPermittedIpAddress } from "../../../../features/customer/customer.selectors";
import {
  onConfirmCreateDPDUser,
  showInvalidUrlModal,
} from "../../../../features/user/user.actions";
import {
  getUserId,
  shouldShowApiSettings,
  shouldShowLeavePrompt,
} from "../../../../features/user/user.selectors";
import { prepareRequiredEditData } from "../../../../features/userDetailsForm/userDetailsForm.actions";
import { isCreateCustomerUserFormDirty } from "../../../../features/userDetailsForm/userDetailsForm.selector";
import { navigateToHome } from "../../../../router/navigation";
import MainUserForm from "../../components/MainUserForm/MainUserForm";

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params;
  return {
    formDirty: isCreateCustomerUserFormDirty(state),
    showPrompt: shouldShowLeavePrompt(state),
    permittedIpAddress: getPermittedIpAddress(customerId)(state),
    userId: getUserId(state),
    showApiSettings: shouldShowApiSettings(state),
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id, customerId },
    },
  }
) => ({
  onCancel: () => navigateToHome(customerId),
  onConfirm: () => dispatch(onConfirmCreateDPDUser(id, customerId)),
  prepareData: () => dispatch(prepareRequiredEditData(id, customerId)),
  showInvalidUrlModal: () => dispatch(showInvalidUrlModal()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTrack({
    loadId: CREATE_MY_DPD_USER_ANALYTICS.LOAD,
    interfaceId: CREATE_MY_DPD_USER_ANALYTICS.INTERFACE_ID,
  }),
  lifecycle({
    componentDidMount() {
      const {
        match: {
          params: { id, customerId },
        },
      } = this.props;

      if (`${id}`.includes(customerId)) {
        this.props.prepareData();
      } else {
        this.props.showInvalidUrlModal();
      }
    },
  }),
  withTrackProps(() => ({
    onCancel: () => CREATE_MY_DPD_USER_ANALYTICS.CANCEL,
    onConfirm: () => CREATE_MY_DPD_USER_ANALYTICS.CONFIRM,
  }))
)(MainUserForm);
