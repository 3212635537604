import { useTheme } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./LogoHeader.module.scss";

const CUSTOMER_PORTAL = "Customer Portal";

const LogoHeader = ({ logoStyles, headerStyles }) => {
  const { images } = useTheme();

  return (
    <header className={classNames(styles.header, headerStyles)}>
      <img
        src={images.logo}
        alt=""
        className={classNames(styles.logo, logoStyles)}
      />
      <h1>{CUSTOMER_PORTAL}</h1>
    </header>
  );
};

LogoHeader.propTypes = {
  logoStyles: PropTypes.string,
  headerStyles: PropTypes.string,
};

export default LogoHeader;
