import PropTypes from "prop-types";

import SimulateTabTitle from "../../../../components/CustomTabs/SimulateTabTitle";
import { INTERNATIONAL } from "../../../../constants/strings";
import ProfileInternationalPreferences from "./ProfileInternationalPreferences";

const ProfilePreferences = ({ activeTabIndex }) => (
  <>
    <SimulateTabTitle title={INTERNATIONAL} activeTabIndex={activeTabIndex} />
    <ProfileInternationalPreferences />
  </>
);

ProfilePreferences.propTypes = {
  activeTabIndex: PropTypes.number,
};
export default ProfilePreferences;
