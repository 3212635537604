export const SHARE_SHIPMENTS_BY_PROFILE_FIELD = "shareShipmentsByProfile";
export const SHARE_SHIPMENTS_FIELD = "shareShipments";
export const USE_JAVA_APPLET_FIELD = "useJavaApplet";
export const RESTRICT_UPGRADE_ACTIONS_FIELD = "restrictUpgradeActions";
export const INCLUDE_SHIPPING_BY_OTHERS_FIELD = "includeShipmentsByOthers";
export const ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD =
  "allowEditShipmentsByProfile";
export const SHARE_COLLECTIONS_FIELD = "shareCollections";
export const INCLUDE_COLLECTIONS = "includeCollections";
export const RESTRICT_REDELIVER_FIELD = "restrictRedeliver";
export const SHOW_USER_COLLECTIONS = "showUserCollections";
