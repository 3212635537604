import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import ConfirmButton from "../ConfirmButton/ConfirmButton";
import styles from "./BottomButtons.module.scss";

const BottomButtons = ({
  cancelText,
  confirmText,
  onCancelClick,
  onConfirmClick,
  confirmDisabled,
  cancelDisabled,
}) => (
  <Grid container className={styles.container}>
    <Grid item>
      <Button
        color="secondary"
        className={styles.button}
        onClick={onCancelClick}
        disabled={cancelDisabled}
      >
        {cancelText}
      </Button>
    </Grid>
    <Grid item>
      <ConfirmButton
        text={confirmText}
        onClick={onConfirmClick}
        disabled={confirmDisabled}
      />
    </Grid>
  </Grid>
);

export default BottomButtons;

BottomButtons.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  cancelDisabled: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
};

BottomButtons.defaultProps = {
  cancelText: "Cancel",
  confirmText: "Confirm",
  confirmDisabled: false,
  cancelDisabled: false,
};
