import { CURRENT_LABEL_NO, INVALID_FIELD_VALUE } from "../../constants/strings";
import { formatMessage } from "../../utils/string";
import { FIELD_CANT_BE_EMPTY } from "../auth/auth.constants";
import { EXISTED_FIELD } from "./sequences.constants";

export const validate = (values) => {
  const errors = {};
  if (values.data) {
    const dataArrayErrors = [];
    values.data.forEach((field, fieldIndex) => {
      const fieldErrors = {};
      const min = `${field.seqName}000001`;
      const max = `${field.seqName}999999`;

      if ((!field || !field.curValue) && !field[EXISTED_FIELD]) {
        fieldErrors.curValue = formatMessage(
          FIELD_CANT_BE_EMPTY,
          CURRENT_LABEL_NO
        );
        dataArrayErrors[fieldIndex] = fieldErrors;
      } else if (
        !field[EXISTED_FIELD] &&
        field.curValue &&
        (+field.curValue < +`${min}` || +field.curValue > +`${max}`)
      ) {
        fieldErrors.curValue = formatMessage(
          INVALID_FIELD_VALUE,
          `(Should be between ${min} and ${max})`
        );
        dataArrayErrors[fieldIndex] = fieldErrors;
      }
    });
    if (dataArrayErrors.length) {
      errors.data = dataArrayErrors;
    }
  }
  return errors;
};
