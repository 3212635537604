import { cloneDeep } from "lodash";
import { change, initialize, SubmissionError } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import {
  ACCOUNT_INTERNATIONAL_TAB_FIELDS,
  INTERNATIONAL_CLASSIC_SLID_FIELD,
  INTERNATIONAL_DIRECT_SLID_FIELD,
  INTERNATIONAL_DPD_LOCAL_TOGGLE_FIELD,
  INTERNATIONAL_DPD_TOGGLE_FIELD,
  INTERNATIONAL_EXPRESS_SLID_FIELD,
  INV_DEFAULT_CURRENCY_FIELD,
  PROFILE_NOT_FOUND,
  PROFILES_FIELD,
  UNABLE_TO_CREATE_PROFILE,
  UNABLE_TO_UPDATE_PROFILE,
} from "./profile.constants";
import {
  formMapper,
  prepareCreateProfileData,
  prepareSearchTableData,
  prepareSlids,
} from "./profile.models";
import {
  getCreateProfileFormValues,
  getProfiles,
  getSearchAccountNumber,
} from "./profile.selectors";
import * as profileService from "./profile.service";
import { createProfileAsyncValidate } from "./profile.validate";
import {
  CONFIRM_SLID_MODAL,
  PROFILE_ANALYTICS,
} from "../../constants/analytics";
import {
  CREATE_PROFILE_FORM,
  SEARCH_PROFILE_FORM,
} from "../../constants/forms";
import {
  ACCOUNT_HAS_TO_BE_PRESENT,
  CANCEL,
  CANCEL_EDIT_PROFILE,
  CLOSE,
  DELETE_PROFILE_MESSAGE,
  DUPLICATE_PROFILE_FROM,
  NO,
  NO_PROFILE_WITH_CURRENT_ID,
  NONE,
  PROFILES_NOT_FOUND,
  WARNING,
  YES,
} from "../../constants/strings";
import {
  DPD_CREATE_PROFILE,
  DPD_PROFILES_PAGE,
  LOGIN_PAGE,
} from "../../router";
import { getUrlSearchParam, navigateTo } from "../../router/navigation";
import { formatMessage } from "../../utils/string";
import { fetchDpdServices } from "../config/config.actions";
import { getConfig, getFilteredCurrencies } from "../config/config.selectors";
import { createLoadingAction } from "../loader/loader.actions";
import { showInfoModal, showModal } from "../modal/modal.actions";
import { ALIGN_BUTTONS, SLIDS } from "../modal/modal.constants";
import {
  createSequences,
  getSequencesBySeqNames,
} from "../sequences/sequences.actions";

const ACTION_NAMESPACE = "PROFILE_FORM";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  ENABLE_NAVIGATE_MODAL: "ENABLE_NAVIGATE_MODAL",
  SAVE_PROFILE: createAsyncActionTypes("SAVE_PROFILE"),
  FETCH_PROFILE_INFO: createAsyncActionTypes("FETCH_PROFILE_INFO"),
  SEARCH_PROFILES: createAsyncActionTypes("SEARCH_PROFILES"),
  DUPLICATE_PROFILE: createAsyncActionTypes("DUPLICATE_PROFILE"),
  DELETE_PROFILE: createAsyncActionTypes("DELETE_PROFILE"),
  DISABLE_NAVIGATE_MODAL: "DISABLE_NAVIGATE_MODAL",
});

export const enableNavigateModal = () =>
  createPayloadAction(ACTIONS.ENABLE_NAVIGATE_MODAL);

export const disableNavigateModal = () =>
  createPayloadAction(ACTIONS.DISABLE_NAVIGATE_MODAL);

export const changeProfilesField = (profiles) =>
  change(SEARCH_PROFILE_FORM, PROFILES_FIELD, profiles);

export const changeCreateProfileForm = (field, value) =>
  change(CREATE_PROFILE_FORM, field, value);

const initializeForms = (profile, duplicate) => async (dispatch, getState) => {
  const state = getState();
  const currencies = getFilteredCurrencies(state);
  const config = getConfig(state);
  dispatch(
    initialize(
      CREATE_PROFILE_FORM,
      formMapper(profile, duplicate, config, currencies)
    )
  );
};

export const duplicateProfile = createLoadingAction(
  createAsyncAction(
    (uid, originalProfileInfo) => async (dispatch) => {
      dispatch(
        showModal({
          contentText: formatMessage(
            DUPLICATE_PROFILE_FROM,
            originalProfileInfo.profileCode,
            originalProfileInfo.accountNumber
          ),
          confirmButtonText: YES,
          onConfirmClick: () => {
            navigateTo(DPD_CREATE_PROFILE);
            dispatch(
              fetchProfileInfo(uid, originalProfileInfo.accountNumber, true)
            );
          },
          loadId: PROFILE_ANALYTICS.LOAD_DUPLICATE_MODAL,
          interfaceId: PROFILE_ANALYTICS.DUPLICATE_PROFILE_MODAL,
          confirmActionId: PROFILE_ANALYTICS.CONFIRM_DUPLICATE_MODAL,
          cancelActionId: PROFILE_ANALYTICS.CANCEL_DUPLICATE_MODAL,
        })
      );
    },
    ACTIONS.DUPLICATE_PROFILE
  )
);

export const fetchProfileInfo = createLoadingAction(
  createAsyncAction(
    (profileCode, account, duplicate) => async (dispatch) => {
      try {
        await dispatch(fetchDpdServices());
        const accountNumber = account || getUrlSearchParam("account");
        const profile = await profileService.fetchProfile(
          profileCode,
          accountNumber
        );
        if (!profile) {
          navigateTo(DPD_PROFILES_PAGE);
          return dispatch(
            showModal({
              contentText: NO_PROFILE_WITH_CURRENT_ID,
              notificationType: WARNING,
              title: WARNING,
              confirmButtonText: CLOSE,
              showCancelButton: false,
              alignButton: ALIGN_BUTTONS.CENTER,
            })
          );
        }
        return dispatch(initializeForms(profile, duplicate));
      } catch (e) {
        if (e.statusCode === 401) {
          return navigateTo(LOGIN_PAGE);
        }
        if (e.errors && e.errors[0]) {
          return dispatch(showInfoModal(e.errors[0].message));
        }
      }
    },
    ACTIONS.FETCH_PROFILE_INFO
  )
);

export const onProfileSearch = createLoadingAction(
  createAsyncAction(
    () => async (dispatch, getState) => {
      const accountNumber = getSearchAccountNumber(getState());
      if (!accountNumber || !accountNumber.trim()) {
        throw new SubmissionError({ accountNumber: ACCOUNT_HAS_TO_BE_PRESENT });
      }

      return profileService
        .getProfilesByAccount(accountNumber)
        .then((profiles) => {
          if (!profiles.length) {
            throw new SubmissionError({ accountNumber: PROFILES_NOT_FOUND });
          }

          return dispatch(
            changeProfilesField(prepareSearchTableData(profiles))
          );
        });
    },
    ACTIONS.SEARCH_PROFILES
  )
);

export const deleteProfile = createLoadingAction(
  createAsyncAction(
    (accountNumber, profileCode) => async (dispatch, getState) => {
      try {
        await profileService.deleteProfile(accountNumber, profileCode);
        const profiles = getProfiles(getState());
        return dispatch(
          changeProfilesField(profiles.filter(({ id }) => id !== profileCode))
        );
      } catch (e) {
        if (e.statusCode === 404) {
          return dispatch(showInfoModal(PROFILE_NOT_FOUND));
        }
        return dispatch(showInfoModal("User must have at least one profile"));
      }
    },
    ACTIONS.DELETE_PROFILE
  )
);

export const onDeleteProfile =
  ({ profileCode, accountNumber }) =>
  async (dispatch, getState) => {
    const profiles = getProfiles(getState());

    if (!profiles || !profiles.length) {
      await dispatch(disableNavigateModal());
      navigateTo(DPD_PROFILES_PAGE);
    }
    return dispatch(deleteProfile(accountNumber, profileCode));
  };

export const showDeleteModal =
  (id, originalProfileInfo, confirmButtonText, analyticsIds) => (dispatch) => {
    dispatch(
      showModal({
        contentText: formatMessage(
          DELETE_PROFILE_MESSAGE,
          originalProfileInfo.profileCode,
          originalProfileInfo.accountNumber
        ),
        cancelButtonText: NO,
        confirmButtonText: confirmButtonText || YES,
        onConfirmClick: () => {
          dispatch(onDeleteProfile(originalProfileInfo));
        },
        ...analyticsIds,
      })
    );
  };

export const resetDefaultsFormValues = (formFields) => (dispatch) => {
  formFields.map((fieldName) =>
    dispatch(changeCreateProfileForm(fieldName, ""))
  );
};

export const createOrUpdateProfile = createAsyncAction(
  (id) => async (dispatch, getState) => {
    const formValues = getCreateProfileFormValues(getState());
    const newValues = cloneDeep(formValues);
    newValues[INV_DEFAULT_CURRENCY_FIELD] =
      newValues[INV_DEFAULT_CURRENCY_FIELD] === NONE
        ? ""
        : newValues[INV_DEFAULT_CURRENCY_FIELD];
    const formData = prepareCreateProfileData(newValues);

    try {
      if (id) {
        await profileService.updateProfile(id, formData);
      } else {
        await profileService.createProfile(formData);
      }
    } catch (e) {
      let message = id ? UNABLE_TO_UPDATE_PROFILE : UNABLE_TO_CREATE_PROFILE;
      if (e.statusCode === 401) {
        return navigateTo(LOGIN_PAGE);
      } else if (e.statusCode === 404) {
        message = PROFILE_NOT_FOUND;
      }
      dispatch(showInfoModal(message));
      return false;
    }
    return true;
  },
  ACTIONS.SAVE_PROFILE
);

export const onSaveProfile = createLoadingAction(
  (id) => (dispatch) =>
    dispatch(createOrUpdateProfile(id)).then((isSuccess) => {
      isSuccess && navigateTo(DPD_PROFILES_PAGE);
    })
);

export const onConfirmSlidModal = createLoadingAction(
  (id) => async (dispatch) => {
    await dispatch(createSequences());
    dispatch(onSaveProfile(id));
  }
);

export const showSlidsModal = (formValues, id) => async (dispatch) => {
  const sequences = prepareSlids(formValues);

  if (!sequences.length) {
    return dispatch(onSaveProfile(id));
  }

  const cancelButtonText = id ? CANCEL_EDIT_PROFILE : CANCEL;

  const data = await dispatch(getSequencesBySeqNames(sequences));

  dispatch(
    showModal({
      initialValues: { data },
      customComponent: SLIDS,
      cancelButtonText,
      onConfirmClick: () => dispatch(onConfirmSlidModal(id)),
      interfaceId: CONFIRM_SLID_MODAL.INTERFACE_ID,
      loadId: CONFIRM_SLID_MODAL.LOAD,
      confirmActionId: CONFIRM_SLID_MODAL.SAVE,
      cancelActionId: CONFIRM_SLID_MODAL.CANCEL,
    })
  );
};

export const onCreateOrUpdateProfile = createLoadingAction(
  (id) => async (dispatch, getState) => {
    const state = getState();
    const formValues = getCreateProfileFormValues(state);

    await createProfileAsyncValidate(formValues, id);

    dispatch(showSlidsModal(formValues, id));
  }
);

export const onChangeInternationalAccountToggle =
  (field, value) => (dispatch) => {
    const fieldName =
      field === INTERNATIONAL_DPD_TOGGLE_FIELD
        ? INTERNATIONAL_DPD_LOCAL_TOGGLE_FIELD
        : INTERNATIONAL_DPD_TOGGLE_FIELD;

    dispatch(changeCreateProfileForm(fieldName, !value));
    dispatch(
      resetDefaultsFormValues([
        INTERNATIONAL_EXPRESS_SLID_FIELD,
        INTERNATIONAL_CLASSIC_SLID_FIELD,
        INTERNATIONAL_DIRECT_SLID_FIELD,
        ...ACCOUNT_INTERNATIONAL_TAB_FIELDS,
      ])
    );
  };
