import { Redirect, Route, Switch } from "react-router";

import CreateCustomerUser from "./pages/createCustomerUser";
import CreateUser from "./pages/createUser";
import Migrations from "./pages/customerMigrations";
import AppManagement from "./pages/appManagement";
import EditCustomerUser from "./pages/editCustomerUser";
import { CreateProfile, EditProfile } from "./pages/profile";
import Profiles from "./pages/profiles";
import Search from "./pages/search";
import DpdUsersMenu from "../../components/Layout/components/DpdUsersMenu";
import Layout from "../../components/Layout/Layout";
import { USER_ROLES } from "../../constants/common";
import AuthRoute from "../../features/auth/HOCS/AuthRoute";
import {
  DPD,
  DPD_CREATE_CUSTOMER_USER_PAGE,
  DPD_CREATE_PROFILE,
  DPD_CREATE_USER_PAGE,
  DPD_MIGRATIONS_PAGE,
  DPD_APP,
  DPD_PROFILES_PAGE,
  DPD_SEARCH_PAGE,
  DPD_UPDATE_FOUND_USER,
  DPD_UPDATE_PROFILE,
  PAGE_404,
} from "../../router";

export const DpdUserRoutes = () => (
  <Layout MenuComponent={DpdUsersMenu}>
    <Switch>
      <Redirect from={DPD} exact to={DPD_SEARCH_PAGE} />
      <Route path={DPD_SEARCH_PAGE} exact component={Search} />
      <AuthRoute
        path={DPD_CREATE_USER_PAGE}
        exact
        roles={[USER_ROLES.ADD_DPD_USER]}
        component={CreateUser}
        fallbackPath={DPD}
      />
      <Route
        path={DPD_CREATE_CUSTOMER_USER_PAGE}
        exact
        component={CreateCustomerUser}
      />
      <Route path={DPD_UPDATE_FOUND_USER} exact component={EditCustomerUser} />
      <Route path={DPD_PROFILES_PAGE} exact component={Profiles} />
      <Route path={DPD_MIGRATIONS_PAGE} exact component={Migrations} />
      <AuthRoute
        path={DPD_APP}
        exact
        component={AppManagement}
        roles={[USER_ROLES.APP_MANAGEMENT]}
        fallbackPath={DPD}
      />
      <Route path={DPD_CREATE_PROFILE} exact component={CreateProfile} />
      <Route path={DPD_UPDATE_PROFILE} exact component={EditProfile} />
      <Redirect to={PAGE_404} />
    </Switch>
  </Layout>
);
