import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { ReactComponent as Locked } from "../../../../assets/icons/locked.svg";
import { ReactComponent as Login } from "../../../../assets/icons/login.svg";
import { ReactComponent as Password } from "../../../../assets/icons/password.svg";
import { ReactComponent as Unlocked } from "../../../../assets/icons/unlocked.svg";
import { ReactComponent as User } from "../../../../assets/icons/username.svg";
import { CheckBox } from "../../../../components/CheckBox/CheckBox";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import PasswordStrengthMeter from "../../../../components/PasswordStrengthMeter/PasswordStrengthMeter.js";
import SvgIconComponent from "../../../../components/SvgIcon/svgIcon";
import CustomSwitchWithTitle from "../../../../components/SwitchWidthTitle/CustomSwitchWithTitle";
import {
  ANALYTICS_FOR_USER,
  CREATE_EDIT_USER_ANALYTICS,
  CREATE_MY_DPD_USER_ANALYTICS,
} from "../../../../constants/analytics";
import { USER_FORMS } from "../../../../constants/forms";
import {
  EMAIL_ADDRESS,
  FIRST_NAME,
  LOGIN,
  PASSWORD,
  RE_ENTER_PASSWORD,
  RESET_PASSWORD,
  SURNAME,
  USE_EMAIL_AS_LOGIN,
} from "../../../../constants/strings";
import {
  enableUseEmailAsLogin,
  onChangeUsername,
  updateLoginFieldWithEmailValue,
} from "../../../../features/myDpdUserForm/userDetails/userDetails.actions";
import {
  ACCOUNT_EXPIRY_DATE_FIELD,
  DEFAULT_PASSWORD,
  EMAIL_FIELD,
  NEW_PASSWORD_FIELD,
  PASSWORD_LOCK_FIELD,
  RE_ENTER_PASSWORD_FIELD,
  USE_EMAIL_AS_LOGIN_FIELD,
  USERNAME_FIELD,
} from "../../../../features/myDpdUserForm/userDetails/userDetails.constants";
import {
  getEmailAddress,
  getInitialUseEmailAddressAsLogin,
  getNewPassword,
  getPasswordStrengthScore,
  getUseEmailAddressAsLogin,
} from "../../../../features/myDpdUserForm/userDetails/userDetails.selectors.js";
import {
  asyncValidate,
  validate,
} from "../../../../features/myDpdUserForm/userDetails/userDetails.validate.js";
import { onUserPasswordResetPress } from "../../../../features/user/user.actions";
import { getUserId } from "../../../../features/user/user.selectors";
import { deselectPasswordLock } from "../../../../features/userDetailsForm/userDetails/userDetails.actions";
import {
  FORENAME_FIELD,
  LASTNAME_FIELD,
} from "../../../../features/userDetailsForm/userDetails/userDetails.constants";
import {
  getMyDpdUserDetailsInitialValues,
  isShowPasswordMeter,
} from "../../../../features/userDetailsForm/userDetails/userDetails.models";
import { getAdminAccess } from "../../../../features/userDetailsForm/userDetailsForm.selector";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import withRef from "../../../../hocs/withRef.js";
import {
  isDefaultPassword,
  isEmailValid,
} from "../../../../utils/commonValidation.js";
import { withoutSpaces } from "../../../../utils/normalize";
import ExpiryDateDatePicker from "./ExpiryDateDatePicker.js";
import styles from "./UserDetails.module.scss";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const UserDetails = ({
  match: {
    params: { id: userId },
  },
  isUseEmailAsLoginEnabled,
  isUseEmailAsLoginInitialEnabled,
  email,
  passwordStrength,
  isAdminAccess,
  onBlurFields,
  newPassword,
  copyEmailValueToLoginField,
  onResetPassword,
  enableUseEmailAsLogin,
  onChangeUsername,
  onDeselectPasswordLock,
  onOpenDatepicker,
  onChangeExpiryDate,
  onPasswordLock,
  currentUserId,
}) => {
  const createUserWithEmailAsLogin = isUseEmailAsLoginEnabled && !userId;
  const updateUserWithEmailAsLogin =
    userId && isUseEmailAsLoginEnabled && !isAdminAccess;
  const disabledEmailField =
    !!updateUserWithEmailAsLogin || isUseEmailAsLoginInitialEnabled;
  const disabledUsername = isUseEmailAsLoginEnabled || !!userId;
  const showResetButton =
    userId && isUseEmailAsLoginInitialEnabled && currentUserId !== userId;
  const showPassword = !isAdminAccess && !isUseEmailAsLoginEnabled;

  return (
    <section className={styles.wrapper}>
      <Grid container className={styles.container}>
        <Grid item className={styles.field}>
          <Field
            name={FORENAME_FIELD}
            component={DecoratedFieldWithError}
            label={FIRST_NAME}
            onBlur={() => onBlurFields(FORENAME_FIELD)}
            icon={<User />}
            inputProps={{ maxLength: 35 }}
            required
            blurNormalize={withoutSpaces}
          />
        </Grid>

        <Grid item className={styles.field}>
          <Field
            name={LASTNAME_FIELD}
            component={DecoratedFieldWithError}
            label={SURNAME}
            icon={<User />}
            onBlur={() => onBlurFields(LASTNAME_FIELD)}
            inputProps={{ maxLength: 35 }}
            required
            blurNormalize={withoutSpaces}
          />
        </Grid>

        <Grid item className={styles.field}>
          <Field
            name={EMAIL_FIELD}
            component={DecoratedFieldWithError}
            label={EMAIL_ADDRESS}
            icon={<SvgIconComponent name="email" />}
            onChange={(event, value) => {
              createUserWithEmailAsLogin && copyEmailValueToLoginField(value);
            }}
            onBlur={() => onBlurFields(EMAIL_FIELD)}
            disabled={disabledEmailField}
            normalize={withoutSpaces}
            inputProps={{ maxLength: 100 }}
            required={isUseEmailAsLoginEnabled || isAdminAccess}
          />
        </Grid>

        <Grid item className={styles.field}>
          <Field
            name={USERNAME_FIELD}
            component={DecoratedFieldWithError}
            label={`${LOGIN}`}
            icon={<Login />}
            required={true}
            onBlur={() => onBlurFields(USERNAME_FIELD)}
            disabled={disabledUsername}
            normalize={withoutSpaces}
            inputProps={{ maxLength: 100 }}
            onChange={(event, value) => {
              onChangeUsername(value);
            }}
          />
        </Grid>

        <Grid container direction="row" justify="space-between">
          <Grid
            container
            item
            direction="column"
            className={styles.emailAsLoginContainer}
          >
            <Grid item className={styles.emailAsLogin}>
              <Field
                name={USE_EMAIL_AS_LOGIN_FIELD}
                component={CustomSwitchWithTitle}
                label={USE_EMAIL_AS_LOGIN}
                labelStyle={styles.switchLabel}
                onChange={(event, value) =>
                  enableUseEmailAsLogin(event, value, email)
                }
                type="checkbox"
                disabled={isAdminAccess || !!userId}
              />
            </Grid>
            <Field
              name={ACCOUNT_EXPIRY_DATE_FIELD}
              component={ExpiryDateDatePicker}
              onOpen={onOpenDatepicker}
              onChange={onChangeExpiryDate}
              disabled={isAdminAccess}
            />
          </Grid>
          {showResetButton && (
            <Grid>
              <Button onClick={() => onResetPassword(email)}>
                {RESET_PASSWORD}
              </Button>
            </Grid>
          )}
          {showPassword && (
            <Grid container item direction="column" className={styles.password}>
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={styles.newPasswordContainer}
              >
                <Grid item className={styles.newPassword}>
                  <Field
                    name={NEW_PASSWORD_FIELD}
                    component={DecoratedFieldWithError}
                    label={PASSWORD}
                    icon={<Password />}
                    adornmentStyles={{ adornmentIcon: styles.adornmentIcon }}
                    onBlur={() => onBlurFields(NEW_PASSWORD_FIELD)}
                    inputProps={{ maxLength: 100 }}
                    onChange={(event, value) => {
                      if (value === DEFAULT_PASSWORD) {
                        onDeselectPasswordLock();
                      }
                    }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    name={PASSWORD_LOCK_FIELD}
                    component={CheckBox}
                    type="checkbox"
                    labelClasses={{ root: styles["checkbox-label"] }}
                    icon={<Unlocked />}
                    checkedIcon={<Locked />}
                    onChange={onPasswordLock}
                    disabled={isDefaultPassword(newPassword)}
                  />
                </Grid>

                <Grid item className={styles.passwordIndicator}>
                  {isShowPasswordMeter(newPassword) && (
                    <PasswordStrengthMeter
                      passwordStrengthScore={passwordStrength}
                    />
                  )}
                </Grid>
              </Grid>
              <Field
                name={RE_ENTER_PASSWORD_FIELD}
                component={DecoratedFieldWithError}
                label={RE_ENTER_PASSWORD}
                icon={<Password />}
                inputProps={{ maxLength: 100 }}
                onBlur={() => onBlurFields(RE_ENTER_PASSWORD_FIELD)}
                adornmentStyles={{ adornmentIcon: styles.adornmentIcon }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </section>
  );
};

UserDetails.propTypes = {
  match: PropTypes.object.isRequired,
  isUseEmailAsLoginEnabled: PropTypes.bool,
  isUseEmailAsLoginInitialEnabled: PropTypes.bool,
  email: PropTypes.string,
  passwordStrength: PropTypes.number,
  isAdminAccess: PropTypes.bool,
  newPassword: PropTypes.string,
  currentUserId: PropTypes.string,
  copyEmailValueToLoginField: PropTypes.func,
  onResetPassword: PropTypes.func,
  onBlurFields: PropTypes.func,
  enableUseEmailAsLogin: PropTypes.func,
  onChangeUsername: PropTypes.func,
  onOpenDatepicker: PropTypes.func,
  onChangeExpiryDate: PropTypes.func,
  onDeselectPasswordLock: PropTypes.func,
  onPasswordLock: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isUseEmailAsLoginEnabled: getUseEmailAddressAsLogin(state),
  isUseEmailAsLoginInitialEnabled: getInitialUseEmailAddressAsLogin(state),
  email: getEmailAddress(state),
  passwordStrength: getPasswordStrengthScore(state),
  isAdminAccess: getAdminAccess(state),
  newPassword: getNewPassword(state),
  currentUserId: getUserId(state),
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id },
    },
  }
) => ({
  copyEmailValueToLoginField: (value) => {
    if (isEmailValid(value)) {
      dispatch(updateLoginFieldWithEmailValue(value));
    }
  },
  onResetPassword: () => dispatch(onUserPasswordResetPress(id)),
  enableUseEmailAsLogin: (event, value, email) =>
    dispatch(enableUseEmailAsLogin(value, email)),
  onDeselectPasswordLock: () => dispatch(deselectPasswordLock()),
  onChangeUsername: (value) => dispatch(onChangeUsername(value)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: USER_FORMS.DPD_USER_DETAILS,
    validate,
    asyncValidate,
    shouldAsyncValidate: () => true,
    asyncChangeFields: [USE_EMAIL_AS_LOGIN_FIELD],
    asyncBlurFields: [USERNAME_FIELD, EMAIL_FIELD],
    initialValues: getMyDpdUserDetailsInitialValues(),
  }),
  withTrackProps(() => ({
    onOpenDatepicker: () => CREATE_EDIT_USER_ANALYTICS.EDIT_ACCOUNT_EXPIRY,
    onResetPassword: () => CREATE_MY_DPD_USER_ANALYTICS.RESET_PASSWORD,
    onPasswordLock: (event) => {
      if (event.target.checked) {
        return ANALYTICS_FOR_USER.CLICK_PADLOCK_TO_LOCK_PASSWORD;
      }
    },
    enableUseEmailAsLogin: (event, value, email) =>
      createLogEntryPayload(CREATE_EDIT_USER_ANALYTICS.CLICK_TOGGLE, {
        value,
        field: USE_EMAIL_AS_LOGIN_FIELD,
        email,
      }),
    onChangeExpiryDate: (value) => {
      if (!value) {
        return CREATE_EDIT_USER_ANALYTICS.REMOVE_ACCOUNT_EXPIRY;
      }
      return CREATE_EDIT_USER_ANALYTICS.ADD_ACCOUNT_EXPIRY;
    },
  })),
  withRef
)(UserDetails);
