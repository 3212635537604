import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import MappingTable from "../../components/MappingTable/MappingTable";
import { ANALYTICS_FOR_USER } from "../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../constants/forms";
import {
  DEFAULT_SERVICE,
  MAPPING,
  MAPPING_DESCRIPTIONS,
} from "../../constants/strings";
import { isMappingFieldValid } from "../../features/userDetailsForm/serviceXrefMappings/serviceXrefMapping.selectors";
import {
  addMappingRow,
  onRemoveMapping,
  onSelectMapping,
  resetServiceXrefMapping,
} from "../../features/userDetailsForm/serviceXrefMappings/serviceXrefMappings.actions";
import { SERVICE_XREF_MAPPINGS_FIELD } from "../../features/userDetailsForm/serviceXrefMappings/serviceXrefMappings.constants";
import { getXrefMappingInitialValues } from "../../features/userDetailsForm/serviceXrefMappings/serviceXrefMappings.model";
import { validate } from "../../features/userDetailsForm/serviceXrefMappings/serviceXrefMappings.validate";
import withRef from "../../hocs/withRef";

const ServiceXrefMappings = ({
  resetMappingForm,
  addNewMappingClick,
  onRemoveItem,
  onSelectItem,
  isButtonDisabled,
}) => (
  <MappingTable
    fieldArrayName={SERVICE_XREF_MAPPINGS_FIELD}
    onClearAllClick={resetMappingForm}
    headerRows={[MAPPING_DESCRIPTIONS, DEFAULT_SERVICE]}
    fieldsNaming={{
      labelName: "mappingText",
      selectName: "serviceCode",
      selectFieldName: "name",
      selectFieldCode: "code",
    }}
    inputPlaceholder={MAPPING}
    onAddMappingClick={addNewMappingClick}
    disableButton={isButtonDisabled}
    onRemoveItem={onRemoveItem}
    onSelectValue={onSelectItem}
  />
);

ServiceXrefMappings.propTypes = {
  resetMappingForm: PropTypes.func.isRequired,
  addNewMappingClick: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isButtonDisabled: isMappingFieldValid(state),
});

const mapDispatchToProps = {
  resetMappingForm: resetServiceXrefMapping,
  addNewMappingClick: addMappingRow,
  onSelectItem: onSelectMapping,
  onRemoveItem: onRemoveMapping,
};

export default compose(
  reduxForm({
    form: USER_DETAILS_FORMS.XREF_MAPPINGS,
    initialValues: getXrefMappingInitialValues(),
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(() => ({
    resetMappingForm: () => ANALYTICS_FOR_USER.CLICK_CLEAR_ALL_MAPPINGS,
    addNewMappingClick: () => ANALYTICS_FOR_USER.CLICK_ADD_MAPPINGS,
    onSelectItem: () => ANALYTICS_FOR_USER.CLICK_DEFAULT_SERVICE_DROPDOWN_LIST,
    onRemoveItem: (index, value) =>
      createLogEntryPayload(ANALYTICS_FOR_USER.CLICK_REMOVE_MAPPINGS, {
        value,
      }),
  })),
  withRef
)(ServiceXrefMappings);
