import { API_ENDPOINTS } from "../../constants/common";
import { webApi } from "../../utils/api";

export const getDPDAppConfig = () =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CONFIG}/dpd-app/config`,
    })
    .then((res) => res.data);

export const updateDPDAppConfig = (body) =>
  webApi
    .put({
      path: `${API_ENDPOINTS.CONFIG}/dpd-app/config`,
      body,
    })
    .then((res) => res.data);

export const getDPDAppVersions = () =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CONFIG}/dpd-app/versions`,
    })
    .then((res) => res.data);

export const getDPDAppVersionsDetails = () =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CONFIG}/dpd-app/versions/details`,
    })
    .then((res) => res.data);

export const updateDPDAppVersionsDetails = (body) =>
  webApi
    .fetch({
      method: "PATCH",
      path: `${API_ENDPOINTS.CONFIG}/dpd-app/versions`,
      body,
    })
    .then((res) => res.data);
