import { memo, useEffect, useReducer, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import get from "lodash/get";
import PropTypes from "prop-types";

import styles from "./SearchFieldWithList.module.scss";
import { dataFetchReducer, initialState } from "./serchField.reducer";
import InputWithTitle from "../InputWithTitle/InputWithTitle";

const fetchData = async (fetchFunction, dispatch, value) => {
  try {
    const result = await fetchFunction(value);

    dispatch({ type: "FETCH_SUCCESS", payload: result });
  } catch (err) {
    dispatch({ type: "FETCH_FAILURE" });
  }
};

const SearchFieldWithList = ({
  input,
  label,
  fetchFunction,
  formatItem,
  onClick,
  ...props
}) => {
  const { active, valid, dirty, initial } = props.meta;
  const inputValue = input.value;
  const shouldFetch =
    valid &&
    inputValue &&
    active &&
    !props.disabled &&
    (dirty || inputValue === initial);
  const [inputRef, setRef] = useState(null);
  const [menuRef, setMenuRef] = useState(null);
  const [{ data, isOpen }, dispatch] = useReducer(
    dataFetchReducer,
    initialState
  );

  useEffect(() => {
    let timeoutId;

    if (shouldFetch) {
      timeoutId = setTimeout(
        () => fetchData(fetchFunction, dispatch, inputValue),
        500
      );
    } else {
      dispatch({ type: "FETCH_FAILURE" });
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [inputValue]);

  const handleInputBlur = (e) => {
    if (get(e, "relatedTarget.parentNode") === menuRef) {
      // if click was on menu item we should stop input blur to prevent close popover
      // popover close before item click because input blur triggered firstly
      return false;
    }

    closePopover();
  };

  const closePopover = () => dispatch({ type: "CLOSE_POPPER" });

  const onItemClick = (item) => {
    closePopover();
    onClick && onClick(item);
  };

  const renderItems = (data) =>
    data.map((item) => {
      const formattedItem = formatItem ? formatItem(item) : item;
      const key = item.udprn || formattedItem;

      return (
        <MenuItem key={key} onClick={() => onItemClick(item)}>
          <span className={styles.menuItem}>{formattedItem}</span>
        </MenuItem>
      );
    });

  return (
    <>
      <InputWithTitle
        inputRef={setRef}
        label={label}
        input={input}
        aria-describedby={isOpen && "simple-popover"}
        onFocus={(e) => {
          data.length && dispatch({ type: "OPEN_POPPER" });
          input.onFocus && input.onFocus(e);
        }}
        onBlur={handleInputBlur}
        autoComplete="disable"
        {...props}
      />
      <Popper
        open={isOpen}
        anchorEl={inputRef}
        transition
        placement={"bottom-start"}
        style={{ zIndex: 1400 }}
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
      >
        {() => (
          <Paper>
            <MenuList
              ref={setMenuRef}
              style={{ minWidth: inputRef.clientWidth }}
              className={styles.menu}
            >
              {renderItems(data)}
            </MenuList>
          </Paper>
        )}
      </Popper>
    </>
  );
};

SearchFieldWithList.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  fetchFunction: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  formatItem: PropTypes.func,
};

export default memo(SearchFieldWithList);
