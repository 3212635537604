import ip6addr from "ip6addr/ip6addr";

export const checkIpBelongToIpRange = (startIp, endIp, ipAddress) => {
  const ipAddressRange = ip6addr.createAddrRange(startIp, endIp);
  return ipAddressRange.contains(ipAddress);
};

export const checkStartIpComesBeforeEndIp = (startIp, endIp) =>
  ip6addr.compare(startIp, endIp) === -1;

export const checkIpValid = (ip) => {
  try {
    return Boolean(ip6addr.parse(ip));
  } catch (error) {
    return false;
  }
};
