import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import {
  LIMIT_COLLECTIONS_USER,
  LIMIT_COLLECTIONS_USER_INFO,
} from "../../constants/strings";
import { SHOW_USER_COLLECTIONS } from "../../features/userDetailsForm/miscellaneousConfiguration/miscellaneousConfiguration.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";
import styles from "./Collections.module.scss";

export const Collections = ({ onInputChange, onInfoMouseOver }) => (
  <Grid container justify="space-between" className={styles.content}>
    <Field
      name={SHOW_USER_COLLECTIONS}
      component={CustomSwitchWithTitle}
      type="checkbox"
      onInfoMouseOver={onInfoMouseOver}
      label={LIMIT_COLLECTIONS_USER}
      infoLabel={LIMIT_COLLECTIONS_USER_INFO}
      onChange={(event, value) => {
        onInputChange(SHOW_USER_COLLECTIONS, value);
      }}
    />
  </Grid>
);

Collections.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onInfoMouseOver: PropTypes.func.isRequired,
};

export default Collections;
