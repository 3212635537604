import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import styles from "./ChangeDelivery.module.scss";
import {
  RESTRICT_DELIVER_ALTERNATIVE_ADDRESS,
  RESTRICT_REDELIVER_INFO,
  RESTRICT_UPGRADE_ACTIONS,
  RESTRICT_UPGRADE_ACTIONS_INFO,
} from "../../constants/strings";
import {
  RESTRICT_REDELIVER_FIELD,
  RESTRICT_UPGRADE_ACTIONS_FIELD,
} from "../../features/userDetailsForm/miscellaneousConfiguration/miscellaneousConfiguration.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

export const ChangeDelivery = ({ onInputChange, onInfoMouseOver }) => (
  <Grid container justify="space-between" className={styles.content}>
    <Field
      name={RESTRICT_REDELIVER_FIELD}
      component={CustomSwitchWithTitle}
      type="checkbox"
      onInfoMouseOver={onInfoMouseOver}
      label={RESTRICT_DELIVER_ALTERNATIVE_ADDRESS}
      infoLabel={RESTRICT_REDELIVER_INFO}
      onChange={(event, value) => {
        onInputChange(RESTRICT_REDELIVER_FIELD, value);
      }}
    />
    <Field
      name={RESTRICT_UPGRADE_ACTIONS_FIELD}
      component={CustomSwitchWithTitle}
      type="checkbox"
      onInfoMouseOver={onInfoMouseOver}
      label={RESTRICT_UPGRADE_ACTIONS}
      infoLabel={RESTRICT_UPGRADE_ACTIONS_INFO}
      onChange={(event, value) => {
        onInputChange(RESTRICT_UPGRADE_ACTIONS_FIELD, value);
      }}
    />
  </Grid>
);

ChangeDelivery.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onInfoMouseOver: PropTypes.func.isRequired,
};

export default ChangeDelivery;
