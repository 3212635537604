import { format } from "date-fns";
import {
  MINIMUM_VERSION_FIELD,
  LATEST_VERSION_FIELD,
  ALLOW_DRAFT_FIELD,
  ALLOW_PRERELEASE_FIELD,
  ALLOW_DEPRECATED_FIELD,
} from "./appManagement.constants";

export const getDPDAppConfigFormInitialValues = () => ({
  [MINIMUM_VERSION_FIELD]: "",
  [LATEST_VERSION_FIELD]: "",
  [ALLOW_DRAFT_FIELD]: false,
  [ALLOW_PRERELEASE_FIELD]: false,
  [ALLOW_DEPRECATED_FIELD]: false,
});

export const getStatusTitle = (status) =>
  status.toUpperCase() !== "RELEASE" ? `(${status.toUpperCase()})` : "";

export const formatDateTime = (dateTime) =>
  format(new Date(dateTime), "d MMMM yyyy ");

export const findAppVersionsChanges = (versions, originVersions) => {
  const assocOriginVersion = originVersions.reduce((acc, versionItem) => {
    acc[versionItem.version] = versionItem;
    return acc;
  }, {});

  return versions.reduce((changesVersions, versionItem) => {
    const originVersion = assocOriginVersion[versionItem.version];

    if (originVersion) {
      if (originVersion.status !== versionItem.status) {
        changesVersions.push(versionItem);
      }
    }
    return changesVersions;
  }, []);
};

export const getUpdateVersionsDetails = (versions) =>
  versions.map((versionItem) => ({
    status: versionItem.status,
    version: versionItem.version,
  }));
