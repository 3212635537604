import { Fragment } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { compose } from "recompose";

import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import SimpleTable from "../../../../components/Table/Table";
import {
  AUDIT_TABLE_HEADERS,
  CHANGE_MESSAGE,
  DATE_CREATED,
  LAST_LOGON,
  LAST_MAINTAINED,
  NO_AUDIT_LOGS,
} from "../../../../constants/strings";
import { getUserAuditData } from "../../../../features/audit/audit.models";
import { getChangeMessage } from "../../../../features/userDetailsForm/userDetails/userDetails.selectors";
import { getUserInfo } from "../../../../features/userDetailsForm/userDetailsForm.selector";
import styles from "./MaintainUser.module.scss";

const UserInfoField = ({ title, value }) => (
  <Fragment>
    <Grid item xs={4}>
      <div className={styles["info-label"]}>{`${title}:`}</div>
    </Grid>
    <Grid item xs={8}>
      <div className={styles["info-value"]}>{value}</div>
    </Grid>
  </Fragment>
);

UserInfoField.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

const MaintainUser = ({ userInfo }) => {
  const { audit, ...user } = userInfo;
  const auditData = getUserAuditData(audit, user);

  const changeMessage = useSelector(getChangeMessage);

  const {
    dateCreated,
    lastMaintained,
    lastLogon,
    auditValues = [],
  } = auditData;

  return (
    <Box width="100%">
      <Box display="flex" flexDirection="row">
        <SelectWithTitle
          value={"View Audit"}
          containerStyles={styles["select-container"]}
          menuContainerStyles={styles["select-menu-container"]}
          displayEmpty
          renderValue={() => (
            <span className={styles["view-select"]}>{"View Audit"}</span>
          )}
        >
          <div className={styles["table-container"]}>
            <SimpleTable
              headerRows={AUDIT_TABLE_HEADERS}
              cellStyles={styles["table-cell"]}
              noValueText={NO_AUDIT_LOGS}
              input={{
                value: auditValues,
              }}
            />
          </div>
        </SelectWithTitle>
      </Box>
      <Box width={1} p={1}>
        <Grid container spacing={1}>
          <Grid container item xs={12} className={styles["info-container"]}>
            <UserInfoField title={CHANGE_MESSAGE} value={changeMessage} />
          </Grid>
          <Grid container item xs={12} className={styles["info-container"]}>
            <UserInfoField title={DATE_CREATED} value={dateCreated} />
          </Grid>
          <Grid container item xs={12} className={styles["info-container"]}>
            <UserInfoField title={LAST_MAINTAINED} value={lastMaintained} />
          </Grid>
          <Grid container item xs={12} className={styles["info-container"]}>
            <UserInfoField title={LAST_LOGON} value={lastLogon} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

MaintainUser.propTypes = {
  userId: PropTypes.string,
  userInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
});

export default compose(connect(mapStateToProps))(MaintainUser);
