import { useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import PropTypes from "prop-types";

import styles from "./Layout.module.scss";
import SideBar from "../SideBar/SideBar.js";
import SvgIconComponent from "../SvgIcon/svgIcon";

const drawerWidth = 238;

// SHOULD MOVE TO SCSS
const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#282828",
    width: drawerWidth,
    left: "auto",
    border: 0,
    height: "auto",
    minHeight: "100%",
  },
}));

export default function Layout({ children, MenuComponent }) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div className={styles.container}>
      <Hidden mdDown>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <SideBar>
            <MenuComponent />
          </SideBar>
        </Drawer>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <SideBar>
            <MenuComponent />
          </SideBar>
        </Drawer>
      </Hidden>
      <div className={styles.main}>
        <Hidden lgUp>
          <AppBar position="fixed" className={styles["app-bar"]}>
            <Toolbar>
              <IconButton
                onClick={handleDrawerToggle}
                className={styles.button}
              >
                <SvgIconComponent name="dpd" width="71px" height="71px" />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Hidden>
        {children}
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  MenuComponent: PropTypes.elementType,
};
