import get from "lodash/get";
import isNil from "lodash/isNil";

import { ACTIONS } from "./userDetailsForm.actions";

export const initialState = {
  isOverrideEnabled: false,
  masterEodDetails: null,
  customerData: {},
  userInfo: {},
  apiUsersKeys: {},
  isEmailSent: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_USER_INFO.SUCCESS: {
      const { account, eodMasterUser, eodMasterAvailable, uid, ...customer } =
        get(action, "payload.customer", {});
      const userInfo = get(action, "payload.user", {});
      const apiUsersKeys = get(action, "payload.apiUsersKeys", {});

      return {
        ...state,
        isOverrideEnabled: true,
        masterEodDetails: {
          // @see https://it.dpduk.live/it/diagram/diag_TDMzvo6C48kIGKjZ.html?id=1669108556422
          eodMasterAvailable: isNil(eodMasterAvailable) || eodMasterAvailable,
          eodMasterUser,
          customerId: uid,
          account,
        },
        customerData: customer,
        userInfo,
        apiUsersKeys,
      };
    }
    case ACTIONS.CHANGE_ACCOUNT:
    case ACTIONS.PREPARE_CUSTOMER_DATA.SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { account, eodMasterUser, eodMasterAvailable, uid, ...customer } =
        action.payload || {};

      return {
        ...state,
        isOverrideEnabled: true,
        masterEodDetails: {
          eodMasterAvailable: isNil(eodMasterAvailable) || eodMasterAvailable,
          eodMasterUser,
          customerId: uid,
          account,
        },
        customerData: customer,
      };

    case ACTIONS.DELETE_API_USER_KEY.SUCCESS:
    case ACTIONS.UPDATE_API_USERS_KEYS: {
      return { ...state, apiUsersKeys: action.payload };
    }

    case ACTIONS.VERIFY_API_USER_EMAIL.SUCCESS: {
      return { ...state, apiUsersKeys: action.payload, isEmailSent: true };
    }

    case ACTIONS.VERIFY_API_USER_EMAIL.FAILURE: {
      return { ...state, isEmailSent: false };
    }

    case ACTIONS.DELETE_API_USER.SUCCESS: {
      return { ...state, apiUsersKeys: initialState.apiUsersKeys };
    }

    case ACTIONS.CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};
