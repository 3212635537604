import { Box, Button, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import styles from "./ServiceRestrictions.module.scss";
import MappingTable from "../../components/MappingTable/MappingTable";
import { ANALYTICS_FOR_USER } from "../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../constants/forms";
import {
  ADD_REPLACE_DAY,
  HIGHLANDS_AND_ISLAND_CODES,
  IRISH_CODES,
  OFFSHORE_CODES,
  RESTRICTION,
  SECTOR,
} from "../../constants/strings";
import {
  addHighlandsCodes,
  addIrishCodes,
  addOffshoreCodes,
  addServiceRestrictionMappingRow,
  onRemoveRestriction,
  onSelectValueRestriction,
  resetServiceRestrictionsMapping,
} from "../../features/userDetailsForm/serviceRestrictions/serviceRestrictions.actions";
import { SERVICE_RESTRICTED_FIELD } from "../../features/userDetailsForm/serviceRestrictions/serviceRestrictions.constants";
import { getRestrictionsInitialValues } from "../../features/userDetailsForm/serviceRestrictions/serviceRestrictions.model";
import { isRestrictionMappingValid } from "../../features/userDetailsForm/serviceRestrictions/serviceRestrictions.selectors";
import { validate } from "../../features/userDetailsForm/serviceRestrictions/serviceRestrictions.validate";
import withRef from "../../hocs/withRef";
import { uppercase } from "../../utils/normalize";

const ServiceRestrictions = ({
  resetMappingForm,
  addNewMappingClick,
  onSelectValue,
  onRemoveItem,
  isButtonDisabled,
  onAddIrishCodesClick,
  onAddHighlandsCodesClick,
  onAddOffshoreCodesClick,
  onChange,
}) => (
  <Grid container direction="column">
    <MappingTable
      fieldArrayName={SERVICE_RESTRICTED_FIELD}
      onClearAllClick={() => {
        onChange("clearAllClick");
        resetMappingForm();
      }}
      headerRows={[SECTOR, RESTRICTION]}
      fieldsNaming={{
        labelName: "postcode",
        selectName: "restriction",
        selectFieldName: "restrictionName",
        selectFieldCode: "restrictionCode",
      }}
      inputPlaceholder={SECTOR}
      onAddMappingClick={(item) => {
        onChange("addMappingClick");
        addNewMappingClick(item);
      }}
      onSelectValue={onSelectValue}
      onRemoveItem={onRemoveItem}
      disableButton={isButtonDisabled}
      normalize={uppercase}
    />

    <Grid
      container
      item
      justify="space-between"
      alignItems="center"
      className={styles["button-container"]}
    >
      <Typography className={styles.title}>{ADD_REPLACE_DAY}</Typography>
      <Box className={styles.buttons}>
        <Button
          classes={{ label: styles.label }}
          onClick={(item) => {
            onChange(IRISH_CODES);
            onAddIrishCodesClick(item);
          }}
        >
          {IRISH_CODES}
        </Button>
        <Button
          classes={{ label: styles.label }}
          onClick={(item) => {
            onChange(OFFSHORE_CODES);
            onAddOffshoreCodesClick(item);
          }}
        >
          {OFFSHORE_CODES}
        </Button>
        <Button
          classes={{ label: styles.label }}
          onClick={(item) => {
            onChange(HIGHLANDS_AND_ISLAND_CODES);
            onAddHighlandsCodesClick(item);
          }}
        >
          {HIGHLANDS_AND_ISLAND_CODES}
        </Button>
      </Box>
    </Grid>
  </Grid>
);

ServiceRestrictions.propTypes = {
  resetMappingForm: PropTypes.func,
  addNewMappingClick: PropTypes.func,
  onSelectValue: PropTypes.func,
  onRemoveItem: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
  onAddIrishCodesClick: PropTypes.func,
  onAddHighlandsCodesClick: PropTypes.func,
  onAddOffshoreCodesClick: PropTypes.func,
  onChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isButtonDisabled: isRestrictionMappingValid(state),
});

const mapDispatchToProps = {
  resetMappingForm: resetServiceRestrictionsMapping,
  addNewMappingClick: addServiceRestrictionMappingRow,
  onSelectValue: onSelectValueRestriction,
  onRemoveItem: onRemoveRestriction,
  onAddIrishCodesClick: addIrishCodes,
  onAddOffshoreCodesClick: addOffshoreCodes,
  onAddHighlandsCodesClick: addHighlandsCodes,
};

export default compose(
  reduxForm({
    form: USER_DETAILS_FORMS.SERVICE_RESTRICTIONS,
    initialValues: getRestrictionsInitialValues(),
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(() => ({
    onRemoveItem: () => ANALYTICS_FOR_USER.CLICK_REMOVE_SECTOR,
    onChange: (field) => {
      let id = "";
      switch (field) {
        case HIGHLANDS_AND_ISLAND_CODES:
          id = ANALYTICS_FOR_USER.CLICK_HIGHLANDS_CODES;
          break;
        case OFFSHORE_CODES:
          id = ANALYTICS_FOR_USER.CLICK_OFFSHORE_CODES;
          break;
        case IRISH_CODES:
          id = ANALYTICS_FOR_USER.CLICK_IRISH_CODES;
          break;
        case "clearAllClick":
          id = ANALYTICS_FOR_USER.CLICK_CLEAR_ALL_SECTOR;
          break;
        case "addMappingClick":
          id = ANALYTICS_FOR_USER.CLICK_ADD_SECTOR;
          break;
      }
      return createLogEntryPayload(id, {
        field,
      });
    },
  })),
  withRef
)(ServiceRestrictions);
