import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import {
  actions,
  button,
  fields,
  form,
  formField,
  icon,
} from "./LoginForm.module.scss";
import { validate } from "./LoginForm.validate";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/username.svg";
import LoginInput from "../../../../components/LoginInput";
import PasswordField from "../../../../components/PasswordField/PasswordField";
import { LOGIN_ANALYTICS } from "../../../../constants/analytics";
import { LOGIN_FORM } from "../../../../constants/forms";
import { signInWithPassword } from "../../../../features/auth/auth.actions";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import ForgotPassword from "../../components/ForgotPassword";
import LoginSubmitButton from "../../components/LoginSubmitButton";

const PASSWORD = "Password";
const LOGIN = "Login";

const DecoratedFieldWithError = withErrorTooltip(LoginInput);
const PasswordFieldWithError = withErrorTooltip(PasswordField);

const LoginForm = ({ handleSubmit, onChange, onLogin, onForgotPassword }) => (
  <form onSubmit={handleSubmit(onLogin)} className={form}>
    <div className={fields}>
      <div className={formField}>
        <Field
          name="email"
          component={DecoratedFieldWithError}
          icon={<EmailIcon className={icon} />}
          placeholder={LOGIN}
        />
      </div>
      <div className={formField}>
        <Field
          name="password"
          component={PasswordFieldWithError}
          id="password"
          placeholder={PASSWORD}
          onChange={onChange}
        />
      </div>
    </div>

    <Grid container className={actions}>
      <Grid item sm={7}>
        <ForgotPassword onForgotPassword={onForgotPassword} />
      </Grid>
      <Grid item sm={5} className={button}>
        <LoginSubmitButton>{LOGIN}</LoginSubmitButton>
      </Grid>
    </Grid>
  </form>
);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  loginError: PropTypes.string,
  onChange: PropTypes.func,
  onLogin: PropTypes.func,
  onForgotPassword: PropTypes.func,
};

export default compose(
  connect(null, {
    onLogin: signInWithPassword,
  }),
  withTrack({
    loadId: LOGIN_ANALYTICS.LOAD,
    interfaceId: LOGIN_ANALYTICS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onForgotPassword: () => LOGIN_ANALYTICS.FORGOTTEN_PASSWORD,
    onLogin: () => LOGIN_ANALYTICS.LOGIN,
  })),
  reduxForm({
    initialValues: {
      email: "",
      password: "",
    },
    form: LOGIN_FORM,
    validate,
  })
)(LoginForm);
