import Select from "@material-ui/core/Select";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./SelectWithTitle.module.scss";
import InputTitleAdornment from "../InputTitleAdornment/InputTitleAdornment";

const SelectWithTitle = ({
  label,
  input,
  required,
  children,
  containerStyles,
  menuContainerStyles,
  showMetaErrors,
  meta,
  selectStyles,
  ...props
}) => {
  const displayMetaError = showMetaErrors && !meta.valid && meta.touched;
  return (
    <div
      className={classNames(
        styles.rounded,
        props.disabled && styles.disabled,
        containerStyles && containerStyles,
        displayMetaError && styles["meta-error"]
      )}
    >
      {label && (
        <InputTitleAdornment
          label={label}
          required={required}
          containerClass={styles.adornment}
        />
      )}
      <Select
        disableUnderline
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          classes: { paper: menuContainerStyles || styles.paper },
        }}
        classes={{ select: styles.select, icon: styles.icon }}
        className={classNames(styles.root, selectStyles && selectStyles)}
        {...input}
        {...props}
      >
        {children}
      </Select>
    </div>
  );
};

SelectWithTitle.propTypes = {
  children: PropTypes.node,
  InputProps: PropTypes.any,
  label: PropTypes.string,
  input: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  containerStyles: PropTypes.any,
  showMetaErrors: PropTypes.bool,
  meta: PropTypes.object,
  menuContainerStyles: PropTypes.string,
  updateFocus: PropTypes.func,
  selectStyles: PropTypes.any,
};

SelectWithTitle.defaultProps = {
  required: false,
};
export default SelectWithTitle;
