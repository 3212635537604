import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { button, container, title } from "./ForgotPasswordPage.module.scss";
import Link from "../../../../components/Link";
import { FORGOT_PASSWORD_ANALYTICS } from "../../../../constants/analytics";
import {
  BACK,
  FORGOTTEN_YOUR_PASSWORD,
  SUBMIT,
} from "../../../../constants/strings";
import { onForgotPasswordSubmit } from "../../../../features/auth/auth.actions";
import { LOGIN_PAGE } from "../../../../router";
import LoginEmailForm from "../../components/LoginEmailForm";

const ForgotPasswordPage = ({ onSubmitPress, goBack }) => (
  <section className={container}>
    <LoginEmailForm
      title={FORGOTTEN_YOUR_PASSWORD}
      buttonText={SUBMIT}
      titleStyle={title}
      buttonStyle={button}
      onSubmitPress={onSubmitPress}
    />
    <Link to={LOGIN_PAGE} onClick={goBack}>
      {BACK}
    </Link>
  </section>
);

ForgotPasswordPage.propTypes = {
  onSubmitPress: PropTypes.func,
  goBack: PropTypes.func,
};

const mapDispatchToProps = {
  onSubmitPress: onForgotPasswordSubmit,
};

export default compose(
  connect(null, mapDispatchToProps),
  withTrack({
    loadId: FORGOT_PASSWORD_ANALYTICS.LOAD,
    interfaceId: FORGOT_PASSWORD_ANALYTICS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onSubmitPress: () => FORGOT_PASSWORD_ANALYTICS.CONTINUE,
    goBack: () => FORGOT_PASSWORD_ANALYTICS.CANCEL,
  }))
)(ForgotPasswordPage);
