import find from "lodash/find";
import omit from "lodash/omit";
import { arrayPush, change, getFormValues } from "redux-form";

import { getCustomerAccountsStartWith } from "./additionalAccounts.models";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { getUserCustomerAccounts } from "../../customer/customer.selectors";
import { getCustomersByAccountNumberBusinessId } from "../accountDetails/actionsDetails.actions";

export const getAdditionalAccountNumbers =
  (accountNumber, form = USER_DETAILS_FORMS.ADDITIONAL_ACCOUNTS) =>
  async (dispatch, getState) => {
    const { accounts } = getFormValues(form)(getState());
    const { account } = getFormValues(USER_DETAILS_FORMS.ACCOUNT_DETAILS)(
      getState()
    );

    const customers = await dispatch(
      getCustomersByAccountNumberBusinessId(accountNumber)
    );
    return customers.filter(
      (customer) =>
        !find(accounts, { account: customer.account }) &&
        customer.account !== account
    );
  };

export const getCustomerAdditionalAccountNumbers =
  (accountNumber, form = USER_DETAILS_FORMS.ADDITIONAL_ACCOUNTS, customerId) =>
  async (dispatch, getState) => {
    const state = getState();
    const { accounts } = getFormValues(form)(state);
    const userCustomers = getUserCustomerAccounts(state);
    const availableAccounts = customerId
      ? omit(userCustomers, [customerId])
      : userCustomers;
    const customers = getCustomerAccountsStartWith(
      availableAccounts,
      accountNumber
    );
    return customers
      ? customers.filter(
          (customer) => !find(accounts, { account: customer.account })
        )
      : [];
  };

export const onAccountClick =
  (item, form = USER_DETAILS_FORMS.ADDITIONAL_ACCOUNTS) =>
  (dispatch) => {
    dispatch(change(form, "additionalAccountNumber", ""));
    dispatch(arrayPush(form, "accounts", item));
  };

export const clearAdditionalAccounts = () => (dispatch) =>
  dispatch(change(USER_DETAILS_FORMS.ADDITIONAL_ACCOUNTS, "accounts", []));
