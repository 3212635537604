import { differenceInCalendarDays, format } from "date-fns";
import { escapeRegExp, get, includes } from "lodash";

import {
  DELETE_USER_CONFIRM_ANALYTICS,
  DELETE_USERS_CONFIRM_ANALYTICS,
  SUSPEND_USER_CONFIRM_ANALYTICS,
  SUSPEND_USERS_CONFIRM_ANALYTICS,
  UNSUSPEND_USER_CONFIRM_ANALYTICS,
  UNSUSPEND_USERS_CONFIRM_ANALYTICS,
} from "../../constants/analytics";
import { USER_ROLES } from "../../constants/common";
import { DPD_EMAIL_REGEX, GMAIL_EMAIL_REGEX } from "../../constants/regex";
import {
  GOOD_NEWS,
  INACTIVE_USER_DAYS,
  NO_INACTIVE_USERS,
  NO_USERS,
} from "../../constants/strings";
import { hasAllRoles } from "../../utils/roles";
import {
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
} from "../userDetailsForm/accountDetails/accountDetails.constants";
import {
  PERMISSION_TO_CREATE_MASTER_USERS_FIELD,
  PERMISSION_TO_MANAGE_MYDPD_APP_FIELD,
  CREATE_PERMISSION_FIELD,
} from "../userDetailsForm/userDetails/userDetails.constants";
import {
  ACCOUNT_CREATED_ON,
  ACCOUNT_EXPIRED_ON,
  ACCOUNT_EXPIRY_ON,
  INACTIVE_FILTER,
  LAST_LOGON,
  MASTER_USER_TYPE,
  SUSPENDED_FILTER,
} from "./user.constants";

const { REACT_APP_USER_TEST_EMAIL_DOMAIN } = process.env;

export const ROWS_PER_PAGE = 6;

export const getExpiryLabel = (accountExpiry) => {
  let text = ACCOUNT_EXPIRY_ON;
  const expiryDay = getFormattedExpiryDate(accountExpiry);
  if (new Date(expiryDay) < new Date()) {
    text = ACCOUNT_EXPIRED_ON;
  }

  return `${text} ${expiryDay}`;
};

export const validateUserEmail = (email) => {
  if (REACT_APP_USER_TEST_EMAIL_DOMAIN) {
    return (
      !email.trim() ||
      (!DPD_EMAIL_REGEX.test(email) && !GMAIL_EMAIL_REGEX.test(email))
    );
  }
  return !email.trim() || !DPD_EMAIL_REGEX.test(email);
};

export const getCreatedLabel = (created) =>
  `${ACCOUNT_CREATED_ON} ${getFormattedExpiryDate(created)}`;

export const updateMultipleUsers = (accountUsers, { uids, values }) =>
  accountUsers.map((item) => {
    if (includes(uids, item.uid)) {
      return { ...item, ...values };
    }
    return item;
  });

export const isSuspendedAllUsers = (availableUsers, selected) => {
  const suspended = availableUsers.filter(
    (user) => user.accountSuspended && selected.has(user.uid)
  );
  return suspended.length !== selected.size;
};

export const getFilteredUsersByName = (search, users) => {
  if (search) {
    const searchRegex = new RegExp(escapeRegExp(search), "i");

    return users.filter((user) =>
      searchRegex.test(`${user.forename} ${user.lastname} ${user.username}`)
    );
  }

  return users;
};

export const updateSingleUser = (accountUsers, { uid, values }) =>
  accountUsers.map((item) => {
    if (item.uid === uid) {
      return { ...item, ...values };
    }
    return item;
  });

export const getFormattedExpiryDate = (expiryDateTimestamp) =>
  format(new Date(expiryDateTimestamp), "d MMMM yyyy");

export const isUserAccountSuspended = (user) =>
  get(user, "accountSuspended", false);

export const isInactiveUser = (user) => {
  const userLastLogonDate = get(user, LAST_LOGON, null);

  if (userLastLogonDate) {
    const dayOfCheck = new Date();
    const dayDifference = differenceInCalendarDays(
      dayOfCheck,
      userLastLogonDate
    );

    return dayDifference > INACTIVE_USER_DAYS;
  }

  return false;
};

export const getProviderNameFromProviderId = (providerId) => {
  if (providerId.includes("saml")) {
    return "Company identity used";
  }
  switch (providerId) {
    case "password":
      return "DPD Username";
    case "google.com":
      return "Google";
    case "facebook.com":
      return "Facebook";
    case "twitter.com":
      return "Twitter";
    case "linkedin.com":
      return "LinkedIn";
    case "yahoo.com":
      return "Yahoo";
    default:
      return "";
  }
};

export const getAvailableForSelectUsers = (users = []) =>
  users.filter((user) => !isCustomerAdmin(user));

export const isDpdAdmin = hasAllRoles([USER_ROLES.DPD_ADMIN]);

export const isCanCreateMasterUser = hasAllRoles([
  USER_ROLES.CREATE_STAFF_OVERRIDE_USER,
]);

export const isAvailableToAppManagement = hasAllRoles([
  USER_ROLES.APP_MANAGEMENT,
]);

export const isDpdUser = hasAllRoles([USER_ROLES.CUSTOMER_USER]);

export const isCustomerAdmin = hasAllRoles([USER_ROLES.CUSTOMER_ADMIN]);

export const isAdminFirstLogin = (user) => user.adminFirstLogin;

export const canAddDpdUsers = hasAllRoles([USER_ROLES.ADD_DPD_USER]);

export const getCreatingUserRoles = (formValues) => {
  const assocRoles = {
    [PERMISSION_TO_CREATE_MASTER_USERS_FIELD]:
      USER_ROLES.CREATE_STAFF_OVERRIDE_USER,
    [PERMISSION_TO_MANAGE_MYDPD_APP_FIELD]: USER_ROLES.APP_MANAGEMENT,
    [CREATE_PERMISSION_FIELD]: USER_ROLES.ADD_DPD_USER,
  };

  return Object.entries(assocRoles).reduce(
    (acc, [fieldName, role]) => {
      if (formValues[fieldName]) {
        acc.push(role);
      }

      return acc;
    },
    [USER_ROLES.DPD_ADMIN]
  );
};

export const prepareUserData = (formValues) => {
  const { isDpdAdmin, email, isCreateMasterUser, ...fields } = formValues;
  return {
    ...fields,
    userRoles: getCreatingUserRoles(formValues),
    email: email.toLowerCase(),
    created: new Date(),
  };
};

export const isMigratedUser = (user) => get(user, "migrated", false);

export const isMasterUser = (user) =>
  get(user, "accountType") === MASTER_USER_TYPE;

export const getManageUserList = (users) =>
  users.filter(
    (user) =>
      !user.accountDeleted && !isMigratedUser(user) && !isMasterUser(user) // todo remove filter
  );

// todo remove filter
export const getUsersByActiveFilter = (users, activeFilter) => {
  if (activeFilter === SUSPENDED_FILTER) {
    return users.filter(isUserAccountSuspended);
  }

  if (activeFilter === INACTIVE_FILTER) {
    return users.filter(isInactiveUser);
  }

  return users;
};

export const isDpdBusinessId = (businessId) => businessId === DPD_BUSINESS_ID;

export const isDpdLocalBusinessId = (businessId) =>
  businessId === DPD_LOCAL_BUSINESS_ID;

export const getEmptyTableSubtitle = (activeFilter, search) =>
  activeFilter === INACTIVE_FILTER && !search ? NO_INACTIVE_USERS : NO_USERS;

export const getEmptyTableTitle = (activeFilter, search) =>
  activeFilter === INACTIVE_FILTER && !search ? GOOD_NEWS : "";

export const getFilteredUserUids = (users, uids, isTemp = false) =>
  users
    .filter((user) => uids.includes(user.uid) && user.isTemp === isTemp)
    .map((user) => user.uid);

export const getAnalyticIds = (uids = [], accountSuspended) => {
  if (!accountSuspended && uids.length > 1) {
    return {
      loadId: SUSPEND_USERS_CONFIRM_ANALYTICS.LOAD,
      interfaceId: SUSPEND_USERS_CONFIRM_ANALYTICS.INTERFACE_ID,
      cancelActionId: SUSPEND_USERS_CONFIRM_ANALYTICS.NO,
      confirmActionId: SUSPEND_USERS_CONFIRM_ANALYTICS.YES,
    };
  } else if (accountSuspended && uids.length > 1) {
    return {
      loadId: UNSUSPEND_USERS_CONFIRM_ANALYTICS.LOAD,
      interfaceId: UNSUSPEND_USERS_CONFIRM_ANALYTICS.INTERFACE_ID,
      cancelActionId: UNSUSPEND_USERS_CONFIRM_ANALYTICS.NO,
      confirmActionId: UNSUSPEND_USERS_CONFIRM_ANALYTICS.YES,
    };
  } else if (!accountSuspended && uids.length === 1) {
    return {
      loadId: SUSPEND_USER_CONFIRM_ANALYTICS.LOAD,
      interfaceId: SUSPEND_USER_CONFIRM_ANALYTICS.INTERFACE_ID,
      cancelActionId: SUSPEND_USER_CONFIRM_ANALYTICS.NO,
      confirmActionId: SUSPEND_USER_CONFIRM_ANALYTICS.YES,
    };
  } else if (accountSuspended && uids.length === 1) {
    return {
      loadId: UNSUSPEND_USER_CONFIRM_ANALYTICS.LOAD,
      interfaceId: UNSUSPEND_USER_CONFIRM_ANALYTICS.INTERFACE_ID,
      cancelActionId: UNSUSPEND_USER_CONFIRM_ANALYTICS.NO,
      confirmActionId: UNSUSPEND_USER_CONFIRM_ANALYTICS.YES,
    };
  }
};

export const getDeleteUserAnalyticsIds = (ids) => {
  if (ids.length > 1) {
    return {
      interfaceId: DELETE_USERS_CONFIRM_ANALYTICS.INTERFACE_ID,
      loadId: DELETE_USERS_CONFIRM_ANALYTICS.LOAD,
      confirmActionId: DELETE_USERS_CONFIRM_ANALYTICS.YES,
      cancelActionId: DELETE_USERS_CONFIRM_ANALYTICS.NO,
    };
  } else if (ids.length === 1) {
    return {
      interfaceId: DELETE_USER_CONFIRM_ANALYTICS.INTERFACE_ID,
      loadId: DELETE_USER_CONFIRM_ANALYTICS.LOAD,
      confirmActionId: DELETE_USER_CONFIRM_ANALYTICS.YES,
      cancelActionId: DELETE_USER_CONFIRM_ANALYTICS.NO,
    };
  }
};

export const moveCurrentUserFirst = (users, currentUser) => [
  currentUser,
  ...users.filter((user) => user.userId !== currentUser.userId),
];
