import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import { Field } from "redux-form";
import KeyInput from "../KeyInput";
import {
  ADD_NEW_KEY,
  KEY_$,
  LIVE,
} from "../../features/userDetailsForm/apiSetting/apiSetting.constants";
import Typography from "@material-ui/core/Typography";
import styles from "./ApiUsersKeys.module.scss";
import { nonEditable } from "../../utils/normalize";

const ApiUsersKeys = ({ keys, addKey, fields, title, deleteKey }) => (
  <>
    <Grid item xs={12}>
      <Typography className={styles.title}>{title}</Typography>
    </Grid>
    {!!keys.length &&
      keys.map((item, index, array) => {
        const label = KEY_$(1 + index);
        const fieldName = fields[index];
        const onDeleteIconClick = () =>
          deleteKey(label, fieldName, title === LIVE);

        return (
          <Grid item xs={12} key={item.key}>
            <Field
              component={KeyInput}
              name={fieldName}
              label={label}
              showDeleteIcon={array.length > 1}
              onDeleteIconClick={onDeleteIconClick}
              normalize={nonEditable}
              onBlur={() => null} // TODO: add analytics
            />
          </Grid>
        );
      })}
    {keys.length < 2 && (
      <Grid item xs={12}>
        <Button
          onClick={(item) => {
            // onChange(IRISH_CODES); // TODO: add analytics
            addKey(title === LIVE);
          }}
        >
          {ADD_NEW_KEY}
        </Button>
      </Grid>
    )}
  </>
);

ApiUsersKeys.defaultProps = {
  title: "",
  keys: [],
  fields: [],
  addKey: () => null,
  deleteKey: () => null,
};

ApiUsersKeys.propTypes = {
  keys: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  addKey: PropTypes.func.isRequired,
  deleteKey: PropTypes.func.isRequired,
};

export default ApiUsersKeys;
