import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";

import { getCustomerAccountId } from "../../features/user/user.selectors";
import { CURRENT_CUSTOMER, CUSTOMER, PAGE_404 } from "../../router";
import { getPageLink } from "../../router/navigation";
import { CustomerNestedRoutes } from "./customerNestedRoutes";

export const CustomerRoutes = () => {
  const customerId = useSelector(getCustomerAccountId);
  return (
    <Switch>
      <Redirect
        from={CUSTOMER}
        exact
        to={getPageLink(CURRENT_CUSTOMER, { customerId })}
      />
      <Route path={CURRENT_CUSTOMER} component={CustomerNestedRoutes} />
      <Redirect to={PAGE_404} />
    </Switch>
  );
};
