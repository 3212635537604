import { useCallback } from "react";

import Grid from "@material-ui/core/Grid";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { compose } from "recompose";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import BottomButtons from "../../../../components/BottomButtons/BottomButtons.js";
import CustomExpansionPanel from "../../../../components/CustomExpansionPanel/CustomExpansionPanel.js";
import InternationalShipments from "../../../../components/InternationaShipments/InternationalShipments";
import PageContainer from "../../../../components/PageContainer/PageContainer.js";
import Prompt from "../../../../components/Prompt/Prompt";
import SecuritySettings from "../../../../components/SecuritySettings/SecuritySettings";
import {
  ANALYTICS_FOR_USER,
  CREATE_EDIT_USER_ANALYTICS,
  NAVIGATE_AWAY_CREATE_USER,
  NAVIGATE_AWAY_EDIT_USER,
  NEW_USER_ANALYTICS,
} from "../../../../constants/analytics";
import { USER_FORMS } from "../../../../constants/forms";
import * as S from "../../../../constants/strings";
import ServiceExclusions from "../../../../containers/ServiceExclusions/ServiceExclusions";
import ServiceRestrictions from "../../../../containers/ServiceRestrictions/ServiceRestrictions";
import ServiceXrefMappings from "../../../../containers/ServiceXrefMappings/ServiceXrefMappings";
import UserProfiles from "../../../../containers/UserProfiles/UserProfiles";
import {
  EMAIL_FIELD,
  NEW_PASSWORD_FIELD,
  RE_ENTER_PASSWORD_FIELD,
  USERNAME_FIELD,
} from "../../../../features/myDpdUserForm/userDetails/userDetails.constants";
import { ADDITIONAL_ACCOUNTS_FIELD } from "../../../../features/userDetailsForm/additionalAccounts/additionalAccounts.constant";
import {
  FORENAME_FIELD,
  LASTNAME_FIELD,
} from "../../../../features/userDetailsForm/userDetails/userDetails.constants";
import { handleMultiformSubmit } from "../../../../utils/redux-form";
import AdvancedCustomerConfiguration from "../AdvancedCustomerConfiguration";
import CustomerLabelSettings from "../CustomerLabelSettings";
import CustomerMiscellaneousConfiguration from "../CustomerMiscellaneousConfiguration";
import CustomerShippingSettings from "../CustomerShippingSettings";
import SystemAccess from "../CustomerSystemAccess";
import IpRestrictions from "../IpRestrictions/IpRestrictions";
import UserAdditionalAccounts from "../UserAdditionalAccounts";
import UserDetails from "../UserDetails/UserDetails.js";
import styles from "./MainUserForm.module.scss";
import CustomerApiSetting from "../CustomerApiSetting";

const refs = {};

const MainUserForm = ({
  match: {
    params: { id, customerId },
  },
  onConfirm,
  formDirty,
  showPrompt,
  onChangeSectionState,
  onCancel,
  permittedIpAddress,
  onBlurFields,
  onToggleChange,
  onInfoMouseOver,
  showApiSettings,
}) => {
  const showIpRestrictions = !!permittedIpAddress.length;
  const onConfirmClick = debounce(
    useCallback(() => {
      handleMultiformSubmit(refs, onConfirm);
      // eslint-disable-next-line
    }, []),
    1000,
    { leading: true, trailing: false }
  );

  return (
    <PageContainer>
      <Prompt
        when={showPrompt && formDirty}
        message={S.GO_AWAY_MESSAGE}
        interfaceId={
          id
            ? NAVIGATE_AWAY_EDIT_USER.INTERFACE_ID
            : NAVIGATE_AWAY_CREATE_USER.INTERFACE_ID
        }
      />
      <div>
        <CustomExpansionPanel
          title={S.USER_DETAILS}
          formName={USER_FORMS.DPD_USER_DETAILS}
          defaultExpanded
          onChange={onChangeSectionState}
        >
          <UserDetails
            onBlurFields={onBlurFields}
            withReference={(ref) => {
              refs[USER_FORMS.DPD_USER_DETAILS] = ref;
            }}
          />
        </CustomExpansionPanel>
      </div>
      <Grid container className={styles.content}>
        {showIpRestrictions && (
          <CustomExpansionPanel
            title={S.IP_RESTRICTIONS}
            onChange={onChangeSectionState}
          >
            <IpRestrictions />
          </CustomExpansionPanel>
        )}
        <CustomExpansionPanel
          title={S.ADDITIONAL_ACCOUNTS}
          onChange={onChangeSectionState}
        >
          <UserAdditionalAccounts />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.SYSTEM_ACCESS}
          formName={USER_FORMS.SYSTEM_ACCESS}
          onChange={onChangeSectionState}
        >
          <SystemAccess
            onInfoMouseOver={onInfoMouseOver}
            withReference={(ref) => {
              refs[USER_FORMS.SYSTEM_ACCESS] = ref;
            }}
          />
        </CustomExpansionPanel>
        {showApiSettings && (
          <CustomExpansionPanel
            title={S.API_SETTING}
            onChange={onChangeSectionState}
            contentPadding={styles["content-padding"]}
          >
            <CustomerApiSetting />
          </CustomExpansionPanel>
        )}
        <CustomExpansionPanel
          title={S.MISCELLANEOUS_CONFIGURATION}
          contentPadding={styles["content-padding"]}
          onChange={onChangeSectionState}
        >
          <CustomerMiscellaneousConfiguration
            onInfoMouseOver={onInfoMouseOver}
            onToggleChange={onToggleChange}
          />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.ADVANCED_CUSTOMER_CONFIGURATION}
          contentPadding={styles["content-padding"]}
          onChange={onChangeSectionState}
        >
          <AdvancedCustomerConfiguration onToggleChange={onToggleChange} />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.SERVICE_EXCLUSIONS}
          contentPadding={styles["content-padding"]}
          onChange={onChangeSectionState}
        >
          <ServiceExclusions />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.SECURITY_SETTINGS}
          onChange={onChangeSectionState}
        >
          <SecuritySettings onToggleChange={onToggleChange} />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.SHIPPING_SETTINGS}
          onChange={onChangeSectionState}
          contentPadding={styles["content-padding"]}
        >
          <CustomerShippingSettings onInputChange={onToggleChange} />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.SERVICE_XREF_MAPPINGS}
          onChange={onChangeSectionState}
          formName={USER_FORMS.XREF_MAPPINGS}
        >
          <ServiceXrefMappings
            withReference={(ref) => {
              refs[USER_FORMS.XREF_MAPPINGS] = ref;
            }}
          />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.SERVICE_RESTRICTIONS}
          onChange={onChangeSectionState}
          formName={USER_FORMS.SERVICE_RESTRICTIONS}
        >
          <ServiceRestrictions
            withReference={(ref) => {
              refs[USER_FORMS.SERVICE_RESTRICTIONS] = ref;
            }}
          />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.INTERNATIONAL_SHIPMENTS}
          onChange={onChangeSectionState}
        >
          <InternationalShipments
            onInfoMouseOver={onInfoMouseOver}
            onInputChange={onToggleChange}
          />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.LABEL_SETTINGS}
          onChange={onChangeSectionState}
        >
          <CustomerLabelSettings />
        </CustomExpansionPanel>
        <CustomExpansionPanel
          title={S.USER_PROFILES}
          onChange={onChangeSectionState}
          formName={USER_FORMS.USER_PROFILES}
        >
          <UserProfiles
            accountId={customerId}
            withReference={(ref) => {
              refs[USER_FORMS.USER_PROFILES] = ref;
            }}
          />
        </CustomExpansionPanel>
      </Grid>
      <BottomButtons
        onCancelClick={onCancel}
        onConfirmClick={onConfirmClick}
        confirmText={!id ? S.SAVE_USER : S.UPDATE_ACCOUNT}
        confirmDisabled={!formDirty}
      />
    </PageContainer>
  );
};

MainUserForm.defaultProps = {
  showApiSettings: false,
};

MainUserForm.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  avatar: PropTypes.string,
  onCancelClick: PropTypes.func,
  onFetchUser: PropTypes.func,
  formDirty: PropTypes.bool,
  onResetForms: PropTypes.func,
  showPrompt: PropTypes.bool,
  showApiSettings: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeSectionState: PropTypes.func,
  onConfirm: PropTypes.func,
  permittedIpAddress: PropTypes.array,
  onBlurFields: PropTypes.func,
  onInfoMouseOver: PropTypes.func,
  onToggleChange: PropTypes.func,
  onApiSettingSectionChange: PropTypes.func,
};

export default compose(
  withTrackProps(() => ({
    onInfoMouseOver: () => ANALYTICS_FOR_USER.HOVER,
    onToggleChange: (field, value) =>
      createLogEntryPayload(CREATE_EDIT_USER_ANALYTICS.CLICK_TOGGLE, {
        field,
        value,
      }),
    onBlurFields: (field) => {
      // eslint-disable-next-line default-case
      switch (field) {
        case ADDITIONAL_ACCOUNTS_FIELD:
          return CREATE_EDIT_USER_ANALYTICS.ADMIN_ACCOUNT_NUMBER_ENTRY;
        case RE_ENTER_PASSWORD_FIELD:
          return ANALYTICS_FOR_USER.ENTER_TEXT_CONFIRM_PASSWORD;
        case EMAIL_FIELD:
          return ANALYTICS_FOR_USER.ENTER_TEXT_EMAIL;
        case FORENAME_FIELD:
          return ANALYTICS_FOR_USER.ENTER_TEXT_FIRSTNAME;
        case NEW_PASSWORD_FIELD:
          return CREATE_EDIT_USER_ANALYTICS.ENTER_TEXT_PASSWORD;
        case LASTNAME_FIELD:
          return ANALYTICS_FOR_USER.ENTER_TEXT_SURNAME;
        case USERNAME_FIELD:
          return NEW_USER_ANALYTICS.ENTER_TEXT_USERNAME;
      }
    },
    onChangeSectionState: (sectionName) => {
      switch (sectionName) {
        case S.IP_RESTRICTIONS:
          return CREATE_EDIT_USER_ANALYTICS.CLICK_IP_RESTRICTIONS_TAB;
        case S.ADDITIONAL_ACCOUNTS:
          return CREATE_EDIT_USER_ANALYTICS.CLICK_ADDITIONAL_ACCOUNTS_TAB;
        case S.ADVANCED_CUSTOMER_CONFIGURATION:
          return ANALYTICS_FOR_USER.CLICK_ADVANCED_CUSTOMER_CONFIGURATION;
        case S.MISCELLANEOUS_CONFIGURATION:
          return ANALYTICS_FOR_USER.CLICK_MISCELLANEOUS_CONFIGURATIONS;
        case S.SERVICE_RESTRICTIONS:
          return ANALYTICS_FOR_USER.CLICK_SERVICE_RESTRICTIONS;
        case S.INTERNATIONAL_SHIPMENTS:
          return ANALYTICS_FOR_USER.CLICK_INTERNATIONAL_SHIPMENTS;
        case S.LABEL_SETTINGS:
          return ANALYTICS_FOR_USER.CLICK_LABEL_SETTINGS;
        case S.SECURITY_SETTINGS:
          return ANALYTICS_FOR_USER.CLICK_SECURITY_SETTINGS;
        case S.SYSTEM_ACCESS:
          return ANALYTICS_FOR_USER.CLICK_SYSTEM_ACCESS;
        case S.USER_PROFILES:
          return ANALYTICS_FOR_USER.CLICK_USER_PROFILES;
        case S.SHIPPING_SETTINGS:
          return ANALYTICS_FOR_USER.CLICK_SHIPPING_SETTINGS;
        case S.SERVICE_XREF_MAPPINGS:
          return ANALYTICS_FOR_USER.CLICK_SERVICE_XREF_MAPPINGS;
        case S.SERVICE_EXCLUSIONS:
          return ANALYTICS_FOR_USER.CLICK_SERVICE_EXCLUSIONS;
        case S.USER_DETAILS:
          return ANALYTICS_FOR_USER.CLICK_USER_DETAILS;
        case S.API_SETTING:
          return ANALYTICS_FOR_USER.CLICK_API_SETTING;
        default:
          return null;
      }
    },
  }))
)(MainUserForm);
