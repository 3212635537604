import { Box, Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { get } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { FieldArray, reduxForm } from "redux-form";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import SelectItem from "../../../../components/SelectItem/SelectItem.js";
import SelectWithTitleStateFull from "../../../../components/SelectWithTitle/SelectWithTitleStateFull.js";
import { SimpleArrayField } from "../../../../components/SimpleArrayField/SimpleArrayField.js";
import { CREATE_EDIT_USER_ANALYTICS } from "../../../../constants/analytics";
import { USER_FORMS } from "../../../../constants/forms.js";
import {
  IP_RESTRICTIONS,
  NO_IP_ADDRESSES_AVAILABLE,
  SELECT_IP_ADDRESS,
} from "../../../../constants/strings.js";
import { getUserCustomerAccounts } from "../../../../features/customer/customer.selectors";
import { formatIpRestriction } from "../../../../features/teamSettings/teamSettings.model.js";
import { addIpRestrictions } from "../../../../features/userDetailsForm/ipRestriction/ipRestriction.actions.js";
import { getSelectableIpAddress } from "../../../../features/userDetailsForm/ipRestriction/ipRestriction.selectors.js";
import { PERMITTED_IP_FIELD } from "../../../../features/userDetailsForm/ipRestriction/ipRestrictions.constants.js";
import styles from "./IpRestrictions.module.scss";

const IpRestrictions = ({
  onSelectIpRestriction,
  ipAddress,
  onRemoveIp,
  onClickIpRestriction,
}) => (
  <Grid container item direction="column">
    <Grid item>
      <Box width="100%" className={styles.wrapper}>
        <Box width={1 / 5} className={styles.labelWrapper}>
          <div>{IP_RESTRICTIONS}</div>
        </Box>
        <SelectWithTitleStateFull
          displayEmpty
          className={styles.dropdown}
          containerStyles={styles.dropdownContainer}
          menuContainerStyles={styles.dropdownMenu}
          selectStyles={styles.selectStyles}
          renderValue={(value) =>
            (value && formatIpRestriction(value)) || SELECT_IP_ADDRESS
          }
          onChange={(selectedItem) => {
            selectedItem && onSelectIpRestriction(selectedItem);
          }}
          onOpen={onClickIpRestriction}
        >
          {ipAddress.length ? (
            ipAddress.map((item) => (
              <MenuItem key={item.ipAddress} value={item}>
                <SelectItem
                  title={formatIpRestriction(item)}
                  containerStyle={styles.menuItem}
                />
              </MenuItem>
            ))
          ) : (
            <MenuItem>
              <SelectItem title={NO_IP_ADDRESSES_AVAILABLE} />
            </MenuItem>
          )}
        </SelectWithTitleStateFull>
      </Box>
      <Box width="100%" className={styles.wrapper}>
        <Box width={1 / 5} className={styles.labelWrapper} />
        <Box width="100%" className={styles.wrapper}>
          <FieldArray
            name={PERMITTED_IP_FIELD}
            formatItem={formatIpRestriction}
            component={SimpleArrayField}
            onRemove={onRemoveIp}
            disableGutters={true}
            containerStyles={styles.listContainer}
            textItemStyles={styles.listTextItem}
          />
        </Box>
      </Box>
    </Grid>
  </Grid>
);

const mapStateToProps = (state, ownProps) => {
  const customerId =
    get(ownProps, "match.params.customerId", null) ||
    getUserCustomerAccounts(state);
  return {
    ipAddress: getSelectableIpAddress(customerId)(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSelectIpRestriction: (item) => dispatch(addIpRestrictions(item)),
});

export default compose(
  withRouter,
  reduxForm({
    form: USER_FORMS.IP_RESTRICTIONS,
    initialValues: { [PERMITTED_IP_FIELD]: [] },
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(() => ({
    onSelectIpRestriction: () =>
      CREATE_EDIT_USER_ANALYTICS.SELECT_IP_ADDRESS_IN_DROPDOWN_LIST,
    onClickIpRestriction: () =>
      CREATE_EDIT_USER_ANALYTICS.CLICK_IP_ADDRESS_DROPDOWN_LIST,
    onRemoveIp: () => CREATE_EDIT_USER_ANALYTICS.CLICK_REMOVE_IP_ADDRESS,
  }))
)(IpRestrictions);

IpRestrictions.propTypes = {
  ipAddress: PropTypes.string,
  onSelectIpRestriction: PropTypes.func,
  onClickIpRestriction: PropTypes.func,
  onRemoveIp: PropTypes.func,
};
