import get from "lodash/get";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { SET_PASSWORD_FORM } from "../../constants/forms";
import { passwordStrength } from "../../utils/commonValidation";

export const isSingOutDialogOpen = (state) => state.auth.isSignOutDialogOpen;

export const isAuthChecked = (state) => state.auth.isAuthChecked;

export const getFirstLoginEmail = (state) => state.auth.firstLoginEmail;

export const isAuthCheckedOnce = (state) => state.auth.isAuthCheckedOnce;

export const getSetPasswordFormValues = getFormValues(SET_PASSWORD_FORM);

export const getNewPassword = createSelector(
  getSetPasswordFormValues,
  (formValues) => get(formValues, "newPassword", "")
);

export const getNewPasswordStrengthScore = createSelector(
  getNewPassword,
  (password) => passwordStrength(password)
);
