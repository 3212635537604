import get from "lodash/get";
import {
  getFormSubmitErrors,
  getFormSyncErrors,
  getFormValues,
  hasSubmitFailed,
  isDirty,
} from "redux-form";
import { createSelector } from "reselect";

import {
  CREATE_PROFILE_FORM,
  SEARCH_PROFILE_FORM,
} from "../../constants/forms";
import {
  ADDRESS_FIELDS,
  CREATE_ACCOUNT_NUMBER_FIELD,
  DPD_ACCOUNT_NUMBER_FIELD,
  DPD_LOCAL_ACCOUNT_NUMBER_FIELD,
  INT_INVOICE_FIELDS,
  PROFILE_CODE_FIELD,
} from "./profile.constants";
import {
  checkIfInternationalFieldsValid,
  checkShouldShowProfileDefaultsBadge,
  checkShouldShowProfileDefaultsDpdBadge,
  checkShouldShowProfileDefaultsLocalDpdBadge,
} from "./profile.models";

const getSearchFormValues = getFormValues(SEARCH_PROFILE_FORM);
export const getCreateProfileFormValues = getFormValues(CREATE_PROFILE_FORM);

export const getProfileCode = createSelector(
  getCreateProfileFormValues,
  (formValues) => get(formValues, PROFILE_CODE_FIELD, "")
);

export const getAccountNumber = createSelector(
  getCreateProfileFormValues,
  (formValues) => get(formValues, CREATE_ACCOUNT_NUMBER_FIELD, "")
);

export const getSearchAccountNumber = createSelector(
  getSearchFormValues,
  (formValues) => get(formValues, "accountNumber")
);

export const getProfiles = createSelector(getSearchFormValues, (formValues) =>
  get(formValues, "profiles", [])
);

export const hasProfiles = createSelector(
  getProfiles,
  (profiles) => !!profiles.length
);

export const getInternationalDpdValue = createSelector(
  getCreateProfileFormValues,
  (profileValues) => get(profileValues, "internationalDpd")
);

export const getInternationalDpdLocalValue = createSelector(
  getCreateProfileFormValues,
  (profileValues) => get(profileValues, "internationalDpdLocal")
);

export const getIsShowNavigateModal = (state) => state.profile.showPrompt;

export const shouldShowPromptOnCreate = createSelector(
  isDirty(CREATE_PROFILE_FORM),
  getIsShowNavigateModal,
  (isDirty, showPrompt) => isDirty && showPrompt
);

export const getUseMyDpdAccountSettings = createSelector(
  getCreateProfileFormValues,
  (profileValues) => get(profileValues, "useMyDpdAccountSettings")
);

export const getProfileFormSyncErrors = getFormSyncErrors(CREATE_PROFILE_FORM);
export const getProfileSubmitErrors = getFormSubmitErrors(CREATE_PROFILE_FORM);
const isProfileFormSubmissionFailed = hasSubmitFailed(CREATE_PROFILE_FORM);

export const shouldShowAccountDpdTabBadge = createSelector(
  isProfileFormSubmissionFailed,
  getProfileFormSyncErrors,
  getProfileSubmitErrors,
  (isFormSubmissionFailed, syncError, submitError) =>
    isFormSubmissionFailed &&
    (!!get(syncError, DPD_ACCOUNT_NUMBER_FIELD) ||
      !!get(submitError, DPD_ACCOUNT_NUMBER_FIELD))
);

export const getInternationalSubmitErrors = createSelector(
  getProfileSubmitErrors,
  checkIfInternationalFieldsValid
);

export const getInternationalSyncErrors = createSelector(
  getProfileFormSyncErrors,
  checkIfInternationalFieldsValid
);

export const shouldShowAccountInternationalTabBadge = createSelector(
  isProfileFormSubmissionFailed,
  getInternationalSubmitErrors,
  getInternationalSyncErrors,
  (isFormSubmissionFailed, submitErrors, asyncErrors) =>
    submitErrors || (asyncErrors && isFormSubmissionFailed)
);

export const shouldShowAccountDpdLocalTabBadge = createSelector(
  isProfileFormSubmissionFailed,
  getProfileFormSyncErrors,
  getProfileSubmitErrors,
  (isFormSubmissionFailed, syncError, submitError) =>
    isFormSubmissionFailed &&
    (get(syncError, DPD_LOCAL_ACCOUNT_NUMBER_FIELD) ||
      get(submitError, DPD_LOCAL_ACCOUNT_NUMBER_FIELD))
);

export const shouldShowAccountTabBadge = createSelector(
  shouldShowAccountDpdTabBadge,
  shouldShowAccountInternationalTabBadge,
  shouldShowAccountDpdLocalTabBadge,
  (dpd, international, dpdLocal) => dpd || international || dpdLocal
);

export const shouldShowDefaultsDpdTabBadge = createSelector(
  isProfileFormSubmissionFailed,
  getProfileFormSyncErrors,
  checkShouldShowProfileDefaultsDpdBadge
);

export const shouldShowDefaultsTab = createSelector(
  isProfileFormSubmissionFailed,
  getProfileFormSyncErrors,
  checkShouldShowProfileDefaultsBadge
);

export const shouldShowInternationalTabBadge = createSelector(
  isProfileFormSubmissionFailed,
  getProfileFormSyncErrors,
  (isProfileFormSubmissionFailed, { defaultDpdInternationalOutboundService }) =>
    !!defaultDpdInternationalOutboundService && isProfileFormSubmissionFailed
);

export const shouldShowDefaultsLocalDpdTabBadge = createSelector(
  isProfileFormSubmissionFailed,
  getProfileFormSyncErrors,
  checkShouldShowProfileDefaultsLocalDpdBadge
);

export const shouldShowLocalInternationalTabBadge = createSelector(
  isProfileFormSubmissionFailed,
  getProfileFormSyncErrors,
  (
    isProfileFormSubmissionFailed,
    { defaultDpdLocalInternationalOutboundService }
  ) =>
    !!defaultDpdLocalInternationalOutboundService &&
    isProfileFormSubmissionFailed
);

export const shouldShowDefaultsTabBadge = createSelector(
  shouldShowDefaultsDpdTabBadge,
  shouldShowDefaultsTab,
  shouldShowInternationalTabBadge,
  shouldShowDefaultsLocalDpdTabBadge,
  shouldShowLocalInternationalTabBadge,
  (dpd, international, defaults, local, internationalLocal) =>
    dpd || international || defaults || local || internationalLocal
);

export const shouldShowIntInvoiceTabBadge = createSelector(
  isProfileFormSubmissionFailed,
  getProfileFormSyncErrors,
  (isFormSubmissionFailed, errors) =>
    Object.keys(errors).some((field) => INT_INVOICE_FIELDS.includes(field)) &&
    isFormSubmissionFailed
);

export const shouldShowAddressTabBadge = createSelector(
  isProfileFormSubmissionFailed,
  getProfileFormSyncErrors,
  (isFormSubmissionFailed, errors) =>
    Object.keys(errors).some((field) => ADDRESS_FIELDS.includes(field)) &&
    isFormSubmissionFailed
);
