import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import {
  ANALYTICS_FOR_USER,
  MAIN_MENU_SECOND_PHASE,
} from "../../../constants/analytics";
import {
  ACTIVITY_MONITOR,
  MANAGE_USERS,
  TEAM_SETTINGS,
} from "../../../constants/strings";
import { setTabsState } from "../../../features/tabs/tabs.actions";
import {
  COLLAPSE_ALL_STATE,
  EXPAND_ALL_STATE,
} from "../../../features/tabs/tabs.constants";
import {
  CUSTOMER_ACTIVITY_MONITOR,
  CUSTOMER_EDIT_USER_PAGE,
} from "../../../router";
import {
  CUSTOMER_CREATE_USER_PAGE,
  CUSTOMER_TEAM_SETTINGS_PAGE,
  CUSTOMER_USERS_PAGE,
} from "../../../router/constants";
import { getPageLink } from "../../../router/navigation";
import ExpandCollapseAll from "../../ExpandCollapseAll/ExpandCollapseAll";
import MenuItem from "../../MenuItem/MenuItem";
import styles from "./DpdUsersMenu.module.scss";

const CustomerMenu = ({
  onExpandAll,
  onCollapseAll,
  onMenuClick,
  match: {
    params: { customerId },
  },
}) => (
  <Grid item>
    <List component="nav">
      <MenuItem
        text={TEAM_SETTINGS}
        to={getPageLink(CUSTOMER_TEAM_SETTINGS_PAGE, {
          customerId,
        })}
        onClick={onMenuClick}
      />
      <MenuItem
        text={MANAGE_USERS}
        to={getPageLink(CUSTOMER_USERS_PAGE, { customerId })}
        onClick={onMenuClick}
      />
      <MenuItem
        text={ACTIVITY_MONITOR}
        to={getPageLink(CUSTOMER_ACTIVITY_MONITOR, { customerId })}
        onClick={onMenuClick}
      />
    </List>
    <Route
      path={[CUSTOMER_CREATE_USER_PAGE, CUSTOMER_EDIT_USER_PAGE]}
      component={() => (
        <section className={styles["expand-collapse"]}>
          <ExpandCollapseAll
            onExpandAllClick={onExpandAll}
            onCollapseAllClick={onCollapseAll}
          />
        </section>
      )}
    />
  </Grid>
);

CustomerMenu.propTypes = {
  match: PropTypes.object,
  onExpandAll: PropTypes.func,
  onCollapseAll: PropTypes.func,
  onMenuClick: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onExpandCollapseClick: (tabsState) => dispatch(setTabsState(tabsState)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withTrackProps((props) => ({
    onExpandAll: () => {
      props.onExpandCollapseClick(EXPAND_ALL_STATE);
      return ANALYTICS_FOR_USER.EXPAND_ALL_TABS;
    },
    onCollapseAll: () => {
      props.onExpandCollapseClick(COLLAPSE_ALL_STATE);
      return ANALYTICS_FOR_USER.MINIMIZE_ALL_TABS;
    },
    onMenuClick: (name) => {
      switch (name) {
        case TEAM_SETTINGS:
          return MAIN_MENU_SECOND_PHASE.CLICK_TEAM_SETTINGS;
        case MANAGE_USERS:
          return MAIN_MENU_SECOND_PHASE.CLICK_MANAGE_USERS;
        case ACTIVITY_MONITOR:
          return MAIN_MENU_SECOND_PHASE.CLICK_ACTIVITY_MONITOR;
      }
    },
  }))
)(CustomerMenu);
