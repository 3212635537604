import PropTypes from "prop-types";
import { compose } from "recompose";
import { reduxForm } from "redux-form";

import CustomerFailedImport from "./CustomerFailedImport";
import CustomerReferences from "./CustomerReferences";
import GroupDispatch from "../../../components/ShippingSettings/GroupDispatch";
import ShippingSettings from "../../../components/ShippingSettings/ShippingSettings";
import { ANALYTICS_FOR_USER } from "../../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import {
  FAILED_IMPORT,
  GROUP_DISPATCH,
  REFERENCES,
} from "../../../constants/strings";
import { getShippingInitialValues } from "../../../features/userDetailsForm/shippingSettings/shippingSettings.models";

const shippingSettingsTabs = [
  {
    label: REFERENCES,
    analyticsId: ANALYTICS_FOR_USER.CLICK_REFERENCES,
    component: CustomerReferences,
  },
  {
    label: FAILED_IMPORT,
    analyticsId: ANALYTICS_FOR_USER.CLICK_FAILED_IMPORT,
    component: CustomerFailedImport,
  },
  {
    label: GROUP_DISPATCH,
    analyticsId: ANALYTICS_FOR_USER.CLICK_GROUP_DISPATCH,
    component: GroupDispatch,
  },
];

const CustomerShippingSettings = ({ onInputChange }) => (
  <ShippingSettings
    shippingSettingsTabs={shippingSettingsTabs}
    onInputChange={onInputChange}
  />
);

CustomerShippingSettings.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: USER_DETAILS_FORMS.SHIPPING_SETTINGS,
    initialValues: getShippingInitialValues(),
  })
)(CustomerShippingSettings);
