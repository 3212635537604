import isEmpty from "lodash/isEmpty";
import { arrayPush, change, initialize, SubmissionError } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import {
  END_IP_FIELD,
  IP_ADDRESS_FIELD,
  IP_ADDRESS_NAME_FIELD,
  IP_RANGE_NAME_FIELD,
  PERMITTED_IP_FIELD,
  START_IP_FIELD,
} from "./teamSettings.constants";
import {
  checkIpAlreadyExist,
  checkIpRangeValid,
  checkIsIpAddressUnique,
  prepareIpAddress,
  prepareIpRange,
  prepareTeamSettingsData,
  teamSettingsFormInitialValues,
} from "./teamSettings.model";
import {
  getPermittedIps,
  getTeamSettingsFormValues,
} from "./teamSettings.selectors";
import { validate } from "./teamSettings.validate";
import { DELETE_IP_CONFIRMATION_MODAL } from "../../constants/analytics";
import { IP_ADDRESS_FORM, TEAM_SETTINGS_FORM } from "../../constants/forms";
import {
  ARE_YOU_SURE_DELETE_IP_ADDRESS,
  DELETE_IP_NOTE,
  ENTERED_IP_RANGE_INVALID,
  IP_ALREADY_ADDED,
  IP_RANGE_ALREADY_ADDED,
  NO,
  SUSPENDED_DAYS_30,
  YES,
} from "../../constants/strings";
import { navigateToHome } from "../../router/navigation";
import * as customerService from "../customer/customer.service";
import { createLoadingAction } from "../loader/loader.actions";
import { showModal } from "../modal/modal.actions";
import { getUserBusinessId } from "../user/user.selectors";

const ACTION_NAMESPACE = "TEAM_SETTINGS_FORM";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SAVE_TEAM_SETTINGS: createAsyncActionTypes("SAVE_PROFILE"),
  FETCH_TEAM_SETTINGS: createAsyncActionTypes("FETCH_TEAM_SETTINGS"),
});

export const changeIpAddressForm = (field, value) =>
  change(IP_ADDRESS_FORM, field, value);

export const clearAddIpFields = () => (dispatch) => {
  dispatch(changeIpAddressForm(IP_ADDRESS_FIELD, ""));
  dispatch(changeIpAddressForm(IP_ADDRESS_NAME_FIELD, ""));
};

export const clearAddIpRangeFields = () => (dispatch) => {
  dispatch(changeIpAddressForm(START_IP_FIELD, ""));
  dispatch(changeIpAddressForm(END_IP_FIELD, ""));
  dispatch(changeIpAddressForm(IP_RANGE_NAME_FIELD, ""));
};

export const addIpAddress =
  ({ ipAddress = "", name = "" }) =>
  (dispatch, getState) => {
    const data = prepareIpAddress(ipAddress, name);
    const permittedIps = getPermittedIps(getState());
    const syncErrors = validate({ ipAddress });

    if (!isEmpty(syncErrors)) {
      throw new SubmissionError(syncErrors);
    }

    if (checkIsIpAddressUnique(permittedIps, data.ipAddress)) {
      throw new SubmissionError({ ipAddress: IP_ALREADY_ADDED });
    }

    dispatch(arrayPush(TEAM_SETTINGS_FORM, PERMITTED_IP_FIELD, data));
    dispatch(clearAddIpFields());
  };

export const addIpRange =
  ({ rangeStart = "", rangeEnd = "", rangeName = "" }) =>
  (dispatch, getState) => {
    const permittedIps = getPermittedIps(getState());
    const data = prepareIpRange(rangeStart, rangeEnd, rangeName);

    const syncErrors = validate({ rangeStart, rangeEnd });

    if (!isEmpty(syncErrors)) {
      throw new SubmissionError(syncErrors);
    }

    if (!checkIpRangeValid(rangeStart, rangeEnd)) {
      throw new SubmissionError({ ipRange: ENTERED_IP_RANGE_INVALID });
    }

    if (checkIsIpAddressUnique(permittedIps, data.ipAddress)) {
      throw new SubmissionError({ ipRange: IP_ALREADY_ADDED });
    }

    if (checkIpAlreadyExist(permittedIps, data.ipAddress)) {
      throw new SubmissionError({ ipRange: IP_RANGE_ALREADY_ADDED });
    }

    dispatch(arrayPush(TEAM_SETTINGS_FORM, PERMITTED_IP_FIELD, data));
    dispatch(clearAddIpRangeFields());
  };

export const confirmDeleteIpAddress = (removedItemCallback) => (dispatch) => {
  dispatch(
    showModal({
      interfaceId: DELETE_IP_CONFIRMATION_MODAL.INTERFACE_ID,
      loadId: DELETE_IP_CONFIRMATION_MODAL.LOAD,
      confirmActionId: DELETE_IP_CONFIRMATION_MODAL.YES,
      cancelActionId: DELETE_IP_CONFIRMATION_MODAL.NO,
      contentText: ARE_YOU_SURE_DELETE_IP_ADDRESS,
      cancelButtonText: NO,
      confirmButtonText: YES,
      note: DELETE_IP_NOTE,
      onConfirmClick: () => {
        removedItemCallback();
      },
    })
  );
};

export const saveTeamSettings = createLoadingAction(
  createAsyncAction(
    (customerId) => async (dispatch, getState) => {
      const state = getState();
      const teamSettingsFormValues = getTeamSettingsFormValues(state);
      const businessId = getUserBusinessId(state);
      const data = prepareTeamSettingsData(teamSettingsFormValues);

      return customerService
        .updateCustomer(`${businessId}*${customerId}`, customerId, data)
        .then(() => navigateToHome(customerId));
    },
    ACTIONS.SAVE_TEAM_SETTINGS
  )
);

export const fetchTeamSettings = createLoadingAction(
  createAsyncAction(
    (currentAccountNumber) => async (dispatch, getState) => {
      const state = getState();
      const businessId = getUserBusinessId(state);

      const { autoSuspendUserAccount = "" } =
        await customerService.getCustomerByAccountNumber(
          currentAccountNumber,
          businessId
        );

      const permittedIp = await customerService.getCustomerIpAddress(
        currentAccountNumber,
        businessId
      );

      const autoSuspendAccounts = !!autoSuspendUserAccount;
      const autoSuspendValue = autoSuspendUserAccount || SUSPENDED_DAYS_30;

      dispatch(
        initialize(TEAM_SETTINGS_FORM, {
          ...teamSettingsFormInitialValues(),
          permittedIp,
          autoSuspendUserAccount: autoSuspendValue,
          autoSuspendAccounts,
        })
      );
    },

    ACTIONS.FETCH_TEAM_SETTINGS
  )
);
