import get from "lodash/get";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { USER_DETAILS_FORMS } from "../../../constants/forms";
import {
  ACCOUNT_ID_FIELD,
  BUSINESS_ID_FIELD,
  COMPANY_FIELD,
  OVERRIDE_DETAILS_FIELD,
  POSTCODE_FIELD,
} from "./accountDetails.constants";

export const getCustomerData = (state) => state.userForm.customerData;

const getAccountDetailsValues = getFormValues(
  USER_DETAILS_FORMS.ACCOUNT_DETAILS
);

export const getSelectedAccountId = createSelector(
  getAccountDetailsValues,
  (accountDetails) => get(accountDetails, ACCOUNT_ID_FIELD)
);

export const getPostcode = createSelector(
  getAccountDetailsValues,
  (accountDetails) => get(accountDetails, POSTCODE_FIELD)
);

export const getSelectedAccountCustomerName = createSelector(
  getAccountDetailsValues,
  (accountDetails) => get(accountDetails, COMPANY_FIELD, "")
);

export const getSelectedAccountDetails = createSelector(
  getSelectedAccountId,
  getSelectedAccountCustomerName,
  (account, customername) => ({ account, customername })
);

export const getBusinessId = createSelector(
  getAccountDetailsValues,
  (accountDetails) => get(accountDetails, BUSINESS_ID_FIELD)
);

export const getOverrideDetails = createSelector(
  getAccountDetailsValues,
  (accountDetails) => get(accountDetails, OVERRIDE_DETAILS_FIELD)
);

export const getAccountId = createSelector(
  getAccountDetailsValues,
  (accountDetails) => get(accountDetails, ACCOUNT_ID_FIELD)
);
