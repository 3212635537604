import { useMemo } from "react";

import PropTypes from "prop-types";
import { useAsync } from "react-async";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { container } from "./LoginResetPasswordPage.module.scss";
import Link from "../../../../components/Link";
import { SET_NEW_PASSWORD } from "../../../../constants/analytics";
import { CANCEL } from "../../../../constants/strings";
import {
  checkResetPasswordCode,
  onResetPasswordSubmit,
} from "../../../../features/auth/auth.actions";
import { LOGIN_PAGE } from "../../../../router";
import { resetPasswordValidation as validate } from "../../../../utils/commonValidation";
import SetPasswordForm from "../../components/SetPasswordForm";

const LoginResetPasswordPage = ({ onSubmit, checkPasswordCode, onCancel }) => {
  const { init } = useMemo(
    () => ({
      init: () => checkPasswordCode(),
    }),
    [checkPasswordCode]
  );

  const { isLoading } = useAsync({ promiseFn: init });

  if (isLoading) {
    return null;
  }

  return (
    <section className={container}>
      <SetPasswordForm onSubmit={onSubmit} validate={validate} />
      <Link to={LOGIN_PAGE} onClick={onCancel}>
        {CANCEL}
      </Link>
    </section>
  );
};

LoginResetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired,
  checkResetPasswordCode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  checkPasswordCode: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: ({ newPassword }) => dispatch(onResetPasswordSubmit(newPassword)),
  checkPasswordCode: () => dispatch(checkResetPasswordCode()),
});

export default compose(
  connect(null, mapDispatchToProps),
  withTrack({
    loadId: SET_NEW_PASSWORD.LOAD,
    interfaceId: SET_NEW_PASSWORD.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onSubmit: () => SET_NEW_PASSWORD.CONTINUE,
    onCancel: () => SET_NEW_PASSWORD.CANCEL,
  }))
)(LoginResetPasswordPage);
