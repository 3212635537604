import { CYRILLIC_REGEX, PHONE_NUMBER_REGEX } from "../../../constants/regex";
import { INVALID_PHONE_NOT_A_VALID_PHONE_NUMBER } from "../../../constants/strings";
import {
  isEmailValid,
  isUserNameValid,
  passwordValidation,
} from "../../../utils/commonValidation";
import {
  PLEASE_ENTER_VALID_EMAIL,
  PLEASE_ENTER_VALID_USERNAME,
  USERNAME_CANNOT_BE_EMPTY,
} from "../../auth/auth.constants";

export const validate = ({
  uid,
  email = "",
  username = "",
  phone,
  newPassword,
  reEnterPassword,
  isAdminAccess,
  useEmailAsLogin,
}) => {
  const errors = {};
  let userErrors = {};
  let passwordErrors = {};
  const emailAsLogin = isAdminAccess || useEmailAsLogin;

  // If 'Use this email address as the login' is enabled, non Latin are forbidden
  if (
    ((email || emailAsLogin) && !isEmailValid(email)) ||
    (emailAsLogin && CYRILLIC_REGEX.test(email))
  ) {
    errors.email = PLEASE_ENTER_VALID_EMAIL;
  }

  if (phone && !PHONE_NUMBER_REGEX.test(phone)) {
    errors.phone = INVALID_PHONE_NOT_A_VALID_PHONE_NUMBER;
  }

  if (!emailAsLogin) {
    if (!uid) {
      userErrors = validateUserName({ username });
    }

    if (!uid || (uid && (newPassword || reEnterPassword))) {
      passwordErrors = passwordValidation({
        reEnterPassword,
        newPassword,
      });
    }
  }

  return { ...errors, ...passwordErrors, ...userErrors };
};

export const validateUserName = ({ username = "" }, emptyOnly = false) => {
  const errors = {};

  if (!username) {
    errors.username = USERNAME_CANNOT_BE_EMPTY;
  }

  if (!emptyOnly && !isUserNameValid(username)) {
    errors.username = PLEASE_ENTER_VALID_USERNAME;
  }

  return errors;
};
