import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import { reduxForm } from "redux-form";

import AddressBook from "../../../../components/AdvancedCustomerConfiguration/AddressBook";
import AdHocForwardDating from "../../../../components/AdvancedCustomerConfiguration/AdHocForwardDating.js";
import styles from "../../../../components/AdvancedCustomerConfiguration/AdvancedCustomerConfiguration.module.scss";
import Collections from "../../../../components/AdvancedCustomerConfiguration/Collections";
import CreateShipment from "../../../../components/AdvancedCustomerConfiguration/CreateShipment";
import ProductBook from "../../../../components/AdvancedCustomerConfiguration/ProductBook";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs.js";
import { USER_DETAILS_FORMS } from "../../../../constants/forms.js";
import { ADVANCED_CONFIGURATION_TABS } from "../../../../constants/strings.js";
import {
  deselectSixMonthsToggle,
  onForwardDateOver7DaysChange,
  onReference1Change,
} from "../../../../features/userDetailsForm/advancedCustomerConfiguration/advancedCustomerConfiguration.actions";
import {
  isSixMonths,
  isSixMonthsDeselect,
  isSixMonthsDisable,
} from "../../../../features/userDetailsForm/advancedCustomerConfiguration/advancedCustomerConfiguration.selectors";
import { getUserProfilesAllocatedFormValues } from "../../../../features/userDetailsForm/userProfiles/userProfiles.selectors";

const AdvancedCustomerConfiguration = ({
  onToggleChange,
  onReference1,
  isSixMonthsDisable,
  onForwardDateOver7DaysChange,
}) => (
  <div className={styles.wrapper}>
    <CustomTabs tabs={ADVANCED_CONFIGURATION_TABS}>
      <AddressBook onAddressBookChange={onToggleChange} />
      <ProductBook onProductBookChange={onToggleChange} />
      <CreateShipment onChangeToggle={onReference1} />
      <Collections onChangeToggle={onToggleChange} />
      <AdHocForwardDating
        onChangeToggle={onToggleChange}
        disableSixMonths={isSixMonthsDisable}
        onForwardDateOver7DaysChange={onForwardDateOver7DaysChange}
      />
    </CustomTabs>
  </div>
);

AdvancedCustomerConfiguration.propTypes = {
  onToggleChange: PropTypes.func.isRequired,
  isSixMonthsDisable: PropTypes.bool,
  deselectSixMonthsToggle: PropTypes.func,
  onReference1: PropTypes.func,
  onForwardDateOver7DaysChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  initialValues: {},
  isSixMonthsDisable: isSixMonthsDisable(state),
  isSixMonths: isSixMonths(state),
  isSixMonthsDeselect: isSixMonthsDeselect(state),
  profiles: getUserProfilesAllocatedFormValues(state),
});

const mapDispatchToProps = {
  deselectSixMonthsToggle,
  onForwardDateOver7DaysChange,
  onAddressBookChangeAction: onReference1Change,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps((props) => ({
    onReference1: (field, value) => {
      props.onAddressBookChangeAction();
      props.onToggleChange(field, value);
    },
  })),
  lifecycle({
    componentDidUpdate() {
      const { isSixMonthsDisable, deselectSixMonthsToggle, isSixMonths } =
        this.props;

      if (isSixMonths && isSixMonthsDisable && this.props.profiles.length) {
        deselectSixMonthsToggle();
      }
    },
  }),
  reduxForm({
    form: USER_DETAILS_FORMS.ADVANCED_CUSTOMER_CONFIGURATION,
  })
)(AdvancedCustomerConfiguration);
