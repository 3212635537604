export const debounce = (fn, timeout = 500) => {
  let lastCallTimer = null;
  let lastCall = null;

  return (...args) => {
    let previousCall = lastCall;

    lastCall = Date.now();

    if (previousCall && lastCall - previousCall <= timeout) {
      clearTimeout(lastCallTimer);
    }

    lastCallTimer = setTimeout(() => fn(...args), timeout);
  };
};
