import { get } from "lodash";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { PERMITTED_IP_FIELD } from "./ipRestrictions.constants";
import { getIpAddressForDropdown } from "./ipRestrictions.model";
import { USER_FORMS } from "../../../constants/forms";
import { getPermittedIpAddress } from "../../customer/customer.selectors";

export const getIpRestrictionFormValues = getFormValues(
  USER_FORMS.IP_RESTRICTIONS
);

export const getUserPermittedIpAddress = createSelector(
  getIpRestrictionFormValues,
  (formValues) => get(formValues, PERMITTED_IP_FIELD, [])
);

export const getSelectableIpAddress = (customerId) => (state) => {
  const customerIpAddresses = getPermittedIpAddress(customerId)(state);
  const userPermittedIp = getUserPermittedIpAddress(state);
  return getIpAddressForDropdown(customerIpAddresses, userPermittedIp);
};
