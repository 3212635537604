import { ACTIONS } from "./user.actions";
import { updateMultipleUsers, updateSingleUser } from "./user.model";
import { ACTIONS as ACTIONS_SEARCH } from "../search/search.actions";
import { ACTIONS as ACTIONS_DETAILS } from "../userDetailsForm/userDetailsForm.actions";

export const initialState = {
  accountUsers: [],
  totalAccountUsers: 0,
  currentUser: null,
  usernames: [],
  error: null,
  showPrompt: true,
  isUpdatedSuccessfully: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_USER.SUCCESS:
      return {
        ...state,
        isUpdatedSuccessfully: true,
      };
    case ACTIONS.FETCH_ACCOUNT_USERS.SUCCESS:
    case ACTIONS_SEARCH.SEARCH_USER.SUCCESS:
      return {
        ...state,
        accountUsers: action.payload.results,
        totalAccountUsers: action.payload.totalResults,
      };
    case ACTIONS.SET_ACCOUNT_USERS:
      return {
        ...state,
        accountUsers: action.payload,
      };
    case ACTIONS.CLEAR_USERNAMES:
      return {
        ...state,
        usernames: [],
      };
    case ACTIONS.FETCH_USERNAMES.SUCCESS:
      return {
        ...state,
        usernames: action.payload,
      };
    case ACTIONS.FETCH_USER.SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };
    case ACTIONS.UPDATE_LOCAL_USER:
      return {
        ...state,
        accountUsers: state.accountUsers
          ? updateSingleUser(state.accountUsers, action.payload)
          : state.accountUsers,
      };
    case ACTIONS.DELETE_LOCAL_USER:
      return {
        ...state,
        accountUsers: state.accountUsers.filter(
          (user) => user.uid !== action.payload
        ),
      };
    case ACTIONS.MULTIPLE_LOCAL_USERS_UPDATE:
      return {
        ...state,
        accountUsers: updateMultipleUsers(state.accountUsers, action.payload),
      };
    case ACTIONS.FETCH_ACCOUNT_USERS.FAILURE:
    case ACTIONS.FETCH_USER.FAILURE:
    case ACTIONS.CREATE_USER.FAILURE:
    case ACTIONS.CREATE_USER_WITH_DETAILS.FAILURE:
    case ACTIONS.UPDATE_USER.FAILURE:
      return {
        ...state,
        error: action.payload,
        showPrompt: true,
      };
    case ACTIONS.CREATE_USER_WITH_DETAILS.REQUEST:
    case ACTIONS_DETAILS.FETCH_USER_INFO.FAILURE:
      return {
        ...state,
        showPrompt: false,
      };
    case ACTIONS.CREATE_USER_WITH_DETAILS.SUCCESS:
      return {
        ...state,
        showPrompt: true,
      };
    case ACTIONS.CLEAR:
      return initialState;
    default:
      return state;
  }
};
