import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withProps } from "recompose";
import { blur, change, Field, reduxForm } from "redux-form";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { ReactComponent as Search } from "../../../../assets/icons/search-white.svg";
import HashIcon from "../../../../components/HashIcon/HashIcon";
import InputWithActionButton from "../../../../components/InputWithActionButton/InputWithActionButton";
import SimpleTable from "../../../../components/Table/Table";
import { PROFILE_MANAGEMENT } from "../../../../constants/analytics";
import { SEARCH_PROFILE_FORM } from "../../../../constants/forms";
import {
  ACCOUNT_HAS_TO_BE_PRESENT,
  ACCOUNT_IS_INVALID,
  ACCOUNT_NUMBER,
  CREATE_NEW_PROFILE,
  SEARCH_PROFILE_TABLE_HEADERS,
} from "../../../../constants/strings";
import { onProfileSearch } from "../../../../features/profile/profile.actions";
import {
  ACCOUNT_NUMBER_FIELD,
  PROFILES_FIELD,
} from "../../../../features/profile/profile.constants";
import { getQueryStringParams } from "../../../../features/profile/profile.models";
import {
  getSearchAccountNumber,
  hasProfiles,
} from "../../../../features/profile/profile.selectors";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import { DPD_CREATE_PROFILE, DPD_PROFILES_PAGE } from "../../../../router";
import { navigateTo } from "../../../../router/navigation";
import { onlyNumbers } from "../../../../utils/normalize";
import styles from "./ProfileSearch.module.scss";
import SearchItem from "./SearchItem";

const DecoratedFieldWithError = withErrorTooltip(InputWithActionButton);

const ProfileSearch = ({
  handleSubmit,
  history,
  onSubmit,
  onCreateProfile,
  hasProfiles,
}) => (
  <Grid className={styles.container}>
    <Grid container justify="space-between" alignItems="center">
      <Grid item md={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name={ACCOUNT_NUMBER_FIELD}
            component={DecoratedFieldWithError}
            label={ACCOUNT_NUMBER}
            icon={<HashIcon />}
            buttonIcon={<Search />}
            normalize={onlyNumbers}
            inputProps={{ maxLength: 11 }}
            onButtonClick={handleSubmit(onSubmit)}
          />
        </form>
      </Grid>
      <Grid>
        <Button size="small" type="submit" onClick={onCreateProfile}>
          <span className={styles.buttonText}>{CREATE_NEW_PROFILE}</span>
        </Button>
      </Grid>
    </Grid>
    {hasProfiles && (
      <Grid className={styles.table}>
        <Field
          name={PROFILES_FIELD}
          component={SimpleTable}
          headerRows={SEARCH_PROFILE_TABLE_HEADERS}
          renderItem={(field, index, id) => (
            <SearchItem
              field={field}
              columnIndex={index}
              id={id}
              history={history}
            />
          )}
          cellStyles={styles.tableCell}
        />
      </Grid>
    )}
  </Grid>
);

ProfileSearch.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  onSearch: PropTypes.func,
  onCreateProfile: PropTypes.func,
  hasProfiles: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  hasProfiles: hasProfiles(state),
  searchAccountNumber: getSearchAccountNumber(state),
});

const mapDispatchToProps = (dispatch) => ({
  onBlurSearchField: () =>
    dispatch(blur(SEARCH_PROFILE_FORM, ACCOUNT_NUMBER_FIELD)),
  onProfileSearch: (accountNumber) => dispatch(onProfileSearch(accountNumber)),
  onChangeSearch: (accountNumber) =>
    dispatch(change(SEARCH_PROFILE_FORM, ACCOUNT_NUMBER_FIELD, accountNumber)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: SEARCH_PROFILE_FORM,
    validate: (values) => {
      const errors = {};
      const account = values[ACCOUNT_NUMBER_FIELD];
      if (!account) {
        errors[ACCOUNT_NUMBER_FIELD] = ACCOUNT_HAS_TO_BE_PRESENT;
      }
      if (account && (account.length < 6 || account.length > 7)) {
        errors[ACCOUNT_NUMBER_FIELD] = ACCOUNT_IS_INVALID;
      }
      return errors;
    },
    initialValues: {
      profiles: [],
    },
  }),
  withTrack({
    loadId: PROFILE_MANAGEMENT.LOAD,
    interfaceId: PROFILE_MANAGEMENT.INTERFACE_ID,
  }),
  withTrackProps((props) => ({
    onSearch: () => PROFILE_MANAGEMENT.CLICK_SEARCH_BUTTON,
    onCreateProfile: () => {
      props.history.push(DPD_CREATE_PROFILE);
      return PROFILE_MANAGEMENT.CLICK_CREATE_NEW_PROFILE;
    },
  })),
  withProps((props) => ({
    load: async () => {
      const query = getQueryStringParams(props.history.location.search);
      if (query.account) {
        await props.onChangeSearch(query.account);
        props.onProfileSearch();
      }
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.load();
    },
    componentDidUpdate(prevProps) {
      const { history } = this.props;
      if (
        !isEqual(
          getQueryStringParams(prevProps.history.location.search),
          getQueryStringParams(history.location.search)
        )
      ) {
        this.props.load();
      }
    },
  }),
  withHandlers({
    onSubmit: (ownerProps) => () => {
      ownerProps.onBlurSearchField();
      ownerProps.onSearch();
      if (ownerProps.searchAccountNumber) {
        navigateTo(
          `${DPD_PROFILES_PAGE}?account=${ownerProps.searchAccountNumber}`
        );
      }
      return ownerProps.onProfileSearch();
    },
  })
)(ProfileSearch);
