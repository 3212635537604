import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";

import { ReactComponent as Info } from "../../assets/icons/info.svg";
import CustomSwitch from "../Switch/CustomSwitch";
import Tooltip from "../Tooltip";
import styles from "./CustomSwitchWithTitle.module.scss";

const CustomSwitchWithTitle = ({
  input,
  label,
  infoLabel,
  labelStyle,
  onInfoMouseOver,
  ...inputProps
}) => (
  <div className={styles.item}>
    <FormControlLabel
      control={<CustomSwitch {...input} />}
      {...inputProps}
      label={label}
      classes={{ root: styles.root, label: labelStyle || styles.label }}
    />
    {infoLabel && (
      <Tooltip
        title={infoLabel}
        tooltipClasses={{ tooltipPlacementTop: styles.tooltipPlacementTop }}
      >
        <IconButton disableRipple classes={{ root: styles.info }}>
          <Info onMouseOver={onInfoMouseOver} />
        </IconButton>
      </Tooltip>
    )}
  </div>
);

CustomSwitchWithTitle.defaultProps = {
  onInfoMouseOver: () => null,
};

CustomSwitchWithTitle.propTypes = {
  input: PropTypes.object,
  label: PropTypes.node,
  infoLabel: PropTypes.string,
  labelStyle: PropTypes.string,
  updateFocus: PropTypes.func,
  onInfoMouseOver: PropTypes.func,
};

export default CustomSwitchWithTitle;
