import { makeStyles, useTheme } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./HashIcon.module.scss";

const useThemeColorStyles = makeStyles((theme) => ({
  icon: () => ({
    color: theme.palette.primary.main,
  }),
}));

const HASH = "#";

const HashIcon = ({ iconStyle }) => {
  const iconColor = useThemeColorStyles(useTheme());
  return (
    <span className={classNames(styles.icon, iconStyle, iconColor.icon)}>
      {HASH}
    </span>
  );
};

HashIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default HashIcon;
