import { Redirect, Route, Router } from "react-router";
import { Switch } from "react-router-dom";

import { CUSTOMER, DPD, LOGIN_PAGE, PAGE_404 } from "./constants";
import { history } from "./history";
import { USER_ROLES } from "../constants/common";
import AuthGate from "../features/auth/HOCS/AuthGate";
import AuthRoute from "../features/auth/HOCS/AuthRoute";
import { DpdUserRoutes } from "../pages/dpd-user";
import { LoginRoutes } from "../pages/login";
import PageNotFound from "../pages/not-found/PageNotFound";
import { CustomerRoutes } from "../pages/user";

export const Routes = () => (
  <AuthGate>
    <Router history={history}>
      <Switch>
        <AuthRoute
          reverse
          path={LOGIN_PAGE}
          fallbackPath={DPD}
          component={LoginRoutes}
        />
        <AuthRoute
          path={DPD}
          component={DpdUserRoutes}
          roles={[USER_ROLES.DPD_ADMIN]}
          fallbackPath={CUSTOMER}
        />
        <AuthRoute
          path={CUSTOMER}
          component={CustomerRoutes}
          roles={[USER_ROLES.CUSTOMER_ADMIN]}
        />
        <Route path={PAGE_404} component={PageNotFound} />
        <Route component={() => <Redirect to={LOGIN_PAGE} />} />
      </Switch>
    </Router>
  </AuthGate>
);
