import { FIELDS_FOR_VALIDATION_CHECK } from "./accountDetails.constants";

export const getCustomerValidationModalError = (errors) => {
  let errorMessage = "";
  for (const fieldName of FIELDS_FOR_VALIDATION_CHECK) {
    if (fieldName in errors) {
      errorMessage = errors[fieldName];
      break;
    }
  }
  return errorMessage;
};
