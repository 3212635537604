import { getAccountPermittedIpById, getEodMasterUser } from "./customer.model";

export const getUserCustomerAccounts = (state) =>
  state.customer.userCustomerAccounts;

export const getPermittedIpAddress = (customerId) => (state) => {
  const accounts = getUserCustomerAccounts(state);
  return getAccountPermittedIpById(accounts, customerId);
};

export const hasMasterEodUser = (customerId) => (state) => {
  const accounts = getUserCustomerAccounts(state);
  return !!getEodMasterUser(accounts, customerId);
};
