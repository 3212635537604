import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { EDIT_CUSTOMER_USER } from "../../../../constants/analytics";
import { onCreateMasterUser } from "../../../../features/createMasterUserForm/createMasterUser.actions";
import {
  onConfirmCreateUser,
  prepareRequiredEditData,
} from "../../../../features/user/user.actions";
import {
  isCurrentUserCanCreateMasterUser,
  shouldShowLeavePrompt,
} from "../../../../features/user/user.selectors";
import { clearUserDetailsForm } from "../../../../features/userDetailsForm/userDetailsForm.actions";
import {
  isCreateUserFormDirty,
  isUserFormSubmitted,
} from "../../../../features/userDetailsForm/userDetailsForm.selector";
import { DPD } from "../../../../router";
import MainCustomerUserForm from "../../components/MainCustomerUserForm";

const mapStateToProps = (state) => ({
  canCreateMasterUser: isCurrentUserCanCreateMasterUser(state),
  isFormDirty: isCreateUserFormDirty(state),
  isSubmitted: isUserFormSubmitted(state),
  showPrompt: shouldShowLeavePrompt(state),
});

const mapDispatchToProps = (
  dispatch,
  {
    history,
    match: {
      params: { id, customerId },
    },
  }
) => ({
  onCancel: () => history.push(DPD),
  onConfirm: () => dispatch(onConfirmCreateUser(id, customerId)),
  onClickCreateMasterUser: () => dispatch(onCreateMasterUser()),
  clearForm: () => dispatch(clearUserDetailsForm()),
  prepareData: () => dispatch(prepareRequiredEditData(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.prepareData();
    },
    componentWillUnmount() {
      this.props.clearForm();
    },
  }),
  withTrack({
    loadId: EDIT_CUSTOMER_USER.LOAD,
    interfaceId: EDIT_CUSTOMER_USER.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onConfirm: () => EDIT_CUSTOMER_USER.CONFIRM,
  }))
)(MainCustomerUserForm);
