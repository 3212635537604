import { Box, Grid, MenuItem } from "@material-ui/core";
import { compose, lifecycle } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import {
  LATEST_VERSION,
  MINIMUM_VERSION,
} from "../../../../../../constants/strings";
import { DPD_APP_CONFIG_FORM } from "../../../../../../constants/forms";
import BottomButtons from "../../../../../../components/BottomButtons/BottomButtons";
import CustomSwitchWithTitle from "../../../../../../components/SwitchWidthTitle/CustomSwitchWithTitle";
import SelectWithTitle from "../../../../../../components/SelectWithTitle/SelectWithTitle";
import SelectItem from "../../../../../../components/SelectItem/SelectItem";
import {
  getDPDAppConfigFormInitialValues,
  getStatusTitle,
} from "../../../../../../features/appManagement/appManagement.models";
import {
  loadDPDAppConfigData,
  onCancelConfig,
  onUpdateConfig,
} from "../../../../../../features/appManagement/appManagement.actions";
import {
  MINIMUM_VERSION_FIELD,
  LATEST_VERSION_FIELD,
  ALLOW_DRAFT_FIELD,
  ALLOW_PRERELEASE_FIELD,
  ALLOW_DEPRECATED_FIELD,
  DRAFT,
  PRERELEASE,
  DEPRECATED,
} from "../../../../../../features/appManagement/appManagement.constants";
import { validateAppConfigDetailsForm } from "../../../../../../features/appManagement/appManagement.validate";
import { getAppVersions } from "../../../../../../features/appManagement/appManagement.selectors";
import withErrorTooltip from "../../../../../../hocs/withErrorTooltip";
import styles from "./AppConfigForm.module.scss";

const renderSelectItems = (items) =>
  items.map(({ version, status }) => (
    <MenuItem key={version} value={version}>
      <SelectItem
        title={`${version} ${getStatusTitle(status)}`}
        containerStyle={styles["select-item"]}
      />
    </MenuItem>
  ));

const SelectWithTitleWithError = withErrorTooltip(SelectWithTitle);

const AppConfigForm = ({
  onCancelConfig,
  onUpdateConfig,
  versions,
  handleSubmit,
  dirty,
}) => {
  return (
    <Box py={2}>
      <Box py={2}>
        <Grid container direction="row" spacing={6}>
          <Grid item xs={6}>
            <Box>
              <Field
                component={SelectWithTitleWithError}
                name={LATEST_VERSION_FIELD}
                label={LATEST_VERSION}
              >
                {renderSelectItems(versions)}
              </Field>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Field
                component={SelectWithTitleWithError}
                name={MINIMUM_VERSION_FIELD}
                label={MINIMUM_VERSION}
              >
                {renderSelectItems(versions)}
              </Field>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box py={2}>
        <Grid container direction="row" justify="space-between">
          <Field
            name={ALLOW_DRAFT_FIELD}
            component={CustomSwitchWithTitle}
            type="checkbox"
            label={`Allow ${DRAFT}`}
          />

          <Field
            name={ALLOW_PRERELEASE_FIELD}
            component={CustomSwitchWithTitle}
            type="checkbox"
            label={`Allow ${PRERELEASE}`}
          />

          <Field
            name={ALLOW_DEPRECATED_FIELD}
            component={CustomSwitchWithTitle}
            type="checkbox"
            label={`Allow ${DEPRECATED}`}
          />
        </Grid>
      </Box>
      <Box py={2}>
        <BottomButtons
          onCancelClick={onCancelConfig}
          onConfirmClick={handleSubmit(onUpdateConfig)}
          confirmDisabled={!dirty}
        />
      </Box>
    </Box>
  );
};

AppConfigForm.propTypes = {
  onCancelConfig: PropTypes.func,
  onUpdateConfig: PropTypes.func,
};

AppConfigForm.defaultProps = {
  onCancelConfig: () => {},
};

const mapDispatchToProps = {
  loadDPDAppConfigData,
  onUpdateConfig,
  onCancelConfig,
};

const mapStateToProps = (state) => ({
  versions: getAppVersions(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: DPD_APP_CONFIG_FORM,
    initialValues: getDPDAppConfigFormInitialValues(),
    validate: validateAppConfigDetailsForm,
  }),
  lifecycle({
    componentDidMount() {
      this.props.loadDPDAppConfigData();
    },
  })
)(AppConfigForm);
