import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { isObject, startCase } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import SimulateTabTitle from "../../../../components/CustomTabs/SimulateTabTitle";
import {
  normalizeKeyString,
  normalizeValueString,
} from "../../../../features/activityMonitor/activityMonitor.model";
import { getConfigForDescriptions } from "../../../../features/activityMonitor/activityMonitor.selectors";
import styles from "./AuditLogDetailsItem.module.scss";

const useThemeColorStyles = makeStyles((theme) => ({
  color: () => ({
    color: `${theme.palette.secondary.light} !important`,
  }),
}));

const AuditLogDetailsItem = ({ sectionName, settingsValues }) => {
  const config = useSelector(getConfigForDescriptions);
  const themeColorStyle = useThemeColorStyles(useTheme());

  const getSectionItem = (key, value, sectionName) => {
    if (isObject(value)) {
      return (
        <div>
          {key && (
            <Typography
              component="div"
              classes={{ root: styles.subtitle }}
              className={styles.subtitle}
            >
              <SimulateTabTitle
                title={startCase(key)}
                containerStyle={styles.subtitle}
              />
            </Typography>
          )}
          <div className={styles.subSections}>
            {Object.entries(value).map(([subSectionKey, subSectionValue]) =>
              getSectionItem(subSectionKey, subSectionValue, sectionName)
            )}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.text}>{`${normalizeKeyString(
        key,
        sectionName,
        config
      )} - ${normalizeValueString(key, value, sectionName)}`}</div>
    );
  };

  return (
    <div className={styles.container}>
      <Typography
        component="div"
        classes={{ root: styles.title }}
        className={themeColorStyle.color}
      >
        {startCase(sectionName)}
      </Typography>
      {Object.entries(settingsValues).map(([key, value]) => (
        <Typography key={key} component="div">
          {getSectionItem(key, value, sectionName)}
        </Typography>
      ))}
    </div>
  );
};

AuditLogDetailsItem.propTypes = {
  sectionName: PropTypes.string,
  settingsValues: PropTypes.object,
};

export default AuditLogDetailsItem;
