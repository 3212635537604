import get from "lodash/get";
import {
  getFormInitialValues,
  getFormMeta,
  getFormSyncErrors,
  getFormValues,
  hasSubmitSucceeded,
  isDirty,
} from "redux-form";
import { createSelector } from "reselect";

import { ACCOUNT_ID_FIELD } from "./accountDetails/accountDetails.constants";
import { getSelectedAccountDetails } from "./accountDetails/accountDetails.selectors";
import { getDiffServices } from "./userProfiles/userProfiles.models";
import { USER_DETAILS_FORMS, USER_FORMS } from "../../constants/forms";
import {
  getServiceExclusionsDpdLocalServices,
  getServiceExclusionsDpdServices,
} from "../config/config.selectors";
import { ADMIN_ACCESS_FIELD } from "./userDetails/userDetails.constants";
import omit from "lodash/omit";

export const getIsOverrideEnabled = (state) => state.userForm.isOverrideEnabled;

export const getMasterEodDetails = (state) => state.userForm.masterEodDetails;

export const getUserInfo = (state) => state.userForm.userInfo;

export const getUsername = (state) =>
  get(state.userForm.userInfo, "username", "");

export const getBusinessId = (state) =>
  get(state.userForm.userInfo, "businessId");

export const getCustomerAddress = (state) =>
  get(state.userForm.userInfo, "customerAddress", {});

export const getEodMasterUser = createSelector(
  getMasterEodDetails,
  (masterEodDetails) => get(masterEodDetails, "eodMasterUser", null)
);

export const isEodMasterUser = createSelector(
  getEodMasterUser,
  getUserInfo,
  (masterEod, user) => user.uid === masterEod
);

export const isMasterEodExist = createSelector(
  getEodMasterUser,
  (eodMasterUser) => !!eodMasterUser
);

export const isEodMasterAvailableForAccount = createSelector(
  getMasterEodDetails,
  (masterEodDetails) => get(masterEodDetails, "eodMasterAvailable", false)
);

export const getSelectedAccount = createSelector(
  getMasterEodDetails,
  ({ account }) => account
);

const formDataSelector = (forms) => (state) =>
  Object.values(forms).reduce(
    (userDetails, form) => {
      userDetails.values[form] = getFormValues(form)(state);
      if (isDirty(form)(state)) {
        // todo remove meta and initialValues after removing audit from web
        userDetails.meta[form] = getFormMeta(form)(state);
        userDetails.initialValues[form] = getFormInitialValues(form)(state);
      }

      return userDetails;
    },
    { values: {}, meta: {}, initialValues: {} }
  );

export const getUserDetailsData = formDataSelector(USER_DETAILS_FORMS);

export const getCustomerUserDetailsData = formDataSelector(USER_FORMS);

export const isCreateCustomerUserFormDirty = (state) =>
  Object.values(omit(USER_FORMS, USER_FORMS.API_SETTING)).some((form) =>
    isDirty(form)(state)
  );

export const isCreateUserFormDirty = (state) =>
  Object.values(USER_DETAILS_FORMS).some((form) => isDirty(form)(state));

export const isUserFormSubmitted = (state) =>
  Object.values(USER_DETAILS_FORMS).some((form) =>
    hasSubmitSucceeded(form)(state)
  );

export const dpdServicesFormValues = (state) =>
  get(getFormValues(USER_DETAILS_FORMS.SERVICE_EXCLUSIONS)(state), "dpd", []);

export const dpdLocalServicesFormValues = (state) =>
  get(
    getFormValues(USER_DETAILS_FORMS.SERVICE_EXCLUSIONS)(state),
    "dpdLocal",
    []
  );

export const getCustomerAdditionalAccounts = (state) =>
  get(
    getFormValues(USER_DETAILS_FORMS.SYSTEM_ACCESS_ACCOUNTS)(state),
    "accounts",
    []
  );

export const getAdminAccess = (state) =>
  get(
    getFormValues(USER_DETAILS_FORMS.DPD_USER_DETAILS)(state),
    ADMIN_ACCESS_FIELD,
    false
  );

export const getDpdAvailableToSelectServices = createSelector(
  getServiceExclusionsDpdServices,
  dpdServicesFormValues,
  getDiffServices
);

export const isInvalidAccountNumber = createSelector(
  getFormSyncErrors(USER_DETAILS_FORMS.ACCOUNT_DETAILS),
  (errors) => get(errors, ACCOUNT_ID_FIELD)
);

export const getCustomerAdminAccountsInitialValues = createSelector(
  getSelectedAccountDetails,
  isInvalidAccountNumber,
  (account, isAccountNumberInvalid) => ({
    accounts: account.account && !isAccountNumberInvalid ? [account] : [],
  })
);

export const isFirstCustomerAdditionalAccount = createSelector(
  getSelectedAccountDetails,
  getCustomerAdditionalAccounts,
  (account, formValues) =>
    formValues[0] &&
    account.account &&
    formValues[0].account === account.account
);

export const getDpdLocalAvailableToSelectServices = createSelector(
  getServiceExclusionsDpdLocalServices,
  dpdLocalServicesFormValues,
  getDiffServices
);
export const getPopulatedFormFields = (form) =>
  createSelector(getFormValues(form), (values) => Object.keys(values));
