import { Redirect, Route, Switch } from "react-router";

import {
  FORGOT_PASSWORD_PAGE,
  LOGIN_FIRST_PAGE,
  LOGIN_PAGE,
  RESET_PASSWORD,
  SET_PASSWORD,
} from "../../router";
import LoginLayout from "./components/LoginLayout";
import LoginFirstPage from "./pages/firstLogin/LoginFirstPage";
import ForgotPasswordPage from "./pages/forgotPassword";
import LoginPage from "./pages/login";
import ResetPasswordPage from "./pages/resetPassword";
import LoginSetPasswordPage from "./pages/setPassword";

export const LoginRoutes = () => (
  <LoginLayout>
    <Switch>
      <Route path={LOGIN_PAGE} exact component={LoginPage} />
      <Route path={RESET_PASSWORD} exact component={ResetPasswordPage} />
      <Route path={LOGIN_FIRST_PAGE} exact component={LoginFirstPage} />
      <Route path={FORGOT_PASSWORD_PAGE} exact component={ForgotPasswordPage} />
      <Route path={SET_PASSWORD} exact component={LoginSetPasswordPage} />
      <Route component={() => <Redirect to={LOGIN_PAGE} />} />
    </Switch>
  </LoginLayout>
);
