import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { FIRST_LOGIN_ANALYTICS } from "../../../../constants/analytics";
import { CREATE_PASSWORD, SUBMIT } from "../../../../constants/strings";
import {
  onLoginFirstInit,
  onLoginFirstSubmit,
} from "../../../../features/auth/auth.actions";
import LoginEmailForm from "../../components/LoginEmailForm";

const LoginFirstPage = ({ onSubmit }) => (
  <LoginEmailForm
    buttonText={SUBMIT}
    title={CREATE_PASSWORD}
    onSubmitPress={onSubmit}
  />
);

LoginFirstPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  onSubmit: () => dispatch(onLoginFirstSubmit(params.id)),
  init: () => dispatch(onLoginFirstInit()),
});

export default compose(
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.init();
    },
  }),
  withTrack({
    loadId: FIRST_LOGIN_ANALYTICS.LOAD,
    interfaceId: FIRST_LOGIN_ANALYTICS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onSubmit: () => FIRST_LOGIN_ANALYTICS.LOGIN,
  }))
)(LoginFirstPage);
