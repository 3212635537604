import get from "lodash/get";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import {
  EXTENDED_HOLD_POLICY,
  FORWARD_DATE_OVER_7_DAYS_FIELD,
  REFERENCE_1_READ_ONLY,
} from "./advancedCustomerConfiguration.constants";
import { isSixMonthsDisableToggle } from "./advancedCustomerConfiguration.models";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { getBusinessId } from "../accountDetails/accountDetails.selectors";
import { getUserProfilesAllocatedFormValues } from "../userProfiles/userProfiles.selectors";

export const getAdvancedCustomerConfigurationFormValues = getFormValues(
  USER_DETAILS_FORMS.ADVANCED_CUSTOMER_CONFIGURATION
);

export const getThirtyDaysForwardDating = createSelector(
  getAdvancedCustomerConfigurationFormValues,
  (formValues) => get(formValues, FORWARD_DATE_OVER_7_DAYS_FIELD, false)
);

export const isSixMonthsDisable = createSelector(
  getBusinessId,
  getUserProfilesAllocatedFormValues,
  isSixMonthsDisableToggle
);

export const isSixMonths = createSelector(
  getAdvancedCustomerConfigurationFormValues,
  (formValues) => get(formValues, EXTENDED_HOLD_POLICY, false)
);

export const isSendersRef1Disabled = createSelector(
  getAdvancedCustomerConfigurationFormValues,
  (formValues) => !!get(formValues, REFERENCE_1_READ_ONLY, false)
);

export const isSixMonthsDeselect = createSelector(
  getUserProfilesAllocatedFormValues,
  isSixMonthsDisable,
  isSixMonths,
  (profiles, disabled) => !profiles.length && disabled
);
