import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { USER_DETAILS_FORMS } from "../../../constants/forms";

import ApiSetting from "../../../components/ApiSetting";
import { API_SETTING_FIELDS } from "../../../features/userDetailsForm/apiSetting/apiSetting.constants";
import {
  getApiSettingFormValues,
  getApiUsersKeys,
  getLiveKeys,
  getSandboxKeys,
  getVerifyEmailsData,
  isEmailSent,
  shouldShowApiSettings,
} from "../../../features/userDetailsForm/apiSetting/apiSettingSelectors";
import {
  addApiUserKey,
  disableApiAccess,
  removeApiUserKey,
  verifyApiUserEmail,
} from "../../../features/userDetailsForm/userDetailsForm.actions";

import { apiSettingValidator } from "../../../features/userDetailsForm/apiSetting/apiSetting.validate";
import { getCurrentUser } from "../../../features/user/user.selectors";

const mapStateToProps = (state) => ({
  apiSettingFormValues: getApiSettingFormValues(state),
  showApiSettings: shouldShowApiSettings(state),
  apiUsersKeys: getApiUsersKeys(state),
  sandboxKeys: getSandboxKeys(state),
  liveKeys: getLiveKeys(state),
  verifyEmailsData: getVerifyEmailsData(state),
  currentUser: getCurrentUser(state),
  isEmailSent: isEmailSent(state),
});
const mapDispatchToProps = (dispatch) => ({
  onVerifyEmailClick: () => dispatch(verifyApiUserEmail()),
  addKey: (isLiveKey) => dispatch(addApiUserKey(isLiveKey)),
  deleteKey: (keyLabel, fieldName, isLiveKey) =>
    dispatch(removeApiUserKey(keyLabel, fieldName, isLiveKey)),
  onApiSettingChange: (event, value) => dispatch(disableApiAccess(value)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: USER_DETAILS_FORMS.API_SETTING,
    enableReinitialize: true,
    validate: (values) => apiSettingValidator(values),
  }),
  withTrackProps(() => ({
    onInputChange: (field, value) => {
      let id = "";
      switch (field) {
        // TODO: add cases with id
        case API_SETTING_FIELDS.API_ACCESS:
          id = "";
          break;
        default:
          return "";
      }

      return createLogEntryPayload(id, {
        field,
        value,
      });
    },
  }))
)(ApiSetting);
