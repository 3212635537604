import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { compose } from "recompose";
import { hasSubmitFailed, isValid, reduxForm } from "redux-form";

import AdminFailedImport from "./AdminFailedImport";
import AdminReferences from "./AdminReferences";
import EndOfDay from "./EndOfDay";
import BulkReverseIt from "../../../../components/ShippingSettings/BulkReverseIt";
import GroupDispatch from "../../../../components/ShippingSettings/GroupDispatch";
import ShippingSettings from "../../../../components/ShippingSettings/ShippingSettings.js";
import {
  ANALYTICS_FOR_USER,
  CREATE_EDIT_CUSTOMER_USER_ANALYTICS,
} from "../../../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../../../constants/forms";
import {
  BULK_REVERSE_IT,
  END_OF_DAY,
  FAILED_IMPORT,
  GROUP_DISPATCH,
  REFERENCES,
} from "../../../../constants/strings";
import { getShippingInitialValues } from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.models";
import { validate } from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.validate";
import withRef from "../../../../hocs/withRef.js";

const AdminShippingSettings = ({ onInputChange }) => {
  const isEndOfDayValid = useSelector(
    isValid(USER_DETAILS_FORMS.SHIPPING_SETTINGS)
  );
  const isEndOfDaySubmitFailed = useSelector(
    hasSubmitFailed(USER_DETAILS_FORMS.SHIPPING_SETTINGS)
  );

  const shippingSettingsTabs = [
    {
      label: REFERENCES,
      analyticsId: ANALYTICS_FOR_USER.CLICK_REFERENCES,
      component: AdminReferences,
    },
    {
      label: FAILED_IMPORT,
      analyticsId: ANALYTICS_FOR_USER.CLICK_FAILED_IMPORT,
      component: AdminFailedImport,
    },
    {
      label: END_OF_DAY,
      analyticsId: CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_END_OF_DAY,
      withBadge: !isEndOfDayValid && isEndOfDaySubmitFailed,
      component: EndOfDay,
    },
    {
      label: GROUP_DISPATCH,
      analyticsId: ANALYTICS_FOR_USER.CLICK_GROUP_DISPATCH,
      component: GroupDispatch,
    },
    {
      label: BULK_REVERSE_IT,
      analyticsId: CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_BULK_REVERSEIT,
      component: BulkReverseIt,
    },
  ];

  return (
    <ShippingSettings
      shippingSettingsTabs={shippingSettingsTabs}
      onInputChange={onInputChange}
    />
  );
};

AdminShippingSettings.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onInputChange: PropTypes.func,
};

export default compose(
  reduxForm({
    form: USER_DETAILS_FORMS.SHIPPING_SETTINGS,
    initialValues: getShippingInitialValues(),
    validate,
  })
)(withRef(AdminShippingSettings));
