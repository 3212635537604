import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { CREATE_EDIT_USER_ANALYTICS } from "../../../constants/analytics.js";
import { USER_FORMS } from "../../../constants/forms.js";
import AdditionalAccounts from "../../../containers/AdditionalAccounts";
import {
  getCustomerAdditionalAccountNumbers,
  onAccountClick,
} from "../../../features/userDetailsForm/additionalAccounts/additionalAccounts.actions";
import { ADDITIONAL_ACCOUNTS_FIELD } from "../../../features/userDetailsForm/additionalAccounts/additionalAccounts.constant.js";
import { getAdditionalAccountsInitialValues } from "../../../features/userDetailsForm/additionalAccounts/additionalAccounts.models";
import { isOwnUser } from "../../../features/userDetailsForm/systemAccess/systemAccess.selectors";

const UserAdditionalAccounts = ({
  onBlurFields,
  onAccountItemClick,
  onRemoveAdditionalAccount,
  fetchAdditionalAccountNumbers,
  isOwnUser,
}) => (
  <AdditionalAccounts
    form={USER_FORMS.ADDITIONAL_ACCOUNTS}
    initialValues={getAdditionalAccountsInitialValues()}
    fetchFunction={fetchAdditionalAccountNumbers}
    onItemClick={onAccountItemClick}
    onBlurFields={onBlurFields}
    disabled={isOwnUser}
    onRemove={onRemoveAdditionalAccount}
  />
);

const mapDispatchToProps = (dispatch, ownProps) => {
  const { customerId } = ownProps.match.params;
  return {
    onAccountItemClick: (item) =>
      dispatch(onAccountClick(item, USER_FORMS.ADDITIONAL_ACCOUNTS)),
    fetchAdditionalAccountNumbers: (accountNumber) =>
      dispatch(
        getCustomerAdditionalAccountNumbers(
          accountNumber,
          USER_FORMS.ADDITIONAL_ACCOUNTS,
          customerId
        )
      ),
  };
};

const mapStateToProps = (state) => ({
  isOwnUser: isOwnUser(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(() => ({
    onAccountItemClick: () =>
      CREATE_EDIT_USER_ANALYTICS.CLICK_ADDITIONAL_ACCOUNTS_NUMBER_ADD,
    onRemoveAdditionalAccount: () =>
      CREATE_EDIT_USER_ANALYTICS.CLICK_ADDITIONAL_ACCOUNTS_NUMBER_REMOVE,
    onBlurFields: (field) => {
      if (field === ADDITIONAL_ACCOUNTS_FIELD) {
        return CREATE_EDIT_USER_ANALYTICS.ADDITIONAL_ACCOUNTS_NUMBER_ENTRY;
      }
    },
  }))
)(UserAdditionalAccounts);

UserAdditionalAccounts.propTypes = {
  fetchAdditionalAccountNumbers: PropTypes.func,
  onAccountItemClick: PropTypes.func,
  onBlurFields: PropTypes.func,
  itemsCount: PropTypes.number,
  isOwnUser: PropTypes.bool,
  onRemoveAdditionalAccount: PropTypes.func,
};
