import { API_ENDPOINTS } from "../../constants/common";
import { webApi } from "../../utils/api";

export const getAuditByAccount = (accountNumber, queryParams) =>
  webApi
    .get({
      path: API_ENDPOINTS.activityMonitor,
      query: {
        ...queryParams,
        accountNumber,
      },
    })
    .then((res) => res.data);
