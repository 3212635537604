import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field, FieldArray, isValid, reduxForm } from "redux-form";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { SLIDS_FORM } from "../../../constants/forms";
import {
  CURRENT_LABEL_NO,
  FILL_LABEL_NUMBERS,
  NEW_SLIDS,
  SAVE,
} from "../../../constants/strings";
import { hideModal } from "../../../features/modal/modal.actions";
import {
  ALIGN_BUTTONS,
  WIDTH_TYPE,
} from "../../../features/modal/modal.constants";
import { GENERAL_SLID_ERROR } from "../../../features/profile/profile.constants";
import { EXISTED_FIELD } from "../../../features/sequences/sequences.constants";
import { validate } from "../../../features/sequences/sequences.validate";
import { onlyNumbers } from "../../../utils/normalize";
import InputWithTitle from "../../InputWithTitle/InputWithTitle";
import WarningText from "../../WarningText/WarningText";
import BaseModal from "../BaseModal";
import styles from "./SlidsModal.module.scss";

const SlidsList = ({ fields }) => (
  <List>
    {fields.map((fieldName, index, fieldArray) => (
      <ListItem key={index} className={styles.listItemWrapper}>
        <ListItemText
          className={styles.listItem}
          primary={`SLID-${fieldArray.get(index).seqName}`}
        />
        <Field
          name={`${fieldName}.curValue`}
          label={CURRENT_LABEL_NO}
          component={InputWithTitle}
          inputProps={{ maxLength: fieldArray.get(index).seqName.length + 6 }}
          normalize={onlyNumbers}
          disabled={fieldArray.get(index)[EXISTED_FIELD]}
          required
          showMetaErrors
        />
      </ListItem>
    ))}
  </List>
);

SlidsList.propTypes = {
  fields: PropTypes.object,
};

function SlidsModal({
  onCancelClick,
  onConfirmClick,
  isFormValid,
  ...otherProps
}) {
  return (
    <BaseModal
      title={NEW_SLIDS}
      confirmButtonText={SAVE}
      onCloseClick={onCancelClick}
      alignButton={ALIGN_BUTTONS.RIGHT}
      widthType={WIDTH_TYPE.LARGE}
      onConfirmClick={onConfirmClick}
      disabledConfirmButton={!isFormValid}
      {...otherProps}
    >
      <DialogContent className={styles.content}>
        <DialogContentText className={styles.text}>
          {FILL_LABEL_NUMBERS}
        </DialogContentText>
        <WarningText text={GENERAL_SLID_ERROR} />
        <FieldArray name="data" component={SlidsList} />
      </DialogContent>
    </BaseModal>
  );
}

SlidsModal.propTypes = {
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  confirmActionId: PropTypes.string,
  cancelActionId: PropTypes.string,
  handleSubmit: PropTypes.func,
  onHide: PropTypes.func,
  isFormValid: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isFormValid: isValid(SLIDS_FORM)(state),
});

const mapDispatchToProps = {
  onHide: hideModal,
};

export default compose(
  reduxForm({
    form: SLIDS_FORM,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps((props) => ({
    onConfirmClick: () => {
      props.onHide();
      return props.confirmActionId;
    },
    onCancelClick: () => {
      props.onHide();
      return props.cancelActionId;
    },
  }))
)(SlidsModal);
