const style = window.getComputedStyle(document.body);

export default {
  white: style.getPropertyValue("--white").trim(),
  uiPrimary: style.getPropertyValue("--uiPrimary").trim(),
  uiPrimaryDark: style.getPropertyValue("--uiPrimaryDark").trim(),
  uiPrimaryLight: style.getPropertyValue("--uiPrimaryLight").trim(),
  uiLocalPrimary: style.getPropertyValue("--uiLocalPrimary").trim(),
  uiLocalPrimaryDark: style.getPropertyValue("--uiLocalPrimaryDark").trim(),
  uiLocalPrimaryLight: style.getPropertyValue("--uiLocalPrimaryLight").trim(),
  uiSecondary: style.getPropertyValue("--uiSecondary").trim(),
  uiSecondaryDark: style.getPropertyValue("--uiSecondaryDark").trim(),
  uiSecondaryLight: style.getPropertyValue("--uiSecondaryLight").trim(),
  uiLocalSecondaryLight: style
    .getPropertyValue("--uiLocalSecondaryLight")
    .trim(),
  buttonGradient: style.getPropertyValue("--buttonGradient").trim(),
  buttonLocalGradient: style.getPropertyValue("--buttonLocalGradient").trim(),
  buttonSecondaryGradient: style
    .getPropertyValue("--buttonSecondaryGradient")
    .trim(),

  paginationDisabled: style.getPropertyValue("--paginationDisabled").trim(),
  tableBorder: style.getPropertyValue("--tableBorder").trim(),
};
