import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import styles from "./ProfileGeneralInfo.module.scss";
import HashIcon from "../../../../components/HashIcon/HashIcon.js";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  ACCOUNT_NUMBER,
  PROFILE_CODE,
  PROFILE_NAME,
} from "../../../../constants/strings";
import {
  CREATE_ACCOUNT_NUMBER_FIELD,
  PROFILE_CODE_FIELD,
  PROFILE_NAME_FIELD,
} from "../../../../features/profile/profile.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import { noSpecialCharacters, onlyNumbers } from "../../../../utils/normalize";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const ProfileGeneralInfo = ({ isEdit, onTextEntry }) => (
  <Grid container spacing={2} className={styles.container}>
    <Grid item className={styles["account-number"]}>
      <Field
        name={CREATE_ACCOUNT_NUMBER_FIELD}
        component={DecoratedFieldWithError}
        label={ACCOUNT_NUMBER}
        icon={<HashIcon />}
        onBlur={(e, value) => {
          onTextEntry(CREATE_ACCOUNT_NUMBER_FIELD, value);
        }}
        inputProps={{ maxLength: 11 }}
        normalize={onlyNumbers}
        disabled={isEdit}
        required
      />
    </Grid>
    <Grid container item direction="row" justify="space-between">
      <Grid item className={styles["profile-item"]}>
        <Field
          name={PROFILE_NAME_FIELD}
          component={DecoratedFieldWithError}
          label={PROFILE_NAME}
          inputProps={{ maxLength: 20 }}
          required
          onBlur={(e, value) => {
            onTextEntry(PROFILE_NAME_FIELD, value);
          }}
        />
      </Grid>
      <Grid item className={styles["profile-item"]}>
        <Field
          name={PROFILE_CODE_FIELD}
          component={DecoratedFieldWithError}
          label={PROFILE_CODE}
          inputProps={{ maxLength: 10 }}
          required
          normalize={noSpecialCharacters}
          disabled={isEdit}
          onBlur={(e, value) => {
            onTextEntry(PROFILE_CODE_FIELD, value);
          }}
        />
      </Grid>
    </Grid>
  </Grid>
);

ProfileGeneralInfo.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  onTextEntry: PropTypes.func,
};

export default withTrackProps(() => ({
  onTextEntry: (field, value) =>
    createLogEntryPayload(PROFILE_ANALYTICS.TEXT_ENTRY, {
      field,
      value,
    }),
}))(ProfileGeneralInfo);
