export const IP_ADDRESS_FIELD = "ipAddress";
export const IP_ADDRESS_NAME_FIELD = "name";
export const AUTO_SUSPEND_ACCOUNTS_FIELD = "autoSuspendAccounts";
export const SUSPEND_USER_ACCOUNT_DAYS_FIELD = "autoSuspendUserAccount";
export const PERMITTED_IP_FIELD = "permittedIp";

export const START_IP_FIELD = "rangeStart";
export const END_IP_FIELD = "rangeEnd";
export const IP_RANGE_NAME_FIELD = "rangeName";
export const IP_RANGE_FIELD = "ipRange";
