import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { reduxForm } from "redux-form";

import CustomTabs from "../../components/CustomTabs/CustomTabs";
import TransferList from "../../components/TransferList/TransferList";
import {
  ANALYTICS_FOR_USER,
  CREATE_EDIT_CUSTOMER_USER_ANALYTICS,
} from "../../constants/analytics";
import colors from "../../constants/colors";
import { USER_FORMS } from "../../constants/forms";
import {
  DPD_SERVICE_LEFT_HEADER,
  DPD_SERVICE_RIGHT_HEADER,
  SERVICE_EXCLUSIONS_TABS,
} from "../../constants/strings.js";
import { onChangeExclusions } from "../../features/userDetailsForm/serviceExclusions/ServiceExclusions.actions";
import {
  dpdLocalServicesFormValues,
  dpdServicesFormValues,
  getDpdAvailableToSelectServices,
  getDpdLocalAvailableToSelectServices,
} from "../../features/userDetailsForm/userDetailsForm.selector";

const useThemeStyles = makeStyles(() => ({
  item: {
    backgroundColor: colors.uiLocalPrimary,
    "&:hover": {
      backgroundColor: colors.uiLocalPrimary,
    },
  },
}));

const ServiceExclusions = ({
  onChangeDpd,
  onInputChange,
  onChangeDpdLocal,
  dpdSelectedServices,
  dpdLocalSelectedServices,
  dpdAvailableToSelectServices,
  dpdLocalAvailableToSelectServices,
  analyticIdsDpd,
  analyticsIdsDpdLocal,
}) => {
  const themeStyles = useThemeStyles(useTheme());

  return (
    <CustomTabs tabs={SERVICE_EXCLUSIONS_TABS}>
      <TransferList
        leftHeader={DPD_SERVICE_LEFT_HEADER}
        rightHeader={DPD_SERVICE_RIGHT_HEADER}
        onChange={(value) => {
          onChangeDpd(value);
          onInputChange(value);
        }}
        selectedValues={dpdSelectedServices}
        availableValues={dpdAvailableToSelectServices}
        {...analyticIdsDpd}
      />
      <TransferList
        leftHeader={DPD_SERVICE_LEFT_HEADER}
        rightHeader={DPD_SERVICE_RIGHT_HEADER}
        rightItemsStyles={themeStyles.item}
        onChange={(value) => {
          onChangeDpdLocal(value);
          onInputChange(value);
        }}
        selectedValues={dpdLocalSelectedServices}
        availableValues={dpdLocalAvailableToSelectServices}
        {...analyticsIdsDpdLocal}
      />
    </CustomTabs>
  );
};

ServiceExclusions.defaultProps = {
  onInputChange: () => null,
  analyticIdsDpd: {},
  analyticsIdsDpdLocal: {},
};

ServiceExclusions.propTypes = {
  onChangeDpd: PropTypes.func,
  onInputChange: PropTypes.func,
  onChangeDpdLocal: PropTypes.func,
  dpdSelectedServices: PropTypes.array,
  dpdLocalSelectedServices: PropTypes.array,
  dpdAvailableToSelectServices: PropTypes.array,
  dpdLocalAvailableToSelectServices: PropTypes.array,
  analyticIdsDpd: PropTypes.object,
  analyticsIdsDpdLocal: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dpdSelectedServices: dpdServicesFormValues(state),
  dpdLocalSelectedServices: dpdLocalServicesFormValues(state),
  dpdAvailableToSelectServices: getDpdAvailableToSelectServices(state),
  dpdLocalAvailableToSelectServices:
    getDpdLocalAvailableToSelectServices(state),
});

const analyticIdsDpd = {
  analyticIdAddAll: ANALYTICS_FOR_USER.EXCLUDED_SERVICES_ADD_ALL_DPD_SERVICES,
  analyticIdRemoveAll:
    ANALYTICS_FOR_USER.EXCLUDED_SERVICES_REMOVE_ALL_DPD_SERVICES,
  analyticIdRemove: ANALYTICS_FOR_USER.EXCLUDED_SERVICES_REMOVE_DPD_SERVICE,
  analyticIdAdd: ANALYTICS_FOR_USER.EXCLUDED_SERVICES_ADD_DPD_SERVICE,
};

const analyticsIdsDpdLocal = {
  analyticIdAddAll:
    CREATE_EDIT_CUSTOMER_USER_ANALYTICS.EXCLUDED_SERVICES_ADD_ALL_DPD_LOCAL_SERVICES,
  analyticIdRemoveAll:
    ANALYTICS_FOR_USER.EXCLUDED_SERVICES_REMOVE_ALL_DPD_LOCAL_SERVICES,
  analyticIdRemove:
    ANALYTICS_FOR_USER.EXCLUDED_SERVICES_REMOVE_DPD_LOCAL_SERVICE,
  analyticIdAdd:
    CREATE_EDIT_CUSTOMER_USER_ANALYTICS.EXCLUDED_SERVICES_ADD_DPD_LOCAL_SERVICE,
};

const mapDispatchToProps = (dispatch) => ({
  onChangeDpd: (selectedItems) =>
    dispatch(onChangeExclusions(selectedItems, "dpd")),
  onChangeDpdLocal: (selectedItems) =>
    dispatch(onChangeExclusions(selectedItems, "dpdLocal")),
});

export default compose(
  withProps({
    analyticIdsDpd,
    analyticsIdsDpdLocal,
  }),
  reduxForm({
    form: USER_FORMS.SERVICE_EXCLUSIONS,
    initialValues: {},
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(ServiceExclusions);
