import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import { USE_BAD_FILE, USE_SCREEN } from "../../constants/strings";
import {
  USE_BAD_FILE_FIELD,
  USE_SCREEN_FIELD,
} from "../../features/userDetailsForm/shippingSettings/shippingSettings.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

const FailedImport = ({ toggleCheckBox, useFields }) => {
  const { useBadFile, useScreen } = useFields;
  return (
    <Grid container spacing={4}>
      <Grid item>
        <Field
          name={USE_BAD_FILE_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          label={USE_BAD_FILE}
          onChange={() => {
            toggleCheckBox(USE_SCREEN_FIELD, useScreen, USE_BAD_FILE_FIELD);
          }}
        />
      </Grid>
      <Grid item>
        <Field
          name={USE_SCREEN_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          label={USE_SCREEN}
          onChange={() => {
            toggleCheckBox(USE_BAD_FILE_FIELD, useBadFile, USE_SCREEN_FIELD);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FailedImport;

FailedImport.defaultProps = {
  useFields: {},
};

FailedImport.propTypes = {
  toggleCheckBox: PropTypes.func.isRequired,
  useFields: PropTypes.object.isRequired,
};
