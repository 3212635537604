import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import { customerFirebase } from "../../utils/firebase";
import { checkAuth, fetchAppData } from "../auth/auth.actions";
import { createLoadingAction } from "../loader/loader.actions";
import { getCurrentUser } from "../user/user.selectors";
const auth = firebase.auth(customerFirebase);

export const launchApp = createLoadingAction(
  () => async (dispatch, getState) => {
    await dispatch(checkAuth());

    auth.onAuthStateChanged((userState) => {
      if (!userState) {
        const user = getCurrentUser(getState());

        !user && dispatch(fetchAppData());
      }
    });
  }
);
