export const DISABLE_NOTIFICATION_ON_PRINT_FIELD = "disableNotificationOnPrint";
export const READ_ONLY_ADDRESS_BOOK_FIELD = "readOnlyAddressBook";
export const READ_ONLY_PRODUCT_BOOK_FIELD = "readOnlyProductBook";
export const NO_THIRD_PARTY_COLLECTIONS_FIELD = "noThirdPartyCollections";
export const NO_MANUAL_ADDRESS_ENTRY_FIELD = "noManualAddressEntry";
export const NO_MANUAL_CONTACT_DETAILS_ENTRY_FIELD =
  "noManualContactDetailsEntry";
export const REFERENCE_1_READ_ONLY = "reference1ReadOnly";
export const FORWARD_DATE_OVER_7_DAYS_FIELD = "forwardDateOver7Days";
export const EXTENDED_HOLD_POLICY = "extendedHoldPolicy";

export const ADVANCED_CUSTOMER_CONFIGURATION_FIELDS = [
  NO_THIRD_PARTY_COLLECTIONS_FIELD,
  READ_ONLY_ADDRESS_BOOK_FIELD,
  READ_ONLY_PRODUCT_BOOK_FIELD,
  REFERENCE_1_READ_ONLY,
  NO_MANUAL_CONTACT_DETAILS_ENTRY_FIELD,
  NO_MANUAL_ADDRESS_ENTRY_FIELD,
  DISABLE_NOTIFICATION_ON_PRINT_FIELD,
  FORWARD_DATE_OVER_7_DAYS_FIELD,
  EXTENDED_HOLD_POLICY,
];
