import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { getFormSyncErrors, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { USER_DETAILS_FORMS } from "../../../constants/forms";
import {
  getDpdLocalServices,
  getDpdServices,
} from "../../config/config.selectors";
import { isDpdBusinessId } from "../../user/user.model";

export const getConfigData = (businessId) =>
  createSelector(
    getDpdServices,
    getDpdLocalServices,
    (dpdServices, dpdLocalServices) => {
      const services = isDpdBusinessId(businessId)
        ? dpdServices
        : dpdLocalServices;
      return sortBy(services, (service) => service.name);
    }
  );

export const getXrefMappingData = getFormValues(
  USER_DETAILS_FORMS.XREF_MAPPINGS
);

export const getMappingValue = createSelector(
  getXrefMappingData,
  (xrefMappingData) => get(xrefMappingData, "mapping")
);

export const getXrefMappingFromErrors = getFormSyncErrors(
  USER_DETAILS_FORMS.XREF_MAPPINGS
);

export const isMappingFieldValid = createSelector(
  getMappingValue,
  getXrefMappingFromErrors,
  (mappingValue = "", { mapping } = {}) => !mappingValue.trim() || !!mapping
);

export const getXrefMappingValues = createSelector(
  getXrefMappingData,
  (xrefMappingData) => get(xrefMappingData, "serviceXrefMappings", [])
);
