import { Box, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "redux-form";

import {
  ALLOW_OTHER_TO_EDIT_MY_SHIPMENTS,
  ALLOW_OTHER_TO_EDIT_MY_SHIPMENTS_INFO,
  INCLUDE_SHIPMENT_RACED_BY_OTHERS,
  INCLUDE_SHIPMENT_RACED_BY_OTHERS_INFO,
  SHARE_MY_SHIPMENTS_BY_ACCOUNT,
  SHARE_MY_SHIPMENTS_BY_PROFILE,
  SHARE_SHIPMENTS_BY_ACCOUNT_PROFILE_INFO,
} from "../../constants/strings";
import { deselectAllowEditShipmentsByProfile } from "../../features/userDetailsForm/miscellaneousConfiguration/miscellaneousConfiguration.actions";
import {
  ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD,
  INCLUDE_SHIPPING_BY_OTHERS_FIELD,
  SHARE_SHIPMENTS_BY_PROFILE_FIELD,
  SHARE_SHIPMENTS_FIELD,
} from "../../features/userDetailsForm/miscellaneousConfiguration/miscellaneousConfiguration.constants";
import { getMiscellaneousConfigurationFormValues } from "../../features/userDetailsForm/miscellaneousConfiguration/miscellaneousConfiguration.selectors";
import { isMasterEodExist } from "../../features/userDetailsForm/userDetailsForm.selector";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";
import styles from "./Shipments.module.scss";

const Shipments = ({
  onToggleChange,
  onInfoMouseOver,
  onChangeCheckMasterEod,
}) => {
  const {
    shareShipments,
    shareShipmentsByProfile,
    allowEditShipmentsByProfile,
  } = useSelector(getMiscellaneousConfigurationFormValues) || {};
  const masterEodExists = useSelector(isMasterEodExist);
  const dispatch = useDispatch();
  const onChangeField = (newValue) => {
    if (!newValue && allowEditShipmentsByProfile) {
      dispatch(deselectAllowEditShipmentsByProfile());
    }
  };

  return (
    <Grid container justify="space-between" className={styles.wrapper}>
      <Grid item container justify="space-between">
        <Box className={styles.item}>
          <Field
            name={SHARE_SHIPMENTS_FIELD}
            component={CustomSwitchWithTitle}
            type="checkbox"
            label={SHARE_MY_SHIPMENTS_BY_ACCOUNT}
            infoLabel={SHARE_SHIPMENTS_BY_ACCOUNT_PROFILE_INFO}
            onInfoMouseOver={onInfoMouseOver}
            disabled={shareShipmentsByProfile}
            onChange={(event, value) => {
              onToggleChange(SHARE_SHIPMENTS_FIELD, value);
            }}
          />
        </Box>
        <Box className={styles.item}>
          <Field
            name={SHARE_SHIPMENTS_BY_PROFILE_FIELD}
            component={CustomSwitchWithTitle}
            type="checkbox"
            label={SHARE_MY_SHIPMENTS_BY_PROFILE}
            onInfoMouseOver={onInfoMouseOver}
            infoLabel={SHARE_SHIPMENTS_BY_ACCOUNT_PROFILE_INFO}
            onChange={(e, newValue) => {
              onChangeCheckMasterEod(
                SHARE_SHIPMENTS_BY_PROFILE_FIELD,
                newValue
              );
              onChangeField(newValue);
            }}
            disabled={masterEodExists || shareShipments}
          />
        </Box>
        <Grid />
        <Grid item container justify="space-between" className={styles.content}>
          <Box className={styles.item}>
            <Field
              name={ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD}
              component={CustomSwitchWithTitle}
              type="checkbox"
              onInfoMouseOver={onInfoMouseOver}
              label={ALLOW_OTHER_TO_EDIT_MY_SHIPMENTS}
              infoLabel={ALLOW_OTHER_TO_EDIT_MY_SHIPMENTS_INFO}
              disabled={masterEodExists || !shareShipmentsByProfile}
              onChange={(event, value) => {
                onChangeCheckMasterEod(
                  ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD,
                  value
                );
              }}
            />
          </Box>
          <Box className={styles.item}>
            <Field
              name={INCLUDE_SHIPPING_BY_OTHERS_FIELD}
              component={CustomSwitchWithTitle}
              type="checkbox"
              onInfoMouseOver={onInfoMouseOver}
              label={INCLUDE_SHIPMENT_RACED_BY_OTHERS}
              infoLabel={INCLUDE_SHIPMENT_RACED_BY_OTHERS_INFO}
              onChange={(event, value) => {
                onChangeCheckMasterEod(INCLUDE_SHIPPING_BY_OTHERS_FIELD, value);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

Shipments.propTypes = {
  onToggleChange: PropTypes.func.isRequired,
  onInfoMouseOver: PropTypes.func.isRequired,
  onChangeIncludeShipping: PropTypes.func,
  deselectMasterEodToggle: PropTypes.func,
  isEodMasterUser: PropTypes.bool,
  onChangeCheckMasterEod: PropTypes.func.isRequired,
};

export default Shipments;
