import {
  PLEASE_ENTER_FORENAME,
  PLEASE_ENTER_LASTNAME,
  PLEASE_ENTER_VALID_DPD_EMAIL,
} from "../auth/auth.constants";
import { validateUserEmail } from "./user.model";

export const validate = ({ forename = "", lastname = "", email = "" }) => {
  const errors = {};
  if (validateUserEmail(email)) {
    errors.email = PLEASE_ENTER_VALID_DPD_EMAIL;
  }
  if (!forename.trim()) {
    errors.forename = PLEASE_ENTER_FORENAME;
  }
  if (!lastname.trim()) {
    errors.lastname = PLEASE_ENTER_LASTNAME;
  }
  return errors;
};
