import { connect } from "react-redux";
import { compose } from "recompose";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import SystemAccess from "../../../../components/SystemAccess/SystemAccess";
import { CREATE_EDIT_CUSTOMER_USER_ANALYTICS } from "../../../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../../../constants/forms";
import {
  getAdditionalAccountNumbers,
  onAccountClick,
} from "../../../../features/userDetailsForm/additionalAccounts/additionalAccounts.actions";
import { ADDITIONAL_ACCOUNTS_FIELD } from "../../../../features/userDetailsForm/additionalAccounts/additionalAccounts.constant";
import { isOwnUser } from "../../../../features/userDetailsForm/systemAccess/systemAccess.selectors";
import { ADMIN_ACCESS_FIELD } from "../../../../features/userDetailsForm/userDetails/userDetails.constants";
import { isValidUserEmail } from "../../../../features/userDetailsForm/userDetails/userDetails.selectors";
import { onAdminAccessToggle } from "../../../../features/userDetailsForm/userDetailsForm.actions";
import {
  getAdminAccess,
  getCustomerAdminAccountsInitialValues,
  isFirstCustomerAdditionalAccount,
} from "../../../../features/userDetailsForm/userDetailsForm.selector";
import withRef from "../../../../hocs/withRef";

const mapStateToProps = (state) => ({
  adminAccess: getAdminAccess(state),
  showInvalidEmailErrorMsg: !isValidUserEmail(state),
  isOwnUser: isOwnUser(state),
  accountsInitialValues: getCustomerAdminAccountsInitialValues(state),
  disableRemoveFirstAccount: isFirstCustomerAdditionalAccount(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdminAccessClick: (value) => dispatch(onAdminAccessToggle(value)),
  getAdditionalAccountNumbers: (accountNumber) =>
    dispatch(
      getAdditionalAccountNumbers(
        accountNumber,
        USER_DETAILS_FORMS.SYSTEM_ACCESS_ACCOUNTS
      )
    ),
  onAccountClick: (item) =>
    dispatch(onAccountClick(item, USER_DETAILS_FORMS.SYSTEM_ACCESS_ACCOUNTS)),
});

export default compose(
  reduxForm({
    form: USER_DETAILS_FORMS.SYSTEM_ACCESS,
    initialValues: {},
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps((props) => ({
    onAccountClick: () =>
      CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CUSTOMER_ADMIN_ACCOUNTS_ADD,
    onRemoveAccount: () =>
      CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CUSTOMER_ADMIN_ACCOUNTS_REMOVE,
    onBlurFields: (field) => {
      if (field === ADDITIONAL_ACCOUNTS_FIELD) {
        return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CUSTOMER_ADMIN_ACCOUNTS;
      }
    },
    onToggleChange: (field, value) => {
      const id = CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_TOGGLE;
      if (field === ADMIN_ACCESS_FIELD) {
        props.onAdminAccessClick(value);
      }
      return createLogEntryPayload(id, {
        field,
        value,
      });
    },
  })),
  withRef
)(SystemAccess);
