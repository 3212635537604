import { arrayPush, change } from "redux-form";

import {
  getConfigData,
  getMappingValue,
  getXrefMappingValues,
} from "./serviceXrefMapping.selectors";
import { SERVICE_XREF_MAPPINGS_FIELD } from "./serviceXrefMappings.constants";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { getUserBusinessId } from "../../user/user.selectors";
import { getBusinessId } from "../accountDetails/accountDetails.selectors";

export const addMappingRow = () => (dispatch, getState) => {
  const state = getState();
  const businessId = getUserBusinessId(state) || getBusinessId(state);
  const selectValues = getConfigData(businessId)(state);
  const mapping = getMappingValue(state);
  const mappings = getXrefMappingValues(getState());
  const newMapping = {
    mappingText: mapping,
    selectValues,
  };
  dispatch(changeXrefMapping(mappings.concat(newMapping)));
  dispatch(resetMappingField());
};

export const resetMappingField = () =>
  change(USER_DETAILS_FORMS.XREF_MAPPINGS, "mapping", "");

export const changeXrefMapping = (value) =>
  change(USER_DETAILS_FORMS.XREF_MAPPINGS, SERVICE_XREF_MAPPINGS_FIELD, value);

export const resetServiceXrefMapping = () => (dispatch) =>
  dispatch(changeXrefMapping([]));

export const addNewMapping = (mapping, selectValues) =>
  arrayPush(USER_DETAILS_FORMS.XREF_MAPPINGS, SERVICE_XREF_MAPPINGS_FIELD, {
    mappingText: mapping,
    selectValues,
  });

export const onSelectMapping = (index, serviceCode) => (dispatch, getState) => {
  const mappings = getXrefMappingValues(getState()).slice();

  mappings[index] = { ...mappings[index], serviceCode };

  dispatch(changeXrefMapping(mappings));
};

export const onRemoveMapping = (deleteIndex) => (dispatch, getState) => {
  const mappings = getXrefMappingValues(getState()).slice();

  mappings.splice(deleteIndex, 1);

  dispatch(changeXrefMapping(mappings));
};
