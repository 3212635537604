import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import styles from "./TabTitleWithIcon.module.scss";

const TabTitleWithIcon = ({ icon, title }) => (
  <div className={styles["tab-icon"]}>
    <img src={icon} height={35} width={35} />
    <Typography variant="inherit">{title}</Typography>
  </div>
);

TabTitleWithIcon.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
};

export default TabTitleWithIcon;
