import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./SimulateTabTitle.module.scss";

const useThemeColorStyles = makeStyles((theme) => ({
  active: () => ({
    borderBottomColor: `${theme.palette.primary.main} !important`,
  }),
}));

const SimulateTabTitle = ({ title, containerStyle = {} }) => {
  const activeTabStyle = useThemeColorStyles(useTheme());
  return (
    <div className={classNames(styles.container, containerStyle)}>
      <Typography className={activeTabStyle.active}>{title}</Typography>
    </div>
  );
};

SimulateTabTitle.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  containerStyle: PropTypes.object,
};

export default SimulateTabTitle;
