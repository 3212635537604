import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import HashIcon from "../../../../components/HashIcon/HashIcon";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import { ACCOUNT_NUMBER } from "../../../../constants/strings";
import { DPD_LOCAL_ACCOUNT_NUMBER_FIELD } from "../../../../features/profile/profile.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import { onlyNumbers } from "../../../../utils/normalize";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

export const ProfileAccountDpdLocal = ({ onTextEntry }) => (
  <Field
    name={DPD_LOCAL_ACCOUNT_NUMBER_FIELD}
    component={DecoratedFieldWithError}
    label={ACCOUNT_NUMBER}
    icon={<HashIcon />}
    inputProps={{ maxLength: 7 }}
    normalize={onlyNumbers}
    onBlur={(e, value) => {
      onTextEntry(DPD_LOCAL_ACCOUNT_NUMBER_FIELD, value);
    }}
  />
);

ProfileAccountDpdLocal.propTypes = {
  onTextEntry: PropTypes.func,
};

export default compose(
  withTrackProps(() => ({
    onTextEntry: (field, value) =>
      createLogEntryPayload(PROFILE_ANALYTICS.TEXT_ENTRY, {
        field,
        value,
      }),
  }))
)(ProfileAccountDpdLocal);
