import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";

import styles from "./ExpandCollapseAll.module.scss";
import downIcon from "../../assets/icons/down-arrow.svg";
import { COLLAPSE_ALL, EXPAND_ALL } from "../../constants/strings";

const ExpandCollapseAll = ({ onExpandAllClick, onCollapseAllClick }) => (
  <>
    <ListItem
      button
      classes={{ root: styles.container }}
      onClick={onExpandAllClick}
    >
      <ListItemText
        primary={EXPAND_ALL}
        classes={{ primary: styles.primary }}
      />
      <img src={downIcon} alt="arrow-down" className={styles["arrow-down"]} />
    </ListItem>
    <ListItem
      button
      classes={{ root: styles.container }}
      onClick={onCollapseAllClick}
    >
      <ListItemText
        primary={COLLAPSE_ALL}
        classes={{ primary: styles.primary }}
      />
      <img src={downIcon} alt="arrow-up" className={styles["arrow-up"]} />
    </ListItem>
  </>
);

ExpandCollapseAll.propTypes = {
  label: PropTypes.string,
  onExpandAllClick: PropTypes.func,
  onCollapseAllClick: PropTypes.func,
  disabled: PropTypes.bool,
  arrowUp: PropTypes.bool,
};

export default ExpandCollapseAll;
