import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import HashIcon from "../../../../components/HashIcon/HashIcon";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import CustomSwitchWithTitle from "../../../../components/SwitchWidthTitle/CustomSwitchWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  CLASSIC_ACCOUNT_NUMBER,
  CLASSIC_SLID,
  DIRECT_ACCOUNT_NUMBER,
  DIRECT_SLID,
  DPD,
  DPD_LOCAL,
  EXPRESS_ACCOUNT_NUMBER,
  EXPRESS_SLID,
} from "../../../../constants/strings";
import { onChangeInternationalAccountToggle } from "../../../../features/profile/profile.actions";
import {
  INTERNATIONAL_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_CLASSIC_SLID_FIELD,
  INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_DIRECT_SLID_FIELD,
  INTERNATIONAL_DPD_LOCAL_TOGGLE_FIELD,
  INTERNATIONAL_DPD_TOGGLE_FIELD,
  INTERNATIONAL_EXPRESS_SLID_FIELD,
} from "../../../../features/profile/profile.constants";
import { getInternationalDpdValue } from "../../../../features/profile/profile.selectors";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import { onlyNumbers } from "../../../../utils/normalize";
import styles from "./ProfileInternationalAccount.module.scss";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const ProfileInternationalAccount = ({
  internationalDpd,
  onTextEntry,
  onToggleChange,
}) => (
  <Grid container className={styles.container}>
    <Grid
      container
      justify="space-between"
      className={styles["switch-container"]}
      item
    >
      <Field
        name={INTERNATIONAL_DPD_TOGGLE_FIELD}
        component={CustomSwitchWithTitle}
        label={DPD}
        type="checkbox"
        onChange={(event, newValue) => {
          onToggleChange(INTERNATIONAL_DPD_TOGGLE_FIELD, newValue);
        }}
      />
      <Field
        name={INTERNATIONAL_DPD_LOCAL_TOGGLE_FIELD}
        component={CustomSwitchWithTitle}
        label={DPD_LOCAL}
        type="checkbox"
        onChange={(event, newValue) => {
          onToggleChange(INTERNATIONAL_DPD_LOCAL_TOGGLE_FIELD, newValue);
        }}
      />
    </Grid>
    <Grid container item>
      <Grid container item>
        <Grid item className={styles["account-number"]}>
          <Field
            name={INTERNATIONAL_ACCOUNT_NUMBER_FIELD}
            component={DecoratedFieldWithError}
            label={EXPRESS_ACCOUNT_NUMBER}
            icon={<HashIcon />}
            inputProps={{ maxLength: 7 }}
            normalize={onlyNumbers}
            onBlur={(e, value) => {
              onTextEntry(INTERNATIONAL_ACCOUNT_NUMBER_FIELD, value);
            }}
            required
          />
        </Grid>
      </Grid>
      <Grid container item direction="row" justify="space-between" wrap="wrap">
        <Grid item className={styles["items-container"]}>
          <Field
            name={INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD}
            component={DecoratedFieldWithError}
            label={CLASSIC_ACCOUNT_NUMBER}
            inputProps={{ maxLength: 7 }}
            normalize={onlyNumbers}
            required
            onBlur={(e, value) => {
              onTextEntry(INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD, value);
            }}
          />
        </Grid>
        <Grid item className={styles["items-container"]}>
          <Field
            name={INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD}
            component={DecoratedFieldWithError}
            label={DIRECT_ACCOUNT_NUMBER}
            inputProps={{ maxLength: 7 }}
            normalize={onlyNumbers}
            onBlur={(e, value) => {
              onTextEntry(INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD, value);
            }}
            required
          />
        </Grid>
        {internationalDpd && (
          <>
            <Grid item className={styles["items-container"]}>
              <Field
                name={INTERNATIONAL_EXPRESS_SLID_FIELD}
                component={DecoratedFieldWithError}
                label={EXPRESS_SLID}
                inputProps={{ maxLength: 4 }}
                normalize={onlyNumbers}
                onBlur={(e, value) => {
                  onTextEntry(INTERNATIONAL_EXPRESS_SLID_FIELD, value);
                }}
              />
            </Grid>
            <Grid item className={styles["items-container"]}>
              <Field
                name={INTERNATIONAL_CLASSIC_SLID_FIELD}
                component={DecoratedFieldWithError}
                label={CLASSIC_SLID}
                inputProps={{ maxLength: 4 }}
                normalize={onlyNumbers}
                onBlur={(e, value) => {
                  onTextEntry(INTERNATIONAL_CLASSIC_SLID_FIELD, value);
                }}
              />
            </Grid>
            <Grid item className={styles["items-container"]}>
              <Field
                name={INTERNATIONAL_DIRECT_SLID_FIELD}
                component={DecoratedFieldWithError}
                label={DIRECT_SLID}
                inputProps={{ maxLength: 4 }}
                normalize={onlyNumbers}
                onBlur={(e, value) => {
                  onTextEntry(INTERNATIONAL_DIRECT_SLID_FIELD, value);
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  </Grid>
);

ProfileInternationalAccount.propTypes = {
  onTextEntry: PropTypes.func,
  onToggleChange: PropTypes.func,
  internationalDpd: PropTypes.bool,
  // https://geopost.jira.com/browse/CUP-2958 do not remove props activeTabIndex and childActiveTabIndex
  activeTabIndex: PropTypes.number.isRequired,
  childActiveTabIndex: PropTypes.number.isRequired,
};

export default compose(
  connect(
    (state) => ({
      internationalDpd: getInternationalDpdValue(state),
    }),
    {
      onToggleChange: onChangeInternationalAccountToggle,
    }
  ),
  withTrackProps(() => ({
    onTextEntry: (field, value) =>
      createLogEntryPayload(PROFILE_ANALYTICS.TEXT_ENTRY, {
        field,
        value,
      }),
    onToggleChange: (field, value) =>
      createLogEntryPayload(PROFILE_ANALYTICS.CLICK_TOGGLE, {
        field,
        value,
      }),
  }))
)(ProfileInternationalAccount);
