import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";

import { LOGIN_PAGE } from "../../../router";
import { hasAnyRole } from "../../../utils/roles";
import { getCurrentUser, getCurrentUserRoles } from "../../user/user.selectors";
import { isAuthChecked } from "../auth.selectors";

const AuthRoute = ({
  reverse,
  fallbackPath = LOGIN_PAGE,
  roles = [],
  ...props
}) => {
  const isAuthenticated = Boolean(useSelector(getCurrentUser));
  const userRoles = useSelector(getCurrentUserRoles);
  const isAuthCheckingFinished = useSelector(isAuthChecked);
  const isRolesMatch = !roles.length || hasAnyRole(roles)({ userRoles });
  const isAuthorized = isAuthenticated && isRolesMatch;
  const shouldRedirect = !(reverse ^ isAuthorized) && isAuthCheckingFinished;

  if (shouldRedirect) {
    return <Route render={() => <Redirect to={fallbackPath} />} />;
  }

  return <Route {...props} />;
};

AuthRoute.propTypes = {
  reverse: PropTypes.bool,
  fallbackPath: PropTypes.string,
  adminOnly: PropTypes.bool,
  roles: PropTypes.array,
};

export default AuthRoute;
