import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

import styles from "./ConfirmButton.module.scss";

const ConfirmButton = ({ text, onClick, themeColor, ...otherProps }) => (
  <Button
    color={themeColor}
    className={styles.button}
    onClick={onClick}
    {...otherProps}
  >
    {text}
  </Button>
);

export default ConfirmButton;

ConfirmButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  themeColor: PropTypes.string,
};

ConfirmButton.defaultProps = {
  text: "Confirm",
  themeColor: "primary",
};
