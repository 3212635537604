import { change } from "redux-form";

import {
  ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD,
  SHARE_SHIPMENTS_BY_PROFILE_FIELD,
  SHARE_SHIPMENTS_FIELD,
} from "./miscellaneousConfiguration.constants";
import { USER_DETAILS_FORMS } from "../../../constants/forms";

const changeMiscellaneousConfField = (field, value) =>
  change(USER_DETAILS_FORMS.MISCELLANEOUS_CONFIGURATION, field, value);

export const deselectAllowEditShipmentsByProfile = () => (dispatch) =>
  dispatch(
    changeMiscellaneousConfField(ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD, false)
  );

export const enableMiscellaneousEodFields = () => (dispatch) => {
  dispatch(changeMiscellaneousConfField(SHARE_SHIPMENTS_FIELD, false));
  dispatch(
    changeMiscellaneousConfField(SHARE_SHIPMENTS_BY_PROFILE_FIELD, true)
  );
  dispatch(
    changeMiscellaneousConfField(ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD, true)
  );
};
