import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { Field, isDirty, isValid, reduxForm } from "redux-form";

import styles from "./CreateMasterUserModal.module.scss";
import { ReactComponent as Locked } from "../../../assets/icons/locked.svg";
import { ReactComponent as Password } from "../../../assets/icons/password.svg";
import { ReactComponent as Unlocked } from "../../../assets/icons/unlocked.svg";
import { CREATE_MASTER_USER_FORM } from "../../../constants/forms";
import {
  CONFIRM_PASSWORD,
  CREATE_MASTER_USER,
  NOTE_EXPIRY_USER,
  PASSWORD,
  USERNAME,
} from "../../../constants/strings";
import { initializeForm } from "../../../features/createMasterUserForm/createMasterUser.actions";
import {
  MASTER_CONFIRM_PASSWORD_FIELD,
  MASTER_PASSWORD_FIELD,
  MASTER_PASSWORD_LOCK_FIELD,
  MASTER_USERNAME_FIELD,
} from "../../../features/createMasterUserForm/createMasterUser.constants";
import { getExpiryDate } from "../../../features/createMasterUserForm/createMasterUser.models";
import { getPasswordStrengthScore } from "../../../features/createMasterUserForm/createMasterUser.selectors";
import { validate } from "../../../features/createMasterUserForm/createMasterUser.validate";
import { WIDTH_TYPE } from "../../../features/modal/modal.constants";
import withErrorTooltip from "../../../hocs/withErrorTooltip";
import { CheckBox } from "../../CheckBox/CheckBox";
import InputWithTitle from "../../InputWithTitle/InputWithTitle";
import PasswordStrengthMeter from "../../PasswordStrengthMeter/PasswordStrengthMeter";
import SvgIconComponent from "../../SvgIcon/svgIcon";
import WarningText from "../../WarningText/WarningText";
import BaseModal from "../BaseModal";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const CreateMasterUserModal = ({
  passwordStrength,
  isFormValid,
  isFormDirty,
  ...otherProps
}) => (
  <BaseModal
    widthType={WIDTH_TYPE.MEDIUM}
    title={CREATE_MASTER_USER}
    alignButton="right"
    titleStyles={styles.titleStyles}
    disabledConfirmButton={!isFormValid || !isFormDirty}
    {...otherProps}
  >
    <Box className={styles.contentWrapper}>
      <Box>
        <Field
          name={MASTER_USERNAME_FIELD}
          component={DecoratedFieldWithError}
          label={USERNAME}
          icon={<SvgIconComponent name="profile" />}
          props={{
            disabled: true,
          }}
          inputProps={{ maxLength: 100 }}
        />
      </Box>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={styles.newPasswordContainer}
      >
        <Grid item className={styles.newPassword}>
          <Field
            name={MASTER_PASSWORD_FIELD}
            component={DecoratedFieldWithError}
            label={PASSWORD}
            required
            icon={<Password />}
            adornmentStyles={{ adornmentIcon: styles.adornmentIcon }}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>

        <Grid item>
          <Field
            name={MASTER_PASSWORD_LOCK_FIELD}
            component={CheckBox}
            type="checkbox"
            labelClasses={{ root: styles["checkbox-label"] }}
            icon={<Unlocked />}
            checkedIcon={<Locked />}
            props={{
              disabled: true,
            }}
          />
        </Grid>
        <Grid item className={styles.passwordIndicator}>
          {isFormDirty ? (
            <PasswordStrengthMeter passwordStrengthScore={passwordStrength} />
          ) : null}
        </Grid>
      </Grid>
      <Field
        name={MASTER_CONFIRM_PASSWORD_FIELD}
        component={DecoratedFieldWithError}
        label={CONFIRM_PASSWORD}
        required
        icon={<SvgIconComponent name="password" />}
        inputProps={{ maxLength: 100 }}
        adornmentStyles={{ adornmentIcon: styles.adornmentIcon }}
      />
    </Box>
    <WarningText
      text={`${NOTE_EXPIRY_USER} ${getExpiryDate()}`}
      customClass={styles.warning}
    />
  </BaseModal>
);

CreateMasterUserModal.propTypes = {
  isFormValid: PropTypes.bool,
  isFormDirty: PropTypes.bool,
  passwordStrength: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isFormValid: isValid(CREATE_MASTER_USER_FORM)(state),
  isFormDirty: isDirty(CREATE_MASTER_USER_FORM)(state),
  passwordStrength: getPasswordStrengthScore(state),
});

const mapDispatchToProps = {
  initializeForm,
};

export default compose(
  reduxForm({
    form: CREATE_MASTER_USER_FORM,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.initializeForm();
    },
  })
)(CreateMasterUserModal);
