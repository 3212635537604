import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { compose, withState } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import styles from "./ActivityListItem.module.scss";
import AuditLogDetailsItem from "./AuditLogDetailsItem";
import { ReactComponent as DownIcon } from "../../../../assets/icons/down-arrow.svg";
import { ACTIVITY_MONITOR_ANALYTICS } from "../../../../constants/analytics";
import { HIDE_DETAILS, SEE_DETAILS } from "../../../../constants/strings";
import { getAuditLogDescription } from "../../../../features/activityMonitor/activityMonitor.model";
import { formatDate } from "../../../../utils/timeFormater";

const ActivityListItem = ({ auditLog, showDescription, toggleDetailsBtn }) => {
  const userSettings =
    auditLog.userSettings && JSON.parse(auditLog.userSettings);
  return (
    <Box classes={{ root: styles.container }}>
      <div className={styles.wrapContainer}>
        <Typography component="div" classes={{ root: styles.time }}>
          {formatDate(auditLog.date, "HH:mm")}
        </Typography>
        <Typography component="div" classes={{ root: styles.action }}>
          <div className={styles.auditLog}>
            {getAuditLogDescription({ ...auditLog, userSettings })}
          </div>
          <div className={styles.expandBtn}>
            {userSettings && !isEmpty(userSettings) && (
              <div className={styles.expandBtn} onClick={toggleDetailsBtn}>
                <span>{showDescription ? HIDE_DETAILS : SEE_DETAILS}</span>
                <DownIcon
                  className={classNames(
                    styles.arrow,
                    showDescription && styles.upArrow
                  )}
                />
              </div>
            )}
          </div>
        </Typography>
      </div>
      {showDescription && (
        <Box classes={{ root: styles.logDescriptionContainer }}>
          {Object.entries(userSettings).map(([sectionName, sectionValues]) => (
            <AuditLogDetailsItem
              key={sectionName}
              sectionName={sectionName}
              settingsValues={sectionValues}
            />
          ))}
        </Box>
      )}
      <Divider classes={{ root: styles.divider }} />
    </Box>
  );
};

ActivityListItem.propTypes = {
  auditLog: PropTypes.object,
  toggleDetailsBtn: PropTypes.func,
  showDescription: PropTypes.bool,
};

export default compose(
  withState("showDescription", "setDescriptionVisibility", false),
  withTrackProps((props) => ({
    toggleDetailsBtn: () => {
      props.setDescriptionVisibility(!props.showDescription);
      if (props.showDescription) {
        return ACTIVITY_MONITOR_ANALYTICS.CLICK_HIDE_DETAILS;
      }
      return ACTIVITY_MONITOR_ANALYTICS.CLICK_SEE_DETAILS;
    },
  }))
)(ActivityListItem);
