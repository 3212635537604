import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ReactComponent as Admin } from "../../../../assets/icons/admin.svg";
import { ReactComponent as CheckboxIcon } from "../../../../assets/icons/checkbox.svg";
import { ReactComponent as CheckBoxWithTick } from "../../../../assets/icons/ticked-box.svg";
import { ACCOUNT_IS_SUSPENDED } from "../../../../constants/strings";
import {
  getCreatedLabel,
  getExpiryLabel,
  isCustomerAdmin,
} from "../../../../features/user/user.model";
import styles from "./TableRow.module.scss";

const getLabel = (user, showCreatedDate) => {
  if (showCreatedDate) {
    return (user.created && getCreatedLabel(user.created)) || "";
  } else if (user.accountSuspended) {
    return ACCOUNT_IS_SUSPENDED;
  } else if (user.accountExpiry) {
    return getExpiryLabel(user.accountExpiry);
  }
};

const UserTableRow = ({
  user,
  isItemSelected,
  onDeletePress,
  currentUserId,
  onEditUser,
  onToggleSuspendUser,
  onToggleResendInvite,
  handleClick,
  customerId,
  ToolsComponent,
  showCreatedDate,
  onRowHover,
}) => {
  const isAdminUser = isCustomerAdmin(user);
  const cellClass = classNames(
    styles["name-cell"],
    user.accountSuspended && styles.suspended
  );
  return (
    <TableRow
      key={user.uid}
      className={styles["table-row"]}
      onMouseEnter={onRowHover}
    >
      <TableCell className={styles.iconContainer}>
        <Checkbox
          checked={isItemSelected}
          onChange={() => handleClick(user.uid)}
          icon={<CheckboxIcon />}
          checkedIcon={<CheckBoxWithTick />}
          disabled={isAdminUser}
          className={classNames(isAdminUser && styles["admin-checkbox"])}
        />
        {isAdminUser && <Admin className={styles.icon} />}
      </TableCell>
      <TableCell className={cellClass}>{user.lastname}</TableCell>
      <TableCell className={cellClass}>{user.forename}</TableCell>
      <TableCell
        className={classNames(
          styles["login-cell"],
          user.accountSuspended && styles.suspended
        )}
      >
        <div>{user.username}</div>
        <div
          className={classNames(
            styles.expired,
            user.accountSuspended && styles["suspended-text"]
          )}
        >
          {getLabel(user, showCreatedDate)}
        </div>
      </TableCell>
      <TableCell className={styles.tools}>
        <div className={classNames(styles.tools, styles["tools-visibility"])}>
          <ToolsComponent
            id={user.uid}
            user={user}
            onSuspendPress={onToggleSuspendUser}
            onResendInvite={onToggleResendInvite}
            onDeletePress={onDeletePress}
            onEditUser={onEditUser}
            accountSuspended={user.accountSuspended}
            customerId={customerId}
            adminUser={isAdminUser}
            resendInvite={user.resendInvite}
            showResendInviteButton={!!user.isTemp}
            showEditButton={!isAdminUser || currentUserId === user.uid}
            showDeleteButton={!isAdminUser}
            showSuspendButton={!isAdminUser}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

UserTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  onDeletePress: PropTypes.func.isRequired,
  onToggleSuspendUser: PropTypes.func.isRequired,
  onEditUser: PropTypes.func.isRequired,
  onToggleResendInvite: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  currentUserId: PropTypes.string,
  showCreatedDate: PropTypes.bool,
  ToolsComponent: PropTypes.any.isRequired,
  onRowHover: PropTypes.func,
};

export default UserTableRow;
