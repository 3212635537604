import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import {
  actions,
  button,
  field,
  fields,
  form,
  formTitle,
} from "./LoginEmailForm.module.scss";
import { validate } from "./LoginEmailForm.validate";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/username.svg";
import LoginInput from "../../../../components/LoginInput";
import { LOGIN_EMAIL_FORM } from "../../../../constants/forms";
import { EMAIL_ADDRESS } from "../../../../constants/strings";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import LoginSubmitButton from "../LoginSubmitButton";

const DecoratedFieldWithError = withErrorTooltip(LoginInput);

const LoginEmailForm = ({
  handleSubmit,
  onSubmitPress,
  title,
  buttonText,
  titleStyle,
  buttonStyle,
}) => (
  <form className={form}>
    <h4 className={classNames(formTitle, titleStyle)}>{title}</h4>
    <div className={fields}>
      <div className={field}>
        <Field
          name="email"
          component={DecoratedFieldWithError}
          icon={<EmailIcon />}
          placeholder={EMAIL_ADDRESS}
        />
      </div>
    </div>

    <Grid container className={classNames(actions, buttonStyle)}>
      <Grid item sm={5} className={button}>
        <LoginSubmitButton onClick={handleSubmit(onSubmitPress)}>
          {buttonText}
        </LoginSubmitButton>
      </Grid>
    </Grid>
  </form>
);

LoginEmailForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  titleStyle: PropTypes.string,
  buttonStyle: PropTypes.string,
  onSubmitPress: PropTypes.func,
};

export default reduxForm({
  initialValues: {
    email: "",
  },
  validate,
  form: LOGIN_EMAIL_FORM,
})(LoginEmailForm);
