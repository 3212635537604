import { ACTIONS } from "./tabs.actions";
import { INITIAL_STATE } from "./tabs.constants";

export const initialState = {
  tabsState: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_TABS_STATE:
      return {
        ...state,
        tabsState: action.payload,
      };
    case ACTIONS.SET_TABS_TO_INITIAL:
      return {
        ...state,
        tabsState: INITIAL_STATE,
      };
    case ACTIONS.CLEAR:
      return initialState;
    default:
      return state;
  }
};
