import { clearSubmitErrors, reset } from "redux-form";

import { USER_DETAILS_FORMS, USER_FORMS } from "../../../constants/forms.js";
import { isEmailValid } from "../../../utils/commonValidation.js";
import { getUserCustomerAccounts } from "../../customer/customer.selectors.js";
import { getUserCustomerAccountById } from "../../userDetailsForm/systemAccess/systemAccess.models.js";
import { getUserId } from "../../userDetailsForm/userDetails/userDetails.selectors.js";
import { setAccountToAdminAccess } from "../../userDetailsForm/userDetailsForm.actions.js";
import {
  clearPasswordSection,
  clearUserExpiryDate,
  triggerUseEmailAsLogin,
  updateLoginFieldWithEmailValue,
} from "../userDetails/userDetails.actions.js";
import { getEmailAddress } from "../userDetails/userDetails.selectors";

export const switchAdminAccess =
  (value, selectedAccount) => (dispatch, getState) => {
    const state = getState();
    const email = getEmailAddress(state);
    const userId = getUserId(state);

    if (value) {
      if (!userId && isEmailValid(email)) {
        dispatch(updateLoginFieldWithEmailValue(email));
      }
      dispatch(clearPasswordSection());
      dispatch(clearUserExpiryDate());
      dispatch(setAccountToAdminAccess(selectedAccount));
    }

    !userId && dispatch(triggerUseEmailAsLogin(value));
    !value && dispatch(clearSubmitErrors(USER_FORMS.DPD_USER_DETAILS));
  };

export const updateAdminAccessCustomerAccountByID =
  (accountId) => (dispatch, getState) => {
    const customerAccounts = getUserCustomerAccounts(getState());
    dispatch(reset(USER_DETAILS_FORMS.SYSTEM_ACCESS_ACCOUNTS));
    dispatch(
      setAccountToAdminAccess(
        getUserCustomerAccountById(accountId, customerAccounts)
      )
    );
  };
