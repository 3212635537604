import { useEffect } from "react";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { useSelector } from "react-redux";

import { getAppTheme } from "../features/app/app.selectors";

const ThemeProvider = (props) => {
  const themeParams = useSelector(getAppTheme);
  const theme = createMuiTheme(themeParams);

  useEffect(() => {
    document.getElementById(
      "favicon"
    ).href = `${window.location.origin}/${theme.favicon}`;
  });

  return <MuiThemeProvider theme={theme} {...props} />;
};

export default ThemeProvider;
