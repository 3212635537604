import { isEmpty } from "lodash";
import { SubmissionError } from "redux-form";

import {
  ASYNC_VALIDATE_ERRORS,
  CREATE_ACCOUNT_NUMBER_FIELD,
  DEFAULT_WEIGHT_FIELD,
  DPD_ACCOUNT_LENGTH,
  UK_IMS_NUMBER_LENGTH,
  DPD_ACCOUNT_NUMBER_FIELD,
  DPD_LOCAL_ACCOUNT_LENGTH,
  DPD_LOCAL_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_DPD_FIELD,
  INTERNATIONAL_DPD_LOCAL_FIELD,
  DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD as INTERNATIONAL_OUT_SERVICE,
  INV_TELEPHONE_NUMBER_FIELD,
  INV_UK_IMS_NUMBER_HEADER,
  DEFAULT_DPD_LOCAL_INTERNATIONAL_OUTBOUND_SERVICE_FIELD as LOCAL_INTERNATIONAL_OUT_SERVICE,
  PROFILE_CODE_FIELD,
  TELEPHONE_FIELD,
  USE_MY_DPD_ACCOUNT_SETTINGS_FIELD,
} from "./profile.constants";
import {
  defaultsDpdLocalRequiredFields,
  defaultsRequiredFields,
  fieldLabels,
  isLocalDpdAccount,
} from "./profile.models";
import * as profileService from "./profile.service";
import { PHONE_NUMBER_REGEX } from "../../constants/regex";
import {
  ACCOUNT_NUMBER,
  ENTERED_WEIGHT_HAS_TO_BE_AN_INTEGER,
  INVALID_PHONE_NOT_A_VALID_PHONE_NUMBER,
  OUTBOUND_SERVICE,
  PLEASE_ENTER_A_VALID_VALUE_THE_TWO_NEAREST_VALID_VALUES,
  UK_IMS_NUMBER_LENGTH_MUST_BE,
} from "../../constants/strings";
import { formatMessage } from "../../utils/string";
import { FIELD_CANT_BE_EMPTY } from "../auth/auth.constants";
import {
  ADMIN_ACCOUNT,
  INVALID_ACCOUNT_CUSTOMER,
  INVALID_ACCOUNT_NUMBER,
} from "../customer/customer.constants";
import * as customerService from "../customer/customer.service";
import { getCustomerByAccountNumber } from "../customer/customer.service";
import {
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
} from "../userDetailsForm/accountDetails/accountDetails.constants";

export const asyncValidate = async (props) => {
  const businessId =
    props[CREATE_ACCOUNT_NUMBER_FIELD].length !== DPD_ACCOUNT_LENGTH ? 2 : 1;
  const customer = await getCustomerByAccountNumber(
    props[CREATE_ACCOUNT_NUMBER_FIELD],
    businessId
  );
  if (isEmpty(customer)) {
    // eslint-disable-next-line no-throw-literal
    throw {
      [CREATE_ACCOUNT_NUMBER_FIELD]: INVALID_ACCOUNT_CUSTOMER,
    };
  }
};

export const validate = (props) => {
  const errors = {};
  let labels = {};
  const telephone = props[TELEPHONE_FIELD];
  const phone = props[INV_TELEPHONE_NUMBER_FIELD];
  const ukImsNumber = props[INV_UK_IMS_NUMBER_HEADER];

  if (telephone && !PHONE_NUMBER_REGEX.test(telephone)) {
    errors[TELEPHONE_FIELD] = INVALID_PHONE_NOT_A_VALID_PHONE_NUMBER;
  }

  if (phone && !PHONE_NUMBER_REGEX.test(phone)) {
    errors[INV_TELEPHONE_NUMBER_FIELD] = INVALID_PHONE_NOT_A_VALID_PHONE_NUMBER;
  }

  if (ukImsNumber && ukImsNumber.length !== UK_IMS_NUMBER_LENGTH) {
    errors[INV_UK_IMS_NUMBER_HEADER] = UK_IMS_NUMBER_LENGTH_MUST_BE;
  }

  if (
    props[CREATE_ACCOUNT_NUMBER_FIELD] !== ADMIN_ACCOUNT &&
    props[CREATE_ACCOUNT_NUMBER_FIELD] &&
    props[CREATE_ACCOUNT_NUMBER_FIELD].length !== DPD_ACCOUNT_LENGTH &&
    props[CREATE_ACCOUNT_NUMBER_FIELD].length !== DPD_LOCAL_ACCOUNT_LENGTH
  ) {
    errors[CREATE_ACCOUNT_NUMBER_FIELD] = INVALID_ACCOUNT_NUMBER;
  }

  if (!props[USE_MY_DPD_ACCOUNT_SETTINGS_FIELD]) {
    if (!isLocalDpdAccount(props[CREATE_ACCOUNT_NUMBER_FIELD])) {
      labels = { ...defaultsRequiredFields };
    } else {
      labels = { ...defaultsDpdLocalRequiredFields };
    }

    if (props[INTERNATIONAL_DPD_LOCAL_FIELD]) {
      labels[LOCAL_INTERNATIONAL_OUT_SERVICE] = OUTBOUND_SERVICE;
    }

    if (props[INTERNATIONAL_DPD_FIELD]) {
      labels[INTERNATIONAL_OUT_SERVICE] = OUTBOUND_SERVICE;
    }
  }

  const {
    dpdDomesticAccountNumber,
    dpdDomesticOutboundSlid,
    dpdDomesticExchangeOutboundSlid,
    dpdDomesticExchangeInboundSlid,
  } = props;

  const isDpdAccountSlidPopulated =
    dpdDomesticOutboundSlid ||
    dpdDomesticExchangeOutboundSlid ||
    dpdDomesticExchangeInboundSlid;

  if (isDpdAccountSlidPopulated && !dpdDomesticAccountNumber) {
    errors[DPD_ACCOUNT_NUMBER_FIELD] = formatMessage(
      FIELD_CANT_BE_EMPTY,
      ACCOUNT_NUMBER
    );
  }

  if (props[DEFAULT_WEIGHT_FIELD]) {
    const dirtyValue = props[DEFAULT_WEIGHT_FIELD].replace(",", ".");

    if (isNaN(dirtyValue)) {
      errors[DEFAULT_WEIGHT_FIELD] = ENTERED_WEIGHT_HAS_TO_BE_AN_INTEGER;
    } else if (!Number.isInteger(parseFloat(dirtyValue))) {
      const wholeNumber = Math.floor(dirtyValue);
      errors[DEFAULT_WEIGHT_FIELD] = formatMessage(
        PLEASE_ENTER_A_VALID_VALUE_THE_TWO_NEAREST_VALID_VALUES,
        wholeNumber,
        wholeNumber + 1
      );
    }
  }
  const fields = { ...fieldLabels, ...labels };

  const invalidFields = Object.keys(fields).reduce((acc, key) => {
    if (!props[key] || !props[key].trim()) {
      acc[key] = formatMessage(FIELD_CANT_BE_EMPTY, fields[key]);
    }

    return acc;
  }, {});

  return { ...errors, ...invalidFields };
};

const getMainAccountBusinessId = (accountNumber) =>
  accountNumber.length === DPD_ACCOUNT_LENGTH || accountNumber === ADMIN_ACCOUNT
    ? DPD_BUSINESS_ID
    : DPD_LOCAL_BUSINESS_ID;

const getBusinessIdForValidation = (formKey, value, businessUnit) => {
  if (formKey === CREATE_ACCOUNT_NUMBER_FIELD) {
    return getMainAccountBusinessId(value);
  } else if (formKey === DPD_ACCOUNT_NUMBER_FIELD) {
    return DPD_BUSINESS_ID;
  } else if (formKey === DPD_LOCAL_ACCOUNT_NUMBER_FIELD) {
    return DPD_LOCAL_BUSINESS_ID;
  }
  return businessUnit;
};

export const createProfileAsyncValidate = async (formValues, id) => {
  const fieldsValue = {
    [CREATE_ACCOUNT_NUMBER_FIELD]: formValues[CREATE_ACCOUNT_NUMBER_FIELD],
    [INTERNATIONAL_ACCOUNT_NUMBER_FIELD]:
      formValues[INTERNATIONAL_ACCOUNT_NUMBER_FIELD],
    [INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD]:
      formValues[INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD],
    [INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD]:
      formValues[INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD],
  };

  const businessUnit = formValues[INTERNATIONAL_DPD_FIELD]
    ? DPD_BUSINESS_ID
    : DPD_LOCAL_BUSINESS_ID;

  if (formValues[DPD_LOCAL_ACCOUNT_NUMBER_FIELD]) {
    fieldsValue[DPD_LOCAL_ACCOUNT_NUMBER_FIELD] =
      formValues[DPD_LOCAL_ACCOUNT_NUMBER_FIELD];
  }

  if (formValues[DPD_ACCOUNT_NUMBER_FIELD]) {
    fieldsValue[DPD_ACCOUNT_NUMBER_FIELD] =
      formValues[DPD_ACCOUNT_NUMBER_FIELD];
  }

  const accountNumbers = {};
  await Object.keys(fieldsValue).reduce(
    (promise, key) =>
      promise.then(() =>
        customerService
          .getCustomerByAccountNumber(
            fieldsValue[key],
            getBusinessIdForValidation(key, fieldsValue[key], businessUnit)
          )
          .then((customer) => {
            if (customer) {
              accountNumbers[key] = customer.account;
            }
          })
          .catch(() => {
            throw new SubmissionError({
              [key]: ASYNC_VALIDATE_ERRORS[key],
            });
          })
      ),
    Promise.resolve()
  );

  if (
    Object.keys(accountNumbers).length === Object.keys(fieldsValue).length &&
    !id
  ) {
    const isProfileExist = await profileService.isProfileExist({
      [CREATE_ACCOUNT_NUMBER_FIELD]: parseInt(
        formValues[CREATE_ACCOUNT_NUMBER_FIELD],
        10
      ),
      profileCode: formValues[PROFILE_CODE_FIELD],
    });

    if (isProfileExist) {
      throw new SubmissionError({
        [PROFILE_CODE_FIELD]: ASYNC_VALIDATE_ERRORS[PROFILE_CODE_FIELD],
      });
    }
  } else {
    Object.keys(fieldsValue).forEach((key) => {
      if (!accountNumbers[key]) {
        throw new SubmissionError({ [key]: ASYNC_VALIDATE_ERRORS[key] });
      }
    });
  }
};
