import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import { NODE_ENV } from "../constants/common";
import { isDpdLocalDomain } from "../features/app/app.modes";

const { REACT_APP_CUSTOMER_AUTH_LOCAL_DOMAIN, REACT_APP_CUSTOMER_AUTH_DOMAIN } =
  process.env;

firebase.initializeApp({
  authDomain: isDpdLocalDomain()
    ? REACT_APP_CUSTOMER_AUTH_LOCAL_DOMAIN
    : REACT_APP_CUSTOMER_AUTH_DOMAIN,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const customerFirebase = firebase.initializeApp(
  {
    authDomain: isDpdLocalDomain()
      ? REACT_APP_CUSTOMER_AUTH_LOCAL_DOMAIN
      : REACT_APP_CUSTOMER_AUTH_DOMAIN,
    apiKey: process.env.REACT_APP_CUSTOMER_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_CUSTOMER_FIREBASE_PROJECT_ID,
  },
  "customer"
);

if (process.env.NODE_ENV !== NODE_ENV.PRODUCTION) {
  window.firebase = firebase;
  window.customerFirebase = customerFirebase;
}

export default firebase;
