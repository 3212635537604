import get from "lodash/get";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import {
  EOD_MASTER_FIELD,
  EOD_USER_FIELD,
  SENDERS_REF_UNIQUE_FIELD,
} from "./shippingSettings.constants";
import * as shippingSettingsModels from "./shippingSettings.models";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { getMiscellaneousConfigurationFormValues } from "../miscellaneousConfiguration/miscellaneousConfiguration.selectors";
import { getUserId } from "../userDetails/userDetails.selectors";
import {
  getEodMasterUser,
  isEodMasterAvailableForAccount,
} from "../userDetailsForm.selector";

export const getShippingSettingsFormValues = getFormValues(
  USER_DETAILS_FORMS.SHIPPING_SETTINGS
);

export const shouldShowEndOfDay = createSelector(
  getShippingSettingsFormValues,
  (endOfDayFormValues) => get(endOfDayFormValues, EOD_USER_FIELD, false)
);

export const isEodMasterSelected = createSelector(
  getShippingSettingsFormValues,
  (endOfDayFormValues) => get(endOfDayFormValues, EOD_MASTER_FIELD, false)
);

export const isReleaseFileDisabled = createSelector(
  getShippingSettingsFormValues,
  (shippingSettingsFormValues) =>
    !get(shippingSettingsFormValues, SENDERS_REF_UNIQUE_FIELD, false)
);

export const isEodMasterEnabledForCurrentUser = createSelector(
  getShippingSettingsFormValues,
  getMiscellaneousConfigurationFormValues,
  shippingSettingsModels.isEodMasterEnabled
);

export const isCurrentUserEodMaster = createSelector(
  getEodMasterUser,
  getUserId,
  (eodMasterUser, userId) => eodMasterUser === userId
);

export const isEodMasterEnabled = createSelector(
  isEodMasterAvailableForAccount,
  isEodMasterEnabledForCurrentUser,
  isCurrentUserEodMaster,
  (eodMasterAvailable, isEodMasterEnabledByUser, isUserEodMaster) =>
    (eodMasterAvailable || isUserEodMaster) && isEodMasterEnabledByUser
);
