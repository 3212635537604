import { Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { reduxForm } from "redux-form";

import TransferList from "../../components/TransferList/TransferList";
import WarningText from "../../components/WarningText/WarningText";
import { ANALYTICS_FOR_USER } from "../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../constants/forms";
import {
  ADD_ALL,
  REMOVE_ALL,
  USER_PROFILES_LEFT_HEADER,
  USER_PROFILES_NO_ACCOUNT_IS_SELECTED,
  USER_PROFILES_NO_PROFILE_LINKED,
  USER_PROFILES_RIGHT_HEADER,
} from "../../constants/strings.js";
import { getAccountId } from "../../features/userDetailsForm/accountDetails/accountDetails.selectors";
import {
  changeAllocatedProfiles,
  registerAllocatedProfileField,
} from "../../features/userDetailsForm/userProfiles/userProfiles.actions";
import {
  getAvailableToSelectProfiles,
  getUserProfilesAllocatedFormValues,
} from "../../features/userDetailsForm/userProfiles/userProfiles.selectors";
import { validate } from "../../features/userDetailsForm/userProfiles/userProfiles.validate";
import withRef from "../../hocs/withRef";

const useThemeStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

const UserProfiles = ({
  accountId,
  analyticIds,
  shouldShowError,
  changeAllocatedProfiles,
  selected,
  available,
}) => {
  const themeStyles = useThemeStyles(useTheme());

  return shouldShowError ? (
    <Grid item container>
      <WarningText
        text={
          !accountId
            ? USER_PROFILES_NO_ACCOUNT_IS_SELECTED
            : USER_PROFILES_NO_PROFILE_LINKED
        }
      />
    </Grid>
  ) : (
    <TransferList
      leftHeader={USER_PROFILES_RIGHT_HEADER}
      rightHeader={USER_PROFILES_LEFT_HEADER}
      rightItemsStyles={themeStyles.item}
      onChange={(value) => {
        changeAllocatedProfiles(value);
      }}
      selectedValues={selected}
      availableValues={available}
      leftButtonTitle={ADD_ALL}
      rightButtonTitle={REMOVE_ALL}
      analyticIdAddAll={analyticIds.addAll}
      analyticIdAdd={analyticIds.add}
      analyticIdRemove={analyticIds.remove}
      analyticIdRemoveAll={analyticIds.removeAll}
    />
  );
};

UserProfiles.defaultProps = {
  selected: [],
  available: [],
  analyticIds: {},
};

UserProfiles.propTypes = {
  shouldShowError: PropTypes.bool.isRequired,
  changeAllocatedProfiles: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  available: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.arrayOf(PropTypes.object),
  analyticIds: PropTypes.object,
};

const analyticIds = {
  addAll: ANALYTICS_FOR_USER.USER_PROFILES_ADD_ALL,
  add: ANALYTICS_FOR_USER.USER_PROFILES_ADD,
  remove: ANALYTICS_FOR_USER.USER_PROFILES_REMOVE,
  removeAll: ANALYTICS_FOR_USER.USER_PROFILES_REMOVE_ALL,
};

const mapStateToProps = (state, ownProps) => {
  const accountId = ownProps.accountId || getAccountId(state);
  const selected = getUserProfilesAllocatedFormValues(state);
  const available = getAvailableToSelectProfiles(state);
  const shouldShowError = (!available.length && !selected.length) || !accountId;
  return {
    accountId,
    available,
    selected,
    shouldShowError,
    analyticIds,
  };
};

export default compose(
  connect(mapStateToProps, {
    changeAllocatedProfiles,
    registerAllocatedProfileField,
  }),
  reduxForm({
    form: USER_DETAILS_FORMS.USER_PROFILES,
    validate,
  }),
  lifecycle({
    componentDidMount() {
      this.props.registerAllocatedProfileField();
    },
  }),
  withRef
)(UserProfiles);
