import React from "react";
import BaseModal from "../BaseModal";
import {
  ABOUT_DISABLE_API_ACCESS,
  DISABLE_API_ACCESS,
  ENTER_DISABLE,
} from "../../../constants/strings";
import Grid from "@material-ui/core/Grid";
import styles from "../AddKeyModal/AddKeyModal.module.scss";
import Typography from "@material-ui/core/Typography";
import { Field, propTypes, reduxForm } from "redux-form";
import InputWithTitle from "../../InputWithTitle/InputWithTitle";

import WarningText from "../../WarningText/WarningText";
import { compose } from "recompose";
import { DISABLE_API_ACCESS_FORM } from "../../../constants/forms";
import { API_SETTING_FIELDS } from "../../../features/userDetailsForm/apiSetting/apiSetting.constants";
import { disableApiAccessValidator } from "../../../features/userDetailsForm/apiSetting/apiSetting.validate";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getApiSettingFormValues,
  shouldDisableModalConfirmButton,
} from "../../../features/userDetailsForm/apiSetting/apiSettingSelectors";
import { uppercase } from "../../../utils/normalize";

const DisableApiAccessModal = ({
  keyLabel,
  onCopyIconClick,
  isFormValid,
  isFormDirty,
  ...otherProps
}) => {
  return (
    <BaseModal title={DISABLE_API_ACCESS} {...otherProps}>
      <Grid container spacing={3} className={styles.container}>
        <Grid item xs={12}>
          <Typography className={styles.text}>
            {ABOUT_DISABLE_API_ACCESS}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <WarningText text={ENTER_DISABLE} />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={InputWithTitle}
            name={API_SETTING_FIELDS.DISABLE}
            normalize={uppercase}
            onBlur={() => null} // TODO: add analytics
          />
        </Grid>
      </Grid>
    </BaseModal>
  );
};

DisableApiAccessModal.propTypes = {
  ...propTypes,
  disabledConfirmButton: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  apiSettingFormValues: getApiSettingFormValues(state),
  disabledConfirmButton: shouldDisableModalConfirmButton(
    DISABLE_API_ACCESS_FORM
  )(state),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: DISABLE_API_ACCESS_FORM,
    validate: (values) => disableApiAccessValidator(values),
  })
)(DisableApiAccessModal);
