import { Box, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import { USER_DETAILS_FORMS } from "../../constants/forms";
import {
  COLLECTION_SHIP_EASE,
  COLLECTIONS_AND_SHIP_EASE_INFO,
  DELIVERY_DASHBOARD_EXCEPTIONS,
  DELIVERY_DASHBOARD_EXCEPTIONS_INFO,
  DISABLE_COLLECTION_ALLOW_REVIEW,
  DISABLE_COLLECTION_ALLOW_REVIEW_INFO,
  EXTRACT_REPORTS,
  EXTRACT_REPORTS_INFO,
  FLIGHT_DELIVERY_ACTIONS,
  IN_FLIGHT_DELIVERY_ACTIONS_INFO,
  POPULATE_EMAIL_MSG,
  REPORTS,
  REPORTS_INFO,
} from "../../constants/strings";
import AdditionalAccounts from "../../containers/AdditionalAccounts";
import {
  CHANGE_DELIVERY_FIELD,
  COLLECTIONS_SHIPEASE_FIELD,
  DELIVERY_EXCEPTIONS_FIELD,
  DISCOLLECT_FIELD,
  EXREPORTS_FIELD,
  PLREPORTS_FIELD,
} from "../../features/userDetailsForm/systemAccess/systemAccess.constants";
import UserDetailsAccessForm from "../../pages/dpd-user/components/UserDetails/UserDetailsAccessForm";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";
import styles from "./SystemAccess.module.scss";

const SystemAccess = ({
  onToggleChange,
  isOwnUser,
  adminAccess,
  showInvalidEmailErrorMsg,
  accountsInitialValues,
  disableRemoveFirstAccount,
  getAdditionalAccountNumbers,
  onAccountClick,
  onInfoMouseOver,
  onRemoveAccount,
  onBlurFields,
  onAdminAccessClick,
}) => (
  <Grid container justify="space-between">
    <Grid
      item
      container
      justify="flex-start"
      classes={{ container: styles.containerOne }}
    >
      <Box className={styles.item}>
        <Field
          name={COLLECTIONS_SHIPEASE_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          label={COLLECTION_SHIP_EASE}
          infoLabel={COLLECTIONS_AND_SHIP_EASE_INFO}
          onInfoMouseOver={onInfoMouseOver}
          onChange={(event, value) => {
            onToggleChange(PLREPORTS_FIELD, value);
          }}
        />
      </Box>
      <Box className={styles.item}>
        <Field
          name={PLREPORTS_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          label={REPORTS}
          onInfoMouseOver={onInfoMouseOver}
          infoLabel={REPORTS_INFO}
          onChange={(event, value) => {
            onToggleChange(PLREPORTS_FIELD, value);
          }}
        />
      </Box>
      <Box className={styles.item}>
        <Field
          name={DELIVERY_EXCEPTIONS_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          label={DELIVERY_DASHBOARD_EXCEPTIONS}
          onInfoMouseOver={onInfoMouseOver}
          infoLabel={DELIVERY_DASHBOARD_EXCEPTIONS_INFO}
          onChange={(event, value) => {
            onToggleChange(DELIVERY_EXCEPTIONS_FIELD, value);
          }}
        />
      </Box>
    </Grid>
    <Grid
      item
      container
      justify="flex-start"
      classes={{ container: styles.containerTwo }}
    >
      <Box className={styles.item}>
        <Field
          name={DISCOLLECT_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          onInfoMouseOver={onInfoMouseOver}
          label={DISABLE_COLLECTION_ALLOW_REVIEW}
          infoLabel={DISABLE_COLLECTION_ALLOW_REVIEW_INFO}
          onChange={(event, value) => {
            onToggleChange(DISCOLLECT_FIELD, value);
          }}
        />
      </Box>
      <Box className={styles.item}>
        <Field
          name={EXREPORTS_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          onInfoMouseOver={onInfoMouseOver}
          label={EXTRACT_REPORTS}
          infoLabel={EXTRACT_REPORTS_INFO}
          onChange={(event, value) => {
            onToggleChange(EXREPORTS_FIELD, value);
          }}
        />
      </Box>
      <Box className={styles.item}>
        <Field
          name={CHANGE_DELIVERY_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          onInfoMouseOver={onInfoMouseOver}
          label={FLIGHT_DELIVERY_ACTIONS}
          infoLabel={IN_FLIGHT_DELIVERY_ACTIONS_INFO}
          onChange={(event, value) => {
            onToggleChange(CHANGE_DELIVERY_FIELD, value);
          }}
        />
      </Box>
    </Grid>
    <Grid
      item
      container
      justify="space-between"
      classes={{ container: styles.systemAccessContainer }}
    >
      <UserDetailsAccessForm
        onInfoMouseOver={onInfoMouseOver}
        isOwnUser={isOwnUser}
        onAdminAccessClick={onAdminAccessClick}
      />
    </Grid>
    {adminAccess && showInvalidEmailErrorMsg && (
      <Grid
        item
        container
        justify="space-between"
        classes={{ container: styles.container }}
      >
        <Box width="100%" className={styles.error}>
          {POPULATE_EMAIL_MSG}
        </Box>
      </Grid>
    )}
    {adminAccess && (
      <AdditionalAccounts
        form={USER_DETAILS_FORMS.SYSTEM_ACCESS_ACCOUNTS}
        initialValues={accountsInitialValues}
        disableRemoveFirst={disableRemoveFirstAccount}
        fetchFunction={getAdditionalAccountNumbers}
        onItemClick={onAccountClick}
        onRemove={onRemoveAccount}
        disabled={isOwnUser}
        onBlurFields={onBlurFields}
      />
    )}
  </Grid>
);

SystemAccess.propTypes = {
  withReference: PropTypes.func,
  onToggleChange: PropTypes.func,
  getAdditionalAccountNumbers: PropTypes.func,
  onAccountClick: PropTypes.func,
  onAdminAccessClick: PropTypes.func,
  onBlurFields: PropTypes.func,
  adminAccess: PropTypes.bool,
  showInvalidEmailErrorMsg: PropTypes.bool,
  accountsInitialValues: PropTypes.object,
  disableRemoveFirstAccount: PropTypes.bool,
  isOwnUser: PropTypes.bool,
  onInfoMouseOver: PropTypes.func.isRequired,
  onRemoveAccount: PropTypes.func,
};

SystemAccess.defaultProps = {
  withReference: () => {},
  onToggleChange: () => {},
  onBlurFields: () => {},
  onRemoveAccount: () => {},
  onAdminAccessClick: () => {},
  disableRemoveFirstAccount: false,
  isOwnUser: false,
};

export default SystemAccess;
