import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Prompt, withRouter } from "react-router";

import { NAVIGATE_AWAY_MODAL } from "../../constants/analytics";
import { NO, YES } from "../../constants/strings";
import { showModal } from "../../features/modal/modal.actions";

const CustomPrompt = ({
  when,
  message,
  history,
  cancelButtonText,
  confirmButtonText,
  interfaceId,
  cancelActionId,
}) => {
  const dispatch = useDispatch();
  const [confirmedNavigation, setConfirmNavigation] = useState(false);
  const [navigationPath, setNavigationPath] = useState("");

  useEffect(() => {
    confirmedNavigation && history.push(navigationPath);
  }, [navigationPath, confirmedNavigation]);

  const openSignOutDialog = (nextLocation) => {
    dispatch(
      showModal({
        loadId: NAVIGATE_AWAY_MODAL.LOAD,
        interfaceId,
        cancelActionId: cancelActionId || NAVIGATE_AWAY_MODAL.NO,
        confirmActionId: NAVIGATE_AWAY_MODAL.YES,
        contentText: message,
        cancelButtonText,
        confirmButtonText,
        onConfirmClick: () => {
          setConfirmNavigation(true);
          setNavigationPath(nextLocation);
        },
      })
    );
  };

  const handlePrompt = (nextLocation) => {
    if (confirmedNavigation) {
      return true;
    }

    openSignOutDialog(nextLocation);

    return false;
  };

  return <Prompt when={when} message={handlePrompt} />;
};

CustomPrompt.defaultProps = {
  cancelButtonText: NO,
  confirmButtonText: YES,
};

CustomPrompt.propTypes = {
  when: PropTypes.bool.isRequired,
  message: PropTypes.string,
  history: PropTypes.object.isRequired,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  interfaceId: PropTypes.string,
  cancelActionId: PropTypes.string,
  location: PropTypes.object,
};

export default withRouter(CustomPrompt);
