/* eslint-disable no-async-promise-executor */
import { find, isUndefined, map, omitBy } from "lodash";

const promisifyHandleSubmit = (handleSubmit) =>
  new Promise(async (resolve, reject) => {
    try {
      const error = await handleSubmit(() => {})();
      error ? reject(error) : resolve();
    } catch (error) {
      reject(error);
    }
  });

export const handleMultiformSubmit = (forms = {}, onSuccess = () => {}) => {
  Promise.allSettled(
    map(omitBy(forms, isUndefined), ({ props }) =>
      promisifyHandleSubmit(props.handleSubmit)
    )
  ).then((res) => {
    const valid = !find(res, { status: "rejected" });
    valid && onSuccess();
  });
};
