export const initialState = {
  isOpen: false,
  isValid: false,
  data: [],
};

export const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case "CLOSE_POPPER":
      return {
        ...state,
        isOpen: false,
      };
    case "OPEN_POPPER":
      return {
        ...state,
        isOpen: true,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isOpen: !!(action.payload && action.payload.length),
        isError: false,
        data: action.payload,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isOpen: false,
        isError: true,
        data: [],
      };
    default:
      throw new Error();
  }
};
