import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { useMemo } from "react";

import SearchInput from "../../../components/SearchInput/SearchInput";
import { SEARCH_FORM } from "../../../constants/forms";
import { SEARCH_FIELD } from "../../../features/user/user.constants";
import { debounce } from "../../../utils/function";

function SearchForm({ handleSubmit, onSubmit }) {
  const onSubmitDebounce = useMemo(
    () => debounce(handleSubmit(onSubmit)),
    [onSubmit]
  );

  return (
    <Field
      name={SEARCH_FIELD}
      component={SearchInput}
      onChange={onSubmitDebounce}
      onEnter={onSubmitDebounce}
      type="text"
    />
  );
}

SearchForm.propTypes = {
  onBlur: PropTypes.func,
};

export default reduxForm({
  form: SEARCH_FORM,
})(SearchForm);
