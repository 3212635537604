import { Box } from "@material-ui/core";
import PropTypes from "prop-types";

import {
  getErrorMessage,
  isErrorMessageVisible,
} from "./withErrorTooltip.model";
import Tooltip from "../../components/Tooltip";

const withErrorTooltip = (Component) => {
  const ErrorTooltip = ({
    showErrors,
    showErrorsOnRender,
    asyncErrorText,
    tooltipClasses,
    ...otherProps
  }) => {
    const { meta, readOnly, fields } = otherProps;

    const isFieldArray = !!fields;

    const error = getErrorMessage({
      meta,
      asyncErrorText,
      showErrorsOnRender,
      readOnly,
      showErrors,
    });

    const isVisible =
      !!error &&
      isErrorMessageVisible({
        asyncErrorText,
        meta,
        isFieldArray,
        showErrorsOnRender,
      });

    return (
      <Tooltip
        open={isVisible}
        title={isVisible && error}
        tooltipClasses={tooltipClasses}
      >
        <Box width="100%">
          <Component {...otherProps} />
        </Box>
      </Tooltip>
    );
  };

  ErrorTooltip.propTypes = {
    meta: PropTypes.object,
    input: PropTypes.object,
    readOnly: PropTypes.bool,
    showErrors: PropTypes.arrayOf(PropTypes.string),
    showErrorsOnRender: PropTypes.bool,
    asyncErrorText: PropTypes.string,
    tooltipClasses: PropTypes.object,
  };

  return ErrorTooltip;
};

export default withErrorTooltip;
