import React from "react";
import Grid from "@material-ui/core/Grid";
import InputWithTitle from "../InputWithTitle/InputWithTitle";
import { Button } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import styles from "./VerifyEmail.module.scss";
import PropTypes from "prop-types";
import withErrorTooltip from "../../hocs/withErrorTooltip";
import { EMAIL_SENT } from "../../features/userDetailsForm/apiSetting/apiSetting.constants";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const VerifyEmail = ({
  onVerifyEmailClick,
  verifyEmailsData: { title, buttonName, showKeys },
  isEmailSent,
  ...props
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography className={styles.text}>{title}</Typography>
      </Grid>
      <Grid item xs={7}>
        <DecoratedFieldWithError {...props} />
      </Grid>
      {!showKeys && (
        <>
          <Grid item xs={3}>
            <Button
              onClick={(item) => {
                // onChange(IRISH_CODES); // TODO: add analytics
                onVerifyEmailClick();
              }}
            >
              {buttonName}
            </Button>
          </Grid>
          {isEmailSent && (
            <Grid item xs={2}>
              <Typography className={styles.email}>{EMAIL_SENT}</Typography>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

VerifyEmail.defaultProps = {
  onVerifyEmailClick: () => null,
  verifyEmailsData: {},
};

VerifyEmail.propTypes = {
  onVerifyEmailClick: PropTypes.func.isRequired,
  verifyEmailsData: PropTypes.object.isRequired,
  isEmailSent: PropTypes.bool,
};

export default VerifyEmail;
