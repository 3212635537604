import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import CustomTabs from "../../../components/CustomTabs/CustomTabs.js";
import Collections from "../../../components/MiscellaneousConfigurationComponents/Collections.js";
import Shipments from "../../../components/MiscellaneousConfigurationComponents/Shipments.js";
import { CREATE_EDIT_USER_ANALYTICS } from "../../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../../constants/forms.js";
import { CUSTOMER_MISCELLANEOUS_CONFIGURATION_TABS } from "../../../constants/strings.js";
import { getMiscellaneousConfigurationInitialValues } from "../../../features/userDetailsForm/miscellaneousConfiguration/miscellaneousConfiguration.selectors";
import styles from "../../dpd-user/components/MiscellaneousConfiguration/MiscellaneousConfiguration.module.scss";

const CustomerMiscellaneousConfiguration = ({
  onToggleChange,
  onInfoMouseOver,
  onChangeCheckMasterEod,
}) => (
  <div className={styles.wrapper}>
    <CustomTabs tabs={CUSTOMER_MISCELLANEOUS_CONFIGURATION_TABS}>
      <Shipments
        onChangeCheckMasterEod={onChangeCheckMasterEod}
        onToggleChange={onToggleChange}
        onInfoMouseOver={onInfoMouseOver}
      />
      <Collections
        onInputChange={onToggleChange}
        onInfoMouseOver={onInfoMouseOver}
      />
    </CustomTabs>
  </div>
);

CustomerMiscellaneousConfiguration.propTypes = {
  onInfoMouseOver: PropTypes.func.isRequired,
  onToggleChange: PropTypes.func.isRequired,
  onChangeCheckMasterEod: PropTypes.func,
};

const mapStateToProps = (state) => ({
  initialValues: getMiscellaneousConfigurationInitialValues(state),
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: USER_DETAILS_FORMS.MISCELLANEOUS_CONFIGURATION,
    enableReinitialize: true,
  }),
  withTrackProps(({ deselectMasterEodToggle }) => ({
    onChangeCheckMasterEod: (field, value) => {
      deselectMasterEodToggle && !value && deselectMasterEodToggle();
      return createLogEntryPayload(CREATE_EDIT_USER_ANALYTICS.CLICK_TOGGLE, {
        field,
        value,
      });
    },
  }))
)(CustomerMiscellaneousConfiguration);
