import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import { useSelector } from "react-redux";

import image from "../../assets/images/lookingBox.png";
import {
  HOME,
  PAGE_HAS_NOT_BEEN_FOUND,
  PAGE_NOT_FOUND,
} from "../../constants/strings";
import { getCustomerAccountId } from "../../features/user/user.selectors";
import { CUSTOMER_USERS_PAGE } from "../../router";
import { navigateTo } from "../../router/navigation";
import LogoHeader from "../login/components/LoginHeader/LogoHeader";
import styles from "./PageNotFound.module.scss";

const useThemeColorStyles = makeStyles((theme) => ({
  button: () => ({
    backgroundColor: theme.palette.primary.main,
  }),
}));

const PageNotFound = () => {
  const customerId = useSelector(getCustomerAccountId);
  const buttonColor = useThemeColorStyles(useTheme());
  return (
    <Box className={styles.container}>
      <LogoHeader
        logoStyles={styles.logo}
        headerStyles={styles.logoContainer}
      />
      <Box className={styles.textContainer}>
        <div className={styles.title}>{PAGE_NOT_FOUND}</div>
        <div className={styles.subtitle}>{PAGE_HAS_NOT_BEEN_FOUND}</div>
      </Box>
      <img src={image} alt="looking box" className={styles.image} />
      <Box>
        <Button
          onClick={() => {
            navigateTo(CUSTOMER_USERS_PAGE, { customerId });
          }}
          className={classNames(styles.button, buttonColor.button)}
        >
          {HOME}
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
