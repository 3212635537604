import { useState } from "react";

import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import PropTypes from "prop-types";

import DatePicker from "../../../../components/DatePicker/DatePicker";
import {
  SET_USER_EXPIRY_DATE,
  USER_EXPIRES_ON,
} from "../../../../constants/strings";
import { formatDateLabel } from "../../../../utils/string";

const ExpiryDateDatePicker = (props) => {
  const [now] = useState(new Date());

  const getDateLabel = (date) =>
    formatDateLabel(date, USER_EXPIRES_ON, SET_USER_EXPIRY_DATE);

  const shouldDisableDate = (day) => {
    const { value } = props.input;
    return !(
      differenceInCalendarDays(day, now) >= 0 ||
      differenceInCalendarDays(day, value) === 0
    );
  };

  return (
    <DatePicker
      {...props}
      labelFunc={getDateLabel}
      shouldDisableDate={shouldDisableDate}
    />
  );
};

export default ExpiryDateDatePicker;

ExpiryDateDatePicker.propTypes = {
  props: PropTypes.object,
  input: PropTypes.object,
};
