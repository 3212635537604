import { DPD_BUSINESS_ID } from "../accountDetails/accountDetails.constants";

export const prepareAdvancedCustomerConfiguration = (fieldValues) => {
  const advancedCustomerConfiguration = Object.entries(fieldValues).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc[key] = 1;
      }

      return acc;
    },
    {}
  );

  return { advancedCustomerConfiguration };
};

export const isSixMonthsDisableToggle = (
  businessId = DPD_BUSINESS_ID,
  selectedProfiles = []
) =>
  businessId !== DPD_BUSINESS_ID ||
  !selectedProfiles.length ||
  !selectedProfiles.filter(({ slid }) => !!slid).length;
