import { ACTIONS } from "./profile.actions";

export const initialState = {
  showPrompt: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SAVE_PROFILE.SUCCESS:
      return {
        ...state,
        showPrompt: false,
      };
    case ACTIONS.DELETE_PROFILE.SUCCESS:
    case ACTIONS.DELETE_PROFILE.FAILURE:
      return {
        ...state,
        showPrompt: true,
      };
    case ACTIONS.ENABLE_NAVIGATE_MODAL:
      return {
        ...state,
        showPrompt: true,
      };
    case ACTIONS.DISABLE_NAVIGATE_MODAL:
      return {
        ...state,
        showPrompt: false,
      };
    default:
      return state;
  }
};
