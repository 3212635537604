const getFilteredError = (errors = [""], enabledErrors) => {
  if (!enabledErrors) {
    return errors.find((error) => !!error) || "";
  }
  for (const error of errors) {
    if (enabledErrors.includes(error)) {
      return error;
    }
  }
  return "";
};

export const isErrorMessageVisible = ({
  meta,
  asyncErrorText,
  showErrorsOnRender,
  readOnly,
  isFieldArray,
}) => {
  const isInvalid = !meta.valid || meta.warning || asyncErrorText;
  const isTouched = meta.touched || showErrorsOnRender || isFieldArray;
  return Boolean(isInvalid && !readOnly && !meta.active && isTouched);
};

export const getErrorMessage = ({ meta, asyncErrorText, showErrors }) =>
  getFilteredError([meta.error, asyncErrorText, meta.warning], showErrors);
