import { compose } from "recompose";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import { DPD_APP_CONFIG_ANALYTICS } from "../../../../constants/analytics";
import {
  DPD_APP_CONFIG,
  DPD_APP_VERSIONS,
} from "../../../../constants/strings";
import CustomExpansionPanel from "../../../../components/CustomExpansionPanel/CustomExpansionPanel";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import AppConfigForm from "./components/AppConfigForm/AppConfigForm";
import AppVersionsForm from "./components/AppVersionsForm/AppVersionsForm";

const AppManagement = () => (
  <PageContainer>
    <CustomExpansionPanel title={DPD_APP_CONFIG}>
      <AppConfigForm />
    </CustomExpansionPanel>

    <CustomExpansionPanel title={DPD_APP_VERSIONS} defaultExpanded>
      <AppVersionsForm />
    </CustomExpansionPanel>
  </PageContainer>
);

export default compose(
  withTrack({
    loadId: DPD_APP_CONFIG_ANALYTICS.LOAD,
    interfaceId: DPD_APP_CONFIG_ANALYTICS.INTERFACE_ID,
  })
)(AppManagement);
