export const LAST_LOGON = "lastLogon";

export const RESET_PASSWORD_CONFIRM =
  "Are you sure you want to reset this user's password?";
export const EMAIL_ALREADY_IN_USE =
  "Email already in use, please enter a different email";
export const SUSPEND_THIS_USER = "Are you sure you want to {0} this user?";
export const SUSPEND = "suspend";
export const UNSUSPEND = "unsuspend";
export const ACCOUNT_EXPIRY_ON = "Account expires on";
export const ACCOUNT_EXPIRED_ON = "Account expired on";
export const ACCOUNT_CREATED_ON = "Account created on";
export const USER_CREATED_SUCCESSFULLY =
  "The user has been created successfully";
export const UNABLE_TO_CREATE_USER = "Unable to create user";
export const UNABLE_TO_UPDATE_USER = "Unable to update user";
export const USER_UPDATED_SUCCESSFULLY =
  "The user has been updated successfully";
export const USERNAME_ALREADY_EXIST =
  "Username already exists for selected business unit";
export const USER_ALREADY_EXIST = "User already exists";
export const USER_ALREADY_CREATED = "User already created";

export const SUSPENDED_FILTER = "suspended";
export const ALL_FILTER = "all";
export const INACTIVE_FILTER = "inactive";
export const INVITED_FILTER = "invited";
export const SEARCH_FIELD = "search";
export const MASTER_USER_TYPE = "myDPDMasterUser";
