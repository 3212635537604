export const prepareSecuritySettingsConfiguration = (formValues = {}) => {
  const securitySettings = Object.keys(formValues).reduce((acc, key) => {
    if (formValues[key]) {
      acc[key] = 1;
    }
    return acc;
  }, {});

  return { securitySettings };
};
