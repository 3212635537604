import {
  ACCOUNT_NUMBER,
  CITY,
  COUNTRY,
  POSTCODE,
  STREET,
} from "../../../constants/strings";

export const DPD_BUSINESS_ID = 1;
export const DPD_LOCAL_BUSINESS_ID = 2;

export const BUSINESS_ID_FIELD = "businessId";
export const ACCOUNT_ID_FIELD = "account";
export const COUNTRY_FIELD = "country";
export const POSTCODE_FIELD = "postcode";
export const POSTCODE_FIELD_CLICK = "postcode click";
export const ADDLINE_1_FIELD = "addressline1";
export const ADDLINE_2_FIELD = "addressline2";
export const ADDLINE_3_FIELD = "addressline3";
export const ADDLINE_4_FIELD = "addressline4";
export const COMPANY_FIELD = "customername";
export const CUSTOMER_ID_FIELD = "customerId";
export const NOTIFICATION_EMAIL_FIELD = "notifyEmail";
export const NOTIFICATION_NUMBER_FIELD = "notifyTel";
export const EOD_MASTER_AVAILABLE_FIELD = "eodMasterAvailable";
export const EOD_MASTER_USER_FIELD = "eodMasterUser";
export const CITY_FIELD = "addressline3";
export const STREET_FIELD = "addressline1";
export const SUBURB_FIELD = "addressline2";
export const OVERRIDE_DETAILS_FIELD = "overrideDetails";
export const COUNTY_FIELD = "addressline4";

export const REQUIRED_VALIDATION_FIELD_LABELS = {
  [CITY_FIELD]: CITY,
  [COUNTRY_FIELD]: COUNTRY,
  [POSTCODE_FIELD]: POSTCODE,
  [STREET_FIELD]: STREET,
  [ACCOUNT_ID_FIELD]: ACCOUNT_NUMBER,
};

export const CUSTOMER_DETAILS_SECTION_FIELDS = [
  POSTCODE_FIELD,
  CITY_FIELD,
  STREET_FIELD,
  SUBURB_FIELD,
  COUNTRY_FIELD,
  NOTIFICATION_EMAIL_FIELD,
  NOTIFICATION_NUMBER_FIELD,
  COUNTY_FIELD,
];
