import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import classNames from "classnames";
import PropTypes from "prop-types";

import SortArrows from "../../../../components/SortArrows/SortArrows";
import styles from "./CustomHeader.module.scss";

const CustomHeaderCell = ({ title, isSortIconVisible, headerCell }) => (
  <TableCell className={classNames(styles.cell, headerCell)} align={"left"}>
    <TableSortLabel
      IconComponent={() => <SortArrows />}
      hideSortIcon={!isSortIconVisible}
    >
      {title.toUpperCase()}
    </TableSortLabel>
  </TableCell>
);

CustomHeaderCell.propTypes = {
  title: PropTypes.string,
  isSortIconVisible: PropTypes.bool,
  headerCell: PropTypes.string,
};

export default CustomHeaderCell;
