import { useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";

import styles from "./LoginLayout.module.scss";

const CUSTOMER_PORTAL = "Customer Portal";

const LoginLayout = ({ children }) => {
  const { images } = useTheme();

  return (
    <Grid
      container
      className={styles.root}
      component={Paper}
      square
      elevation={6}
    >
      <Grid item xs={12} sm={8} md={6} lg={6} className={styles.gridContainer}>
        <header className={styles.header}>
          <img src={images.logo} alt="" className={styles.logo} />
          <h1>{CUSTOMER_PORTAL}</h1>
        </header>
        <section className={styles.content}>{children}</section>
      </Grid>
      <Grid item xs={false} sm={4} md={6} lg={6} className={styles.image} />
    </Grid>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
