import { Component } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import colors from "../constants/colors";
import { hideSnackbar } from "../features/snackbar/snackbar.actions";
import {
  getSnackbarProps,
  isSnackbarOpened,
} from "../features/snackbar/snackbar.selectors";

class ErrorSnackbar extends Component {
  static propTypes = {
    message: PropTypes.string,
    open: PropTypes.bool,
    hideSnackbar: PropTypes.func,
  };

  onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.hideSnackbar();
  };

  render() {
    const { message, open, hideSnackbar } = this.props;

    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={open}
        ContentProps={{
          style: { background: colors.buttonGradient },
        }}
        variant="error"
        autoHideDuration={4000}
        message={message}
        onClick={hideSnackbar}
        onClose={this.onClose}
      />
    );
  }
}

export default connect(
  (state) => ({
    message: getSnackbarProps(state).message,
    open: isSnackbarOpened(state),
  }),
  { hideSnackbar }
)(ErrorSnackbar);
