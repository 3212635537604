import { ACTIONS } from "./appManagement.actions";

export const initialState = {
  appVersionsDetails: [],
  appVersions: [],
  appConfig: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_DPD_APP_CONFIG.SUCCESS:
      return {
        ...state,
        appConfig: action.payload,
      };
    case ACTIONS.FETCH_DPD_APP_VERSION.SUCCESS:
      return {
        ...state,
        appVersions: action.payload,
      };
    case ACTIONS.UPDATE_DPD_APP_VERSION_DETAILS:
    case ACTIONS.FETCH_DPD_APP_VERSION_DETAILS.SUCCESS:
      return {
        ...state,
        appVersionsDetails: action.payload,
      };
    default:
      return state;
  }
};
