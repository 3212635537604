import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import AddIpAddressSection from "./AddIpAddressSection";
import AddIpRangeSection from "./AddIpRangeSection";
import styles from "./AddIpRestrictionsForm.module.scss";
import { TEAM_SETTINGS_ANALYTICS } from "../../../../constants/analytics";
import { IP_ADDRESS_FORM } from "../../../../constants/forms";
import {
  addIpAddress,
  addIpRange,
} from "../../../../features/teamSettings/teamSetting.actions";
import {
  END_IP_FIELD,
  IP_ADDRESS_FIELD,
  IP_ADDRESS_NAME_FIELD,
  IP_RANGE_NAME_FIELD,
  START_IP_FIELD,
} from "../../../../features/teamSettings/teamSettings.constants";
import { addIpAddressFormInitialValues } from "../../../../features/teamSettings/teamSettings.model";
import {
  isDisabledAddIpButton,
  isDisabledAddIpRangeButton,
} from "../../../../features/teamSettings/teamSettings.selectors";

export const AddIpRestrictionsForm = ({
  onBlurFields,
  isAddIpButtonDisabled,
  handleSubmit,
  isAddIpRangeButtonDisabled,
  onAddIpRangeClick,
  onAddSingleIpClick,
}) => (
  <Box className={styles.addIpContainer}>
    <div className={styles.addIp}>
      <AddIpAddressSection
        handleSubmit={handleSubmit}
        onAddIpAddress={onAddSingleIpClick}
        isAddIpButtonDisabled={isAddIpButtonDisabled}
        onBlurFields={onBlurFields}
      />
    </div>
    <Divider orientation="vertical" className={styles.verticalDivider} />
    <div className={styles.addIp}>
      <AddIpRangeSection
        addIpRestriction={onAddIpRangeClick}
        handleSubmit={handleSubmit}
        isAddButtonDisabled={isAddIpRangeButtonDisabled}
        onBlurFields={onBlurFields}
      />
    </div>
  </Box>
);

AddIpRestrictionsForm.propTypes = {
  onBlurFields: PropTypes.func,
  isAddIpButtonDisabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isAddIpRangeButtonDisabled: PropTypes.bool,
  onAddIpRangeClick: PropTypes.func,
  onAddSingleIpClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAddIpButtonDisabled: isDisabledAddIpButton(state),
  isAddIpRangeButtonDisabled: isDisabledAddIpRangeButton(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAddSingleIpClick: (ipAddress) => dispatch(addIpAddress(ipAddress)),
  onAddIpRangeClick: (ipAddress) => dispatch(addIpRange(ipAddress)),
});

export default compose(
  reduxForm({
    form: IP_ADDRESS_FORM,
    initialValues: addIpAddressFormInitialValues(),
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(() => ({
    onAddSingleIpClick: () => TEAM_SETTINGS_ANALYTICS.ADD_IP,
    onAddIpRangeClick: () => TEAM_SETTINGS_ANALYTICS.CLICK_ADD_IP_RANGE,
    onBlurFields: (event, value, text, fieldName) => {
      let id = "";
      switch (fieldName) {
        case IP_ADDRESS_FIELD:
          id = TEAM_SETTINGS_ANALYTICS.ENTER_TEXT_IP_ADDRESS;
          break;
        case IP_ADDRESS_NAME_FIELD:
          id = TEAM_SETTINGS_ANALYTICS.ENTER_TEXT_NAME;
          break;
        case START_IP_FIELD:
          id = TEAM_SETTINGS_ANALYTICS.ENTER_TEXT_START_IP;
          break;
        case END_IP_FIELD:
          id = TEAM_SETTINGS_ANALYTICS.ENTER_TEXT_END_IP;
          break;
        case IP_RANGE_NAME_FIELD:
          id = TEAM_SETTINGS_ANALYTICS.ENTER_TEXT_RANGE_NAME;
          break;
      }
      return createLogEntryPayload(id, {
        fieldName,
        value,
      });
    },
  }))
)(AddIpRestrictionsForm);
