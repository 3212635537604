import { Fab } from "@material-ui/core";
import PropTypes from "prop-types";

import { ReactComponent as Add } from "../../assets/icons/add.svg";
import styles from "./StyledButtonIcon.module.scss";

function StyledButtonIcon({ onClick, disabled, icon }) {
  return (
    <Fab
      size="small"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      classes={{ disabled: styles.disabled }}
    >
      {icon}
    </Fab>
  );
}

StyledButtonIcon.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
};

StyledButtonIcon.defaultProps = {
  icon: <Add />,
};
export default StyledButtonIcon;
