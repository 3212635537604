import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import CustomSwitchWithTitle from "../../../../components/SwitchWidthTitle/CustomSwitchWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  ENABLE_AIR_CLASSIC_PARCEL,
  ENABLE_AIR_EXPRESS_PARCEL,
  ENABLE_DPD_CLASSIC_PALLET,
  ENABLE_DPD_CLASSIC_PARCEL,
  ENABLE_DPD_CLASSIC_PARCEL_FREIGHT,
  ENABLE_DPD_DIRECT_LITE,
  ENABLE_DPD_DIRECT_PARCEL,
  ENABLE_DPD_SMALL_PARCEL,
} from "../../../../constants/strings";
import { changeCreateProfileForm } from "../../../../features/profile/profile.actions";
import {
  ENABLE_AIR_CLASSIC_PARCEL_FIELD,
  ENABLE_DPD_CLASSIC_FREIGHT_FIELD,
  ENABLE_DPD_CLASSIC_PALLET_FIELD,
  ENABLE_DPD_DIRECT_LITE_FIELD,
  ENABLE_DPD_DIRECT_PARCEL_FIELD,
  ENABLE_DPD_SMALL_PARCEL_FIELD,
  ENABLED_AIR_EXPRESS_PARCEL_FIELD,
  ENABLED_DPD_CLASSIC_PARCEL_FIELD,
} from "../../../../features/profile/profile.constants";
import styles from "./ProfileInternationalPreferences.module.scss";

const ProfileInternationalPreferences = ({ handleChange }) => (
  <Grid container className={styles.container}>
    <Grid
      container
      justify="space-between"
      className={styles["switch-container"]}
      item
    >
      <Field
        name={ENABLED_AIR_EXPRESS_PARCEL_FIELD}
        component={CustomSwitchWithTitle}
        label={ENABLE_AIR_EXPRESS_PARCEL}
        type="checkbox"
        onChange={(event, newValue) => {
          handleChange(newValue, ENABLED_AIR_EXPRESS_PARCEL_FIELD);
        }}
      />
      <Field
        name={ENABLED_DPD_CLASSIC_PARCEL_FIELD}
        component={CustomSwitchWithTitle}
        label={ENABLE_DPD_CLASSIC_PARCEL}
        type="checkbox"
        onChange={(event, newValue) => {
          handleChange(newValue, ENABLED_DPD_CLASSIC_PARCEL_FIELD);
        }}
      />
      <Field
        name={ENABLE_DPD_DIRECT_PARCEL_FIELD}
        component={CustomSwitchWithTitle}
        label={ENABLE_DPD_DIRECT_PARCEL}
        type="checkbox"
        onChange={(event, newValue) => {
          handleChange(newValue, ENABLE_DPD_DIRECT_PARCEL_FIELD);
        }}
      />
      <Field
        name={ENABLE_DPD_CLASSIC_FREIGHT_FIELD}
        component={CustomSwitchWithTitle}
        label={ENABLE_DPD_CLASSIC_PARCEL_FREIGHT}
        type="checkbox"
        onChange={(event, newValue) => {
          handleChange(newValue, ENABLE_DPD_CLASSIC_FREIGHT_FIELD);
        }}
      />
    </Grid>
    <Grid
      container
      justify="space-between"
      className={styles["switch-container"]}
      item
    >
      <Field
        name={ENABLE_AIR_CLASSIC_PARCEL_FIELD}
        component={CustomSwitchWithTitle}
        label={ENABLE_AIR_CLASSIC_PARCEL}
        type="checkbox"
        onChange={(event, newValue) => {
          handleChange(newValue, ENABLE_AIR_CLASSIC_PARCEL_FIELD);
        }}
      />
      <Field
        name={ENABLE_DPD_SMALL_PARCEL_FIELD}
        component={CustomSwitchWithTitle}
        label={ENABLE_DPD_SMALL_PARCEL}
        type="checkbox"
        onChange={(event, newValue) => {
          handleChange(newValue, ENABLE_DPD_SMALL_PARCEL_FIELD);
        }}
      />
      <Field
        name={ENABLE_DPD_CLASSIC_PALLET_FIELD}
        component={CustomSwitchWithTitle}
        label={ENABLE_DPD_CLASSIC_PALLET}
        type="checkbox"
        onChange={(event, newValue) => {
          handleChange(newValue, ENABLE_DPD_CLASSIC_PALLET_FIELD);
        }}
      />
      <Field
        name={ENABLE_DPD_DIRECT_LITE_FIELD}
        component={CustomSwitchWithTitle}
        label={ENABLE_DPD_DIRECT_LITE}
        type="checkbox"
        onChange={(event, newValue) => {
          handleChange(newValue, ENABLE_DPD_DIRECT_LITE_FIELD);
        }}
      />
    </Grid>
  </Grid>
);

ProfileInternationalPreferences.propTypes = {
  handleChange: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value, field) =>
    dispatch(changeCreateProfileForm(field, value)),
});

export default compose(
  connect(null, mapDispatchToProps),
  withTrackProps(() => ({
    handleChange: (field, value) =>
      createLogEntryPayload(PROFILE_ANALYTICS.CLICK_TOGGLE, {
        field,
        value,
      }),
  }))
)(ProfileInternationalPreferences);
