import compare from "semver/functions/compare";
import {
  LATEST_VERSION_FIELD,
  LATEST_VERSION_FIELD_SHOULD_BE_HIGHER,
} from "./appManagement.constants";

export const validateAppConfigDetailsForm = (values) => {
  if (
    values.minVersion &&
    compare(values.minVersion, values.latestVersion) > 0
  ) {
    return {
      [LATEST_VERSION_FIELD]: LATEST_VERSION_FIELD_SHOULD_BE_HIGHER,
    };
  }
};
