import classNames from "classnames";

import styles from "./SortArrows.module.scss";

export default function SortArrows() {
  return (
    <div className={styles.container}>
      <div className={styles.top} />
      <div className={classNames(styles.bottom)} />
    </div>
  );
}
