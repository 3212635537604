import {
  createAction,
  createActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

const ACTION_NAMESPACE = "TABS";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SET_TABS_STATE: "SET_TABS_STATE",
  SET_TABS_TO_INITIAL: "SET_TABS_TO_INITIAL",
  CLEAR: "CLEAR",
});

export const clearTabsState = () => createAction(ACTIONS.CLEAR);
export const setTabsState = (tabsState) =>
  createPayloadAction(ACTIONS.SET_TABS_STATE, tabsState);

export const setTabsToInitialState = () =>
  createAction(ACTIONS.SET_TABS_TO_INITIAL);
