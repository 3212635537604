import { touch } from "redux-form";

import { REQUIRED_VALIDATION_MODAL_FIELDS } from "./accountDetails.constants";
import { USER_DETAILS_FORMS } from "../../../constants/forms";

export const checkRequiredFields = () => (dispatch) => {
  REQUIRED_VALIDATION_MODAL_FIELDS.forEach((field) => {
    dispatch(touch(USER_DETAILS_FORMS.ACCOUNT_DETAILS, field));
  });
};
