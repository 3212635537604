import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import styles from "./ProfileSearch.module.scss";
import { ReactComponent as Delete } from "../../../../assets/icons/delete-red.svg";
import {
  DELETE_PROFILE_FROM_MANAGEMENT_PAGE,
  PROFILE_MANAGEMENT,
} from "../../../../constants/analytics";
import { showDeleteModal } from "../../../../features/profile/profile.actions";
import { DELETE_PREFIX } from "../../../../features/profile/profile.constants";
import { getProfiles } from "../../../../features/profile/profile.selectors";
import { DPD_PROFILES_PAGE } from "../../../../router/constants";

const SearchItem = ({
  profiles,
  onDelete,
  id,
  field,
  columnIndex,
  onProfileCodeClick,
}) => {
  if (columnIndex === 0) {
    const currentProfile = profiles.find((profile) => profile.id === id);
    return (
      <Link
        to={`${DPD_PROFILES_PAGE}/${id}?account=${currentProfile.account}`}
        onClick={onProfileCodeClick}
      >
        {field}
      </Link>
    );
  }
  if (field && field.toString().includes(DELETE_PREFIX)) {
    const currentProfile = profiles.find((profile) => profile.id === id);
    const profileCode = currentProfile.fields.field0;
    const accountNumber = currentProfile.fields.field2;
    return (
      <IconButton
        onClick={() => {
          onDelete(profileCode, accountNumber);
        }}
        className={styles["date-button"]}
      >
        <Delete width="20" height="20" />
      </IconButton>
    );
  }

  return field;
};

const mapStateToProps = (state) => ({
  profiles: getProfiles(state),
});

const mapDispatchToProps = (dispatch, { id }) => ({
  onDeleteModal: (profileCode, accountNumber) =>
    dispatch(
      showDeleteModal(
        id,
        {
          profileCode,
          accountNumber,
        },
        null,
        {
          loadId: DELETE_PROFILE_FROM_MANAGEMENT_PAGE.LOAD,
          interfaceId: DELETE_PROFILE_FROM_MANAGEMENT_PAGE.INTERFACE_ID,
          confirmActionId: DELETE_PROFILE_FROM_MANAGEMENT_PAGE.YES,
          cancelActionId: DELETE_PROFILE_FROM_MANAGEMENT_PAGE.NO,
        }
      )
    ),
});

SearchItem.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func,
  id: PropTypes.string,
  field: PropTypes.string,
  columnIndex: PropTypes.string,
  onProfileCodeClick: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps((props) => ({
    onDelete: (profileCode, accountNumber) => {
      props.onDeleteModal(profileCode, accountNumber);
      return PROFILE_MANAGEMENT.CLICK_DELETE_PROFILE;
    },
    onProfileCodeClick: () => PROFILE_MANAGEMENT.CLICK_PROFILE_CODE,
  }))
)(SearchItem);
