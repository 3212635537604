import { differenceWith, filter, isEqual, orderBy, sortBy } from "lodash";

import { EXCHANGE_SERVICE_CODE_TO_OMIT } from "./config.constants";

export const filterLocalOutboundServices = (services) =>
  services.filter(
    ({ domesticOutboundService, offshoreOutboundService }) =>
      domesticOutboundService && offshoreOutboundService
  );

export const filterOutboundServices = (services) =>
  services.filter(
    ({ outboundService, offshoreOutboundService }) =>
      outboundService && offshoreOutboundService
  );

export const filterInternationalService = (services) =>
  services.filter(
    ({ outboundServiceInternational }) => outboundServiceInternational
  );

export const filterExchangeServices = (services) =>
  services.filter(
    ({
      exchangeOutboundService,
      exchangeReturnService,
      offshoreExchangeService,
      code,
    }) =>
      exchangeOutboundService &&
      exchangeReturnService &&
      offshoreExchangeService &&
      code !== EXCHANGE_SERVICE_CODE_TO_OMIT
  );

export const filterCurrency = (currencies = []) => {
  const webEntryCurrencies = filter(
    currencies,
    (currency) => currency.webEntry
  );
  const domesticCurrencies = webEntryCurrencies.filter(
    (currency) =>
      currency.currencyCode === "GBP" || currency.currencyCode === "EUR"
  );
  const sortedDomesticCurrencies = orderBy(
    domesticCurrencies,
    ["currencyCode"],
    ["desc"]
  );
  const internationalCurrencies = differenceWith(
    webEntryCurrencies,
    sortedDomesticCurrencies,
    isEqual
  );
  return [
    ...sortedDomesticCurrencies,
    ...sortBy(
      internationalCurrencies,
      (currency) => currency.currencyDescription
    ),
  ];
};
