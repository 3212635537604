import { API_ENDPOINTS } from "../../constants/common";
import { webApi } from "../../utils/api";

export const getCustomersStartsWith = (accountNumber, businessId, limit = 50) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CUSTOMER}/search`,
      query: {
        account: encodeURIComponent(accountNumber),
        businessId,
        limit,
      },
    })
    .then((res) => res.data);

export const updateCustomer = (id, accountNumber, body) =>
  webApi
    .put({
      path: `${API_ENDPOINTS.CUSTOMER}/${encodeURIComponent(id)}`,
      body,
      query: {
        accountNumber,
      },
    })
    .then((res) => res.data);

export const getCustomerByAccountNumber = (accountNumber, businessId) =>
  webApi
    .get({
      path: API_ENDPOINTS.CUSTOMER,
      query: {
        accountNumber,
        businessId,
      },
    })
    .then((res) => res.data);

export const getCustomerIpAddress = (accountNumber, businessId) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CUSTOMER}/ipaddress/${encodeURIComponent(
        `${businessId}*${accountNumber}`
      )}`,
      query: {
        accountNumber,
      },
    })
    .then((res) => res.data);
