import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { CREATE_EDIT_CUSTOMER_USER_ANALYTICS } from "../../../constants/analytics.js";
import { USER_DETAILS_FORMS } from "../../../constants/forms.js";
import AdditionalAccounts from "../../../containers/AdditionalAccounts/index.js";
import {
  getAdditionalAccountNumbers,
  onAccountClick,
} from "../../../features/userDetailsForm/additionalAccounts/additionalAccounts.actions.js";
import { ADDITIONAL_ACCOUNTS_FIELD } from "../../../features/userDetailsForm/additionalAccounts/additionalAccounts.constant.js";
import { getAdditionalAccountsInitialValues } from "../../../features/userDetailsForm/additionalAccounts/additionalAccounts.models.js";
import { isOwnUser } from "../../../features/userDetailsForm/systemAccess/systemAccess.selectors";

const UserAdditionalAccounts = ({
  onBlurFields,
  onAccountItemClick,
  onRemoveAdditionalAccount,
  fetchAdditionalAccountNumbers,
  isOwnUser,
}) => (
  <AdditionalAccounts
    form={USER_DETAILS_FORMS.ADDITIONAL_ACCOUNTS}
    initialValues={getAdditionalAccountsInitialValues()}
    fetchFunction={fetchAdditionalAccountNumbers}
    onItemClick={onAccountItemClick}
    onBlurFields={onBlurFields}
    disabled={isOwnUser}
    onRemove={onRemoveAdditionalAccount}
  />
);

const mapStateToProps = (state) => ({
  isOwnUser: isOwnUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdditionalAccountNumbers: (accountNumber) =>
    dispatch(getAdditionalAccountNumbers(accountNumber)),
  onAccountItemClick: (item) => dispatch(onAccountClick(item)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(() => ({
    onAccountItemClick: (item) =>
      createLogEntryPayload(
        CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ADDITIONAL_ACCOUNTS_ADD,
        {
          item,
        }
      ),
    onRemoveAdditionalAccount: () =>
      CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ADDITIONAL_ACCOUNTS_REMOVE,
    onBlurFields: (field) => {
      if (field === ADDITIONAL_ACCOUNTS_FIELD) {
        return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ADDITIONAL_ACCOUNTS;
      }
    },
  }))
)(UserAdditionalAccounts);

UserAdditionalAccounts.propTypes = {
  fetchAdditionalAccountNumbers: PropTypes.func,
  onAccountItemClick: PropTypes.func,
  onBlurFields: PropTypes.func,
  isOwnUser: PropTypes.bool,
  onRemoveAdditionalAccount: PropTypes.func,
};
