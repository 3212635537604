import { useMemo } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose, lifecycle, withHandlers, withProps } from "recompose";
import qs from "query-string";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import Filters from "./components/FIlters";
import SearchForm from "./components/SearchForm";
import Table from "./components/Table.js";
import InvitedUsersTools from "./components/tableComponents/InvitedUsersTools";
import Tools from "./components/tableComponents/Tools";
import styles from "./ManageUsers.module.scss";
import NoResultsWavingBox from "../../components/NoResultsWavingBox/NoResultsWavingBox";
import PageContainer from "../../components/PageContainer/PageContainer";
import { HOME_PAGE_ANALYTICS } from "../../constants/analytics";
import { ADD_NEW_USER } from "../../constants/strings";
import {
  fetchAccountUsersByFilter,
  onToggleResendInviteClick,
} from "../../features/user/user.actions";
import { ALL_FILTER, INVITED_FILTER } from "../../features/user/user.constants";
import {
  getEmptyTableSubtitle,
  getEmptyTableTitle,
  ROWS_PER_PAGE,
} from "../../features/user/user.model";
import {
  getUserId,
  getFilteredAccountUsers,
  getTotalFilteredAccountUsers,
} from "../../features/user/user.selectors";
import { CUSTOMER_CREATE_USER_PAGE } from "../../router";
import { getPageLink } from "../../router/navigation";
import { getLocationState, parseQueryWithPagination } from "../../utils/query";

const ManageUsers = ({
  users,
  totalUsers,
  emptyTableTitle,
  emptyTableSubtitle,
  currentUserId,
  activeFilter,
  customerId,
  onCreate,
  onToggleResendInvite,
  onSearch,
  onBlurSearch,
  onPageChange,
  refreshUsers,
  location,
}) => {
  const { page, pageSize, searchBy } = useMemo(() => {
    const {
      page = 1,
      pageSize = ROWS_PER_PAGE,
      searchBy,
    } = qs.parse(location.search);

    return {
      page: parseInt(page, 10) - 1,
      pageSize: parseInt(pageSize, 10),
      searchBy,
    };
  }, [location]);

  return (
    <PageContainer>
      <Grid item container spacing={4} className={styles.search}>
        <Grid item>
          <SearchForm
            onSubmit={onSearch}
            onBlur={onBlurSearch}
            initialValues={{ search: searchBy }}
          />
        </Grid>
        <Grid item>
          <Button
            className={styles["add-button"]}
            component={Link}
            onClick={onCreate}
            to={getPageLink(CUSTOMER_CREATE_USER_PAGE, {
              customerId,
            })}
          >
            {ADD_NEW_USER}
          </Button>
        </Grid>
      </Grid>
      <Filters activeFilter={activeFilter} location={location} />

      <Table
        page={page}
        pageSize={pageSize}
        totalUsers={totalUsers}
        users={users}
        rowsPerPage={pageSize}
        customerId={customerId}
        activeFilter={activeFilter}
        currentUserId={currentUserId}
        ToolsComponent={
          activeFilter === INVITED_FILTER ? InvitedUsersTools : Tools
        }
        onPageChange={onPageChange}
        onResendInvite={onToggleResendInvite}
        refreshUsers={refreshUsers}
        showResendInviteButton={activeFilter === INVITED_FILTER}
        showCreatedDate={activeFilter === INVITED_FILTER}
        emptyTableComponent={
          <NoResultsWavingBox
            title={emptyTableTitle}
            subtitle={emptyTableSubtitle}
          />
        }
      />
    </PageContainer> // todo refresh after delete users
  );
};

ManageUsers.propTypes = {
  users: PropTypes.array,
  emptyTableTitle: PropTypes.string,
  emptyTableSubtitle: PropTypes.string,
  currentUserId: PropTypes.string,
  activeFilter: PropTypes.string,
  customerId: PropTypes.string,
  onSearch: PropTypes.func,
  onCreate: PropTypes.func,
  onToggleResendInvite: PropTypes.func,
  onBlurSearch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  pageSize: ROWS_PER_PAGE,
  totalUsers: getTotalFilteredAccountUsers(state),
  users: getFilteredAccountUsers(state),
  currentUserId: getUserId(state),
});

const mapDispatchToProps = (
  dispatch,
  { customerId, activeFilter: filter, search }
) => ({
  onFetchUsersByFilter: (params) => {
    return dispatch(
      fetchAccountUsersByFilter(customerId, {
        filter,
        search,
        ...params,
      })
    );
  },
  onToggleResendInvite: (id) => dispatch(onToggleResendInviteClick(id)),
});

export default compose(
  withProps((props) => {
    const querySearch = parseQueryWithPagination(props.location);
    const activeFilter = querySearch.filter || ALL_FILTER;

    return {
      activeFilter,
      emptyTableTitle: getEmptyTableTitle(activeFilter, querySearch.searchBy),
      emptyTableSubtitle: getEmptyTableSubtitle(
        activeFilter,
        querySearch.searchBy
      ),
      customerId: props.match.params.customerId,
    };
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    refreshUsers:
      ({ onFetchUsersByFilter, location }) =>
      async (params = null) => {
        const querySearch = parseQueryWithPagination(location, {
          initPageQuery: null,
        });
        const requestParams = { ...querySearch, ...params };

        if (requestParams.page) {
          requestParams.page--;
        }

        await onFetchUsersByFilter(requestParams);
      },
  }),
  withHandlers({
    onPageChange:
      ({ history, location, refreshUsers }) =>
      async (page) => {
        const locationState = getLocationState(location);
        const querySearch = parseQueryWithPagination(location, {
          initPageQuery: null,
        });

        querySearch.page = page + 1;

        await refreshUsers(querySearch);

        history.push({
          ...location,
          state: locationState,
          search: qs.stringify(querySearch, { skipNull: true }),
        });
      },
    onSearch:
      ({ location, history, refreshUsers }) =>
      async ({ search: searchBy = null }) => {
        const querySearch = parseQueryWithPagination(location, {
          initPageQuery: null,
        });

        delete querySearch.page;

        const params = {
          ...querySearch,
          searchBy: searchBy || null,
        };

        await refreshUsers(params);

        history.replace({
          pathname: location.pathname,
          search: qs.stringify(params, { skipNull: true }),
        });
      },
  }),
  withTrack({
    loadId: HOME_PAGE_ANALYTICS.LOAD,
    interfaceId: HOME_PAGE_ANALYTICS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onBlurSearch: () => HOME_PAGE_ANALYTICS.SEARCH_TEXT_ENTRY,
    onCreate: () => HOME_PAGE_ANALYTICS.NEW_USER,
  })),
  lifecycle({
    componentDidMount() {
      return this.props.refreshUsers();
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.activeFilter !== this.props.activeFilter ||
        prevProps.customerId !== this.props.customerId
      ) {
        prevProps.refreshUsers({ filter: this.props.activeFilter });
      }
    },
  })
)(ManageUsers);
