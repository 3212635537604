import { Box, Grid, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import HeaderWithStar from "../../../../components/HeaderWithStar/HeaderWithStar";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  OUTBOUND_SERVICE,
  PLEASE_SELECT_SERVICE,
} from "../../../../constants/strings";
import { getInternationalServices } from "../../../../features/config/config.selectors";
import { DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD } from "../../../../features/profile/profile.constants";
import { renderSelectItems } from "./DefaultsDpd";
import styles from "./DefaultsDpd.module.scss";

const DefaultsInternational = ({ onDropdownClick, internationalServices }) => (
  <Box width="80%" className={styles.wrapper}>
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.spacing}
    >
      <HeaderWithStar text={OUTBOUND_SERVICE} required />
      <Grid item className={styles.select}>
        <Field
          name={DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD}
          component={SelectWithTitle}
          displayEmpty
          onClick={() =>
            onDropdownClick(DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD)
          }
          showMetaErrors
        >
          <MenuItem value={""}>{PLEASE_SELECT_SERVICE}</MenuItem>
          {renderSelectItems(internationalServices)}
        </Field>
      </Grid>
    </Grid>
  </Box>
);

DefaultsInternational.propTypes = {
  onDropdownClick: PropTypes.func,
  internationalServices: PropTypes.array,
};

export default compose(
  connect((state) => ({
    internationalServices: getInternationalServices(state),
  })),
  withTrackProps(() => ({
    onDropdownClick: (field) =>
      createLogEntryPayload(PROFILE_ANALYTICS.CLICK_DROPDOWN_LIST, {
        field,
      }),
  }))
)(DefaultsInternational);
