import {
  ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD,
  RESTRICT_UPGRADE_ACTIONS_FIELD,
  SHARE_SHIPMENTS_BY_PROFILE_FIELD,
  SHARE_SHIPMENTS_FIELD,
  USE_JAVA_APPLET_FIELD,
} from "./miscellaneousConfiguration.constants";
import { RESTRICT_ACTIONS_ROLE } from "../systemAccess/systemAccess.constants";

export const prepareMiscellaneousConfiguration = (formValues, { appRoles }) => {
  const miscellaneousConfiguration = Object.entries(formValues).reduce(
    (acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      switch (key) {
        case SHARE_SHIPMENTS_BY_PROFILE_FIELD: {
          acc[SHARE_SHIPMENTS_FIELD] = "2";
          break;
        }
        case USE_JAVA_APPLET_FIELD: {
          acc[key] = "Y";
          break;
        }
        case RESTRICT_UPGRADE_ACTIONS_FIELD: {
          appRoles.push(RESTRICT_ACTIONS_ROLE);
          break;
        }
        default:
          acc[key] = "1";
      }

      return acc;
    },
    {}
  );

  return { miscellaneousConfiguration, appRoles };
};

export const getEodMasterAccountDefaults = () => ({
  [SHARE_SHIPMENTS_FIELD]: false,
  [SHARE_SHIPMENTS_BY_PROFILE_FIELD]: true,
  [ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD]: true,
});
