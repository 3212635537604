import React from "react";
import { Grid } from "@material-ui/core";
import { Field } from "redux-form";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";
import PropTypes from "prop-types";
import {
  API_SETTING_FIELDS,
  EMAIL,
  ENABLE_API_ACCESS,
  LIVE,
  LIVE_FIELDS,
  SANDBOX,
  SANDBOX_FIELDS,
} from "../../features/userDetailsForm/apiSetting/apiSetting.constants";
import VerifyEmail from "../VerifyEmail";
import ApiUsersKeys from "../ApiUsersKeys";
import styles from "./ApiSetting.module.scss";

const ApiSetting = ({
  onInputChange,
  showApiSettings,
  onVerifyEmailClick,
  onApiSettingChange,
  addKey,
  deleteKey,
  verifyEmailsData,
  handleSubmit,
  sandboxKeys,
  liveKeys,
  isEmailSent,
}) => (
  <Grid
    container
    spacing={3}
    alignItems={"center"}
    className={styles.container}
  >
    <Grid item xs={12}>
      <Field
        name={API_SETTING_FIELDS.API_ACCESS}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={ENABLE_API_ACCESS}
        onChange={(event, value, previousValue, name) => {
          onApiSettingChange(event, value, previousValue, name);
          onInputChange(API_SETTING_FIELDS.API_ACCESS, value);
        }}
      />
    </Grid>
    {showApiSettings && (
      <Field
        component={VerifyEmail}
        name={API_SETTING_FIELDS.EMAIL}
        label={EMAIL}
        verifyEmailsData={verifyEmailsData}
        onVerifyEmailClick={handleSubmit(onVerifyEmailClick)}
        isEmailSent={isEmailSent}
        disabled={verifyEmailsData.showKeys}
        onBlur={() => null} // TODO: add analytics
      />
    )}
    {verifyEmailsData.showKeys && (
      <>
        <ApiUsersKeys
          keys={sandboxKeys}
          addKey={addKey}
          fields={SANDBOX_FIELDS}
          title={SANDBOX}
          deleteKey={deleteKey}
        />
        <ApiUsersKeys
          keys={liveKeys}
          addKey={addKey}
          fields={LIVE_FIELDS}
          title={LIVE}
          deleteKey={deleteKey}
        />
      </>
    )}
  </Grid>
);

ApiSetting.defaultProps = {
  onInputChange: () => null,
  onApiSettingChange: () => null,
  addKey: () => null,
  deleteKey: () => null,
  showApiSettings: false,
  sandboxKeys: [],
  liveKeys: [],
};

ApiSetting.propTypes = {
  onInputChange: PropTypes.func,
  addKey: PropTypes.func,
  deleteKey: PropTypes.func,
  onVerifyEmailClick: PropTypes.func,
  onApiSettingChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  showApiSettings: PropTypes.bool.isRequired,
  isEmailSent: PropTypes.bool,
  verifyEmailsData: PropTypes.object,
  sandboxKeys: PropTypes.array,
  liveKeys: PropTypes.array,
};

export default ApiSetting;
