import { initialize, touch, updateSyncErrors } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import {
  MASTER_PASSWORD_LOCK_FIELD,
  MASTER_USERNAME_FIELD,
} from "./createMasterUser.constants";
import { buildUsername, prepareMasterUser } from "./createMasterUser.models";
import { getCreateMasterUserValues } from "./createMasterUser.selectors";
import { CREATE_STAFF_OVERRIDE_USER_FOR_MYDPD } from "../../constants/analytics";
import { CREATE_MASTER_USER_FORM } from "../../constants/forms";
import {
  CANCEL,
  CONFIRM,
  MY_DPD_USER_ALREADY_CREATED,
} from "../../constants/strings";
import { usernameAsyncValidation } from "../../utils/commonValidation";
import { createLoadingAction } from "../loader/loader.actions";
import { showModal } from "../modal/modal.actions";
import { CREATE_MASTER_USER } from "../modal/modal.constants";
import { getCurrentUserEmail } from "../user/user.selectors";
import * as userService from "../user/user.service";
import {
  getBusinessId,
  getUserInfo,
  getUsername,
} from "../userDetailsForm/userDetailsForm.selector";

const ACTION_NAMESPACE = "masterUser";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  CREATE_MASTER_USER: createAsyncActionTypes("CREATE_MASTER_USER"),
});

export const initializeForm = () => async (dispatch, getState) => {
  const state = getState();
  const currentUserUsername = getCurrentUserEmail(state);
  const editUserUsername = getUsername(state);
  const businessId = getBusinessId(state);

  const masterUsername = buildUsername(
    currentUserUsername,
    editUserUsername,
    businessId
  );
  await dispatch(
    initialize(CREATE_MASTER_USER_FORM, {
      [MASTER_USERNAME_FIELD]: masterUsername,
      [MASTER_PASSWORD_LOCK_FIELD]: 1,
    })
  );

  try {
    await usernameAsyncValidation({ username: masterUsername }, true);
  } catch (e) {
    dispatch(
      updateSyncErrors(CREATE_MASTER_USER_FORM, {
        [MASTER_USERNAME_FIELD]: MY_DPD_USER_ALREADY_CREATED,
      })
    );
  }
  dispatch(touch(CREATE_MASTER_USER_FORM, MASTER_USERNAME_FIELD));
};

export const onCreateMasterUser = () => (dispatch) =>
  dispatch(
    showModal({
      interfaceId: CREATE_STAFF_OVERRIDE_USER_FOR_MYDPD.INTERFACE_ID,
      loadId: CREATE_STAFF_OVERRIDE_USER_FOR_MYDPD.LOAD,
      confirmActionId: CREATE_STAFF_OVERRIDE_USER_FOR_MYDPD.CONFIRM,
      cancelActionId: CREATE_STAFF_OVERRIDE_USER_FOR_MYDPD.CANCEL,
      customComponent: CREATE_MASTER_USER,
      cancelButtonText: CANCEL,
      confirmButtonText: CONFIRM,
      onConfirmClick: () => {
        dispatch(createMasterUser());
      },
    })
  );

export const createMasterUser = createLoadingAction(
  createAsyncAction(
    () => async (dispatch, getState) => {
      const state = getState();
      const formValues = getCreateMasterUserValues(state);
      const user = getUserInfo(state);
      return userService.createMasterUser(prepareMasterUser(formValues, user));
    },
    ACTIONS.CREATE_MASTER_USER
  )
);
