import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import {
  DISABLE_NOTIFICATION_ON_PRINT,
  ENABLE_BY_AUTOMATION_TEAM,
  PLEASE_ENSURE_PERMISSION,
  STORE_SHIPMENTS_SIX_MONTHS,
  THIRTY_DAYS_FORWARD_DATING,
} from "../../constants/strings";
import {
  DISABLE_NOTIFICATION_ON_PRINT_FIELD,
  EXTENDED_HOLD_POLICY,
  FORWARD_DATE_OVER_7_DAYS_FIELD,
} from "../../features/userDetailsForm/advancedCustomerConfiguration/advancedCustomerConfiguration.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";
import WarningText from "../WarningText/WarningText";
import styles from "./AdHocForwardDating.module.scss";

const AdHocForwardDating = ({
  onChangeToggle,
  disableSixMonths,
  onForwardDateOver7DaysChange,
}) => (
  <Grid>
    <Grid item container className={styles.text}>
      <WarningText text={PLEASE_ENSURE_PERMISSION} />
    </Grid>
    <Grid container justify="space-between" className={styles.container}>
      <Field
        name={FORWARD_DATE_OVER_7_DAYS_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={THIRTY_DAYS_FORWARD_DATING}
        onChange={(event, value) => {
          onChangeToggle(FORWARD_DATE_OVER_7_DAYS_FIELD, value);
          onForwardDateOver7DaysChange(value);
        }}
      />
      <Field
        name={DISABLE_NOTIFICATION_ON_PRINT_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={DISABLE_NOTIFICATION_ON_PRINT}
        disabled={true}
        onChange={(event, value) => {
          onChangeToggle(DISABLE_NOTIFICATION_ON_PRINT_FIELD, value);
        }}
      />
    </Grid>
    <Field
      name={EXTENDED_HOLD_POLICY}
      component={CustomSwitchWithTitle}
      type="checkbox"
      label={STORE_SHIPMENTS_SIX_MONTHS}
      disabled={disableSixMonths}
      onChange={(event, value) => {
        onChangeToggle(EXTENDED_HOLD_POLICY, value);
      }}
    />
    <WarningText text={ENABLE_BY_AUTOMATION_TEAM} />
  </Grid>
);

AdHocForwardDating.propTypes = {
  onChangeToggle: PropTypes.func,
  disableSixMonths: PropTypes.bool,
  onForwardDateOver7DaysChange: PropTypes.func,
};

AdHocForwardDating.defaultProps = {
  onChangeToggle: () => {},
  disableSixMonths: true,
};

export default AdHocForwardDating;
