import { connect } from "react-redux";
import { compose } from "recompose";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import References from "../../../../components/ShippingSettings/References";
import { CREATE_EDIT_CUSTOMER_USER_ANALYTICS } from "../../../../constants/analytics";
import { isSendersRef1Disabled } from "../../../../features/userDetailsForm/advancedCustomerConfiguration/advancedCustomerConfiguration.selectors";
import {
  onReferenceOneChange,
  onSendersUniqChange,
} from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.actions";
import { SENDERS_REF1_FIELD } from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.constants";
import { getShippingSettingsFormValues } from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.selectors";

const mapStateToProps = (state) => ({
  formValues: getShippingSettingsFormValues(state),
  isSendersRef1Disabled: isSendersRef1Disabled(state),
});

const mapDispatchToProps = {
  onSendersUniqChange: (e, value) => (dispatch) => {
    !value && dispatch(onSendersUniqChange());
  },
  onReferenceOneChange: (e, value) => (dispatch) => {
    !value && dispatch(onReferenceOneChange());
  },
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(() => ({
    onReferenceOneChange: (e, value) =>
      createLogEntryPayload(CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_TOGGLE, {
        field: SENDERS_REF1_FIELD,
        value,
      }),
  }))
)(References);
