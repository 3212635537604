import { TablePagination } from "@material-ui/core";
import PropTypes from "prop-types";
import qs from "query-string";
import { withRouter } from "react-router";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { ACTIVITY_MONITOR_ANALYTICS } from "../../../../constants/analytics";
import { getSelectedPage } from "../../../../features/activityMonitor/activityMonitor.model";
import TablePaginationActions from "../../../main/components/tableComponents/TablePaginationActions";
import styles from "./ActivityMonitorPagination.module.scss";

const Pagination = ({ totalItems, location, onChangePage }) => {
  const { limit, page } = getSelectedPage(qs.parse(location.search).page);
  return (
    <TablePagination
      rowsPerPageOptions={[]}
      count={totalItems}
      rowsPerPage={limit}
      component={"div"}
      page={page - 1}
      onChangePage={onChangePage}
      ActionsComponent={TablePaginationActions}
      labelDisplayedRows={({ from, to, count }) => (
        <span className={styles.counter}>
          {`RESULTS ${from} TO ${to} (OF ${count})`}
        </span>
      )}
      className={styles.pagination}
      classes={{ spacer: styles.paginationSpacer }}
    />
  );
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withTrackProps(({ history, location }) => ({
    onChangePage: (event, newPage) => {
      const nextPage = newPage + 1;
      const currentQuery = qs.parse(location.search);
      history.push({
        ...location,
        search: qs.stringify({ ...currentQuery, page: nextPage }),
      });

      if (parseInt(currentQuery.page || 1) < nextPage) {
        return ACTIVITY_MONITOR_ANALYTICS.CLICK_NEXT_PAGE;
      }
      return ACTIVITY_MONITOR_ANALYTICS.CLICK_PREVIOUS_PAGE;
    },
  }))
)(Pagination);
