import { TableCell, TableRow } from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "./EmptyTableComponent.module.scss";

export const EmptyTableComponent = ({ children }) => (
  <TableRow>
    <TableCell className={styles.emptyCell} colSpan={5} padding="none">
      {children}
    </TableCell>
  </TableRow>
);

EmptyTableComponent.propTypes = {
  children: PropTypes.node,
};
