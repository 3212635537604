import PropTypes from "prop-types";

import GradientHeader from "../../../../components/GradientHeader/GradientHeader";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import { PROFILE_MANAGEMENT } from "../../../../constants/strings";
import ProfileSearch from "../../components/ProfileManagement";

const Profiles = ({ history }) => (
  <PageContainer>
    <GradientHeader title={PROFILE_MANAGEMENT} />
    <ProfileSearch history={history} />
  </PageContainer>
);

Profiles.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Profiles;
