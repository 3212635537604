import {
  createAction,
  createActionTypes,
} from "@dpdgroupuk/redux-action-creator";
const ACTION_NAMESPACE = "UI/LOADER";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  TOGGLE_SHOW_LOADER: "TOGGLE_SHOW_LOADER",
});

export const toggleShowLoader = () => createAction(ACTIONS.TOGGLE_SHOW_LOADER);

export const createLoadingAction =
  (loadFunction) =>
  (...args) =>
  (dispatch, getState) => {
    dispatch(toggleShowLoader());
    let loading;

    try {
      loading = loadFunction(...args);

      if (typeof loading === "function") {
        loading = loading(dispatch, getState);
      }
    } catch (e) {
      loading = Promise.reject(e);
    }

    return loading.then(
      (result) => {
        dispatch(toggleShowLoader());
        return result;
      },
      (e) => {
        dispatch(toggleShowLoader());
        // propagate error
        throw e;
      }
    );
  };
