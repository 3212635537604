import { PureComponent } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import get from "lodash/get";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import styles from "./SideBar.module.scss";
import { ReactComponent as LogOut } from "../../assets/icons/log-out.svg";
import {
  MAIN_MENU_FIRST_PHASE,
  MAIN_MENU_SECOND_PHASE,
} from "../../constants/analytics";
import { ACCOUNT, SWITCH_ACCOUNT } from "../../constants/strings";
import {
  onCustomerAccountClick,
  openSignOutDialog,
} from "../../features/auth/auth.actions";
import {
  getCustomerAdminAccounts,
  isCurrentUserDpdAdmin,
} from "../../features/user/user.selectors";
import { CUSTOMER_ACTIVITY_MONITOR, CUSTOMER_USERS_PAGE } from "../../router";
import SelectItem from "../SelectItem/SelectItem";
import SelectWithTitleStateFull from "../SelectWithTitle/SelectWithTitleStateFull";
import SvgIconComponent from "../SvgIcon/svgIcon";

class SideBar extends PureComponent {
  renderCustomerDropDown = () => (
    <Box
      className={styles.switchContainer}
      display="flex"
      justifyContent="center"
      my={2}
    >
      <Route
        exact
        path={[CUSTOMER_USERS_PAGE, CUSTOMER_ACTIVITY_MONITOR]}
        component={({ match }) => (
          <SelectWithTitleStateFull
            displayEmpty
            className={styles.dropdown}
            containerStyles={styles.dropdownContainer}
            menuContainerStyles={styles.dropdownMenu}
            renderValue={(value) =>
              (value && `${value} Account`) || SWITCH_ACCOUNT
            }
            onClick={this.props.onClickAccount}
            onChange={(selectedItem) => {
              this.props.onCustomerAccountClick(selectedItem, match);
            }}
          >
            {this.props.userAccounts.map((item) => (
              <MenuItem
                key={item}
                value={item}
                classes={{ selected: styles.selectedItem }}
              >
                <SelectItem title={`${item} Account`} />
              </MenuItem>
            ))}
          </SelectWithTitleStateFull>
        )}
      />
    </Box>
  );

  render() {
    const {
      children,
      isUserDpdAdmin,
      onSignOut,
      userAccounts,
      match: {
        params: { customerId },
      },
    } = this.props;
    const shouldShowDropDown =
      !isUserDpdAdmin && !!get(userAccounts, "length", 0);

    return (
      <Grid container className={styles.container} direction="column">
        <Grid item>
          <section className={classNames(styles.logo)}>
            <SvgIconComponent name="dpd" width="71px" height="71px" />
            {shouldShowDropDown && (
              <span className={classNames(styles.accountLabel)}>
                {`${ACCOUNT}: ${customerId}`}
              </span>
            )}
          </section>
        </Grid>
        {shouldShowDropDown && this.renderCustomerDropDown()}
        <Grid item>
          {children}
          <section className={classNames(styles.logout)}>
            <IconButton aria-label="Delete" onClick={onSignOut}>
              <LogOut />
            </IconButton>
          </section>
        </Grid>
      </Grid>
    );
  }
}

SideBar.propTypes = {
  children: PropTypes.node,
  isUserDpdAdmin: PropTypes.bool,
  onSignOut: PropTypes.func,
  onCustomerAccountClick: PropTypes.func,
  onClickAccount: PropTypes.func,
  userAccounts: PropTypes.array,
  account: PropTypes.string,
  isEdit: PropTypes.bool,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isUserDpdAdmin: isCurrentUserDpdAdmin(state),
  userAccounts: getCustomerAdminAccounts(state),
});

const mapDispatchToProps = {
  onSignOut: openSignOutDialog,
  onCustomerAccountClick,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps((props) => ({
    onSignOut: () => {
      if (props.isUserDpdAdmin) {
        return MAIN_MENU_FIRST_PHASE.CLICK_LOGOUT;
      }
      return MAIN_MENU_SECOND_PHASE.CLICK_CLICK_LOGOUT;
    },
    onCustomerAccountClick: () => MAIN_MENU_SECOND_PHASE.CLICK_ACCOUNT_NUMBER,
    onClickAccount: () =>
      MAIN_MENU_SECOND_PHASE.CLICK_ACCOUNT_NUMBER_DROPDOWN_LIST,
  }))
)(SideBar);
