import { ACTIONS } from "./loader.actions";

export const initialState = {
  isLoaderShown: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_SHOW_LOADER:
      return {
        ...state,
        isLoaderShown: !state.isLoaderShown,
      };
    default:
      return state;
  }
};
