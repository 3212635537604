import classNames from "classnames";
import PropTypes from "prop-types";

import InputWithTitle from "../InputWithTitle/InputWithTitle";
import StyledButtonIcon from "../StyledSearchIcon/StyledButtonIcon";
import styles from "./InputWithActionButton.module.scss";

const InputWithActionButton = ({
  onButtonClick,
  buttonIcon,
  smallInput,
  disableActionButton,
  ...otherProps
}) => (
  <div className={styles.container}>
    <InputWithTitle
      inputStyles={smallInput && styles.inputStyles}
      {...otherProps}
    />
    <div
      className={classNames(
        styles["action-button"],
        smallInput && styles.smallInput
      )}
    >
      <StyledButtonIcon
        onClick={onButtonClick}
        icon={buttonIcon}
        disabled={disableActionButton}
      />
    </div>
  </div>
);

InputWithActionButton.propTypes = {
  onButtonClick: PropTypes.func,
  buttonIcon: PropTypes.node,
  smallInput: PropTypes.bool,
  disableActionButton: PropTypes.bool,
};

InputWithActionButton.defaultProps = {
  disableActionButton: false,
};

export default InputWithActionButton;
