import { useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import styles from "./ProfileAccount.module.scss";
import ProfileAccountDpdLocal from "./ProfileAccountDpdLocal";
import ProfileDpdAccount from "./ProfileDpdAccount";
import ProfileInternationalAccount from "./ProfileInternationalAccount";
import DpdIconLocal from "../../../../assets/icons/dpd-local.svg";
import DpdLogo from "../../../../assets/icons/dpd.svg";
import SimpleTabs from "../../../../components/CustomTabs/CustomTabs";
import TabTitleWithIcon from "../../../../components/CustomTabs/TabTitleWithIcon";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import { DPD, DPD_LOCAL, INTERNATIONAL } from "../../../../constants/strings";
import {
  shouldShowAccountDpdLocalTabBadge,
  shouldShowAccountDpdTabBadge,
  shouldShowAccountInternationalTabBadge,
} from "../../../../features/profile/profile.selectors";

const analyticsData = {
  [DPD]: PROFILE_ANALYTICS.CLICK_DPD_TAB,
  [DPD_LOCAL]: PROFILE_ANALYTICS.CLICK_DPD_LOCAL_TAB,
  [INTERNATIONAL]: PROFILE_ANALYTICS.CLICK_INTERNATIONAL_TAB,
};

const ProfileAccount = ({ activeTabIndex }) => {
  const [childActiveTabIndex, setChildActiveTabIndex] = useState(0);
  const showDpdTabBadge = useSelector(shouldShowAccountDpdTabBadge);

  const showInternationalTabBadge = useSelector(
    shouldShowAccountInternationalTabBadge
  );

  const showDpdLocalTabBadge = useSelector(shouldShowAccountDpdLocalTabBadge);

  const profileAccountTabs = [
    {
      label: <TabTitleWithIcon icon={DpdLogo} title={DPD} key={DPD} />,
      analyticsId: PROFILE_ANALYTICS.CLICK_DPD_TAB,
      withBadge: showDpdTabBadge,
    },
    {
      label: INTERNATIONAL,
      analyticsId: PROFILE_ANALYTICS.CLICK_INTERNATIONAL_TAB,
      withBadge: showInternationalTabBadge,
    },
    {
      label: (
        <TabTitleWithIcon
          icon={DpdIconLocal}
          title={DPD_LOCAL}
          key={DPD_LOCAL}
        />
      ),
      analyticsId: PROFILE_ANALYTICS.CLICK_DPD_LOCAL_TAB,
      withBadge: showDpdLocalTabBadge,
    },
  ];

  return (
    <SimpleTabs
      analyticsData={analyticsData}
      tabs={profileAccountTabs}
      tabsStyles={styles.tabs}
      onChange={(activeTabIndex) => {
        setChildActiveTabIndex(activeTabIndex);
      }}
    >
      <ProfileDpdAccount />
      <ProfileInternationalAccount
        activeTabIndex={activeTabIndex}
        childActiveTabIndex={childActiveTabIndex}
      />
      <ProfileAccountDpdLocal />
    </SimpleTabs>
  );
};

ProfileAccount.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
};

export default ProfileAccount;
