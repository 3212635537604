import { IconButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";

import styles from "./Tools.module.scss";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import SvgIconComponent from "../../../../components/SvgIcon/svgIcon";
import { INVITATION_SENT } from "../../../../constants/strings";

const useThemeStyles = makeStyles((theme) => ({
  invitationText: {
    color: theme.palette.primary.main,
    visibility: "visible",
    fontSize: 16,
    fontWeight: 400,
    textTransform: "initial",
    lineHeight: 1.43,
    wordBreak: "normal",
  },
}));

const InvitedUsersTools = ({
  id,
  onDeletePress,
  onResendInvite,
  adminUser,
  resendInvite,
  showResendInviteButton,
  user,
}) => {
  const themeStyles = useThemeStyles(useTheme());
  return (
    <section className={styles["tools-container"]}>
      {!resendInvite && showResendInviteButton && (
        <IconButton
          onClick={() => {
            onResendInvite(id);
          }}
        >
          <SvgIconComponent name="resend" />
        </IconButton>
      )}
      {!adminUser && !resendInvite && (
        <IconButton
          onClick={() => {
            onDeletePress(id, user);
          }}
        >
          <Delete />
        </IconButton>
      )}
      {resendInvite && (
        <div className={themeStyles.invitationText}>{INVITATION_SENT}</div>
      )}
    </section>
  );
};

InvitedUsersTools.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onDeletePress: PropTypes.func,
  onResendInvite: PropTypes.func,
  user: PropTypes.object,
  adminUser: PropTypes.bool,
  accountSuspended: PropTypes.bool,
  showResendInviteButton: PropTypes.bool,
  resendInvite: PropTypes.bool,
};

export default InvitedUsersTools;
