import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import styles from "./LabelSettings.module.scss";
import {
  HOMECALL_LABEL_PREFERENCE,
  HOMECALL_LABEL_PREFERENCE_ITEMS,
  INCLUDE_CUSTOMER_CODE,
  INCLUDE_TRACKING_BARCODE,
  REFERENCE_IN_CUSTOMER_ZONE,
  REFERENCE_IN_CUSTOMER_ZONE_AS_BARCODE,
  REFERENCE_IN_CUSTOMER_ZONE_BARCODE_ITEMS,
  REFERENCE_IN_CUSTOMER_ZONE_ITEMS,
  USE_6_INCH_LABELS,
} from "../../constants/strings";
import {
  HOMECALL_REFERENCE,
  INCLUDE_CUSTOMER_CODE_FIELD,
  INCLUDE_REFERENCE_AS_BARCODE,
  INCLUDE_REFERENCE_CUSTOMER_ZONE,
  INCLUDE_TRACKING_BARCODE_FIELD,
  USE_6_INCH_LABEL_FIELD,
} from "../../features/userDetailsForm/userDetails/userDetails.constants";
import HeaderWithStar from "../HeaderWithStar/HeaderWithStar";
import SelectItem from "../SelectItem/SelectItem";
import SelectWithTitle from "../SelectWithTitle/SelectWithTitle";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

const renderSelectItems = (items) =>
  items.map(({ title, value }) => (
    <MenuItem key={title + value} value={value}>
      <SelectItem title={title} />
    </MenuItem>
  ));

const LabelSettings = ({
  onInputChange,
  showLabelSettings,
  resetLabelSettings,
}) => (
  <Grid container item direction="column" spacing={5} md={11}>
    <Grid item>
      <Field
        name={USE_6_INCH_LABEL_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        label={USE_6_INCH_LABELS}
        onChange={(event, value) => {
          !value && resetLabelSettings();
          onInputChange(USE_6_INCH_LABEL_FIELD, value);
        }}
      />
    </Grid>
    {showLabelSettings && (
      <>
        <Grid
          container
          item
          justify="space-between"
          direction="row"
          alignItems="center"
        >
          <HeaderWithStar
            text={REFERENCE_IN_CUSTOMER_ZONE}
            headerStyles={styles.headerStyles}
          />
          <Field
            component={SelectWithTitle}
            displayEmpty
            name={INCLUDE_REFERENCE_CUSTOMER_ZONE}
            className={styles.dropdown}
            onChange={(event, value) => {
              onInputChange(INCLUDE_REFERENCE_CUSTOMER_ZONE, value);
            }}
          >
            {renderSelectItems(REFERENCE_IN_CUSTOMER_ZONE_ITEMS)}
          </Field>
          <HeaderWithStar
            text={REFERENCE_IN_CUSTOMER_ZONE_AS_BARCODE}
            headerStyles={styles.headerStyles}
          />
          <Field
            component={SelectWithTitle}
            displayEmpty
            name={INCLUDE_REFERENCE_AS_BARCODE}
            className={styles.dropdown}
            onChange={(event, value) => {
              onInputChange(INCLUDE_REFERENCE_AS_BARCODE, value);
            }}
          >
            {renderSelectItems(REFERENCE_IN_CUSTOMER_ZONE_BARCODE_ITEMS)}
          </Field>
        </Grid>
        <Grid container item direction="row" spacing={5}>
          <Grid item>
            <Field
              name={INCLUDE_TRACKING_BARCODE_FIELD}
              component={CustomSwitchWithTitle}
              type="checkbox"
              label={INCLUDE_TRACKING_BARCODE}
              onChange={(event, value) => {
                onInputChange(INCLUDE_TRACKING_BARCODE_FIELD, value);
              }}
            />
          </Grid>
          <Grid item>
            <Field
              name={INCLUDE_CUSTOMER_CODE_FIELD}
              component={CustomSwitchWithTitle}
              type="checkbox"
              label={INCLUDE_CUSTOMER_CODE}
              onChange={(event, value) => {
                onInputChange(INCLUDE_CUSTOMER_CODE_FIELD, value);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          justify="space-between"
          direction="row"
          alignItems="center"
        >
          <HeaderWithStar text={HOMECALL_LABEL_PREFERENCE} />
          <Field
            component={SelectWithTitle}
            displayEmpty
            name={HOMECALL_REFERENCE}
            className={styles.dropdown}
            onChange={(event, value) => {
              onInputChange(HOMECALL_REFERENCE, value);
            }}
          >
            {renderSelectItems(HOMECALL_LABEL_PREFERENCE_ITEMS)}
          </Field>
        </Grid>
      </>
    )}
  </Grid>
);

LabelSettings.defaultProps = {
  onInputChange: () => null,
  showLabelSettings: false,
  resetLabelSettings: () => null,
};

LabelSettings.propTypes = {
  onInputChange: PropTypes.func,
  resetLabelSettings: PropTypes.func,
  showLabelSettings: PropTypes.bool.isRequired,
};

export default LabelSettings;
