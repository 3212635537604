import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./TablePaginationActions.module.scss";
import SvgIconComponent from "../../../../components/SvgIcon/svgIcon";
import { NEXT_PAGE, PREVIOUS_PAGE } from "../../../../constants/strings";

const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <Grid container direction="row" className={styles["buttons-container"]}>
      <Grid item>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label={PREVIOUS_PAGE}
          className={styles["pagination-button"]}
        >
          <SvgIconComponent
            name="next"
            className={classNames(
              styles.previous,
              page === 0 && styles["arrow-disabled"]
            )}
          />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label={NEXT_PAGE}
          className={styles["pagination-button"]}
        >
          <SvgIconComponent
            name="next"
            className={classNames(
              page >= Math.ceil(count / rowsPerPage) - 1 &&
                styles["arrow-disabled"]
            )}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;
