import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  item: {
    fontSize: 15,
    minWidth: 110,
    display: "flex",
    alignItems: "center",
  },
}));

const SelectItem = ({ icon, title, containerStyle = {} }) => {
  const styles = useStyles();

  return (
    <div className={classNames(styles.item, containerStyle)}>
      {icon && <img src={icon} height={35} width={35} />}
      <Typography variant="inherit">{title}</Typography>
    </div>
  );
};

SelectItem.propTypes = {
  value: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  containerStyle: PropTypes.object,
};

export default SelectItem;
