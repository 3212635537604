import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import {
  ALLOCATE_SENDERS_REFERENCE,
  ENABLE_SCANNING_SCREEN,
  ENSURE_REFERENCE_UNIQUE,
  SENDERS_REFERENCE_ONE,
  SENDERS_REFERENCE_THREE,
  SENDERS_REFERENCE_TWO,
  UPDATE_RECORDS_WITH_DUPLICATE,
} from "../../constants/strings";
import {
  ALLOCATE_SENDERS_FIELD,
  ENABLE_SCANNING_SCREEN_FIELD,
  SENDERS_REF1_FIELD,
  SENDERS_REF2_FIELD,
  SENDERS_REF3_FIELD,
  SENDERS_REF_UNIQUE_FIELD,
  UPDATE_RECORDS_DUPLICATE_FIELD,
} from "../../features/userDetailsForm/shippingSettings/shippingSettings.constants";
import { CheckBox } from "../CheckBox/CheckBox";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";
import styles from "./References.module.scss";

const References = ({
  onInputChange,
  formValues: { sendersRef1, sendersRefUnique },
  onSendersUniqChange,
  onReferenceOneChange,
  isSendersRef1Disabled,
}) => (
  <Grid container justify="space-between">
    <Grid item container justify="space-between">
      <Grid item className={styles.switch}>
        <Field
          name={SENDERS_REF1_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          label={SENDERS_REFERENCE_ONE}
          disabled={isSendersRef1Disabled}
          onChange={onReferenceOneChange}
        />
      </Grid>
      <Grid item className={styles.switch}>
        <Field
          name={SENDERS_REF2_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          label={SENDERS_REFERENCE_TWO}
          onChange={(event, value) => {
            onInputChange(SENDERS_REF2_FIELD, value);
          }}
        />
      </Grid>
      <Grid item className={styles.switch}>
        <Field
          name={SENDERS_REF3_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          label={SENDERS_REFERENCE_THREE}
          onChange={(event, value) => {
            onInputChange(SENDERS_REF3_FIELD, value);
          }}
        />
      </Grid>
    </Grid>
    {!!sendersRef1 && (
      <>
        <Grid item className={styles["checkbox-container"]}>
          <Field
            name={SENDERS_REF_UNIQUE_FIELD}
            component={CheckBox}
            type="checkbox"
            label={ENSURE_REFERENCE_UNIQUE}
            onChange={onSendersUniqChange}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          className={styles.options}
        >
          <Field
            name={ENABLE_SCANNING_SCREEN_FIELD}
            component={CheckBox}
            type="checkbox"
            label={ENABLE_SCANNING_SCREEN}
            disabled={!sendersRefUnique}
          />
          <Field
            name={ALLOCATE_SENDERS_FIELD}
            component={CheckBox}
            type="checkbox"
            label={ALLOCATE_SENDERS_REFERENCE}
            disabled={!sendersRefUnique}
          />
          <Field
            name={UPDATE_RECORDS_DUPLICATE_FIELD}
            component={CheckBox}
            type="checkbox"
            label={UPDATE_RECORDS_WITH_DUPLICATE}
            disabled={!sendersRefUnique}
          />
        </Grid>
      </>
    )}
  </Grid>
);

export default References;

References.defaultProps = {
  onInputChange: () => null,
  onSendersUniqChange: () => null,
  onReferenceOneChange: () => null,
  isSendersRef1Disabled: false,
  formValues: {},
};

References.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  onSendersUniqChange: PropTypes.func.isRequired,
  isSendersRef1Disabled: PropTypes.bool.isRequired,
  onReferenceOneChange: PropTypes.func.isRequired,
};
