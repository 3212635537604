import find from "lodash/find";
import get from "lodash/get";

import { RESTRICTION_2DAY } from "./serviceRestrictions.constants";
import filter from "lodash/filter";

export const prepareServiceRestrictions = ({ serviceRestricted }) => {
  const values = serviceRestricted.reduce(
    (acc, { postcode, restriction }) => acc.concat({ postcode, restriction }),
    []
  );

  return { serviceRestricted: values };
};

export const getSectorSelectValue = (
  serviceRestrictionHelper,
  serviceRestrictions,
  serviceWithZeroRestrictionCode,
  mapping
) =>
  find(serviceRestrictionHelper, { postcode: mapping })
    ? serviceRestrictions
    : serviceWithZeroRestrictionCode;

export const get2DayRestrictionCode = (restriction) =>
  get(
    find(restriction, { restrictionName: RESTRICTION_2DAY }),
    "restrictionCode"
  );

export const getRestrictionsInitialValues = () => ({
  serviceRestricted: [],
  mapping: "",
});

export const getZeroRestrictionItems = (serviceRestrictions) =>
  filter(
    serviceRestrictions,
    (restriction) => `${restriction.restrictionCode}` === "0"
  );
