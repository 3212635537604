import { isArray } from "lodash";
import get from "lodash/get";

import { ADMIN_ACCOUNT, EOD_MASTER_USER } from "./customer.constants";
import { PERMITTED_IP_FIELD } from "../teamSettings/teamSettings.constants";

export const formatCustomerItem = ({ account, customername = "" }) =>
  `${account} ${customername}`;

export const isAdminAccount = (accountId) => accountId === ADMIN_ACCOUNT;

export const getAccountPermittedIpById = (accounts, id) =>
  get(accounts[id], PERMITTED_IP_FIELD, []);

export const getEodMasterUser = (accounts, id) =>
  get(accounts[id], EOD_MASTER_USER);

export const convertToAssociativeArray = (key) => (items) => {
  const array = isArray(items) ? items : [items];
  return array.reduce((acc, item) => {
    if (item) {
      acc[item[key]] = item;
    }

    return acc;
  }, {});
};
