import PropTypes from "prop-types";

import Link from "../../../../components/Link";
import { FORGOT_PASSWORD } from "../../../../constants/strings";
import { FORGOT_PASSWORD_PAGE } from "../../../../router";

const ForgotPassword = ({ onForgotPassword }) => (
  <>
    <Link to={FORGOT_PASSWORD_PAGE} onClick={onForgotPassword}>
      {FORGOT_PASSWORD}
    </Link>
  </>
);

ForgotPassword.propTypes = {
  onForgotPassword: PropTypes.func,
};

export default ForgotPassword;
