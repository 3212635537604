import firebaseApp from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { API_ENDPOINTS } from "../../constants/common";
import api from "../../utils/api";
import { customerFirebase as firebase } from "../../utils/firebase";
import {
  _DOMAIN_NOT_SETUP,
  SOCIAL_LOGIN_TYPES,
  UNABLE_TO_AUTHORISE,
} from "./auth.constants";

const { FACEBOOK, TWITTER, GOOGLE, YAHOO, LINKEDIN } = SOCIAL_LOGIN_TYPES;

const signOut = () => firebase.auth().signOut();

export const signInWithRedirect = (provider) =>
  firebase.auth().signInWithRedirect(provider);

export const signInWithEmailAndPassword = (...args) =>
  firebase.auth().signInWithEmailAndPassword(...args);

const getSamlProviderIdByDomain = (domain) =>
  firebase
    .firestore()
    .collection("samlDomains")
    .doc(domain)
    .get()
    .then(({ exists, data }) => {
      if (!exists) {
        return null;
      }

      return data().provider;
    });

const createSamlProvider = (provider) =>
  new firebase.auth.SAMLAuthProvider(`saml.${provider}`);

export const signInWithSaml = async (domain) => {
  const provider = await getSamlProviderIdByDomain(domain);
  const providerInstance = createSamlProvider(provider);

  if (!provider) {
    throw new Error(_DOMAIN_NOT_SETUP);
  }

  return signInWithRedirect(providerInstance);
};

const getProvider = (socialNetwork) => {
  switch (socialNetwork) {
    case FACEBOOK:
      return new firebaseApp.auth.FacebookAuthProvider();
    case TWITTER:
      return new firebaseApp.auth.TwitterAuthProvider();
    case YAHOO:
      return new firebaseApp.auth.OAuthProvider("yahoo.com").addScope("sdpp-w");
    case LINKEDIN:
      return new firebaseApp.auth.OAuthProvider("linkedin.com");
    case GOOGLE:
    default:
      return new firebaseApp.auth.GoogleAuthProvider();
  }
};

export const signInWithSocial = (socialNetwork) =>
  signInWithRedirect(getProvider(socialNetwork));

export const isUserFromRedirect = () =>
  firebase
    .auth()
    .getRedirectResult()
    .catch(() => {
      throw new Error(UNABLE_TO_AUTHORISE);
    });

export const isEmailOccupied = (email) =>
  api
    .get({
      path: `${API_ENDPOINTS.AUTH}/isEmailOccupied?email=${encodeURIComponent(
        email
      )}`,
    })
    .then(({ data }) => data);

export const confirmResetPassword = (password, email, uid) =>
  api
    .post({
      path: `${API_ENDPOINTS.AUTH}/resetPassword`,
      body: {
        email,
        password,
        uid,
      },
    })
    .then((res) => res.data);

export const refreshToken = () =>
  api.get({
    path: `${API_ENDPOINTS.AUTH}/refreshToken`,
  });

export const requestForgotPassword = (email) =>
  api
    .get({
      path: `${API_ENDPOINTS.AUTH}/forgotPassword?email=${encodeURIComponent(
        email
      )}`,
    })
    .then((res) => res.data);

export const requestValidateLink = (link) =>
  api
    .post({
      path: `${API_ENDPOINTS.AUTH}/validateInvite`,
      body: {
        link,
      },
    })
    .then((res) => res.data);

export const isUserExist = (uid, email) =>
  api
    .get({
      path: `${API_ENDPOINTS.AUTH}/exist`,
      query: {
        uid,
        email,
      },
    })
    .then((res) => res.data);

const dpdAdminlogin = (idToken, email) =>
  api.post({
    path: `${API_ENDPOINTS.AUTH}/login`,
    body: { idToken, email },
  });

const login = (username, password) =>
  api.post({
    path: `${API_ENDPOINTS.AUTH}/login`,
    body: { username, password },
  });

export const failedLogin = (email) =>
  api.post({
    path: `${API_ENDPOINTS.AUTH}/login-failed`,
    body: { email },
  });

export const isExpireUser = (uid) =>
  api
    .get({
      path: `${API_ENDPOINTS.AUTH}/isExpireUser/${encodeURIComponent(uid)}`,
    })
    .then((res) => res.data);

export const logout = () => api.post({ path: `${API_ENDPOINTS.AUTH}/logout` });

// don't use finally it doesn't support in IE and old EDGE
export const loginWithIdToken = async (idToken, email) => {
  try {
    await dpdAdminlogin(idToken, email);
    await signOut();
  } catch (e) {
    await signOut();
    throw e;
  }
};

export const loginWithPassword = async (email, password) =>
  login(email, password);

export const createAuthUserByEmailAndPassword = (email, password) =>
  api
    .post({
      path: `${API_ENDPOINTS.AUTH}/create`,
      body: {
        email,
        password,
      },
    })
    .then((res) => res.data);
