import { InputAdornment } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import PropTypes from "prop-types";

import styles from "./SearchInput.module.scss";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { SEARCH_USERS } from "../../constants/strings";

export function SearchInput({ input, onSearch, onEnter }) {
  return (
    <InputBase
      className={styles.input}
      placeholder={SEARCH_USERS}
      onKeyDown={(e) => {
        if (e.keyCode === 13 && onEnter) {
          onEnter(e.target.value);
        }
      }}
      onChange={(e) => onSearch && onSearch(e.target.value)}
      {...input}
      endAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
    />
  );
}

SearchInput.propTypes = {
  input: PropTypes.object,
  onSearch: PropTypes.func,
  onEnter: PropTypes.func,
};

export default SearchInput;
