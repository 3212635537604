import { Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { Field, FieldArray, isDirty, isValid, reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import AddIpRestrictionsForm from "./AddIpRestrictionsForm";
import styles from "./TeamSettings.module.scss";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import ConfirmButton from "../../../../components/ConfirmButton/ConfirmButton";
import SimulateTabTitle from "../../../../components/CustomTabs/SimulateTabTitle";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import Prompt from "../../../../components/Prompt/Prompt";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import { SimpleArrayField } from "../../../../components/SimpleArrayField/SimpleArrayField";
import SwitchWithTitle from "../../../../components/SwitchWidthTitle/CustomSwitchWithTitle";
import {
  NAVIGATE_AWAY_TEAM_SETTINGS,
  TEAM_SETTINGS_ANALYTICS,
} from "../../../../constants/analytics";
import { TEAM_SETTINGS_FORM } from "../../../../constants/forms";
import {
  ACCOUNTS_WILL_BE_SUSPENDED_FOR,
  AUTO_SUSPEND_ACCOUNTS,
  DAYS,
  GO_AWAY_MESSAGE,
  PERMITTED_IP_ADDRESSES,
  SAVE_SETTINGS,
  SUSPENDED_DAYS_30,
  SUSPENDED_DAYS_60,
  SUSPENDED_DAYS_90,
} from "../../../../constants/strings";
import {
  confirmDeleteIpAddress,
  fetchTeamSettings,
  saveTeamSettings,
} from "../../../../features/teamSettings/teamSetting.actions";
import {
  AUTO_SUSPEND_ACCOUNTS_FIELD,
  PERMITTED_IP_FIELD,
  SUSPEND_USER_ACCOUNT_DAYS_FIELD,
} from "../../../../features/teamSettings/teamSettings.constants";
import { formatIpRestriction } from "../../../../features/teamSettings/teamSettings.model";
import {
  getAutoSuspendAccounts,
  getIpAddress,
  shouldShowPromptOnTeamSettings,
} from "../../../../features/teamSettings/teamSettings.selectors";

const TeamSettings = ({
  isAutoSuspendAccountsEnabled,
  onDeleteIpAddress,
  onSave,
  isFormDirty,
  showPrompt,
  handleSubmit,
  onInputChange,
}) => (
  <PageContainer>
    <Prompt
      when={showPrompt}
      message={GO_AWAY_MESSAGE}
      interfaceId={NAVIGATE_AWAY_TEAM_SETTINGS.INTERFACE_ID}
    />
    <Box className={styles.section}>
      <AddIpRestrictionsForm />
      <div className={styles.inputTitle}>{PERMITTED_IP_ADDRESSES}</div>
      <div className={styles.ipList}>
        <FieldArray
          name={PERMITTED_IP_FIELD}
          component={SimpleArrayField}
          formatItem={formatIpRestriction}
          icon={<DeleteIcon className={styles.icon} />}
          onRemoveCallback={onDeleteIpAddress}
        />
      </div>
    </Box>
    <Divider className={styles.separator} />
    <Box className={styles.section}>
      <SimulateTabTitle title={AUTO_SUSPEND_ACCOUNTS} />
      <Field
        name={AUTO_SUSPEND_ACCOUNTS_FIELD}
        component={SwitchWithTitle}
        label={AUTO_SUSPEND_ACCOUNTS}
        type="checkbox"
        onChange={(event, value) =>
          onInputChange(AUTO_SUSPEND_ACCOUNTS_FIELD, value)
        }
      />
      <div className={styles.autoSuspendContainer}>
        <div className={classNames(styles.inputTitle, styles.selectTitle)}>
          {ACCOUNTS_WILL_BE_SUSPENDED_FOR}
        </div>
        <Field
          name={SUSPEND_USER_ACCOUNT_DAYS_FIELD}
          component={SelectWithTitle}
          containerStyles={styles.selectContainer}
          selectStyles={styles.selectStyles}
          disabled={!isAutoSuspendAccountsEnabled}
          onChange={(event, value) =>
            onInputChange(SUSPEND_USER_ACCOUNT_DAYS_FIELD, value)
          }
        >
          <MenuItem
            value={SUSPENDED_DAYS_30}
          >{`${SUSPENDED_DAYS_30} ${DAYS}`}</MenuItem>
          <MenuItem
            value={SUSPENDED_DAYS_60}
          >{`${SUSPENDED_DAYS_60} ${DAYS}`}</MenuItem>
          <MenuItem
            value={SUSPENDED_DAYS_90}
          >{`${SUSPENDED_DAYS_90} ${DAYS}`}</MenuItem>
        </Field>
      </div>
    </Box>
    <Box className={styles.buttonContainer}>
      <ConfirmButton
        text={SAVE_SETTINGS}
        onClick={handleSubmit(onSave)}
        disabled={!isFormDirty}
      />
    </Box>
  </PageContainer>
);

const mapStateToProps = (state) => ({
  ipAddress: getIpAddress(state),
  isAutoSuspendAccountsEnabled: getAutoSuspendAccounts(state),
  isValidTeamSettingsFrom: isValid(TEAM_SETTINGS_FORM)(state),
  isFormDirty: isDirty(TEAM_SETTINGS_FORM)(state),
  showPrompt: shouldShowPromptOnTeamSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteIpAddressClick: (removedItemCallback) =>
    dispatch(confirmDeleteIpAddress(removedItemCallback)),
  onSaveTeamSettingsClick: (customerId) =>
    dispatch(saveTeamSettings(customerId)),
  onLoadTeamSettings: (customerId) => dispatch(fetchTeamSettings(customerId)),
});

export default compose(
  reduxForm({
    form: TEAM_SETTINGS_FORM,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { onLoadTeamSettings, match } = this.props;
      const { customerId } = match.params;
      onLoadTeamSettings(customerId);
    },
    componentDidUpdate(prevProps) {
      const {
        onLoadTeamSettings,
        match: {
          params: { customerId },
        },
      } = this.props;
      if (customerId !== prevProps.match.params.customerId) {
        onLoadTeamSettings(customerId);
      }
    },
  }),
  withTrack({
    loadId: TEAM_SETTINGS_ANALYTICS.ON_LOAD,
    interfaceId: TEAM_SETTINGS_ANALYTICS.INTERFACE_ID,
  }),
  withTrackProps((props) => ({
    onSave: () => {
      const {
        onSaveTeamSettingsClick,
        match: {
          params: { customerId },
        },
      } = props;
      onSaveTeamSettingsClick(customerId);
      return TEAM_SETTINGS_ANALYTICS.CLICK_SAVE;
    },
    onDeleteIpAddress: (removedItemCallback) => {
      props.onDeleteIpAddressClick(removedItemCallback);
      return TEAM_SETTINGS_ANALYTICS.CLICK_DELETE_IP;
    },
    onInputChange: (field, value) => {
      let id = "";
      switch (field) {
        case AUTO_SUSPEND_ACCOUNTS_FIELD:
          id = TEAM_SETTINGS_ANALYTICS.CLICK_AUTO_SUSPEND_ACCOUNT_TOGGLE;
          break;
        case SUSPEND_USER_ACCOUNT_DAYS_FIELD:
          id = TEAM_SETTINGS_ANALYTICS.CLICK_AUTO_SUSPEND_ACCOUNT_DROPDOWN;
      }
      return createLogEntryPayload(id, {
        field,
        value,
      });
    },
  }))
)(TeamSettings);

TeamSettings.propTypes = {
  isAutoSuspendAccountsEnabled: PropTypes.bool,
  isValidTeamSettingsFrom: PropTypes.bool,
  onAddIpRestrictionClick: PropTypes.func,
  onDeleteIpAddressClick: PropTypes.func,
  onSaveTeamSettingsClick: PropTypes.func,
  isFormDirty: PropTypes.bool,
  showPrompt: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  onDeleteIpAddress: PropTypes.func,
  onSave: PropTypes.func,
};
