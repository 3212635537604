import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { isLoaderShown } from "../../features/loader/loader.selectors";
import styles from "./Loader.module.scss";

export default function Loader() {
  const { images } = useTheme();
  const shouldShowLoader = useSelector(isLoaderShown);

  return (
    shouldShowLoader && (
      <div className={classNames(styles.wrapper)}>
        <img src={images.loader} alt="" className={styles.image} />
      </div>
    )
  );
}
