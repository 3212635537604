import { Avatar } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./InputTitleAdornment.module.scss";

const useThemeColorStyles = makeStyles((theme) => ({
  icon: () => ({
    svg: {
      fill: theme.palette.primary.main,
    },
    fill: theme.palette.primary.main,
  }),
}));

const InputTitleAdornment = ({
  label,
  avatar,
  icon,
  required,
  containerClass,
  showMetaErrors,
  adornmentStyles,
}) => {
  const iconColor = useThemeColorStyles(useTheme());
  const renderDynamicContent = () => {
    if (avatar) {
      return (
        <div className={classNames(styles.wrapper)}>
          <Avatar alt="Avatar" src={avatar} className={styles.avatar} />
        </div>
      );
    }

    if (icon) {
      return (
        <div
          className={classNames(
            styles.wrapper,
            adornmentStyles.adornmentWrapper,
            showMetaErrors && styles["meta-error"]
          )}
        >
          <div
            className={classNames(
              styles.icon,
              adornmentStyles.adornmentIcon,
              iconColor.icon
            )}
          >
            {icon}
          </div>
        </div>
      );
    }
  };

  return (
    <InputAdornment
      position="start"
      disablePointerEvents
      className={classNames(
        styles.adornment,
        containerClass && containerClass,
        (avatar || icon) && styles["without-border"],
        showMetaErrors && styles["meta-error"]
      )}
    >
      {renderDynamicContent()}
      {label && (
        <>
          <label className={styles.label}>{`${label}:`}</label>
          <span className={styles.required}>{required && "*"}</span>
        </>
      )}
    </InputAdornment>
  );
};

InputTitleAdornment.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  containerClass: PropTypes.any,
  avatar: PropTypes.string,
  icon: PropTypes.node,
  showMetaErrors: PropTypes.bool,
  adornmentStyles: PropTypes.object,
};

export default InputTitleAdornment;
