import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import classNames from "classnames";
import PropTypes from "prop-types";
import { compose, lifecycle } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { EDIT_CUSTOMER_USER } from "../../../constants/analytics";
import BaseModal from "../BaseModal";
import styles from "./ChangeMessageModal.module.scss";

function ChangeMessageModal({ changeMessage, ...otherProps }) {
  return (
    <BaseModal {...otherProps}>
      <DialogContent className={classNames(styles.contentText)}>
        <DialogContentText id="alert-dialog-description">
          {changeMessage}
        </DialogContentText>
      </DialogContent>
    </BaseModal>
  );
}

ChangeMessageModal.propTypes = {
  changeMessage: PropTypes.string,
};

export default compose(
  withTrackProps(() => ({
    onInitModal: () => EDIT_CUSTOMER_USER.LOAD_CHANGE_MESSAGE_MODAL,
  })),
  lifecycle({
    componentDidMount() {
      this.props.onInitModal();
    },
  })
)(ChangeMessageModal);
