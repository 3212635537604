import get from "lodash/get";
import {
  getFormSyncErrors,
  getFormValues,
  hasSubmitSucceeded,
  isDirty,
  isSubmitting,
} from "redux-form";
import { createSelector } from "reselect";

import {
  AUTO_SUSPEND_ACCOUNTS_FIELD,
  END_IP_FIELD,
  IP_ADDRESS_FIELD,
  PERMITTED_IP_FIELD,
  START_IP_FIELD,
} from "./teamSettings.constants";
import { IP_ADDRESS_FORM, TEAM_SETTINGS_FORM } from "../../constants/forms";

export const getTeamSettingsFormValues = getFormValues(TEAM_SETTINGS_FORM);

export const getAddIpFromValues = getFormValues(IP_ADDRESS_FORM);

export const getIpAddress = createSelector(getAddIpFromValues, (formValues) =>
  get(formValues, IP_ADDRESS_FIELD, "")
);

export const getAutoSuspendAccounts = createSelector(
  getTeamSettingsFormValues,
  (formValues) => get(formValues, AUTO_SUSPEND_ACCOUNTS_FIELD, false)
);

export const shouldShowPromptOnTeamSettings = createSelector(
  isDirty(TEAM_SETTINGS_FORM),
  isSubmitting(TEAM_SETTINGS_FORM),
  hasSubmitSucceeded(TEAM_SETTINGS_FORM),
  (isDirty, isSubmitting, hasSubmitSucceeded) =>
    isDirty && !isSubmitting && !hasSubmitSucceeded
);

export const isIpFieldsInValid = createSelector(
  getFormSyncErrors(IP_ADDRESS_FORM),
  ({ ipAddress = "" }) => !!ipAddress
);

export const isDisabledAddIpButton = createSelector(
  isIpFieldsInValid,
  getIpAddress,
  (isInValid, ipAddress) => Boolean(isInValid || !ipAddress)
);

export const getPermittedIps = createSelector(
  getTeamSettingsFormValues,
  (formValues) => get(formValues, PERMITTED_IP_FIELD, [])
);

export const getStartIp = createSelector(getAddIpFromValues, (formValues) =>
  get(formValues, START_IP_FIELD, "")
);

export const getEndIp = createSelector(getAddIpFromValues, (formValues) =>
  get(formValues, END_IP_FIELD, "")
);

export const isIpRangeFieldsInValid = createSelector(
  getFormSyncErrors(IP_ADDRESS_FORM),
  ({ rangeStart = "", rangeEnd = "" }) => Boolean(rangeStart || rangeEnd)
);

export const isDisabledAddIpRangeButton = createSelector(
  isIpRangeFieldsInValid,
  getStartIp,
  getEndIp,
  (invalid, startIp, endIp) => invalid || !startIp || !endIp
);
