import { ACTIONS } from "./config.actions";

export const initialState = {
  dpdServices: [],
  dpdLocalServices: [],
  serviceRestrictions: {},
  serviceRestrictionHelper: {},
  appVersions: [],
  currencies: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_APP_VERSIONS.SUCCESS:
      return {
        ...state,
        appVersions: action.payload,
      };
    case ACTIONS.FETCH_CURRENCIES.SUCCESS:
      return {
        ...state,
        currencies: action.payload,
      };
    case ACTIONS.FETCH_SERVICE_RESTRICTIONS.SUCCESS:
      return {
        ...state,
        serviceRestrictions: action.payload,
      };
    case ACTIONS.FETCH_SERVICE_RESTRICTION_TEMPLATES.SUCCESS:
      return {
        ...state,
        serviceRestrictionHelper: action.payload,
      };
    case ACTIONS.FETCH_DPD_SERVICES.SUCCESS:
      return {
        ...state,
        dpdLocalServices: action.payload.filter((service) => service.isLocal),
        dpdServices: action.payload.filter((service) => !service.isLocal),
      };
    default:
      return state;
  }
};
