import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import CustomTabs from "../../../../components/CustomTabs/CustomTabs.js";
import ChangeDelivery from "../../../../components/MiscellaneousConfigurationComponents/ChangeDelivery.js";
import Collections from "../../../../components/MiscellaneousConfigurationComponents/Collections.js";
import Shipments from "../../../../components/MiscellaneousConfigurationComponents/Shipments.js";
import { CREATE_EDIT_CUSTOMER_USER_ANALYTICS } from "../../../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../../../constants/forms.js";
import { MISCELLANEOUS_CONFIGURATION_TABS } from "../../../../constants/strings.js";
import { deselectEodMaster } from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.actions";
import { isEodMasterSelected } from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.selectors";
import styles from "./MiscellaneousConfiguration.module.scss";
import TerminalPrinterInterface from "./TerminalPrinterInterface.js";

const MiscellaneousConfiguration = ({
  deselectMasterEodToggle,
  isMasterEodSelected,
  onInfoMouseOver,
  onToggleChange,
  onChangeCheckMasterEod,
}) => (
  <div className={styles.wrapper}>
    <CustomTabs tabs={MISCELLANEOUS_CONFIGURATION_TABS}>
      <Shipments
        deselectMasterEodToggle={() =>
          isMasterEodSelected && deselectMasterEodToggle()
        }
        onInfoMouseOver={onInfoMouseOver}
        onToggleChange={onToggleChange}
        onChangeCheckMasterEod={onChangeCheckMasterEod}
      />
      <Collections
        onInfoMouseOver={onInfoMouseOver}
        onInputChange={onToggleChange}
      />
      <ChangeDelivery
        onInputChange={onToggleChange}
        onInfoMouseOver={onInfoMouseOver}
      />
      <TerminalPrinterInterface
        onInputChange={onToggleChange}
        onInfoMouseOver={onInfoMouseOver}
      />
    </CustomTabs>
  </div>
);

MiscellaneousConfiguration.propTypes = {
  isMasterEodSelected: PropTypes.bool,
  deselectMasterEodToggle: PropTypes.func,
  onInfoMouseOver: PropTypes.func.isRequired,
  onToggleChange: PropTypes.func.isRequired,
  onChangeCheckMasterEod: PropTypes.func,
};

const mapStateToProps = (state) => ({
  initialValues: {},
  isMasterEodSelected: !!isEodMasterSelected(state),
});

const mapDispatchToProps = {
  deselectMasterEodToggle: deselectEodMaster,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: USER_DETAILS_FORMS.MISCELLANEOUS_CONFIGURATION,
    enableReinitialize: true,
  }),
  withTrackProps(({ deselectMasterEodToggle }) => ({
    onChangeCheckMasterEod: (field, value) => {
      deselectMasterEodToggle && !value && deselectMasterEodToggle();
      return createLogEntryPayload(
        CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_TOGGLE,
        {
          field,
          value,
        }
      );
    },
  }))
)(MiscellaneousConfiguration);
