import isEmpty from "lodash/isEmpty";
import zxcvbn from "zxcvbn";

import { EMAIL_REGEX, USER_NAME_REGEX } from "../constants/regex";
import { PASSWORD_STRENGTH } from "../constants/strings";
import {
  LESS_100_SYMBOL,
  PASSWORD_DONT_MATCH,
  PLEASE_ENTER_PASSWORD,
  USERNAME_IN_USE,
  WEAK_PASSWORD,
} from "../features/auth/auth.constants";
import * as authService from "../features/auth/auth.service";
import { DEFAULT_PASSWORD } from "../features/myDpdUserForm/userDetails/userDetails.constants";
import { EMAIL_ALREADY_IN_USE } from "../features/user/user.constants";
import * as userService from "../features/user/user.service";
import { validateUserName } from "../features/userDetailsForm/userDetails/userDetails.validate";

export const isEmailValid = (email) => EMAIL_REGEX.test(email);

export const passwordStrength = (password) => zxcvbn(password).score;

export const isWeakPassword = (passwordStrength) =>
  passwordStrength < PASSWORD_STRENGTH.GOOD_PASSWORD;

export const isDefaultPassword = (password) => password === DEFAULT_PASSWORD;

export const resetPasswordValidation = ({
  reEnterPassword,
  newPassword = "",
}) => {
  const errors = {};
  if (!newPassword) {
    errors.newPassword = PLEASE_ENTER_PASSWORD;
  } else if (isWeakPassword(passwordStrength(newPassword))) {
    errors.newPassword = WEAK_PASSWORD;
  } else if (reEnterPassword !== newPassword) {
    errors.reEnterPassword = PASSWORD_DONT_MATCH;
  } else if (newPassword.length >= 100) {
    errors.newPassword = LESS_100_SYMBOL;
  }

  return errors;
};

export const passwordValidation = ({ reEnterPassword, newPassword = "" }) => {
  const errors = {};
  if (!newPassword) {
    errors.newPassword = PLEASE_ENTER_PASSWORD;
  } else if (
    !isDefaultPassword(newPassword) &&
    !isDefaultPassword(reEnterPassword) &&
    isWeakPassword(passwordStrength(newPassword))
  ) {
    errors.newPassword = WEAK_PASSWORD;
  } else if (reEnterPassword !== newPassword) {
    errors.reEnterPassword = PASSWORD_DONT_MATCH;
  }

  return errors;
};

export const usernameAsyncValidation = async (
  { username },
  emptyOnly = false
) => {
  const userErrors = validateUserName({ username }, emptyOnly);

  if (!isEmpty(userErrors)) {
    throw userErrors;
  }

  const isUsernameUniq = await userService.isUsernameUniq(username);

  if (!isUsernameUniq) {
    // eslint-disable-next-line no-throw-literal
    throw {
      username: USERNAME_IN_USE,
    };
  }
};

export const emailAsyncValidation = async ({ email }) => {
  const isEmailOccupied = await authService.isEmailOccupied(email);

  if (isEmailOccupied) {
    // eslint-disable-next-line no-throw-literal
    throw {
      email: EMAIL_ALREADY_IN_USE,
    };
  }
};

export const isUserNameValid = (username) =>
  !!username && USER_NAME_REGEX.test(username);
