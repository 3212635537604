import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import styles from "./ProfileDpdAccount.module.scss";
import HashIcon from "../../../../components/HashIcon/HashIcon";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import {
  ACCOUNT_NUMBER,
  DOMESTIC_OUTBOUND_SLID,
  EXCHANGE_INBOUND_SLID,
  EXCHANGE_OUTBOUND_SLID,
} from "../../../../constants/strings";
import {
  DPD_ACCOUNT_NUMBER_FIELD,
  DPD_DOMESTIC_OUTBOUND_SLID_FIELD,
  DPD_INBOUND_SLID_FIELD,
  DPD_OUTBOUND_SLID_FIELD,
} from "../../../../features/profile/profile.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import { onlyNumbers } from "../../../../utils/normalize";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const ProfileDpdAccount = ({ onTextEntry }) => (
  <Grid container className={styles.container}>
    <Grid container item direction="row">
      <Grid item className={styles["account-number"]}>
        <Field
          name={DPD_ACCOUNT_NUMBER_FIELD}
          component={DecoratedFieldWithError}
          label={ACCOUNT_NUMBER}
          icon={<HashIcon />}
          inputProps={{ maxLength: 7 }}
          normalize={onlyNumbers}
          onBlur={(e, value) => {
            onTextEntry(DPD_ACCOUNT_NUMBER_FIELD, value);
          }}
        />
      </Grid>
    </Grid>

    <Grid container item>
      <Grid container item direction="row" justify="space-between">
        <Grid item className={styles["profile-item"]}>
          <Field
            name={DPD_DOMESTIC_OUTBOUND_SLID_FIELD}
            component={DecoratedFieldWithError}
            label={DOMESTIC_OUTBOUND_SLID}
            inputProps={{ maxLength: 4 }}
            normalize={onlyNumbers}
            onBlur={(e, value) => {
              onTextEntry(DPD_DOMESTIC_OUTBOUND_SLID_FIELD, value);
            }}
          />
        </Grid>
        <Grid item className={styles["profile-item"]}>
          <Field
            name={DPD_OUTBOUND_SLID_FIELD}
            component={DecoratedFieldWithError}
            label={EXCHANGE_OUTBOUND_SLID}
            inputProps={{ maxLength: 4 }}
            normalize={onlyNumbers}
            onBlur={(e, value) => {
              onTextEntry(DPD_OUTBOUND_SLID_FIELD, value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container item direction="row" justify="flex-end">
        <Grid item className={styles["profile-item"]}>
          <Field
            name={DPD_INBOUND_SLID_FIELD}
            component={DecoratedFieldWithError}
            label={EXCHANGE_INBOUND_SLID}
            inputProps={{ maxLength: 4 }}
            normalize={onlyNumbers}
            onBlur={(e, value) => {
              onTextEntry(DPD_INBOUND_SLID_FIELD, value);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

ProfileDpdAccount.propTypes = {
  onTextEntry: PropTypes.func,
};

export default compose(
  withTrackProps(() => ({
    onTextEntry: (field, value) =>
      createLogEntryPayload(PROFILE_ANALYTICS.TEXT_ENTRY, {
        field,
        value,
      }),
  }))
)(ProfileDpdAccount);
