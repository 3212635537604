import { Component } from "react";

import { Grid, IconButton, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";

import { ReactComponent as Delete } from "../../assets/icons/delete-red.svg";
import { SERVICE_SELECT_DEFAULT } from "../../constants/strings";
import SelectWithTitleStateFull from "../SelectWithTitle/SelectWithTitleStateFull";
import { StyledHeaderCell, StyledTableCell } from "../Table/Table";
import styles from "./ServiceTable.module.scss";

class ServiceTable extends Component {
  renderSimpleFieldLabel = (input) => <div>{input}</div>;

  renderSelectItems = (selectData) =>
    selectData.map((service) => (
      <MenuItem
        key={service[this.props.fieldsNaming.selectFieldCode]}
        value={service[this.props.fieldsNaming.selectFieldCode]}
        className={styles.option}
      >
        {service[this.props.fieldsNaming.selectFieldName]}
      </MenuItem>
    ));

  renderHeaderRows = () =>
    this.props.headerRows.map((item) => (
      <StyledHeaderCell key={item} className={styles.fixed}>
        {item.toUpperCase()}
      </StyledHeaderCell>
    ));

  renderRows = (items) =>
    items.map(
      (
        {
          [this.props.fieldsNaming.selectName]: selectName,
          [this.props.fieldsNaming.labelName]: labelName,
          selectValues,
        },
        index
      ) => (
        <TableRow key={labelName}>
          <StyledTableCell cellStyles={styles.mapping}>
            {this.renderSimpleFieldLabel(labelName)}
          </StyledTableCell>
          <StyledTableCell cellStyles={styles["service-cell"]}>
            <Grid container justify="space-between">
              <Grid item className={styles.service}>
                <SelectWithTitleStateFull
                  containerStyles={styles.heightStyle}
                  classes={{ select: styles.select }}
                  displayEmpty
                  value={selectName}
                  onChange={(value) => this.props.onSelectValue(index, value)}
                  onBlur={() => this.props.input.onBlur()}
                >
                  <MenuItem value={""}>{SERVICE_SELECT_DEFAULT}</MenuItem>
                  {this.renderSelectItems(selectValues)}
                </SelectWithTitleStateFull>
              </Grid>

              <IconButton
                onClick={() => this.props.onRemoveItem(index)}
                className={styles.delete}
              >
                <Delete width="20" height="20" />
              </IconButton>
            </Grid>
          </StyledTableCell>
        </TableRow>
      )
    );

  render() {
    const {
      input: { value = [] },
    } = this.props;

    return value.length ? (
      <div className={styles.tableContainer}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>{this.renderHeaderRows()}</TableRow>
          </TableHead>
          <TableBody>{this.renderRows(value)}</TableBody>
        </Table>
      </div>
    ) : null;
  }
}

ServiceTable.propTypes = {
  headerRows: PropTypes.array,
  input: PropTypes.object,
  selectData: PropTypes.array,
  fieldsNaming: PropTypes.object,
  fieldName: PropTypes.string,
  labelName: PropTypes.string,
  selectName: PropTypes.string,
  onRemoveItem: PropTypes.func,
  onSelectValue: PropTypes.func,
};

ServiceTable.defaultProps = {
  selectData: [],
  fields: [],
};

export default ServiceTable;
