import { ADDITIONAL_ACCOUNT_REGEX } from "../../../constants/regex";
import {
  ADMIN_ACCOUNT,
  INVALID_ACCOUNT_NUMBER,
} from "../../customer/customer.constants";
export const validate = ({ additionalAccountNumber = "" }) => {
  const errors = {};
  if (
    (additionalAccountNumber &&
      additionalAccountNumber.length < 3 &&
      additionalAccountNumber !== ADMIN_ACCOUNT) ||
    (additionalAccountNumber.length > 3 &&
      !ADDITIONAL_ACCOUNT_REGEX.test(additionalAccountNumber))
  ) {
    errors.additionalAccountNumber = INVALID_ACCOUNT_NUMBER;
  }
  return errors;
};
