import { Component } from "react";

import { Box, withTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import get from "lodash/get";
import PropTypes from "prop-types";
import { compose } from "recompose";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as MinusIcon } from "../../assets/icons/minus.svg";
import { ADD_ALL, REMOVE_ALL } from "../../constants/strings";
import styles from "./TransferList.module.scss";

function not(a, b) {
  return a.filter((value) => b !== value);
}

class TransferList extends Component {
  handleChecked = (value, isActiveSide) => {
    const {
      onChange,
      leftButtonTitle,
      rightButtonTitle,
      selectedValues,
      analyticIdRemove,
      analyticIdAdd,
      analyticHandle,
    } = this.props;

    const newSelectedValues = !isActiveSide
      ? selectedValues.concat(value)
      : not(selectedValues, value);

    const analyticId = isActiveSide ? analyticIdRemove : analyticIdAdd;
    const analyticValue = isActiveSide ? rightButtonTitle : leftButtonTitle;

    analyticHandle(analyticId, newSelectedValues, analyticValue);
    onChange(newSelectedValues);
  };

  handleAll = (isActiveSide) => {
    const {
      onChange,
      leftButtonTitle,
      rightButtonTitle,
      availableValues,
      selectedValues,
      analyticIdAddAll,
      analyticIdRemoveAll,
      analyticHandle,
    } = this.props;
    const newSelectedValues = isActiveSide
      ? []
      : selectedValues.concat(availableValues);

    const analyticId = isActiveSide ? analyticIdRemoveAll : analyticIdAddAll;
    const analyticValue = isActiveSide ? rightButtonTitle : leftButtonTitle;

    analyticHandle(analyticId, newSelectedValues, analyticValue);
    onChange(newSelectedValues);
  };

  getButtonIcon = (isActiveSide, useOppositeIcon) => {
    const isAddIcon =
      (useOppositeIcon && isActiveSide) || (!isActiveSide && !useOppositeIcon);

    return isAddIcon ? (
      <AddIcon
        className={!isActiveSide && !useOppositeIcon ? styles.icon : null}
      />
    ) : (
      <MinusIcon
        className={useOppositeIcon && !isActiveSide ? styles.icon : null}
        width={19}
        height={19}
      />
    );
  };

  renderListItems = (items, isActiveSide, useOppositeIcon) => {
    const { rightItemsStyles } = this.props;

    return items.map((item) => {
      const value = item.name;
      const labelId = `transfer-list-item-${value}-label`;

      return (
        <ListItem
          key={item.code}
          role="listitem"
          button
          disableRipple
          onClick={() => this.handleChecked(item, isActiveSide)}
          className={classNames(
            styles.listItem,
            isActiveSide && (rightItemsStyles || styles.itemColor),
            !isActiveSide && styles.removeItem
          )}
          classes={{
            container: styles.itemContainer,
          }}
        >
          <ListItemText
            id={labelId}
            className={classNames(
              styles.itemText,
              !isActiveSide && styles.removeItemText
            )}
            primary={value}
          />
          <ListItemSecondaryAction className={styles.buttonContainer}>
            <Icon>{this.getButtonIcon(isActiveSide, useOppositeIcon)}</Icon>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  renderSectionList = ({
    title,
    buttonTitle,
    isActiveSide,
    useOppositeIcon,
  }) => {
    const { availableValues, selectedValues, theme } = this.props;
    const sectionItems = isActiveSide ? selectedValues : availableValues;

    return (
      <Paper className={styles.sideContainer}>
        <Box className={styles.headerContainer}>
          <Typography className={styles.headerText}>{title}</Typography>
          <Typography
            className={styles.headerButton}
            style={{
              color: get(theme, "palette.primary.main"),
            }}
            onClick={() => this.handleAll(isActiveSide)}
          >
            {buttonTitle}
          </Typography>
        </Box>

        <List dense component="div" role="list" className={styles.list}>
          {this.renderListItems(sectionItems, isActiveSide, useOppositeIcon)}
          <ListItem />
        </List>
      </Paper>
    );
  };

  render() {
    const {
      leftHeader,
      leftButtonTitle,
      rightHeader,
      rightButtonTitle,
      activeSide,
      oppositeIcon,
    } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item className={styles.section}>
          {this.renderSectionList({
            title: leftHeader,
            buttonTitle: leftButtonTitle,
            isActiveSide: activeSide === "left",
            useOppositeIcon: oppositeIcon,
          })}
        </Grid>
        <Grid item className={styles.section}>
          {this.renderSectionList({
            title: rightHeader,
            buttonTitle: rightButtonTitle,
            isActiveSide: activeSide !== "left",
            useOppositeIcon: oppositeIcon,
          })}
        </Grid>
      </Grid>
    );
  }
}

TransferList.defaultProps = {
  leftButtonTitle: ADD_ALL,
  rightButtonTitle: REMOVE_ALL,
  activeSide: "right",
  availableValues: [],
  selectedValues: [],
  onChange: () => null,
  analyticIdAddAll: "",
  analyticIdRemoveAll: "",
  analyticIdRemove: "",
  analyticIdAdd: "",
  oppositeIcon: false,
};

TransferList.propTypes = {
  onChange: PropTypes.func.isRequired,
  analyticHandle: PropTypes.func,
  leftHeader: PropTypes.string,
  leftButtonTitle: PropTypes.string,
  rightHeader: PropTypes.string,
  rightButtonTitle: PropTypes.string,
  rightItemsStyles: PropTypes.string,
  activeSide: PropTypes.oneOf(["left", "right"]),
  availableValues: PropTypes.array,
  selectedValues: PropTypes.array,
  analyticIdAddAll: PropTypes.string,
  analyticIdRemoveAll: PropTypes.string,
  analyticIdRemove: PropTypes.string,
  analyticIdAdd: PropTypes.string,
  oppositeIcon: PropTypes.bool,
  theme: PropTypes.object,
};

export default compose(
  withTheme,
  withTrackProps(() => ({
    analyticHandle: (id, fieldData = {}, value = "") => {
      let field = fieldData;
      if (typeof fieldData === "object") {
        field = fieldData.map((data) => data.code);
      }
      return createLogEntryPayload(id, {
        field,
        value,
      });
    },
  }))
)(TransferList);
