import find from "lodash/find";

import {
  SECTOR_ALREADY_EXIST,
  SECTOR_LENGTH_ERROR,
  SELECT_RESTRICTION_FOR_EACH_SECTOR,
  SERVICE_RESTRICTED_FIELD,
} from "./serviceRestrictions.constants";

export const validate = ({ serviceRestricted = [], mapping = "" }) => {
  const errors = {};

  const mappingField = mapping.trim();

  if (`${mappingField}`.length > 4) {
    errors.mapping = SECTOR_LENGTH_ERROR;
  }

  if (mappingField && find(serviceRestricted, { postcode: mappingField })) {
    errors.mapping = SECTOR_ALREADY_EXIST;
  }

  const emptyService = serviceRestricted.some(
    (mappingValue) =>
      !mappingValue.restriction && mappingValue.restriction !== 0
  );

  if (emptyService) {
    errors[SERVICE_RESTRICTED_FIELD] = SELECT_RESTRICTION_FOR_EACH_SECTOR;
  }
  return errors;
};
