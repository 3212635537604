import { compose } from "recompose";
import { Field, reduxForm } from "redux-form";

import CustomSwitchWithTitle from "../../../../components/SwitchWidthTitle/CustomSwitchWithTitle";
import styles from "../../../../components/SystemAccess/SystemAccess.module.scss";
import { USER_DETAILS_FORMS } from "../../../../constants/forms";
import {
  ADMIN_ACCESS_ACTIONS_INFO,
  ADMIN_ACCESS_TO_CUSTOMER_PROFILES,
} from "../../../../constants/strings";
import { asyncValidate } from "../../../../features/myDpdUserForm/userDetails/userDetails.validate.js";
import { ADMIN_ACCESS_FIELD } from "../../../../features/userDetailsForm/userDetails/userDetails.constants";

export default compose(
  reduxForm({
    form: USER_DETAILS_FORMS.DPD_USER_DETAILS,
    asyncValidate,
    asyncChangeFields: [ADMIN_ACCESS_FIELD],
  })
)(({ onInfoMouseOver, onAdminAccessClick, isOwnUser }) => (
  <Field
    name={ADMIN_ACCESS_FIELD}
    component={CustomSwitchWithTitle}
    type="checkbox"
    disabled={isOwnUser}
    onInfoMouseOver={onInfoMouseOver}
    label={ADMIN_ACCESS_TO_CUSTOMER_PROFILES.toUpperCase()}
    infoLabel={ADMIN_ACCESS_ACTIONS_INFO}
    labelStyle={styles["custom-label"]}
    onChange={(event, value) => {
      onAdminAccessClick(value);
    }}
  />
));
