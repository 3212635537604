import { makeStyles, useTheme } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./GradientHeader.module.scss";

const useThemeColorStyles = makeStyles((theme) => ({
  containerColor: () => ({
    background: theme.palette.primary.background,
  }),
}));

const GradientHeader = ({ title }) => {
  const backgroundColor = useThemeColorStyles(useTheme());
  return (
    <div
      className={classNames(styles.container, backgroundColor.containerColor)}
    >
      <span>{title}</span>
    </div>
  );
};

GradientHeader.propTypes = {
  title: PropTypes.string,
};

export default GradientHeader;
