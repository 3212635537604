import { useCallback } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { compose } from "recompose";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import CustomerUserAdditionalAccounts from "./CustomerUserAdditionalAccounts";
import styles from "./MainCustomerUserForm.module.scss";
import BottomButtons from "../../../components/BottomButtons/BottomButtons";
import CustomExpansionPanel from "../../../components/CustomExpansionPanel/CustomExpansionPanel";
import InternationalShipments from "../../../components/InternationaShipments/InternationalShipments";
import PageContainer from "../../../components/PageContainer/PageContainer";
import Prompt from "../../../components/Prompt/Prompt";
import SecuritySettings from "../../../components/SecuritySettings/SecuritySettings";
import {
  ANALYTICS_FOR_USER,
  CREATE_EDIT_CUSTOMER_USER_ANALYTICS,
  EDIT_CUSTOMER_USER,
  NAVIGATE_AWAY_CREATE_CUSTOMER_USER,
  NAVIGATE_AWAY_EDIT_CUSTOMER_USER,
} from "../../../constants/analytics";
import { USER_DETAILS_FORMS, USER_FORMS } from "../../../constants/forms";
import {
  ACCOUNT_DETAILS,
  ADDITIONAL_ACCOUNTS,
  ADVANCED_CUSTOMER_CONFIGURATION,
  CHANGE_MESSAGE,
  CREATE_MASTER_USER,
  GO_AWAY_MESSAGE,
  INTERNATIONAL_SHIPMENTS,
  LABEL_SETTINGS,
  MAINTAIN_USER,
  MISCELLANEOUS_CONFIGURATION,
  SECURITY_SETTINGS,
  SERVICE_EXCLUSIONS,
  SERVICE_RESTRICTIONS,
  SERVICE_XREF_MAPPINGS,
  SHIPPING_SETTINGS,
  SYSTEM_ACCESS,
  USER_DETAILS,
  USER_PROFILES,
} from "../../../constants/strings";
import ServiceExclusions from "../../../containers/ServiceExclusions/ServiceExclusions";
import ServiceRestrictions from "../../../containers/ServiceRestrictions/ServiceRestrictions";
import ServiceXrefMappings from "../../../containers/ServiceXrefMappings/ServiceXrefMappings";
import UserProfiles from "../../../containers/UserProfiles/UserProfiles";
import {
  EMAIL_FIELD,
  RE_ENTER_PASSWORD_FIELD,
} from "../../../features/myDpdUserForm/userDetails/userDetails.constants";
import {
  DESCRIPTION_FIELD,
  FORENAME_FIELD,
  LASTNAME_FIELD,
  NEW_PASSWORD_FIELD,
  PHONE_FIELD,
  POSITION_FIELD,
  USERNAME_FIELD,
} from "../../../features/userDetailsForm/userDetails/userDetails.constants";
import { handleMultiformSubmit } from "../../../utils/redux-form";
import AccountDetails from "../components/AccountDetails";
import AdvancedCustomerConfiguration from "../components/AdvancedCustomerConfiguration/AdvancedCustomerConfiguration";
import ChangeMessage from "../components/ChangeMessage/ChangeMessage";
import AdminLabelSettings from "../components/LabelSettings/AdminLabelSettings";
import MaintainUser from "../components/MaintainUser/MaintainUser";
import MiscellaneousConfiguration from "../components/MiscellaneousConfiguration/MiscellaneousConfiguration";
import AdminShippingSettings from "../components/ShippingSettings/AdminShippingSettings";
import SystemAccess from "../components/SystemAccess/AdminSystemAccess";
import UserDetailsFrom from "../components/UserDetails/UserDetailsForm";

const refs = {};

const MainCustomerUserForm = ({
  match: {
    params: { id },
  },
  onConfirm,
  onCancel,
  isFormDirty,
  isSubmitted,
  showPrompt,
  onChangeSectionState,
  onBlurChangeMessage,
  onBlurFields,
  onInfoMouseOver,
  onToggleChange,
  onClickCreateMasterUser,
  canCreateMasterUser,
}) => {
  const onConfirmClick = debounce(
    useCallback(() => {
      handleMultiformSubmit(refs, onConfirm);
    }, []),
    1000,
    { leading: true, trailing: false }
  );

  return (
    <PageContainer>
      <Prompt
        when={showPrompt && isFormDirty && !isSubmitted}
        message={GO_AWAY_MESSAGE}
        interfaceId={
          id
            ? NAVIGATE_AWAY_EDIT_CUSTOMER_USER.INTERFACE_ID
            : NAVIGATE_AWAY_CREATE_CUSTOMER_USER.INTERFACE_ID
        }
      />
      {!!id && canCreateMasterUser && (
        <Grid className={styles.buttonContainer}>
          <Button color="primary" onClick={onClickCreateMasterUser}>
            {CREATE_MASTER_USER}
          </Button>
        </Grid>
      )}
      {!!id && (
        <CustomExpansionPanel
          title={MAINTAIN_USER}
          defaultExpanded
          onChange={onChangeSectionState}
        >
          <MaintainUser userId={id} />
        </CustomExpansionPanel>
      )}
      <CustomExpansionPanel
        title={ACCOUNT_DETAILS}
        defaultExpanded
        formName={USER_DETAILS_FORMS.ACCOUNT_DETAILS}
        onChange={onChangeSectionState}
      >
        <AccountDetails
          userId={id}
          withReference={(ref) => {
            refs[USER_DETAILS_FORMS.ACCOUNT_DETAILS] = ref;
          }}
        />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={USER_DETAILS}
        onChange={onChangeSectionState}
        formName={USER_DETAILS_FORMS.DPD_USER_DETAILS}
      >
        <UserDetailsFrom
          onBlurFields={onBlurFields}
          withReference={(ref) => {
            refs[USER_DETAILS_FORMS.DPD_USER_DETAILS] = ref;
          }}
        />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={ADDITIONAL_ACCOUNTS}
        onChange={onChangeSectionState}
      >
        <CustomerUserAdditionalAccounts />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={SYSTEM_ACCESS}
        formName={USER_FORMS.SYSTEM_ACCESS}
        onChange={onChangeSectionState}
      >
        <SystemAccess
          onInfoMouseOver={onInfoMouseOver}
          withReference={(ref) => {
            refs[USER_DETAILS_FORMS.SYSTEM_ACCESS] = ref;
          }}
        />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={MISCELLANEOUS_CONFIGURATION}
        onChange={onChangeSectionState}
        contentPadding={styles["content-padding"]}
      >
        <MiscellaneousConfiguration
          onInfoMouseOver={onInfoMouseOver}
          onToggleChange={onToggleChange}
        />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={ADVANCED_CUSTOMER_CONFIGURATION}
        onChange={onChangeSectionState}
        contentPadding={styles["content-padding"]}
      >
        <AdvancedCustomerConfiguration onToggleChange={onToggleChange} />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={SERVICE_EXCLUSIONS}
        onChange={onChangeSectionState}
        contentPadding={styles["content-padding"]}
      >
        <ServiceExclusions />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={SECURITY_SETTINGS}
        onChange={onChangeSectionState}
      >
        <SecuritySettings onToggleChange={onToggleChange} />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={SHIPPING_SETTINGS}
        onChange={onChangeSectionState}
        contentPadding={styles["content-padding"]}
        formName={USER_DETAILS_FORMS.SHIPPING_SETTINGS}
      >
        <AdminShippingSettings
          onInputChange={onToggleChange}
          withReference={(ref) => {
            refs[USER_DETAILS_FORMS.SHIPPING_SETTINGS] = ref;
          }}
        />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={SERVICE_XREF_MAPPINGS}
        onChange={onChangeSectionState}
        formName={USER_DETAILS_FORMS.XREF_MAPPINGS}
      >
        <ServiceXrefMappings
          withReference={(ref) => {
            refs[USER_DETAILS_FORMS.XREF_MAPPINGS] = ref;
          }}
        />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={SERVICE_RESTRICTIONS}
        onChange={onChangeSectionState}
        formName={USER_DETAILS_FORMS.SERVICE_RESTRICTIONS}
      >
        <ServiceRestrictions
          withReference={(ref) => {
            refs[USER_DETAILS_FORMS.SERVICE_RESTRICTIONS] = ref;
          }}
        />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={INTERNATIONAL_SHIPMENTS}
        onChange={onChangeSectionState}
      >
        <InternationalShipments
          onInputChange={onToggleChange}
          onInfoMouseOver={onInfoMouseOver}
        />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={CHANGE_MESSAGE}
        onChange={onChangeSectionState}
      >
        <ChangeMessage onBlurChangeMessage={onBlurChangeMessage} />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={LABEL_SETTINGS}
        onChange={onChangeSectionState}
      >
        <AdminLabelSettings />
      </CustomExpansionPanel>
      <CustomExpansionPanel
        title={USER_PROFILES}
        onChange={onChangeSectionState}
        formName={USER_DETAILS_FORMS.USER_PROFILES}
      >
        <UserProfiles
          withReference={(ref) => {
            refs[USER_DETAILS_FORMS.USER_PROFILES] = ref;
          }}
        />
      </CustomExpansionPanel>
      <BottomButtons onCancelClick={onCancel} onConfirmClick={onConfirmClick} />
    </PageContainer>
  );
};

MainCustomerUserForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onInfoMouseOver: PropTypes.func,
  onToggleChange: PropTypes.func,
  onChangeSectionState: PropTypes.func,
  onBlurChangeMessage: PropTypes.func,
  onClickCreateMasterUser: PropTypes.func,
  onBlurFields: PropTypes.func,
  isFormDirty: PropTypes.bool,
  showPrompt: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  canCreateMasterUser: PropTypes.bool,
};

export default compose(
  withTrackProps(() => ({
    onInfoMouseOver: () => ANALYTICS_FOR_USER.HOVER,
    onToggleChange: (field, value) =>
      createLogEntryPayload(CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_TOGGLE, {
        field,
        value,
      }),
    onBlurFields: (field) => {
      switch (field) {
        case DESCRIPTION_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_DESCRIPTION;
        case POSITION_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_POSITION;
        case PHONE_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_TELEPHONE;
        case RE_ENTER_PASSWORD_FIELD:
          return ANALYTICS_FOR_USER.ENTER_TEXT_CONFIRM_PASSWORD;
        case EMAIL_FIELD:
          return ANALYTICS_FOR_USER.ENTER_TEXT_EMAIL;
        case FORENAME_FIELD:
          return ANALYTICS_FOR_USER.ENTER_TEXT_FIRSTNAME;
        case LASTNAME_FIELD:
          return ANALYTICS_FOR_USER.ENTER_TEXT_SURNAME;
        case NEW_PASSWORD_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_PASSWORD;
        case USERNAME_FIELD:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_USERNAME;
      }
    },
    onClickCreateMasterUser: () =>
      EDIT_CUSTOMER_USER.CLICK_CREATE_CREATE_STAFF_OVERRIDE_USER_FOR_MYDPD,
    onBlurChangeMessage: () =>
      CREATE_EDIT_CUSTOMER_USER_ANALYTICS.ENTER_TEXT_CHANGE_MESSAGE,
    onChangeSectionState: (sectionName) => {
      switch (sectionName) {
        case ACCOUNT_DETAILS:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_ACCOUNT_DETAILS;
        case USER_DETAILS:
          return ANALYTICS_FOR_USER.CLICK_USER_DETAILS;
        case ADDITIONAL_ACCOUNTS:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_ADDITIONAL_ACCOUNTS;
        case SERVICE_EXCLUSIONS:
          return ANALYTICS_FOR_USER.CLICK_SERVICE_EXCLUSIONS;
        case SERVICE_XREF_MAPPINGS:
          return ANALYTICS_FOR_USER.CLICK_SERVICE_XREF_MAPPINGS;
        case SHIPPING_SETTINGS:
          return ANALYTICS_FOR_USER.CLICK_SHIPPING_SETTINGS;
        case USER_PROFILES:
          return ANALYTICS_FOR_USER.CLICK_USER_PROFILES;
        case SYSTEM_ACCESS:
          return ANALYTICS_FOR_USER.CLICK_SYSTEM_ACCESS;
        case SECURITY_SETTINGS:
          return ANALYTICS_FOR_USER.CLICK_SECURITY_SETTINGS;
        case LABEL_SETTINGS:
          return ANALYTICS_FOR_USER.CLICK_LABEL_SETTINGS;
        case CHANGE_MESSAGE:
          return CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_CHANGE_MESSAGE;
        case MISCELLANEOUS_CONFIGURATION:
          return ANALYTICS_FOR_USER.CLICK_MISCELLANEOUS_CONFIGURATIONS;
        case ADVANCED_CUSTOMER_CONFIGURATION:
          return ANALYTICS_FOR_USER.CLICK_ADVANCED_CUSTOMER_CONFIGURATION;
        case SERVICE_RESTRICTIONS:
          return ANALYTICS_FOR_USER.CLICK_SERVICE_RESTRICTIONS;
        case INTERNATIONAL_SHIPMENTS:
          return ANALYTICS_FOR_USER.CLICK_INTERNATIONAL_SHIPMENTS;
      }
    },
  }))
)(MainCustomerUserForm);
