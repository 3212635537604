import { isEmailValid } from "../../utils/commonValidation";
import { getEmailDomain } from "../app/app.modes";
import {
  PASSWORD_MIN_LENGTH,
  UNABLE_TO_AUTHORISE,
  ZERO_WIDTH_NON_JOINER,
} from "./auth.constants";

export const preparePassword = (password, email) => {
  if (!password) {
    return null;
  }
  let preparedPassword = password;
  if (!isEmailValid(email)) {
    preparedPassword = password.toUpperCase();
  }
  return preparedPassword.padEnd(PASSWORD_MIN_LENGTH, ZERO_WIDTH_NON_JOINER);
};

export const getSignInEmail = (email) =>
  isEmailValid(email)
    ? email
    : `${encodeURIComponent(email)}${getEmailDomain()}`;

export const isUnableToAuthorise = (error) =>
  error.message &&
  error.message.toLowerCase().includes(UNABLE_TO_AUTHORISE.toLowerCase());
