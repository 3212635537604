import PropTypes from "prop-types";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import { fetchAppVersions } from "../../../../features/config/config.actions";
import GradientHeader from "../../../../components/GradientHeader/GradientHeader";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import { CUSTOMER_MIGRATIONS_ANALYTICS } from "../../../../constants/analytics";
import { SEARCH } from "../../../../constants/strings";
import SearchForm from "./SearchForm";

const Migrations = () => (
  <PageContainer>
    <GradientHeader title={SEARCH} />
    <SearchForm />
  </PageContainer>
);

Migrations.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  fetchAppVersions: () => dispatch(fetchAppVersions()),
});

export default compose(
  connect(null, mapDispatchToProps),
  withTrack({
    loadId: CUSTOMER_MIGRATIONS_ANALYTICS.LOAD,
    interfaceId: CUSTOMER_MIGRATIONS_ANALYTICS.INTERFACE_ID,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchAppVersions();
    },
  })
)(Migrations);
