import { FORBIDDEN, NOT_FOUND, UNAUTHORIZED } from "http-status-codes";
import { get } from "lodash";

import { showAccessDeniedModal } from "../features/modal/modal.actions";
import { clearUserData } from "../features/user/user.actions";
import {
  getCurrentUser,
  getCustomerAccountId,
} from "../features/user/user.selectors";
import { CUSTOMER_USERS_PAGE, LOGIN_PAGE, PAGE_404 } from "../router";
import { navigateTo } from "../router/navigation";

export const showAccessDeniedModalMiddleware =
  (store) => (next) => (action) => {
    const error = get(action.payload, "error", null);

    if (error && error.statusCode === FORBIDDEN) {
      const customerId = getCustomerAccountId(store.getState());

      store.dispatch(
        showAccessDeniedModal(() =>
          navigateTo(CUSTOMER_USERS_PAGE, { customerId })
        )
      );
    } else if (error && error.statusCode === NOT_FOUND) {
      navigateTo(PAGE_404);
    } else if (error && error.statusCode === UNAUTHORIZED) {
      if (!window.location.href.includes(LOGIN_PAGE)) {
        navigateTo(LOGIN_PAGE);
      }
    }

    return next(action);
  };

export const errorHandlerMiddleware = (store) => (next) => (action) => {
  const user = getCurrentUser(store.getState());

  if (!user) {
    return next(action);
  }

  const error = get(action.payload, "error", null);

  if (error && error.statusCode === UNAUTHORIZED) {
    store.dispatch(clearUserData());
    if (!window.location.href.includes(LOGIN_PAGE)) {
      navigateTo(LOGIN_PAGE);
    }
  }

  return next(action);
};
