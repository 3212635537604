import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./FieldWithLabel.module.scss";

const FieldWithLabel = ({ label, input, icon, classNameValue }) => (
  <div className={styles.container}>
    {icon && <span className={styles.icon}>{icon}</span>}
    <span className={styles.label}>{`${label}:`}</span>
    <span className={classNames(styles.name, classNameValue && classNameValue)}>
      {input.value}
    </span>
  </div>
);

FieldWithLabel.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  icon: PropTypes.string,
  classNameValue: PropTypes.string,
};

export default FieldWithLabel;
