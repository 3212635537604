import { EXISTED_FIELD, MAX_VALUE, MIN_VALUE } from "./sequences.constants";

export const createSequenceObject = ({ seqName, curValue }) => ({
  seqName,
  seqNumber: 0,
  minValue: Number(`${seqName}${MIN_VALUE}`),
  maxValue: Number(`${seqName}${MAX_VALUE}`),
  curValue: Number(curValue),
  cycle: 1,
});

export const prepareSequencesData = ({ data }) =>
  data.filter((sequence) => !sequence[EXISTED_FIELD]).map(createSequenceObject);
