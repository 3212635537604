import { change } from "redux-form";

import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { selectDisableNotificationOnPrint } from "../advancedCustomerConfiguration/advancedCustomerConfiguration.actions";
import { getThirtyDaysForwardDating } from "../advancedCustomerConfiguration/advancedCustomerConfiguration.selectors";
import {
  EOD_DAY_FIELD,
  EOD_FIELDS,
  EOD_MASTER_FIELD,
  EOD_USER_FIELD,
  WEEKDAYS_DEFAULT,
} from "./shippingSettings.constants";
import { getShippingSettingsFormValues } from "./shippingSettings.selectors";

export const changeShippingFormFieldValue = (fieldName, value) =>
  change(USER_DETAILS_FORMS.SHIPPING_SETTINGS, fieldName, value);

export const onSendersUniqChange = () => (dispatch, getState) => {
  const {
    enableScanningScreen,
    allocateSenders,
    updateRecordsDuplicate,
    enableReleaseFile,
  } = getShippingSettingsFormValues(getState());
  return dispatch(
    uncheckReferenceUniqueCheckboxes({
      enableScanningScreen,
      allocateSenders,
      updateRecordsDuplicate,
      enableReleaseFile,
    })
  );
};

export const onReferenceOneChange = () => (dispatch, getState) => {
  const {
    sendersRefUnique,
    enableScanningScreen,
    allocateSenders,
    updateRecordsDuplicate,
    enableReleaseFile,
  } = getShippingSettingsFormValues(getState());
  return dispatch(
    uncheckReferenceUniqueCheckboxes({
      sendersRefUnique,
      enableScanningScreen,
      allocateSenders,
      updateRecordsDuplicate,
      enableReleaseFile,
    })
  );
};

export const uncheckReferenceUniqueCheckboxes = (fields) => (dispatch) => {
  // eslint-disable-next-line array-callback-return
  Object.keys(fields).map((key) => {
    if (fields[key]) {
      return dispatch(changeShippingFormFieldValue(key, false));
    }
  });
};

export const changeEndOfDayField = (fieldName, value) =>
  change(USER_DETAILS_FORMS.SHIPPING_SETTINGS, fieldName, value);

export const deselectEodMaster = () => (dispatch) =>
  dispatch(changeEndOfDayField(EOD_MASTER_FIELD, false));

export const selectEndOfDay = () => (dispatch) =>
  dispatch(changeEndOfDayField(EOD_USER_FIELD, true));

export const resetEndOfDay = () => (dispatch) => {
  let value = "";
  EOD_FIELDS.forEach((key) => {
    if (key === EOD_DAY_FIELD) {
      value = WEEKDAYS_DEFAULT;
    }
    dispatch(changeEndOfDayField(key, value));
  });
};

export const onEnableDisableNotification = (value) => (dispatch, getState) => {
  const thirtyDaysForwardDatingToggle = getThirtyDaysForwardDating(getState());
  dispatch(
    selectDisableNotificationOnPrint(thirtyDaysForwardDatingToggle || value)
  );
};
