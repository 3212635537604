import { filter, isArray, isEmpty, union } from "lodash";
import get from "lodash/get";
import {
  change,
  clearSubmitErrors,
  getFormSubmitErrors,
  initialize,
  isInvalid,
  SubmissionError,
} from "redux-form";

import {
  createAction,
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import { getAccountDetailsInitialValues } from "./accountDetails/accountDetails.models";
import {
  getPostcode,
  getSelectedAccountDetails,
} from "./accountDetails/accountDetails.selectors";
import { enableMiscellaneousEodFields } from "./miscellaneousConfiguration/miscellaneousConfiguration.actions";
import { getShippingInitialValues } from "./shippingSettings/shippingSettings.models";
import { clearPasswordSection } from "./userDetails/userDetails.actions";
import { USERNAME_FIELD } from "./userDetails/userDetails.constants";
import { getUserDetailsInitialValues } from "./userDetails/userDetails.models";
import { getEmail, getUserId } from "./userDetails/userDetails.selectors";
import {
  createFormValuesFromDeleteResponse,
  getAddKeyModalData,
  isApiSettingAvailable,
  updateFormMapper,
} from "./userDetailsForm.models";
import {
  prepareProfilesForm,
  registerAllocatedProfileField,
} from "./userProfiles/userProfiles.actions";
import { CHANGE_MESSAGE_MODAL } from "../../constants/analytics";
import { USER_DETAILS_FORMS, USER_FORMS } from "../../constants/forms";
import {
  CHANGE_MESSAGE,
  CLOSE,
  NO_USER_WITH_CURRENT_ID,
  WARNING,
} from "../../constants/strings";
import { ROOT } from "../../router";
import { navigateTo } from "../../router/navigation";
import { getConfig } from "../config/config.selectors";
import {
  fetchCustomerAccountsByIds,
  fetchPermittedIp,
} from "../customer/customer.actions";
import { hasMasterEodUser } from "../customer/customer.selectors";
import * as customerService from "../customer/customer.service";
import { createLoadingAction } from "../loader/loader.actions";
import {
  showAddKeyModal,
  showDeleteKeyModal,
  showDisableApiAccessModal,
  showModal,
} from "../modal/modal.actions";
import { ALIGN_BUTTONS, WIDTH_TYPE } from "../modal/modal.constants";
import { getProfilesByAccount } from "../profile/profile.service";
import { setTabsToInitialState } from "../tabs/tabs.actions";
import { onChangeGeneratedUserName } from "../user/user.actions";
import { isCustomerAdmin } from "../user/user.model";
import * as userService from "../user/user.service";
import {
  getPopulatedFormFields,
  getUserInfo,
} from "./userDetailsForm.selector";
import {
  getApiSettingFormValues,
  getApiUsersKeys,
} from "./apiSetting/apiSettingSelectors";
import {
  ADD_NEW_KEY,
  API_SETTING_FIELDS,
  DISABLE_API_ACCESS,
  DELETE_KEY,
  DELETE_KEYS_SYSTEM,
  EMAIL_ALREADY_VERIFIED,
  INVALID_EMAIL,
  UNABLE_TO_$,
  VERIFIED_EMAIL_ERROR,
  CANNOT_SENT,
  EMAIL_CANNOT_SENT,
} from "./apiSetting/apiSetting.constants";
import { createSnackbarErrorAction } from "../snackbar/snackbar.actions";

const ACTION_NAMESPACE = "USER_FORM";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  CHANGE_ACCOUNT: "CHANGE_ACCOUNT",
  FETCH_USER_INFO: createAsyncActionTypes("FETCH_USER_INFO"),
  PREPARE_CUSTOMER_DATA: createAsyncActionTypes("PREPARE_CUSTOMER_DATA"),
  PREPARE_API_SETTING_DATA: createAsyncActionTypes("PREPARE_API_SETTING_DATA"),
  VERIFY_API_USER_EMAIL: createAsyncActionTypes("VERIFY_API_USER_EMAIL"),
  CREATE_API_USER_KEY: createAsyncActionTypes("CREATE_API_USER_KEY"),
  DELETE_API_USER_KEY: createAsyncActionTypes("DELETE_API_USER_KEY"),
  DELETE_API_USER: createAsyncActionTypes("DELETE_API_USER"),
  UPDATE_API_USERS_KEYS: "UPDATE_API_USERS_KEYS",
  CLEAR: "CLEAR",
});

const initializeForms = (props, isCustomerUser) => (dispatch, getState) => {
  const state = getState();
  const config = getConfig(state);

  const formData = {
    ...props,
    ...config,
    customer: props.customer,
  };

  const hasAdminAccess = isCustomerAdmin(formData.userInfo);
  Object.entries(updateFormMapper(isCustomerUser)).map(([form, mapper]) => {
    if (form === USER_FORMS.SYSTEM_ACCESS_ACCOUNTS && !hasAdminAccess) {
      // eslint-disable-next-line array-callback-return
      return;
    }

    if (form === USER_FORMS.API_SETTING) {
      // eslint-disable-next-line array-callback-return
      if (isEmpty(props.apiUsersKeys)) {
        return;
      } else return dispatch(updateFormValues(form, mapper(formData))); // NOTE: use updateFormValues instead of initialize for properly resetting form
    }

    return dispatch(initialize(form, mapper(formData)));
  });
};

export const redirectNotFoundUser = () => (dispatch) => {
  navigateTo(ROOT);

  return dispatch(
    showModal({
      contentText: NO_USER_WITH_CURRENT_ID,
      notificationType: WARNING,
      title: WARNING,
      confirmButtonText: CLOSE,
      showCancelButton: false,
      alignButton: ALIGN_BUTTONS.CENTER,
    })
  );
};

export const showChangeMessageModal = (changeMessage) => (dispatch) => {
  if (changeMessage) {
    dispatch(
      showModal({
        confirmButtonText: CLOSE,
        customComponent: CHANGE_MESSAGE,
        showCancelButton: false,
        alignButton: ALIGN_BUTTONS.RIGHT,
        widthType: WIDTH_TYPE.MEDIUM,
        title: CHANGE_MESSAGE,
        changeMessage,
        loadId: CHANGE_MESSAGE_MODAL.LOAD,
        interfaceId: CHANGE_MESSAGE_MODAL.INTERFACE_ID,
        confirmActionId: CHANGE_MESSAGE_MODAL.CLOSE,
      })
    );
  }
};

export const getAccountsModels = async (userInfo) => {
  const userAccountIds = userInfo.accounts || [];
  const adminAccountIds = userInfo.customerAdminAccounts || [];
  let accounts = await customerService.getCustomerByAccountNumber(
    union(userAccountIds, adminAccountIds),
    userInfo.businessId
  );
  if (!isArray(accounts)) {
    accounts = [accounts];
  }
  const additionalAccounts = filter(accounts, (item) =>
    userAccountIds.includes(get(item, "account"))
  );
  const customerAdminAccounts = filter(accounts, (item) =>
    adminAccountIds.includes(get(item, "account"))
  );
  return { additionalAccounts, customerAdminAccounts };
};

export const fetchUserInfo = createAsyncAction(
  (uid = "", customerId) =>
    async (dispatch) => {
      let apiUsersKeys = {};
      const user = await userService.fetchUser(uid);
      if (!user) {
        dispatch(redirectNotFoundUser());
      }

      const userInfo = { ...user, uid };

      if (isApiSettingAvailable(userInfo, uid)) {
        apiUsersKeys = await userService.fetchApiUsersKeys();
      }

      const customer = await customerService.getCustomerByAccountNumber(
        customerId || userInfo.account,
        user.businessId
      );
      const permittedIp = await customerService.getCustomerIpAddress(
        customerId || userInfo.account,
        user.businessId
      );

      const accounts = await getAccountsModels(userInfo);
      const { additionalAccounts, customerAdminAccounts } = accounts;

      if (!customerId) {
        dispatch(showChangeMessageModal(userInfo.changeMessage));
      }

      const profiles = await getProfilesByAccount(
        customerId || userInfo.account
      );
      dispatch(
        initializeForms(
          {
            userInfo,
            customer: { ...customer, permittedIp },
            additionalAccounts,
            profiles,
            customerAdminAccounts,
            apiUsersKeys,
          },
          !!customerId
        )
      );
      dispatch(fetchPermittedIp(userInfo));

      return {
        customer: { ...customer, permittedIp },
        user: userInfo,
        apiUsersKeys,
      };
    },
  ACTIONS.FETCH_USER_INFO
);

export const onAccountChange = (payload) =>
  createPayloadAction(ACTIONS.CHANGE_ACCOUNT, payload);

const changeUserDetailsField = (field, value) =>
  change(USER_DETAILS_FORMS.DPD_USER_DETAILS, field, value);

export const changeUsername = (username) => (dispatch) =>
  dispatch(changeUserDetailsField(USERNAME_FIELD, username));

export const onAdminAccessToggle = (value) => (dispatch, getState) => {
  if (value) {
    const state = getState();
    const selectedAccount = getSelectedAccountDetails(state);
    const postcode = getPostcode(state);
    const id = getUserId(state);

    if (!id) {
      const email = getEmail(state);

      dispatch(changeUsername(email));
    }

    dispatch(clearPasswordSection());
    dispatch(onChangeGeneratedUserName());
    selectedAccount.account &&
      postcode &&
      dispatch(setAccountToAdminAccess(selectedAccount));
  }

  !value && dispatch(clearSubmitErrors(USER_DETAILS_FORMS.DPD_USER_DETAILS));
};

export const setAccountToAdminAccess = (account) => (dispatch) => {
  dispatch(
    change(
      USER_DETAILS_FORMS.SYSTEM_ACCESS_ACCOUNTS,
      "accounts",
      account ? [account] : []
    )
  );
};

export const prepareRequiredCreateData = createLoadingAction(
  (customerId) => async (dispatch, getState) => {
    dispatch(registerAllocatedProfileField());
    await dispatch(prepareProfilesForm(customerId));
    await dispatch(prepareCustomerData(customerId));
    const hasMasterUser = hasMasterEodUser(customerId)(getState());
    hasMasterUser && dispatch(enableMiscellaneousEodFields());
    dispatch(setTabsToInitialState());
  }
);

export const prepareRequiredEditData = createLoadingAction(
  (id, customerId) => async (dispatch) => {
    dispatch(registerAllocatedProfileField());
    await dispatch(prepareCustomerData(customerId)); // NOTE: we should fetch customer before user
    // else it will cause reinitialize of miscellaneous configuration form and inconsistent data on ui
    await dispatch(fetchUserInfo(id, customerId));
    dispatch(setTabsToInitialState());
  }
);

export const prepareCustomerData = createAsyncAction(
  (accountId) => async (dispatch) => {
    const accounts = await dispatch(fetchCustomerAccountsByIds());

    await dispatch(fetchPermittedIp({ account: accountId }));
    return get(accounts, accountId, {});
  },
  ACTIONS.PREPARE_CUSTOMER_DATA
);

export const initializeCreateCustomerForm = () => (dispatch) => {
  dispatch(
    initialize(USER_FORMS.ACCOUNT_DETAILS, getAccountDetailsInitialValues())
  );
  dispatch(
    initialize(USER_FORMS.DPD_USER_DETAILS, getUserDetailsInitialValues())
  );
  dispatch(
    initialize(USER_FORMS.SHIPPING_SETTINGS, getShippingInitialValues())
  );
};

export const clearUserDetailsForm = () => createAction(ACTIONS.CLEAR);

export const updateFormValues = (formName, values) => (dispatch) => {
  const fields = Object.keys(values);
  fields.map((fieldName) =>
    dispatch(change(formName, fieldName, get(values, fieldName, "")))
  );
};
export const resetFormValues = (formName) => (dispatch, getState) => {
  const fields = getPopulatedFormFields(formName)(getState());

  fields.map((fieldName) => dispatch(change(formName, fieldName, undefined)));
};

export const verifyApiUserEmail = createLoadingAction(
  createAsyncAction(
    () => async (dispatch, getState) => {
      const state = getState();
      const apiSettingFormValues = getApiSettingFormValues(state);

      // NOTE: repeated submit clear submitErrors
      if (isInvalid(USER_DETAILS_FORMS.API_SETTING)(state)) {
        throw new SubmissionError(
          getFormSubmitErrors(USER_DETAILS_FORMS.API_SETTING)(state)
        );
      }

      try {
        return await userService.verifyApiUserEmail({
          [[API_SETTING_FIELDS.EMAIL]]:
            apiSettingFormValues[API_SETTING_FIELDS.EMAIL],
        });
      } catch (error) {
        const errorMessage = get(error, "errors[0].message", "");

        if (errorMessage === EMAIL_ALREADY_VERIFIED) {
          throw new SubmissionError({
            email: VERIFIED_EMAIL_ERROR,
          });
        }

        if (errorMessage.includes(CANNOT_SENT)) {
          throw new SubmissionError({
            email: EMAIL_CANNOT_SENT,
          });
        }

        throw new SubmissionError({
          email: INVALID_EMAIL,
        });
      }
    },
    ACTIONS.VERIFY_API_USER_EMAIL
  )
);

export const updateApiUsersKeys = (data) =>
  createPayloadAction(ACTIONS.UPDATE_API_USERS_KEYS, data);

export const addApiUserKey = createLoadingAction(
  createSnackbarErrorAction(
    UNABLE_TO_$(ADD_NEW_KEY),
    (isLiveKey) => async (dispatch, getState) => {
      const apiUsersKeys = getApiUsersKeys(getState());
      const newKey = await userService.createApiUserKey({ isLiveKey });
      const { updatedApiUsersKeys, ...modalData } = getAddKeyModalData({
        apiUsersKeys,
        newKey,
        isLiveKey,
      });

      dispatch(updateApiUsersKeys(updatedApiUsersKeys));
      dispatch(showAddKeyModal(modalData));
    },
    ACTIONS.CREATE_API_USER_KEY
  )
);

export const deleteApiUserKey = createLoadingAction(
  createSnackbarErrorAction(
    UNABLE_TO_$(DELETE_KEY),
    (system, key, isLiveKey) => async (dispatch) => {
      const data = await userService.deleteApiUserKey(system, key);
      dispatch(
        updateFormValues(
          USER_FORMS.API_SETTING,
          createFormValuesFromDeleteResponse(data, isLiveKey)
        )
      );

      return data;
    },
    ACTIONS.DELETE_API_USER_KEY
  )
);

export const removeApiUserKey =
  (keyLabel, fieldName, isLiveKey) => (dispatch, getState) => {
    const apiSettingFormValues = getApiSettingFormValues(getState());
    const key = get(apiSettingFormValues, fieldName, "");
    const onConfirmClick = async () =>
      dispatch(
        deleteApiUserKey(
          isLiveKey ? DELETE_KEYS_SYSTEM.LIVE : DELETE_KEYS_SYSTEM.SANDBOX,
          key,
          isLiveKey
        )
      );

    dispatch(
      showDeleteKeyModal({ keyLabel, initialValues: { key }, onConfirmClick })
    );
  };

export const deleteApiUser = createLoadingAction(
  createSnackbarErrorAction(
    UNABLE_TO_$(DISABLE_API_ACCESS),
    () => async (dispatch) => {
      try {
        await userService.deleteApiUser();
        dispatch(resetFormValues(USER_DETAILS_FORMS.API_SETTING));
      } catch (error) {
        dispatch(
          change(
            USER_DETAILS_FORMS.API_SETTING,
            API_SETTING_FIELDS.API_ACCESS,
            true
          )
        );
        throw error;
      }
    },
    ACTIONS.DELETE_API_USER
  )
);
export const disableApiAccess = (value) => (dispatch, getState) => {
  const state = getState();
  const apiUsersKeys = getApiUsersKeys(state);
  const user = getUserInfo(state);

  if (!value && apiUsersKeys.apiUser) {
    const onConfirmClick = async () => dispatch(deleteApiUser());
    const onCloseClick = () =>
      dispatch(
        change(
          USER_DETAILS_FORMS.API_SETTING,
          API_SETTING_FIELDS.API_ACCESS,
          true
        )
      );

    dispatch(
      showDisableApiAccessModal({
        onConfirmClick,
        onCloseClick,
      })
    );
  } else {
    dispatch(
      change(
        USER_DETAILS_FORMS.API_SETTING,
        API_SETTING_FIELDS.EMAIL,
        get(user, API_SETTING_FIELDS.EMAIL, "")
      )
    );
  }
};
