import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { getPermittedIpAddress } from "../../../../features/customer/customer.selectors";
import { onVerifyEmail } from "../../../../features/user/user.actions";
import {
  getUserId,
  shouldShowLeavePrompt,
} from "../../../../features/user/user.selectors";
import { isCreateCustomerUserFormDirty } from "../../../../features/userDetailsForm/userDetailsForm.selector";
import EditUserPage from "../edit/EditUserPage";

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params;
  return {
    formDirty: isCreateCustomerUserFormDirty(state),
    showPrompt: shouldShowLeavePrompt(state),
    permittedIpAddress: getPermittedIpAddress(customerId)(state),
    userId: getUserId(state),
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id, customerId },
    },
  }
) => ({
  onVerify: () => dispatch(onVerifyEmail(id, customerId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const {
        match: {
          params: { email },
        },
      } = this.props;
      if (email) {
        this.props.onVerify();
      }
    },
  })
)(EditUserPage);
