import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./Filter.module.scss";

const useThemeColorStyles = makeStyles((theme) => ({
  active: () => ({
    borderBottomColor: `${theme.palette.primary.main} !important`,
  }),
}));

export default function Filter({ text, active, to, onClick }) {
  const activeTabStyle = useThemeColorStyles(useTheme());

  return (
    <ListItem
      button
      className={classNames(styles.item, active && activeTabStyle.active)}
      component={Link}
      onClick={onClick}
      to={to}
    >
      <ListItemText>{text.toUpperCase()}</ListItemText>
    </ListItem>
  );
}

Filter.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

Filter.defaultProps = {
  onClick: () => null,
};
