import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import SelectWithTitle from "./SelectWithTitle";

const SelectWithTitleStateFull = ({ value, onChange, ...props }) => {
  const [currVal, setValue] = useState("");

  useEffect(() => {
    currVal !== value && setValue(value);
  }, [value]);

  return (
    <SelectWithTitle
      {...props}
      value={currVal}
      onChange={({ target: { value } }) => {
        onChange(value);
      }}
    />
  );
};

SelectWithTitleStateFull.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

SelectWithTitleStateFull.defaultProps = {
  value: "",
  onChange: () => null,
};
export default SelectWithTitleStateFull;
