import {
  DELETE,
  ENTER_VALID_DELETE,
  ENTER_VALID_DISABLE,
  INVALID_EMAIL,
} from "./apiSetting.constants";
import { DISABLE_API_ACCESS } from "../../../constants/strings";
import { isEmailValid } from "../../../utils/commonValidation";

export const disableApiAccessValidator = ({ disable = "" }) => {
  const errors = {};

  if (!disable || disable.trim() !== DISABLE_API_ACCESS.toUpperCase()) {
    errors.disable = ENTER_VALID_DISABLE;
  }

  return errors;
};

export const deleteKeyValidator = ({ deleteKey = "" }) => {
  const errors = {};

  if (!deleteKey || deleteKey.trim() !== DELETE.toUpperCase()) {
    errors.deleteKey = ENTER_VALID_DELETE;
  }

  return errors;
};

export const apiSettingValidator = ({ email = "" }) => {
  const errors = {};

  if (!isEmailValid(email)) {
    errors.email = INVALID_EMAIL;
  }

  return errors;
};
