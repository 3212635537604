import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import { USER_DETAILS_FORMS } from "../../../../constants/forms";
import { CHANGE_MESSAGE } from "../../../../constants/strings";
import { CHANGE_MESSAGE_FIELD } from "../../../../features/userDetailsForm/userDetails/userDetails.constants";

const ChangeMessage = ({ onBlurChangeMessage }) => (
  <Field
    name={CHANGE_MESSAGE_FIELD}
    label={CHANGE_MESSAGE}
    component={InputWithTitle}
    inputProps={{ maxLength: 100 }}
    onBlur={onBlurChangeMessage}
  />
);

ChangeMessage.propTypes = {
  onBlurChangeMessage: PropTypes.func,
};

export default reduxForm({
  form: USER_DETAILS_FORMS.DPD_USER_DETAILS,
})(ChangeMessage);
