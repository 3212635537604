import { get } from "lodash";
import { getFormValues, hasSubmitSucceeded } from "redux-form";
import { createSelector } from "reselect";

import { CUSTOMER_MIGRATION_SEARCH_FORM } from "../../constants/forms";
import {
  SEARCH_PARAM_FIELD,
  SEARCH_PAGE_FIELD,
  SEARCH_VALUE_FIELD,
  SEARCH_RESULTS_TOTAL_FIELD,
  SEARCH_RESULTS_FIELD,
} from "./search.constants";

export const getMigrationSearchFormValues = getFormValues(
  CUSTOMER_MIGRATION_SEARCH_FORM
);

export const getSearchMigrationResults = createSelector(
  getMigrationSearchFormValues,
  (state) => get(state, SEARCH_RESULTS_FIELD, {})
);

export const getSearchMigrationResultsTotal = createSelector(
  getMigrationSearchFormValues,
  (state) => get(state, SEARCH_RESULTS_TOTAL_FIELD, 0)
);

export const getSearchPage = createSelector(
  getMigrationSearchFormValues,
  (state) => get(state, SEARCH_PAGE_FIELD, 0)
);

export const getSearchValue = createSelector(
  getMigrationSearchFormValues,
  (state) => get(state, SEARCH_VALUE_FIELD, "")
);

export const getSearchParam = createSelector(
  getMigrationSearchFormValues,
  (state) => get(state, SEARCH_PARAM_FIELD, "")
);

export const isFormSubmitted = (state) =>
  hasSubmitSucceeded(CUSTOMER_MIGRATION_SEARCH_FORM)(state);
