import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import styles from "./CustomHeader.module.scss";
import CustomHeaderCell from "./CustomHeaderCell";
import { FIRST_NAME, LOGIN, SURNAME } from "../../../../constants/strings";

const header = [
  {
    title: "",
    isSortIconVisible: false,
  },
  {
    title: SURNAME,
    isSortIconVisible: true,
  },
  {
    title: FIRST_NAME,
    isSortIconVisible: true,
  },
  {
    title: LOGIN,
    isSortIconVisible: false,
  },
  {
    title: "",
    isSortIconVisible: false,
  },
];

const CustomHeader = () => (
  <TableHead className={styles.tableHead}>
    <TableRow>
      {header.map((v, k) => (
        <CustomHeaderCell {...v} key={k} />
      ))}
    </TableRow>
  </TableHead>
);

export default CustomHeader;
