import find from "lodash/find";

import {
  MAPPING_EXIST,
  REGEX_MAPPING,
  SELECT_SERVICE_FOR_XREF,
  SERVICE_XREF_MAPPINGS_FIELD,
  THE_MAPPING_VALUE_ENTERED_CONTAINS_AN_INVALID_CHARACTER,
} from "./serviceXrefMappings.constants";

export const validate = ({ serviceXrefMappings = [], mapping = "" }) => {
  const errors = {};

  const mappingField = mapping.trim();
  if (REGEX_MAPPING.test(mappingField)) {
    errors.mapping = THE_MAPPING_VALUE_ENTERED_CONTAINS_AN_INVALID_CHARACTER;
  }

  if (
    mappingField &&
    find(serviceXrefMappings, { mappingText: mappingField })
  ) {
    errors.mapping = MAPPING_EXIST;
  }

  const emptyService = serviceXrefMappings.some(
    (mappingValue) => !mappingValue.serviceCode
  );

  if (emptyService) {
    errors[SERVICE_XREF_MAPPINGS_FIELD] = SELECT_SERVICE_FOR_XREF;
  }
  return errors;
};
