import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { CREATE_CUSTOMER_USER } from "../../../../constants/analytics";
import { setTabsToInitialState } from "../../../../features/tabs/tabs.actions";
import { onConfirmCreateUser } from "../../../../features/user/user.actions";
import { shouldShowLeavePrompt } from "../../../../features/user/user.selectors";
import {
  clearUserDetailsForm,
  initializeCreateCustomerForm,
} from "../../../../features/userDetailsForm/userDetailsForm.actions";
import {
  isCreateUserFormDirty,
  isUserFormSubmitted,
} from "../../../../features/userDetailsForm/userDetailsForm.selector";
import { DPD } from "../../../../router";
import MainCustomerUserForm from "../../components/MainCustomerUserForm";

const mapStateToProps = (state) => ({
  isFormDirty: isCreateUserFormDirty(state),
  showPrompt: shouldShowLeavePrompt(state),
  isSubmitted: isUserFormSubmitted(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onCancel: () => props.history.push(DPD),
  onConfirm: () => dispatch(onConfirmCreateUser()),
  onSetTabsToInitialState: () => dispatch(setTabsToInitialState()),
  initializeForms: () => dispatch(initializeCreateCustomerForm()),
  clearForm: () => dispatch(clearUserDetailsForm()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.onSetTabsToInitialState();
      this.props.initializeForms();
    },
    componentWillUnmount() {
      this.props.clearForm();
    },
  }),
  withTrack({
    loadId: CREATE_CUSTOMER_USER.LOAD,
    interfaceId: CREATE_CUSTOMER_USER.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onCancel: () => CREATE_CUSTOMER_USER.CANCEL,
    onConfirm: () => CREATE_CUSTOMER_USER.CONFIRM,
  }))
)(MainCustomerUserForm);
