import { SEARCH_ERRORS } from "./search.constants";

export const validate = ({ username = "", account = "" }) => {
  const errors = {};

  if (!username.trim() && !account.trim()) {
    errors.username = SEARCH_ERRORS.enter;
    errors.account = SEARCH_ERRORS.enter;
  }

  return errors;
};
