import { ACTIONS } from "./auth.actions";
import { ACTIONS as USER_ACTIONS } from "../user/user.actions";

export const initialState = {
  isSignOutDialogOpen: false,
  isAuthChecked: false,
  isAuthCheckedOnce: false,
  firstLoginEmail: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_FIRST_LOGIN_EMAIL:
      return {
        ...state,
        firstLoginEmail: action.payload,
      };
    case USER_ACTIONS.FETCH_USER.SUCCESS:
    case USER_ACTIONS.FETCH_USER.FAILURE:
      return {
        ...state,
        isAuthCheckedOnce: true,
        isAuthChecked: true,
      };
    default:
      return state;
  }
};
