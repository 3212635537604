import { format } from "date-fns";
import { get } from "lodash";

const createData = (uid, ...fields) => {
  const tableFields = fields.reduce((acc, value, key) => {
    acc[`field${key}`] = value;
    return acc;
  }, {});
  return { id: uid, fields: tableFields };
};

export const prepareAuditTableData = (data) =>
  data.map(({ uid, username, auditType, date }) =>
    createData(uid, username, auditType, getFormattedDate(date, "dd-LL-yyyy"))
  );

export const getFormattedDate = (timestamp, dateFormat = "iii do MMM yyyy") =>
  timestamp && format(new Date(timestamp), dateFormat);

export const getUserAuditData = (userInfo, user) => {
  // todo move to server side
  const dateCreated = get(user, "created") || get(userInfo, "creationTime", "");
  const lastSignInTime =
    get(userInfo, "lastSignInTime", "") || get(user, "lastLogon", "");
  const formatedDate = dateCreated && getFormattedDate(dateCreated);
  const audit = get(userInfo, "audit", []);
  const lastUpdated =
    get(user, "lastUpdated") || get(audit[0], "date", "") || dateCreated;
  const auditValues = prepareAuditTableData(audit);
  const lastMaintained = lastUpdated && getFormattedDate(lastUpdated);
  const lastLogon = lastSignInTime && getFormattedDate(lastSignInTime);
  return {
    changeMessage: get(userInfo, "changeMessage", ""),
    dateCreated: formatedDate,
    lastMaintained,
    lastLogon,
    auditValues,
  };
};
