import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import { NO_THIRD_PARTY_COLLECTIONS } from "../../constants/strings";
import { NO_THIRD_PARTY_COLLECTIONS_FIELD } from "../../features/userDetailsForm/advancedCustomerConfiguration/advancedCustomerConfiguration.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

const Collections = ({ onChangeToggle }) => (
  <Grid container justify="space-between">
    <Field
      name={NO_THIRD_PARTY_COLLECTIONS_FIELD}
      component={CustomSwitchWithTitle}
      type="checkbox"
      label={NO_THIRD_PARTY_COLLECTIONS}
      onChange={(event, value) => {
        onChangeToggle(NO_THIRD_PARTY_COLLECTIONS_FIELD, value);
      }}
    />
  </Grid>
);

Collections.propTypes = {
  onChangeToggle: PropTypes.func,
};

Collections.defaultProps = {
  onChangeToggle: () => {},
};

export default Collections;
