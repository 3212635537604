import format from "date-fns/format";
import isValid from "date-fns/isValid";

export const formatMessage = (template, ...args) =>
  template.replace(/{(\d+)}/g, (s, num) => args[num]);

export const formatDateLabel = (date, dateLabel = "", emptyDateLabel = "") => {
  const newDate = new Date(date);
  return date && isValid(newDate)
    ? `${dateLabel} ${format(newDate, "d MMMM yyyy")}`
    : emptyDateLabel;
};
