import { PLEASE_ENTER_VALID_EMAIL } from "../../../../features/auth/auth.constants";
import { isEmailValid } from "../../../../utils/commonValidation";

export const validate = ({ email }) => {
  const errors = {};

  if (!email || !isEmailValid(email)) {
    errors.email = PLEASE_ENTER_VALID_EMAIL;
  }
  return errors;
};
