import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { getSequences } from "./sequences.selectors.js";
import { addSequence, getSequenceByName } from "./sequences.service";
import { createLoadingAction } from "../loader/loader.actions";

const ACTION_NAMESPACE = "SEQUENCE";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  CREATE_SEQUENCE: createAsyncActionTypes("CREATE_SEQUENCE"),
  GET_SEQUENCES: createAsyncActionTypes("GET_SEQUENCES"),
});

export const createSequences = createAsyncAction(
  () => (dispatch, getState) => {
    const state = getState();
    const sequences = getSequences(state);
    return Promise.allSettled(sequences.map(addSequence));
  },
  ACTIONS.CREATE_SEQUENCE
);

export const getSequencesBySeqNames = createLoadingAction(
  createAsyncAction(
    (sequences) => getSequenceByName(sequences.map((seq) => seq.seqName)),
    ACTIONS.GET_SEQUENCES
  )
);
