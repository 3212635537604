import { get } from "lodash";
import queryString from "query-string";

export const INITIAL_PAGE_QUERY = { page: 1, pageSize: 25 };

export const getLocationState = (location) => {
  const locationState = get(location, "state", {}) || {};

  if (locationState.expiresIn && locationState.expiresIn > Date.now()) {
    return locationState;
  }

  return {};
};

export const stringifyQuery = (query, encode = true) =>
  queryString.stringify(query, {
    encode,
  });

export const parseQueryWithPagination = (
  location,
  { initPageQuery = INITIAL_PAGE_QUERY, parseNumbers } = {}
) => {
  const query = queryString.parse(location.search, { parseNumbers });

  if (query.page) {
    query.page = parseInt(query.page, 10);
  }

  if (query.pageSize) {
    query.pageSize = parseInt(query.pageSize, 10);
  }

  return {
    ...initPageQuery,
    ...query,
  };
};
