import PropTypes from "prop-types";
import { Field } from "redux-form";

import CustomSwitchWithTitle from "../../../../components/SwitchWidthTitle/CustomSwitchWithTitle.js";
import {
  USE_JAVA_APPLET,
  USE_JAVA_APPLET_INFO,
} from "../../../../constants/strings.js";
import { USE_JAVA_APPLET_FIELD } from "../../../../features/userDetailsForm/miscellaneousConfiguration/miscellaneousConfiguration.constants";

const TerminalPrinterInterface = ({ onInputChange, onInfoMouseOver }) => (
  <Field
    name={USE_JAVA_APPLET_FIELD}
    component={CustomSwitchWithTitle}
    type="checkbox"
    onInfoMouseOver={onInfoMouseOver}
    label={USE_JAVA_APPLET}
    infoLabel={USE_JAVA_APPLET_INFO}
    onChange={(event, value) => {
      onInputChange(USE_JAVA_APPLET_FIELD, value);
    }}
  />
);

TerminalPrinterInterface.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onInfoMouseOver: PropTypes.func.isRequired,
};

export default TerminalPrinterInterface;
