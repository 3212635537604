// all done
export const PROFILE_MANAGEMENT = {
  INTERFACE_ID: "RS4mcraFYFxqgGma",
  CLICK_CREATE_NEW_PROFILE: "dYFA8raFYFxqgA76",
  CLICK_DELETE_PROFILE: "1jCLgbaFYFxqomCK",
  CLICK_PROFILE_CODE: "ob3L1baFYFxqeOQj",
  CLICK_SEARCH_BUTTON: "9o7ecraFYFxqgH4Z",
  LOAD: "0fbecraFYFxqgH2x",
};

// all done
export const DELETE_PROFILE_MODAL = {
  INTERFACE_ID: "OaXQ1baFYFxqeLoL",
  LOAD: "LOMO1baFYFxqeLvL",
  NO: "_eMO1baFYFxqeLvm",
  YES: "zhMO1baFYFxqeLwM",
};

// https://it.dpduk.live/it/diagram/diag_jgG9ZbaFYFxqeBb6.html?id=1591264052192
// https://it.dpduk.live/it/diagram/diag_I3qQ6baFYFxqeEFw.html?id=1591264043815
// https://it.dpduk.live/it/diagram/diag_I3qQ6baFYFxqeEFw.html?id=1591264075286
export const PROFILE_ANALYTICS = {
  CREATE_INTERFACE_ID: "3ZwA8raFYFxqgAOW",
  EDIT_INTERFACE_ID: "DHqQ6baFYFxqeEFQ",
  CLICK_SAVE_CREATE_PROFILE: "jGmI8raFYFxqgBov",
  CLICK_SAVE_UPDATE_PROFILE: "dR_yZbaFYFxqeB4f",
  CLICK_CANCEL_CREATE_PROFILE: "5Dr6uHaFYFxqaIYW",
  CLICK_CANCEL_UPDATE_PROFILE: "LcYWuHaFYFxqaJ95",
  CLICK_DUPLICATE_PROFILE: "g7dfZbaFYFxqeNaq",
  CLICK_DELETE_PROFILE: "5XVvZbaFYFxqeMc2",
  LOAD_DUPLICATE_MODAL: "R1BB1baFYFxqeMoo",
  DUPLICATE_PROFILE_MODAL: "gVDe1baFYFxqeMi1",
  CANCEL_DUPLICATE_MODAL: "ZtBB1baFYFxqeMpE",
  CONFIRM_DUPLICATE_MODAL: "sDBB1baFYFxqeMpl",
  CREATE_LOAD: "EduvcraFYFxqgOoD",
  UPDATE_LOAD: "kfjw6baFYFxqeEkK",
  CLICK_ACCOUNT_TAB: "SNzw6baFYFxqeE86",
  CLICK_ADDRESS_TAB: "2MLw6baFYFxqeFDa",
  CLICK_CHECKBOX: "vILw6baFYFxqeFB3",
  CLICK_DPD_LOCAL_TAB: "6.zw6baFYFxqeE6Q",
  CLICK_DPD_TAB: "Nmzw6baFYFxqeE5O",
  CLICK_DEFAULTS_DPD_LOCAL_INTERNATIONAL_TAB: "PALw6baFYFxqeFBG",
  CLICK_DEFAULTS_DPD_LOCAL_TAB: "9nzw6baFYFxqeE_o",
  CLICK_DEFAULTS_DPD_TAB: "mbzw6baFYFxqeE.5",
  CLICK_DEFAULTS_INTERNATIONAL_TAB: "wvzw6baFYFxqeFAV",
  CLICK_DEFAULTS_TAB: "cjzw6baFYFxqeE99",
  CLICK_DROPDOWN_LIST: ".4Lw6baFYFxqeFCY",
  CLICK_INT_INVOICE_TAB: "AULw6baFYFxqeFC5",
  CLICK_INTERNATIONAL_TAB: "5Ozw6baFYFxqeE5v",
  CLICK_PREFERENCES_TAB: "Hhzw6baFYFxqeE6x",
  CLICK_TOGGLE: "Nlzw6baFYFxqeE8W",
  TEXT_ENTRY: "I2Lw6baFYFxqeFGF",
};

export const CONFIRM_SLID_MODAL = {
  INTERFACE_ID: "2RFwFraFYFxqomBg", // done
  CANCEL: "d6fLTraFYFxqokSd", // done
  CANCEL_EDIT_PROFILE: "Fc.Z1baFYFxqeODN", // TODO: check it
  LOAD: "e2AYFraFYFxqonLl", // done
  SAVE: "5aNbTraFYFxqokX9", // done
};

// all done
export const ACTIVITY_MONITOR_ANALYTICS = {
  INTERFACE_ID: "IGanPg6FYFxo.RZp",
  CLICK_CALENDAR: "yMQBgQ6FYFxo.XN.",
  CLICK_DATE: "eXYRgQ6FYFxo.XTB",
  CLICK_ENTER__SEARCH: "4HtXAQ6FYFxo.T3G",
  CLICK_HIDE_DETAILS: "6z.pdw6C48kIGKtl",
  CLICK_NEXT_PAGE: "1i2xYQ6FYFxoManH",
  CLICK_PREVIOUS_PAGE: "Iv864Q6FYFxoMVSA",
  CLICK_SEE_DETAILS: "QIIBdw6C48kIGKNY",
  LOAD: ".wO_Pg6FYFxo.SEY",
};

// https://it.dpduk.live/it/diagram/diag_KYXjTdaFYFxsDZXr.html?id=1591344133447
export const CREATE_MY_DPD_USER_ANALYTICS = {
  INTERFACE_ID: "Z_YTTdaFYFxsDZY0",
  LOAD: "nj7rTdaFYFxsDaLJ",
  DELETE_ACCOUNT: "o_Hfp9aFYFxqewSp", // ???
  CANCEL: "HCPrTdaFYFxsDaLP",
  CONFIRM: "DCCbTdaFYFxsDaL7",
  RESET_PASSWORD: "2EjPp9aFYFxqe.an", // done
  SUSPEND_ACCOUNT: "On2vp9aFYFxqe.cz", // ???
  UNSUSPEND_ACCOUNT: "moLlrjaFYFxqeXHc", // ???
};

// https://it.dpduk.live/it/diagram/diag_YuCS87aC48kIGHAJ.html?id=1591340881293
export const CHANGE_MESSAGE_MODAL = {
  INTERFACE_ID: "9Jni87aC48kIGG__",
  LOAD: "_R2O87aC48kIGHVf",
  CLOSE: "Up2O87aC48kIGHWZ",
};

// https://it.dpduk.live/it/diagram/diag_Vi_iMQ6FYFxoMcSZ.html?id=1591184049472
export const NAVIGATE_AWAY_MODAL = {
  INTERFACE_ID: "c0_iMQ6FYFxoMcRS",
  LOAD: "OVMJgbaFYFxqoj3q",
  YES: "q9MJgbaFYFxqoj41",
  NO_EDIT_PROFILE: "oQ.51baFYFxqeOPS",
  NO: "TNMJgbaFYFxqoj4P",
};

// https://it.dpduk.live/it/diagram/diag_K7Gsm7aFYFxqP012.html?id=1591341078260
// https://geopost.jira.com/wiki/spaces/CP/pages/1594490913/Action+IDs Q11
export const USER_UPDATE_CONFIRMED = {
  INTERFACE_ID: "ubGsm7aFYFxqP01S",
  LOAD: "_R2O87aC48kIGHVf",
  CLOSE: "Up2O87aC48kIGHWZ",
};

// https://it.dpduk.live/it/diagram/diag_gF5qMQ6FYFxoMcmi.html?id=1592988037060
export const NAVIGATE_AWAY_TEAM_SETTINGS = {
  INTERFACE_ID: "Nh5qMQ6FYFxoMclR",
};

// https://it.dpduk.live/it/diagram/diag_0h1YC7aFYFxqPylA.html?id=1591193881444
export const UPDATE_CUSTOMER_USER_CONFIRMATION_MODAL = {
  INTERFACE_ID: "JB1YC7aFYFxqPykt",
  LOAD: "efYUC7aFYFxqPyp4",
  YES: "HlIe87aC48kIGIsM",
  NO: "rg4UC7aFYFxqPyq3",
};

// https://it.dpduk.live/it/diagram/diag_caoTfLaFYFxqOK0u.html?id=1591788259765
export const CREATE_CUSTOMER_USER_CONFIRMATION_MODAL = {
  INTERFACE_ID: "qKoTfLaFYFxqOK0F",
  LOAD: "K9FLfLaFYFxqOK4X",
  NO: "kjFLfLaFYFxqOK4.",
  YES: "6zFLfLaFYFxqOK5c",
};

// https://it.dpduk.live/it/diagram/diag_wijM8PaFYFxqeYUO.html?id=1591114021437
export const UPDATE_USER_CONFIRMATION_MODAL = {
  CANCEL: "yhQs8PaFYFxqeYhL",
  LOAD: "oeQs8PaFYFxqeYfh",
  INTERFACE_ID: "Fs8C8PaFYFxqeaBj",
  YES: "6oos8PaFYFxqeZCJ",
};

// all done
export const NO_PROFILE_WARNING_MODAL = {
  INTERFACE_ID: "NDSPLbaFYFxquUZl",
  LOAD: "LcDfLbaFYFxquU.t",
  CLOSE: "3CDfLbaFYFxquU_d",
};

// all done
export const LOGOUT_MODAL = {
  INTERFACE_ID: "Bv6gi9aFYFxqe2b6",
  LOAD: "W3nIi9aFYFxqe3V_",
  CANCEL: "EJw4i9aFYFxqe5zW",
  CONFIRM: "72KYi9aFYFxqe5dd",
};

// all done
export const ADMIN_FIRST_LOGIN_MODAL = {
  INTERFACE_ID: "5tkAJfaFYFxqio7i",
  LOAD: "0UVoJfaFYFxqiqCK",
  GOT_IT: "kX9YJfaFYFxqiqF8",
};

// all done
export const TEAM_SETTINGS_ANALYTICS = {
  INTERFACE_ID: "KXkyavaFYFxqNnbW",
  ON_LOAD: "btAm6vaFYFxqNrUN",
  ADD_IP: "XaE62vaFYFxqNgjR",
  CLICK_AUTO_SUSPEND_ACCOUNT_DROPDOWN: "qS5tpvaFYFxqJUCi",
  CLICK_AUTO_SUSPEND_ACCOUNT_TOGGLE: "teEVpvaFYFxqJThj",
  CLICK_DELETE_IP: "H1o22vaFYFxqNhJR",
  CLICK_SAVE: "exqp2vaFYFxqNorr",
  ENTER_TEXT_IP_ADDRESS: "hVpjofaFYFxqikAz",
  ENTER_TEXT_NAME: "x6cDofaFYFxqijb8",
  CLICK_ADD_IP_RANGE: "t0Tmb_aFYFxo3EvA",
  ENTER_TEXT_END_IP: "Ti9sb_aFYFxo3LLC",
  ENTER_TEXT_RANGE_NAME: "7Cscb_aFYFxo3LMW",
  ENTER_TEXT_START_IP: "DYGsb_aFYFxo3LJ1",
};

export const DELETE_IP_CONFIRMATION_MODAL = {
  LOAD: "U4Ih2vaFYFxqNhyH",
  NO: "57th2vaFYFxqNh_D",
  INTERFACE_ID: "BwHO2vaFYFxqNhhv",
  YES: "TTsR2vaFYFxqNiJ6",
};

// all done https://it.dpduk.live/it/diagram/diag_Bi6VZ9aFYFxqe7.i.html?id=1591190645889
export const FIRST_LOGIN_ANALYTICS = {
  INTERFACE_ID: "o86VZ9aFYFxqe79l",
  LOAD: "0qh1Z9aFYFxqe8OZ",
  LOGIN: "3Gh1Z9aFYFxqe8O9",
};

// done for diagram https://it.dpduk.live/it/diagram/diag_jwY4h9aFYFxqe2cD.html?id=1591027619884
export const FORGOT_PASSWORD_ANALYTICS = {
  CANCEL: "yTJOh9aFYFxqe3gN",
  CONTINUE: "X28xh9aFYFxqe4js",
  INTERFACE_ID: "YwY4h9aFYFxqe2bg",
  LOAD: "T.GOh9aFYFxqe3c.",
};

// https://it.dpduk.live/it/diagram/diag_MJqXZ9aFYFxqewQu.html?id=1591028309192
export const SET_FIRST_LOGIN_PASSWORD_ANALYTICS = {
  CANCEL_: "NpB3Z9aFYFxqewh.",
  CONTINUE: "Q9b3Z9aFYFxqexGr",
  LOAD: "hJB3Z9aFYFxqewht",
  INTERFACE_ID: "FhqXZ9aFYFxqewQV",
};

// Done for diagram https://it.dpduk.live/it/diagram/diag_YNR_NdaFYFxqF9zI.html?id=1591027269196
export const LOGIN_ANALYTICS = {
  INTERFACE_ID: "_TR_NdaFYFxqF9.R",
  FORGOTTEN_PASSWORD: "0k9NB9aFYFxqexVv",
  LOAD: "7DR_NdaFYFxqF98f",
  LOGIN: "XDR_NdaFYFxqF98i",
};

// https://it.dpduk.live/it/diagram/diag_JToyp9aFYFxqe0oj.html?id=1591186267902
export const SET_NEW_PASSWORD = {
  CONTINUE: "NJGKp9aFYFxqe0uS",
  CANCEL: "zRGKp9aFYFxqe0tx",
  LOAD: "IBGKp9aFYFxqe0s2",
  INTERFACE_ID: "Ljoyp9aFYFxqe0oZ",
};

// all done
export const ACCOUNT_VALIDATION_MODAL = {
  INTERFACE_ID: "hWOj9I6C48kIGOlh",
  ON_LOAD: "Z.jb9I6C48kIGCVY",
  CLICK_SAVE: "GVVojI6C48kIGCHY",
  CLICK_CANCEL: "EABIjI6C48kIGBq0",
};
// https://it.dpduk.live/version/customer-profile/sprint-1.23/diag_7FhvMUGGAqCIaRXf.html?id=1666597178830
export const DPD_APP_CONFIG_ANALYTICS = {
  LOAD: "DPD APP CONFIG LOAD",
  INTERFACE_ID: "INTERFACE ID",
  ON_CLICK_SEARCH: "n2b0sUGGAqCIaUVW",
  ON_CLICK_SEARCH_DROPDOWN: "rSFosUGGAqCIaT.8",
  ON_CLICK_SEARCH_DROPDOWN_ITEM: "qWJYsUGGAqCIaUPT",
  ON_CLICK_SYNC: "XXoh1UGGAqCIab5N",
  ON_CLICK_VERSION_DROPDOWN: "8lY.1UGGAqCIabOE",
  ON_CLICK_VERSION_DROPDOWN_ITEM: "qiK.1UGGAqCIabpC",
};

// https://it.dpduk.live/version/customer-profile/sprint-1.23/diag_7FhvMUGGAqCIaRXf.html?id=1666597178830
export const CUSTOMER_MIGRATIONS_ANALYTICS = {
  LOAD: "yp8osUGGAqCIaT8u",
  INTERFACE_ID: "HwhvMUGGAqCIaQvo",
  ON_CLICK_SEARCH: "n2b0sUGGAqCIaUVW",
  ON_CLICK_SEARCH_DROPDOWN: "rSFosUGGAqCIaT.8",
  ON_CLICK_SEARCH_DROPDOWN_ITEM: "qWJYsUGGAqCIaUPT",
  ON_CLICK_SYNC: "XXoh1UGGAqCIab5N",
  ON_CLICK_VERSION_DROPDOWN: "8lY.1UGGAqCIabOE",
  ON_CLICK_VERSION_DROPDOWN_ITEM: "qiK.1UGGAqCIabpC",
};

// https://it.dpduk.live/it/diagram/diag_YNR_NdaFYFxqF9zM.html?id=1591274069252
export const HOME_PAGE_ANALYTICS = {
  CLICK_CHECKBOX__SELECT_ALL: "lM6uwXaFYFxqWdo.", // done
  CLICK_CHECKBOX__SELECT_USER: "LTCZwXaFYFxqWQV4",
  CLICK_DELETE_SELECTED_USERS: "CgepwXaFYFxqWQUi", // done
  CLICK_DROPDOWN_LIST: "4zPTnLaFYFxqvRtO",
  CLICK_ENTER: "bKBbC3aFYFxqn5zQ",
  CLICK_FILTER__ALL: "hWvu_3aFYFxqMSNj", // done
  CLICK_FILTER__INACTIVE: "X8XPM_aFYFxm.uP_", // done
  CLICK_FILTER__INVITED_USERS: "2YVTD_aFYFxo3GjL", // done
  CLICK_FILTER__SUSPENDED: "kHtG_3aFYFxqMRjy", // done
  CLICK_LOGON_ID: "xjIzK7aFYFxqP0V7",
  CLICK_REMOVE_ACCOUNT_NUMBER: "Ne5jsraFYFxqgI_0",
  CLICK_REMOVE_LOGON_ID: "qqvNsraFYFxqgH9o",
  CLICK_RESEND_USER_INVITE: "c2Aoj_aFYFxo3Afb", // done
  CLICK_SEARCH: "o7xTnLaFYFxqvRnV", // done
  CLICK_SUSPEND_SELECTED_USERS: "bU8PwXaFYFxqWcU9", // done
  CLICK_UNSUSPEND_SELECTED_USERS: "dHzPwXaFYFxqWcih", // done
  CLICK_VIEW_INVITED_USERS: "Gb5Wd46C48kIGCkM", // done
  CREATE_CUSTOMER_USER: "rYahdTaFYFxqk8HZ",
  DELETE_USER: "o8hMoTaFYFxqebOm", // done
  DPD_CREATE_USER: "zBcelTaFYFxqkwh4",
  EDIT_USER: "8ZyVg9aFYFxq6615", // done
  INTERFACE_ID: "wzR_NdaFYFxqF9.V", // done
  HOVER: "WoLkoTaFYFxqea5c",
  INTERNAL_CLICK_CHECKBOX: "EdMed46C48kIGCuK", // done
  INTERNAL_DELETE_USER: "h5zCiI6GAqACwzA7",
  INTERNAL_HOVER: "gmt8iI6GAqACwy.T",
  INTERNAL_SUSPEND_USER: "OrHiiI6GAqACwzCj",
  INTERNAL_UNSUSPEND_USER: "5OpSiI6GAqACwzEQ",
  LOAD: "vDR_NdaFYFxqF98l", // done
  // LOGOUT: "amnPC9aFYFxqe1Gn", // don't use it (save it just for correct coverage)
  NEW_USER: "ktgdp9aFYFxqe4GD", // done
  SEARCH_TEXT_ENTRY: "S2sPf3aFYFxqMYBh", // done
  SUSPEND_USER: "powY39aFYFxqe09N", // done
  UNSUSPEND_USER: "aXy5rjaFYFxqeVn2", // done
};
// all done
export const UNSUSPEND_USERS_CONFIRM_ANALYTICS = {
  LOAD: "16v6IXaFYFxqWZmH",
  NO: "Rmv6IXaFYFxqWZmz",
  INTERFACE_ID: "CoDEIXaFYFxqWWzJ",
  YES: "l2v6IXaFYFxqWZnl",
};
// all done
export const UNSUSPEND_USER_CONFIRM_ANALYTICS = {
  LOAD: "5qAprjaFYFxqeUpn",
  NO: "M6AprjaFYFxqeUqE",
  INTERFACE_ID: "AbRxrjaFYFxqeUWG",
  YES: "dGAprjaFYFxqeUql",
};
// all done
export const SUSPEND_USERS_CONFIRM_ANALYTICS = {
  LOAD: "B6qoIXaFYFxqWWH1",
  NO: "UmqoIXaFYFxqWWIJ",
  INTERFACE_ID: "yBeIIXaFYFxqWWEE",
  YES: "bWqoIXaFYFxqWWIc",
};
// all done
export const SUSPEND_USER_CONFIRM_ANALYTICS = {
  LOAD: "s0e039aFYFxqe1UD",
  NO: "vMe039aFYFxqe1Ud",
  INTERFACE_ID: "8rpU39aFYFxqe1Q.",
  YES: "9ce039aFYFxqe1U3",
};
// all done
export const DELETE_USER_CONFIRM_ANALYTICS = {
  LOAD: "Bsts39aFYFxqe1kx",
  NO: "3cts39aFYFxqe1lN",
  INTERFACE_ID: "dmqs39aFYFxqe1i1",
  YES: "qCts39aFYFxqe1lp",
};
// all done
export const DELETE_USERS_CONFIRM_ANALYTICS = {
  LOAD: "EEVAIXaFYFxqWTb1",
  NO: "fkVAIXaFYFxqWTcU",
  INTERFACE_ID: "ve5_wXaFYFxqWTS5",
  YES: "z0VAIXaFYFxqWTcz",
};
// all done
export const CREATE_DPD_USER_ANALYTICS = {
  INTERFACE_ID: "rIvBlTaFYFxqkxHR",
  CANCEL: "TPNJlTaFYFxqkzwR",
  CONFIRM: "5KkplTaFYFxqkzyE",
  LOAD: "wD0JlTaFYFxqkzuH",
  TOGGLE_CREATE_DPD_USERS: "mK4Vd46C48kIGMDA",
  TOGGLE_CREATE_STAFF_OVERRIDE_USERS: "6ok1d46C48kIGMS0",
};

// https://it.dpduk.live/it/diagram/diag_H0Cc8raFYFxqgLSj.html?id=1591285975859
export const MAIN_MENU_FIRST_PHASE = {
  CLICK_CREATE_DPD_USER: "updS8raFYFxqgBV_",
  CLICK_CREATE_USER: "y6ky8raFYFxqgBio",
  CLICK_LOGOUT: "IOGK8raFYFxqgCaa",
  CLICK_PROFILE_MANAGEMENT: "2XRy8raFYFxqgBjw",
  CLICK_SEARCH: "Ieni8raFYFxqgA_4",
};

// https://it.dpduk.live/it/diagram/diag_L6Irf3aFYFxqMVtt.html?id=1591263605694
export const MAIN_MENU_SECOND_PHASE = {
  CLICK_ACCOUNT_NUMBER: "G.ii0faFYFxqimLs",
  CLICK_ACTIVITY_MONITOR: "PakXPg6FYFxo.Ri5",
  CLICK_CLICK_LOGOUT: "lNCZ_3aFYFxqMTjw",
  CLICK_MANAGE_USERS: "LRlrf3aFYFxqMV3y",
  CLICK_TEAM_SETTINGS: "vjOKavaFYFxqNpKp",
  // https://it.dpduk.live/it/diagram/diag_VDl9W3aFYFxqn8MV.html?id=1591788259776
  CLICK_ACCOUNT_NUMBER_DROPDOWN_LIST: "R.ii0faFYFxqimLy",
};

// https://it.dpduk.live/it/diagram/diag_CdYyMQ6FYFxoMcYZ.html?id=1592987514222
export const NAVIGATE_AWAY_CREATE_CUSTOMER_USER = {
  INTERFACE_ID: "wFYyMQ6FYFxoMcXX",
};

// https://it.dpduk.live/it/diagram/diag_pjL8MQ6FYFxoMcDu.html?id=1592987581674
export const NAVIGATE_AWAY_EDIT_CUSTOMER_USER = {
  INTERFACE_ID: "XFL8MQ6FYFxoMcCl",
};

// https://it.dpduk.live/it/diagram/diag_CmtMMQ6FYFxoMbhc.html?id=1592987849643
export const NAVIGATE_AWAY_CREATE_USER = {
  INTERFACE_ID: "dStMMQ6FYFxoMbfA",
};

// https://it.dpduk.live/it/diagram/diag__REKMQ6FYFxoMcdw.html?id=1592987880206
export const NAVIGATE_AWAY_EDIT_USER = {
  INTERFACE_ID: "FuEKMQ6FYFxoMccx",
};

// https://it.dpduk.live/it/diagram/diag_jlgAZ9aFYFxqewgI.html?id=1591114021435
export const RESET_PASSWORD_CONFIRMATION = {
  LOAD: "Uj1oZ9aFYFxqe5LV",
  NO: "qT1oZ9aFYFxqe5L7",
  INTERFACE_ID: "n5gAZ9aFYFxqewf2",
  YES: "Jr1oZ9aFYFxqe5My",
};

// https://it.dpduk.live/it/diagram/diag_KAHHd46C48kIGOBU.html?id=1591259761336
export const CREATE_STAFF_OVERRIDE_USER_FOR_MYDPD = {
  CANCEL: "QAHXd46C48kIGIaW",
  CONFIRM: "1kHXd46C48kIGIdR",
  INTERFACE_ID: "pf7Hd46C48kIGOAf",
  LOAD: "d37Xd46C48kIGIWv",
};

// https://it.dpduk.live/it/diagram/diag_gOCpa9aFYFxqezcn.html?id=1591091225576
export const NEW_USER_ANALYTICS = {
  CANCEL: "pyBZa9aFYFxqezey",
  CONFIRM: "ZA1Za9aFYFxqez27",
  ENTER_TEXT_USERNAME: "r4ITJfaFYFxqiuZR",
  LOAD: "8YLZa9aFYFxqez3B",
  INTERFACE_ID: "PQHCO9aFYFxqe9dC",
};

// https://it.dpduk.live/it/diagram/diag_xB3o8PaFYFxqeaUI.html?id=1590505697934
export const CREATE_EDIT_USER_ANALYTICS = {
  ADD_ACCOUNT_EXPIRY: "ozgsbjaFYFxqecGj", // done
  ADMIN_ACCOUNT_NUMBER_ENTRY: "AhlPdfaFYFxqig0N", // done
  ADDITIONAL_ACCOUNTS_NUMBER_ENTRY: "51SuMI6GAqACw9U0", // done
  CLICK_ADDITIONAL_ACCOUNTS_NUMBER_ADD: "E93eMI6GAqACw9cQ", // done
  CLICK_ADDITIONAL_ACCOUNTS_NUMBER_REMOVE: "jKj.MI6GAqACw9gO", // done
  CLICK_ADDITIONAL_ACCOUNTS_TAB: "df92MI6GAqACw9Ko", // done
  CLICK_ADMIN_ACCESS_TOGGLE: "Z0OXdfaFYFxqigKG", // done
  CLICK_IP_ADDRESS_DROPDOWN_LIST: "fx_F2vaFYFxqNp66", // done
  CLICK_IP_RESTRICTIONS_TAB: "1uAHcfaFYFxqipIO", // done
  CLICK_REMOVE_IP_ADDRESS: "z2Wt2vaFYFxqNqgC", // done
  CLICK_TOGGLE: "whKg23aFYFxqnxYA", // done
  EDIT_ACCOUNT_EXPIRY: "aJrsbjaFYFxqecKd", // done
  ENTER_TEXT_PASSWORD: "yiRLJfaFYFxqihXn", // done
  REMOVE_ACCOUNT_EXPIRY: "uaccbjaFYFxqecLY", // done
  SELECT_IP_ADDRESS_IN_DROPDOWN_LIST: "rPfl2vaFYFxqNqIq", // done
};

// https://it.dpduk.live/it/diagram/diag_yZL587aC48kIGL1E.html?id=1591114021428
export const CREATE_EDIT_CUSTOMER_USER_ANALYTICS = {
  ADDITIONAL_ACCOUNTS: "v21ZGzaFYFxq7pMM", // done
  ADDITIONAL_ACCOUNTS_ADD: "VVP1GzaFYFxq7qaD", // done
  ADDITIONAL_ACCOUNTS_REMOVE: "wNsdGzaFYFxq7rF3", // done
  CLICK_ACCOUNT_DETAILS: "GaS6XvaFYFxqvAXS", // done
  CLICK_ADDITIONAL_ACCOUNTS: "Rzk.uzaFYFxqbiY_", // done
  CLICK_AUTO_EOD_FRIDAY_CHECKBOX: "LKKTIfaFYFxqvIiV", // done
  CLICK_AUTO_EOD_MONDAY_CHECKBOX: "dv7dIfaFYFxqvINR", // done
  CLICK_AUTO_EOD_SATURDAY_CHECKBOX: "mMrTIfaFYFxqvIkE", // done
  CLICK_AUTO_EOD_SUNDAY_CHECKBOX: "pb1zIfaFYFxqvIl3", // done
  CLICK_AUTO_EOD_THURSDAY_CHECKBOX: "3RxjIfaFYFxqvIgT", // done
  CLICK_AUTO_EOD_TIME_DROPDOWN_LIST: "CDTlIfaFYFxqvHnG", // done
  CLICK_AUTO_EOD_TUESDAY_CHECKBOX: "ab79IfaFYFxqvIbg", // done
  CLICK_AUTO_EOD_WEDNESDAY_CHECKBOX: "DhPDIfaFYFxqvIeY", // done
  CLICK_BULK_REVERSEIT: "_TXxnLaFYFxqvZdp", // done
  CLICK_BUSINESS_ID_DROPDOWN_LIST: "qD9GXvaFYFxqvAt9", // done
  CLICK_CHANGE_DELIVERY: "hMOg4LaFYFxqvw.5", // done
  CLICK_CHANGE_MESSAGE: "1x6VnLaFYFxqvaCE",
  CLICK_END_OF_DAY: "CyhRnLaFYFxqvZFw", // done
  CLICK_FORWARD_DATING: "r65p3zaFYFxqv301",
  CLICK_THERMAL_PRINTER_INTERFACE: "2aRw4LaFYFxqvxHk", // done
  CLICK_TITLE_DROPDOWN: "QYXJXvaFYFxqvBXX", // done
  CLICK_TOGGLE: "3LWM4LaFYFxqv6eh",
  CLICK_TOGGLE_API_USERS: "BwAaVC6C48kIGFOr",
  CLICK_GENERATE_TOKEN: "dsHGVC6C48kIGFUe",
  CUSTOMER_ADMIN_ACCOUNTS: "RniNSXaFYFxqhF4J", // done
  CUSTOMER_ADMIN_ACCOUNTS_REMOVE: "zQldSXaFYFxqhGPy", // done
  CUSTOMER_ADMIN_ACCOUNTS_ADD: "v0StSXaFYFxqhGEb", // done
  ENTER_TEXT_CHANGE_MESSAGE: "aGi43vaFYFxqvDP_", // done
  ENTER_TEXT_CITY: "wu8.XvaFYFxqvBBU", // done
  ENTER_TEXT_COUNTRY: "jv6BXvaFYFxqvBE1", // done
  ENTER_TEXT_COUNTY: "wCr.XvaFYFxqvBDR", // done
  ENTER_TEXT_DESCRIPTION: "0JMNXvaFYFxqvEWP", // done
  ENTER_TEXT_NOTIFICATION_EMAIL: "LJyRXvaFYFxqvBGx", // done
  ENTER_TEXT_NOTIFICATION_PHONE: "6k7RXvaFYFxqvBHp", // done
  ENTER_TEXT_POSITION: "AUkZXvaFYFxqvBlA", // done
  ENTER_TEXT_STREET: "bTFmXvaFYFxqvAxE", // done
  ENTER_TEXT_SUBURB: "HlheXvaFYFxqvA_o", // done
  ENTER_TEXT_TELEPHONE: "Hfq1XvaFYFxqvEUX", // done
  ENTER_TEXT_PASSWORD: "VVNtXvaFYFxqvEaJ", // done
  EXCLUDED_SERVICES_ADD_ALL_DPD_LOCAL_SERVICES: "gPs.qLaFYFxqPXv1", // done
  EXCLUDED_SERVICES_ADD_DPD_LOCAL_SERVICE: "KsCeqLaFYFxqPXtR", // done
  ONOVERRIDECHECKBOX: "_G1nHTaFYFxqk0F_", // done
  POSTCODE_LOOKUP: "xEQAQLaFYFxqvzR_", // done
  POSTCODE_LOOKUP_ADDRESS_CLICKED: "TSkQQLaFYFxqvzjQ", // done
  ENTER_TEXT_USERNAME: "XNwtXvaFYFxqvEYI", // done
};

// https://it.dpduk.live/it/diagram/diag_xIg9rbaFYFxquaBU.html?id=1591366566812
export const EDIT_CUSTOMER_USER = {
  CLICK_CREATE_CREATE_STAFF_OVERRIDE_USER_FOR_MYDPD: "x_bbd46C48kIGNVO",
  CONFIRM: "Wndjy7aFYFxqP69c",
  INTERFACE_ID: "BDMjrbaFYFxqubOs",
  LOAD: "q_JPrbaFYFxquTZ6",
  LOAD_CHANGE_MESSAGE_MODAL: "hX5SK7aFYFxqP5ji", // ???
};

// https://it.dpduk.live/it/diagram/diag_WxkBdTaFYFxqk7KS.html?id=1591788259765
export const CREATE_CUSTOMER_USER = {
  ACCOUNT_NUMBER_ENTRY: "szwbwraFYFxqOEeS",
  CANCEL: "NMxJ9TaFYFxqk4GK",
  CLICK_AUTO_USERNAME_DROPDOWN_LIST: "Gw1K.o6C48kIGNrA",
  CONFIRM: "bbEp9TaFYFxqk4dD",
  INTERFACE_ID: "yL0BdTaFYFxqk7Kb",
  LOAD: "qJ5h9TaFYFxqk30J",
  SELECT_ACCOUNT_NUMBER: "dCv_wraFYFxqOE6P",
  TOGGLE_SELECT_AUTO_GENERATED_USERNAME_: "7yRU.o6C48kIGNRl",
};

// https://it.dpduk.live/it/diagram/diag_mWsuNXaFYFxqhGBg.html?id=1591344780784
export const ANALYTICS_FOR_USER = {
  CLICK_ADDRESS_BOOK: "T6jB7zaFYFxqv9LN", // done
  CLICK_ADD_MAPPINGS: "N.IhpLaFYFxqQS5D", // done
  CLICK_ADD_SECTOR: "tXNQVLaFYFxqQQT5", // done
  CLICK_ADVANCED_CUSTOMER_CONFIGURATION: "YYiijzaFYFxqvz15", // done
  CLICK_CLEAR_ALL_MAPPINGS: "dhKFpLaFYFxqQctT", // done
  CLICK_CLEAR_ALL_SECTOR: "eE90VLaFYFxqQR4u", // done
  CLICK_COLLECTION: "Qdrx7zaFYFxqv.TR", // done
  CLICK_COLLECTIONS: "ghER3zaFYFxqv3k7", // done
  CLICK_CREATE_SHIPMENT: "7vfR7zaFYFxqv.HU", // done
  CLICK_DEFAULT_SERVICE_DROPDOWN_LIST: "6r2o3vaFYFxqvFhk", // done
  CLICK_DPD_LOCAL_SERVICE_EXCLUSIONS: "dzuKXLaFYFxqvePq",
  CLICK_DPD_SERVICE_EXCLUSIONS: "H8lLALaFYFxqvx6c",
  CLICK_FAILED_IMPORT: "vgzBnLaFYFxqvZDr", // done
  CLICK_GROUP_DISPATCH: "JpcxnLaFYFxqvZR.", // done
  CLICK_HIGHLANDS_CODES: "O2lqVLaFYFxqQXWg", // done
  CLICK_INTERNATIONAL_SHIPMENTS: "qEClnLaFYFxqvZ2A", // done
  CLICK_IRISH_CODES: "sf98VLaFYFxqQWmd", // done
  CLICK_LABEL_SETTINGS: "k2pzVLaFYFxqQYkR", // done
  CLICK_LABEL_SETTINGS_DROPDOWN1: "48CunLaFYFxqvYJj", // done
  CLICK_LABEL_SETTINGS_DROPDOWN2: "zWkenLaFYFxqvYbJ", // done
  CLICK_LABEL_SETTINGS_DROPDOWN3: "JlBenLaFYFxqvYcp", // done
  CLICK_MISCELLANEOUS_CONFIGURATIONS: "IhqpuzaFYFxqbk1w", // done
  CLICK_OFFSHORE_CODES: "l1DyVLaFYFxqQXK0", // done
  CLICK_PADLOCK_TO_LOCK_PASSWORD: "Xv2SeraFYFxqouWL", // done
  CLICK_REFERENCES: "evQBnLaFYFxqvY3h", // done
  CLICK_REMOVE_MAPPINGS: "OmPZpLaFYFxqQcXh", // done
  CLICK_REMOVE_SECTOR: "EJ3UVLaFYFxqQRl9", // done
  CLICK_SECURITY_SETTINGS: "NDQwpLaFYFxsTt45", // done
  CLICK_SERVICE_EXCLUSIONS: "3yV6rbaFYFxquY7n", // done
  CLICK_SERVICE_RESTRICTIONS: "k6q.pLaFYFxsTugn", // done
  CLICK_SERVICE_XREF_MAPPINGS: "mBdepLaFYFxsTuVE", // done
  CLICK_SHIPMENTS: "DNCh3zaFYFxqv3fH", // done
  CLICK_SHIPPING_SETTINGS: "CmwopLaFYFxsTuIs", // done
  CLICK_SYSTEM_ACCESS: "lsZBuzaFYFxqbjdr", // done
  CLICK_USER_DETAILS: "YnjuuzaFYFxqbiAn", // done
  CLICK_USER_PROFILES: "fP2DYbaFYFxo36hv", // done
  ENTER_TEXT_CONFIRM_PASSWORD: "Xl2TXvaFYFxqvJtA", // done
  ENTER_TEXT_EMAIL: "Yv8VXvaFYFxqvD_7", // done
  ENTER_TEXT_FIRSTNAME: "FgOFXvaFYFxqvBoS", // done
  ENTER_TEXT_SURNAME: "LrWlXvaFYFxqvBqL", // done
  EXCLUDED_SERVICES_ADD_ALL_DPD_SERVICES: "dGIKqLaFYFxqPW_M", // done
  EXCLUDED_SERVICES_ADD_DPD_SERVICE: "1psyqLaFYFxqPWpz", // done
  EXCLUDED_SERVICES_REMOVE_ALL_DPD_LOCAL_SERVICES: "KMQuqLaFYFxqPXX6", // done
  EXCLUDED_SERVICES_REMOVE_ALL_DPD_SERVICES: "i5RiqLaFYFxqPWN5", // done
  EXCLUDED_SERVICES_REMOVE_DPD_LOCAL_SERVICE: "MP2OqLaFYFxqPXL6", // done
  EXCLUDED_SERVICES_REMOVE_DPD_SERVICE: "K6CcqLaFYFxqPV.N", // done
  EXPAND_ALL_TABS: "4h28aLaFYFxqPST0", // done
  HOVER: "khoV87aC48kIGMSF",
  MINIMIZE_ALL_TABS: "mbLSaLaFYFxqPSik", // done
  USER_PROFILES_ADD: "HA1RYbaFYFxo3zC_", // done
  USER_PROFILES_ADD_ALL: "EvtxYbaFYFxo3zKZ", // done
  USER_PROFILES_REMOVE: "IZeJYbaFYFxo3zMp", // done
  USER_PROFILES_REMOVE_ALL: "ZT7JYbaFYFxo3zN4", // done
  // TODO: add id
  CLICK_API_SETTING: "",
};

// https://it.dpduk.live/it/diagram/diag_8esrgbaFYFxqomXc.html?id=1591259761343
export const DELETE_PROFILE_FROM_MANAGEMENT_PAGE = {
  INTERFACE_ID: "B2srgbaFYFxqomW1",
  LOAD: "VeebgbaFYFxqomlk",
  NO: "1BebgbaFYFxqoml.",
  YES: "wJebgbaFYFxqommo",
};

// TODO: implement with suspend user from edit page
// https://it.dpduk.live/it/diagram/diag_hLSgZ9aFYFxqewjg.html?id=1591788259778
export const SUSPEND_ACCOUNT_CONFIRMATION = {
  LOAD: "TUXaZ9aFYFxqe6bK",
  NO: "f0XaZ9aFYFxqe6ba",
  INTERFACE_ID: "hzSgZ9aFYFxqewjQ",
  YES: "8sXaZ9aFYFxqe6bo",
};

// TODO: implement with unsuspend user from edit page
// https://it.dpduk.live/it/diagram/diag_qGF1rjaFYFxqeQzM.html?id=1591788259779
export const UNSUSPEND_ACCOUNT_CONFIRMATION = {
  LOAD: "lcuNrjaFYFxqeQ_b",
  NO: "hCuNrjaFYFxqeQ_3",
  INTERFACE_ID: "SaF1rjaFYFxqeQx5",
  YES: "KSuNrjaFYFxqeRAb",
};

// TODO: implement with delete user from edit page
// https://it.dpduk.live/it/diagram/diag_WIzgZ9aFYFxqewln.html?id=1591788259773
export const DELETE_ACCOUNT_CONFIRMATION = {
  INTERFACE_ID: "WwzgZ9aFYFxqewlX",
  LOAD: "aSwWZ9aFYFxqewbU",
  NO: "bywWZ9aFYFxqewbl",
  YES: "QqwWZ9aFYFxqewb0",
};
