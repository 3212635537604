import {
  CUSTOMER_EDIT_USER_PAGE,
  CUSTOMER_USERS_PAGE,
  DPD_UPDATE_FOUND_USER,
  INTERNAL_LINK,
  ROOT,
} from "./constants";
import { history } from "./history";

export const getPageLink = (path, params) => {
  let newPath = path;
  for (const param in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(param)) {
      newPath = newPath.replace(`:${param}`, params[param]);
    }
  }
  return newPath;
};

export const navigateTo = (path, params = {}) =>
  history.push(getPageLink(path, params));

export const historyReplace = (path, params) =>
  history.replace(getPageLink(path, params));

export const externalNavigateTo = (link) => {
  window.location.href = link;
};

export const getLocationHref = () => window.location.href;

export const getLocationHostname = () => window.location.hostname;

export const getLocationPathname = () => window.location.pathname;

export const getLocationSearch = () => window.location.search;

export const isUserFromInternalLink = () =>
  getLocationPathname() === INTERNAL_LINK;

export const navigateToHome = (customerId) => {
  if (customerId) {
    navigateTo(CUSTOMER_USERS_PAGE, { customerId });
  } else {
    navigateTo(ROOT);
  }
};

export const navigateToEditUser = (customerId, id) => {
  if (customerId) {
    navigateTo(CUSTOMER_EDIT_USER_PAGE, { customerId, id });
  } else {
    navigateTo(DPD_UPDATE_FOUND_USER, { id });
  }
};

export const getUrlSearchParam = (param) => {
  const params = new URLSearchParams(getLocationSearch());

  return params.get(param) || "";
};
