import {
  createAction,
  createActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import {
  ACCOUNT_ACCESS_DENIED_MESSAGE,
  ADD_KEY,
  CLOSE,
  DELETE_KEY,
  DISABLE,
  DISABLE_API_ACCESS,
} from "../../constants/strings";
import { ALIGN_BUTTONS, DELAY, WIDTH_TYPE } from "./modal.constants";
import { KEY_$ } from "../userDetailsForm/apiSetting/apiSetting.constants";
import { change } from "redux-form";
import { USER_FORMS } from "../../constants/forms";

const ACTION_NAMESPACE = "MODAL";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
});

export const showModal = (modalProps) =>
  createPayloadAction(ACTIONS.SHOW_MODAL, modalProps);

export const hideModal = () => createAction(ACTIONS.HIDE_MODAL);

export const showModalWithDelay =
  ({ ...modalProps }) =>
  (dispatch) =>
    setTimeout(() => {
      dispatch(
        showModal({
          ...modalProps,
        })
      );
    }, DELAY);

export const showInfoModal =
  (contentText, props = {}) =>
  (dispatch) =>
    dispatch(
      showModal({
        contentText,
        confirmButtonText: CLOSE,
        showCancelButton: false,
        alignButton: ALIGN_BUTTONS.CENTER,
        ...props,
      })
    );

export const showAccessDeniedModal = (onConfirmClick) => (dispatch) =>
  dispatch(
    showModal({
      contentText: ACCOUNT_ACCESS_DENIED_MESSAGE,
      confirmButtonText: CLOSE,
      showCancelButton: false,
      alignButton: ALIGN_BUTTONS.CENTER,
      onConfirmClick,
    })
  );
export const showDeleteKeyModal =
  ({ keyLabel, initialValues, onConfirmClick }) =>
  (dispatch) => {
    if (keyLabel) {
      dispatch(
        showModal({
          // TODO: add analytics
          interfaceId: "",
          loadId: "",
          confirmActionId: "",
          cancelActionId: "",
          customComponent: DELETE_KEY,
          confirmButtonText: DELETE_KEY,
          alignButton: ALIGN_BUTTONS.RIGHT,
          widthType: WIDTH_TYPE.MEDIUM,
          keyLabel,
          initialValues,
          onConfirmClick,
        })
      );
    }
  };
export const showAddKeyModal =
  ({ initialValues, field, keyIndex }) =>
  (dispatch) => {
    dispatch(
      showModal({
        // TODO: add analytics
        interfaceId: "",
        loadId: "",
        confirmActionId: "",
        cancelActionId: "",
        customComponent: ADD_KEY,
        cancelButtonText: CLOSE,
        keyLabel: KEY_$(keyIndex),
        showConfirmButton: false,
        initialValues,
        onCloseClick: () =>
          dispatch(change(USER_FORMS.API_SETTING, field, initialValues.key)),
        alignButton: ALIGN_BUTTONS.RIGHT,
        widthType: WIDTH_TYPE.MEDIUM,
      })
    );
  };

export const showDisableApiAccessModal =
  ({ onConfirmClick, onCloseClick }) =>
  (dispatch) => {
    dispatch(
      showModal({
        // TODO: add analytics
        interfaceId: "",
        loadId: "",
        confirmActionId: "",
        cancelActionId: "",
        customComponent: DISABLE_API_ACCESS,
        confirmButtonText: DISABLE,
        alignButton: ALIGN_BUTTONS.RIGHT,
        widthType: WIDTH_TYPE.MEDIUM,
        onConfirmClick,
        onCloseClick,
      })
    );
  };
