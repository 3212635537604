import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import { READ_ONLY_ADDRESS_BOOK } from "../../constants/strings";
import { READ_ONLY_ADDRESS_BOOK_FIELD } from "../../features/userDetailsForm/advancedCustomerConfiguration/advancedCustomerConfiguration.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";

const AddressBook = ({ onAddressBookChange }) => (
  <Grid container justify="space-between">
    <Field
      name={READ_ONLY_ADDRESS_BOOK_FIELD}
      component={CustomSwitchWithTitle}
      type="checkbox"
      label={READ_ONLY_ADDRESS_BOOK}
      onChange={(event, value) => {
        onAddressBookChange(READ_ONLY_ADDRESS_BOOK_FIELD, value);
      }}
    />
  </Grid>
);

AddressBook.propTypes = {
  onAddressBookChange: PropTypes.func,
};

AddressBook.defaultProps = {
  onAddressBookChange: () => {},
};

export default AddressBook;
