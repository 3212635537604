import get from "lodash/get";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { isEmailValid } from "../../../utils/commonValidation";
import {
  AUTOGENERATED_USERNAME_FIELD,
  CHANGE_MESSAGE_FIELD,
  USE_6_INCH_LABEL_FIELD,
  USERNAME_FIELD,
} from "./userDetails.constants";

const getUserDetailsFormValues = getFormValues(
  USER_DETAILS_FORMS.DPD_USER_DETAILS
);

export const shouldShowLabelSettings = createSelector(
  getUserDetailsFormValues,
  (labelSettingsFormValues) =>
    get(labelSettingsFormValues, USE_6_INCH_LABEL_FIELD, false)
);

export const getUserId = createSelector(
  getUserDetailsFormValues,
  (userDetailFormValues) => get(userDetailFormValues, "uid")
);

export const getChangeMessage = createSelector(
  getUserDetailsFormValues,
  (userData) => get(userData, CHANGE_MESSAGE_FIELD, "")
);

export const getEmail = createSelector(getUserDetailsFormValues, (userData) =>
  get(userData, "email", "")
);

export const isValidUserEmail = createSelector(getEmail, (email) =>
  isEmailValid(email)
);

export const getUsername = createSelector(
  getUserDetailsFormValues,
  (formValues) => get(formValues, USERNAME_FIELD, "")
);

export const isAutoGeneratedUsername = createSelector(
  getUserDetailsFormValues,
  (formValues) => get(formValues, AUTOGENERATED_USERNAME_FIELD, false)
);

export const isUseEmailAsLoginEnabled = createSelector(
  getEmail,
  getUsername,
  (email, username) => isEmailValid(username) && email === username
);
