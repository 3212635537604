export const prepareAdditionalAccounts = ({ accounts }, { account }) => ({
  accounts: [...new Set([account, ...accounts.map(({ account }) => account)])],
});

export const getAdditionalAccountsInitialValues = () => ({ accounts: [] });

export const getCustomerAccountsStartWith = (customers, value) => {
  const res = [];
  for (const key in customers) {
    if (key.startsWith(value)) {
      res.push(customers[key]);
    }
  }
  return res;
};
