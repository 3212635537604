import { API_ENDPOINTS } from "../../constants/common";
import { api, webApi } from "../../utils/api";

export const getProfilesByAccount = (account) =>
  webApi
    .get({ path: API_ENDPOINTS.PROFILE, query: { account } })
    .then(({ data }) => data);

export const fetchProfile = (uid, account) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.PROFILE}/${encodeURIComponent(uid)}`,
      query: { account },
    })
    .then(({ data }) => data);

export const deleteProfile = (account, profileCode) =>
  webApi.delete({
    path: `${API_ENDPOINTS.PROFILE}/${encodeURIComponent(profileCode)}`,
    query: {
      account,
    },
  });

export const isProfileExist = ({ customerID, profileCode }) =>
  api
    .get({
      path: `${API_ENDPOINTS.ESG}/isProfileExist/${customerID}/${profileCode}`,
    })
    .then((res) => res.data);

export const createProfile = (values) =>
  webApi.post({ path: `${API_ENDPOINTS.PROFILE}/create`, body: values });

export const updateProfile = (uid, values) =>
  webApi.put({
    path: `${API_ENDPOINTS.PROFILE}/${encodeURIComponent(uid)}`,
    body: values,
  });
