import find from "lodash/find";
import { change } from "redux-form";

import { SERVICE_RESTRICTED_FIELD } from "./serviceRestrictions.constants";
import {
  get2DayRestrictionCode,
  getPostcodeRestrictions,
  getRestrictionMappingValue,
  getRestrictionServiceRestrictedValue,
} from "./serviceRestrictions.selectors";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import {
  getHighlandsAndIslandCodes,
  getIrishCodes,
  getOffshoreCodes,
  getServiceRestrictions,
} from "../../config/config.selectors";

export const resetRestrictionMappingField = () =>
  change(USER_DETAILS_FORMS.SERVICE_RESTRICTIONS, "mapping", "");

export const resetServiceRestrictionsMapping = () => (dispatch) =>
  dispatch(changeServiceRestriction([]));

export const changeServiceRestriction = (value) =>
  change(
    USER_DETAILS_FORMS.SERVICE_RESTRICTIONS,
    SERVICE_RESTRICTED_FIELD,
    value
  );

export const addServiceRestrictionMappingRow = () => (dispatch, getState) => {
  const state = getState();
  const mapping = getRestrictionMappingValue(state);
  const selectValues = getPostcodeRestrictions(state);
  const restrictions = getRestrictionServiceRestrictedValue(state);
  const newRestriction = {
    postcode: mapping,
    selectValues,
    restriction: "",
  };

  dispatch(changeServiceRestriction(restrictions.concat(newRestriction)));
  dispatch(resetRestrictionMappingField());
};

export const addCodes = (codes) => (dispatch, getState) => {
  const state = getState();
  const serviceRestrictions = getServiceRestrictions(state);
  const serviceRestricted = getRestrictionServiceRestrictedValue(state);
  const restrictionCode2Day = get2DayRestrictionCode(state);

  const filteredServiceRestricted = serviceRestricted.map((item) => {
    if (find(codes, { postcode: item.postcode })) {
      return {
        ...item,
        restriction: restrictionCode2Day,
      };
    }

    return item;
  });

  const filteredValues = codes.filter(
    ({ postcode }) => !find(filteredServiceRestricted, { postcode })
  );

  const values = filteredValues.map(({ postcode }) => ({
    postcode,
    selectValues: serviceRestrictions,
    restriction: restrictionCode2Day,
  }));

  const newRestrictions = filteredServiceRestricted.concat(values);

  dispatch(changeServiceRestriction(newRestrictions));
};

export const addIrishCodes = () => (dispatch, getState) => {
  const state = getState();
  const irishCodes = getIrishCodes(state);
  dispatch(addCodes(irishCodes));
};

export const addOffshoreCodes = () => (dispatch, getState) => {
  const state = getState();
  const offshoreCodes = getOffshoreCodes(state);
  dispatch(addCodes(offshoreCodes));
};

export const addHighlandsCodes = () => (dispatch, getState) => {
  const state = getState();
  const highlandsAndIslandCodes = getHighlandsAndIslandCodes(state);
  dispatch(addCodes(highlandsAndIslandCodes));
};

export const onSelectValueRestriction =
  (index, restriction) => (dispatch, getState) => {
    const restrictions = getRestrictionServiceRestrictedValue(
      getState()
    ).slice();

    restrictions[index] = { ...restrictions[index], restriction };

    dispatch(changeServiceRestriction(restrictions));
  };

export const onRemoveRestriction = (deleteIndex) => (dispatch, getState) => {
  const restrictions = getRestrictionServiceRestrictedValue(getState()).slice();

  restrictions.splice(deleteIndex, 1);

  dispatch(changeServiceRestriction(restrictions));
};
