export const DISABLE_ADHOC_EDIT_SHIPMENT_FIELD = "disableAdhocEditShipment";
export const DISABLE_ADD_EXTRA_LABEL_FIELD = "disableAddExtraLabel";
export const DISABLE_IMPORT_FILE_SETUP_FIELD = "disableImportFileSetup";
export const DISABLE_EXPORT_FILE_SETUP_FIELD = "disableExportFileSetup";
export const DISABLE_EXTENDED_LIABILITY_FIELD = "disableExtendedLiability";

export const SECURITY_SETTINGS_FIELDS = [
  DISABLE_ADD_EXTRA_LABEL_FIELD,
  DISABLE_ADHOC_EDIT_SHIPMENT_FIELD,
  DISABLE_EXPORT_FILE_SETUP_FIELD,
  DISABLE_EXTENDED_LIABILITY_FIELD,
  DISABLE_IMPORT_FILE_SETUP_FIELD,
];
