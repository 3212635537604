import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import SystemAccess from "../../../components/SystemAccess/SystemAccess";
import { CREATE_EDIT_USER_ANALYTICS } from "../../../constants/analytics";
import { USER_DETAILS_FORMS } from "../../../constants/forms";
import { switchAdminAccess } from "../../../features/myDpdUserForm/systemAccess/systemAccess.actions";
import { isValidUserEmail } from "../../../features/myDpdUserForm/userDetails/userDetails.selectors";
import {
  getCustomerAdditionalAccountNumbers,
  onAccountClick,
} from "../../../features/userDetailsForm/additionalAccounts/additionalAccounts.actions";
import { ADDITIONAL_ACCOUNTS_FIELD } from "../../../features/userDetailsForm/additionalAccounts/additionalAccounts.constant";
import {
  getCustomerAccountsInitialValues,
  isFirstAdditionalAccount,
  isOwnUser,
} from "../../../features/userDetailsForm/systemAccess/systemAccess.selectors";
import { getAdminAccess } from "../../../features/userDetailsForm/userDetailsForm.selector";
import withRef from "../../../hocs/withRef";

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params;
  return {
    adminAccess: getAdminAccess(state),
    isOwnUser: isOwnUser(state),
    showInvalidEmailErrorMsg: !isValidUserEmail(state),
    disableRemoveFirstAccount: isFirstAdditionalAccount(customerId)(state),
    accountsInitialValues: getCustomerAccountsInitialValues(customerId)(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { customerId } = ownProps.match.params;
  return {
    onAdminAccessClick: (value) =>
      dispatch(switchAdminAccess(value, customerId)),
    getAdditionalAccountNumbers: (accountNumber) =>
      dispatch(
        getCustomerAdditionalAccountNumbers(
          accountNumber,
          USER_DETAILS_FORMS.SYSTEM_ACCESS_ACCOUNTS
        )
      ),
    onAccountClick: (item) =>
      dispatch(onAccountClick(item, USER_DETAILS_FORMS.SYSTEM_ACCESS_ACCOUNTS)),
  };
};

export default compose(
  withRouter,
  reduxForm({
    form: USER_DETAILS_FORMS.SYSTEM_ACCESS,
    initialValues: {},
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(() => ({
    onAdminAccessClick: () =>
      CREATE_EDIT_USER_ANALYTICS.CLICK_ADMIN_ACCESS_TOGGLE,
    onBlurFields: (field) => {
      // eslint-disable-next-line default-case
      switch (field) {
        case ADDITIONAL_ACCOUNTS_FIELD:
          return CREATE_EDIT_USER_ANALYTICS.ADMIN_ACCOUNT_NUMBER_ENTRY;
      }
    },
    onToggleChange: (field, value) => {
      const id = CREATE_EDIT_USER_ANALYTICS.CLICK_TOGGLE;
      return createLogEntryPayload(id, {
        field,
        value,
      });
    },
  })),
  withRef
)(SystemAccess);
