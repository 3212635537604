export const prepareXrefMappings = ({ serviceXrefMappings }) => {
  const values = serviceXrefMappings.reduce(
    (acc, { mappingText, serviceCode }) =>
      acc.concat({ mappingText, serviceCode }),
    []
  );

  return { serviceXrefMappings: values };
};

export const getXrefMappingInitialValues = () => ({
  serviceXrefMappings: [],
  mapping: "",
});
