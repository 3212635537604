import { Box } from "@material-ui/core";
import { isEqual } from "lodash";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import qs from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, lifecycle, withProps } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import SimulateTabTitle from "../../../../components/CustomTabs/SimulateTabTitle";
import CustomDatePicker from "../../../../components/DatePicker/DatePicker";
import NoResultsWavingBox from "../../../../components/NoResultsWavingBox/NoResultsWavingBox";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import { ACTIVITY_MONITOR_ANALYTICS } from "../../../../constants/analytics";
import {
  CHOOSE_DATE,
  NO_RESULTS_FOUND,
  PLEASE_SEARCH_AGAIN,
  YOUR_TEAMS_ACTIVITY,
} from "../../../../constants/strings";
import { fetchAccountActivityLogs } from "../../../../features/activityMonitor/activityMonitor.action";
import { getQueryStringParams } from "../../../../features/activityMonitor/activityMonitor.model";
import { customerActivityLogs } from "../../../../features/activityMonitor/activityMonitor.selectors";
import { formatDateLabel } from "../../../../utils/string";
import { formatDate } from "../../../../utils/timeFormater";
import { ActivityItem } from "./ActivityItem";
import styles from "./ActivityMonitor.module.scss";

const ActivityMonitor = ({
  activities,
  onSearchWithAnalytic,
  onDateChange,
  onOpenDatepicker,
  location,
  customerId,
}) => {
  const getDateLabel = () => formatDateLabel(date, "", CHOOSE_DATE);
  const { search, date } = qs.parse(location.search);
  const { audits = [], total = 0 } = activities;

  return (
    <PageContainer>
      <Box classes={{ root: styles.pageHeader }}>
        <SearchInput
          key={customerId}
          onSearch={onSearchWithAnalytic}
          input={{
            defaultValue: search,
          }}
        />
        <Box classes={{ root: styles.pickerContainer }}>
          <CustomDatePicker
            labelFunc={getDateLabel}
            emptyLabel={CHOOSE_DATE}
            onOpen={onOpenDatepicker}
            disableFuture
            onChange={onDateChange}
            input={{ value: date }}
          />
        </Box>
      </Box>
      <Box className={styles.logItemsContainer}>
        <SimulateTabTitle title={YOUR_TEAMS_ACTIVITY} />
        {!audits.length ? (
          <NoResultsWavingBox
            title={NO_RESULTS_FOUND}
            subtitle={PLEASE_SEARCH_AGAIN}
          />
        ) : (
          <ActivityItem activities={audits} total={total} />
        )}
      </Box>
    </PageContainer>
  );
};

ActivityMonitor.propTypes = {
  activities: PropTypes.array,
  onSearch: PropTypes.func,
  onDateChange: PropTypes.func,
  onSearchWithAnalytic: PropTypes.func,
  onOpenDatepicker: PropTypes.func,
  selectedDate: PropTypes.any,
  searchString: PropTypes.string,
  customerId: PropTypes.string,
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  activities: customerActivityLogs(state),
});

const mapDispatchToProps = (dispatch, { customerId }) => ({
  onFetchAccountActivityLogs: (query) =>
    dispatch(fetchAccountActivityLogs(customerId, query)),
});

export default compose(
  withRouter,
  withTrack({
    loadId: ACTIVITY_MONITOR_ANALYTICS.LOAD,
    interfaceId: ACTIVITY_MONITOR_ANALYTICS.INTERFACE_ID,
  }),
  withProps((props) => ({
    customerId: props.match.params.customerId,
  })),
  withTrackProps((props) => ({
    onSearchWithAnalytic: debounce((search = "") => {
      const initialParams = {
        ...qs.parse(props.location.search),
        page: 1,
        search: undefined,
      };
      if (!search) {
        props.history.push({
          ...props.location,
          search: qs.stringify(initialParams),
        });
      }
      if (search.length > 2) {
        props.history.push({
          ...props.location,
          search: qs.stringify({ ...initialParams, search }),
        });
      }
      return ACTIVITY_MONITOR_ANALYTICS.CLICK_ENTER__SEARCH;
    }, 500),
    onOpenDatepicker: () => ACTIVITY_MONITOR_ANALYTICS.CLICK_CALENDAR,
    onDateChange: (value) => {
      props.history.push({
        ...props.location,
        search: qs.stringify({
          ...qs.parse(props.location.search),
          date: (value && formatDate(value, "y/MM/d")) || undefined,
          page: 1,
        }),
      });
      return ACTIVITY_MONITOR_ANALYTICS.CLICK_DATE;
    },
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withProps((props) => ({
    load: () => {
      const query = getQueryStringParams(props.location.search);
      props.onFetchAccountActivityLogs(query);
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.load();
    },
    componentDidUpdate(prevProps) {
      const { customerId, location } = this.props;
      if (
        !isEqual(
          getQueryStringParams(prevProps.location.search),
          getQueryStringParams(location.search)
        ) ||
        prevProps.customerId !== customerId
      ) {
        this.props.load();
      }
    },
  })
)(ActivityMonitor);
