export const SENDERS_REF1_FIELD = "sendersRef1";
export const SENDERS_REF2_FIELD = "sendersRef2";
export const SENDERS_REF3_FIELD = "sendersRef3";
export const SENDERS_REF_UNIQUE_FIELD = "sendersRefUnique";
export const ENABLE_SCANNING_SCREEN_FIELD = "enableScanningScreen";
export const ALLOCATE_SENDERS_FIELD = "allocateSenders";
export const UPDATE_RECORDS_DUPLICATE_FIELD = "updateRecordsDuplicate";
export const USE_BAD_FILE_FIELD = "useBadFile";
export const USE_SCREEN_FIELD = "useScreen";
export const EXPIRATION_DATE_ON_LABEL_FIELD = "expirationDateOnLabel";
export const ENABLE_BULK_REV_FIELD = "enableBulkRev";
export const REVERSE_FOUR_INCH_FIELD = "reverseFourInchLabel";
export const EOD_USER_FIELD = "eodUser";
export const EOD_DAY_FIELD = "autoEodDay";
export const EOD_TIME_FIELD = "autoEodTime";
export const EOD_MASTER_FIELD = "eodMaster";
export const EOD_ENABLE_RELEASE_FILE_FIELD = "enableReleaseFile";
export const EOD_DISABLE_PRINT_MANIFEST_FIELD = "disablePrintManifest";
export const NO_TIME_SELECTED = "No time selected, please select a time";
export const SELECT_THREE_AUTO_EOD = "Please select at least 3 Auto EOD Days";
export const MIN_AUTO_EOD = 3;
export const AUTO_HOLD_FIELD = "autoHold";
export const ENABLE_RELEASE_FILE_FIELD = "enableReleaseFile";
export const EXTENDED_HOLD_FIELD = "extendedHold";
export const ON_SCREEN_IMPORT_FIX_FIELD = "onScreenImportFix";

export const WEEKDAYS_DEFAULT = [1, 2, 3, 4, 5];

export const EOD_FIELDS = [
  EOD_MASTER_FIELD,
  EOD_TIME_FIELD,
  AUTO_HOLD_FIELD,
  EXTENDED_HOLD_FIELD,
  EOD_ENABLE_RELEASE_FILE_FIELD,
  EOD_DISABLE_PRINT_MANIFEST_FIELD,
  EOD_DAY_FIELD,
];

export const SHIPPINGS_SETTINGS_FIELDS = [
  ALLOCATE_SENDERS_FIELD,
  ENABLE_SCANNING_SCREEN_FIELD,
  SENDERS_REF2_FIELD,
  SENDERS_REF3_FIELD,
  SENDERS_REF_UNIQUE_FIELD,
  UPDATE_RECORDS_DUPLICATE_FIELD,
  ENABLE_BULK_REV_FIELD,
  EXPIRATION_DATE_ON_LABEL_FIELD,
  REVERSE_FOUR_INCH_FIELD,
  REVERSE_FOUR_INCH_FIELD,
];

export const EOD_TOGGLES_FIELDS = [
  AUTO_HOLD_FIELD,
  ENABLE_RELEASE_FILE_FIELD,
  EOD_DISABLE_PRINT_MANIFEST_FIELD,
  EXTENDED_HOLD_FIELD,
];
