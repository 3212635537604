import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { reduxForm, FieldArray } from "redux-form";
import { Box, Grid } from "@material-ui/core";

import { DPD_APP_VERSION_DETAILS_FORM } from "../../../../../../constants/forms";
import BottomButtons from "../../../../../../components/BottomButtons/BottomButtons";
import AppVersionTable from "../AppVersionTable/AppVersionTable";
import {
  loadDPDAppVersionsDetails,
  onUpdateVersions,
  onCancelVersions,
} from "../../../../../../features/appManagement/appManagement.actions";

const DPDAppVersionsForm = ({ onCancelVersions, onUpdateVersions, dirty }) => (
  <>
    <Box>
      <Grid container direction="row">
        <FieldArray name="versions" component={AppVersionTable} />
      </Grid>
      <Box py={5}>
        <BottomButtons
          onCancelClick={onCancelVersions}
          onConfirmClick={onUpdateVersions}
          confirmDisabled={!dirty}
        />
      </Box>
    </Box>
  </>
);

const mapDispatchToProps = {
  loadDPDAppVersionsDetails,
  onUpdateVersions,
  onCancelVersions,
};

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: DPD_APP_VERSION_DETAILS_FORM,
  }),
  lifecycle({
    componentDidMount() {
      this.props.loadDPDAppVersionsDetails();
    },
  })
)(DPDAppVersionsForm);
