export const SOCIAL_LOGIN_TYPES = {
  FACEBOOK: "Facebook",
  TWITTER: "Twitter",
  GOOGLE: "Google",
  YAHOO: "Yahoo",
  LINKEDIN: "Linkedin",
};

export const DPD_EMAIL = "@dpd.dpduk.live";
export const DPD_LOCAL_EMAIL = "@dpdlocal.dpduk.live";
export const DPD_LOCAL_DOMAIN = "dpdlocal.co.uk";
export const MY_DPD_LOGIN_PAGE = "/umslogon/public/logon.do";

export const _NOT_AUTHENTICATED = "User is not authenticated";
export const _NO_USER_ACCOUNT =
  "Profile not found. Please contact your administrator";
export const UNABLE_TO_AUTHORISE_CONTACT_SUPPORT =
  "Unable to Authorise, please contact support";
export const PLEASE_ENTER_VALID_EMAIL = "Please enter a valid email";
export const PLEASE_ENTER_VALID_USERNAME = "Please enter a valid username";
export const USERNAME_CANNOT_BE_EMPTY = "Username field can not be blank";
export const PLEASE_ENTER_VALID_DPD_EMAIL = "Please enter valid DPD email";
export const PLEASE_ENTER_PASSWORD = "Please enter a password";
export const PLEASE_ENTER_FORENAME = "Please enter a first name";
export const PLEASE_ENTER_LASTNAME = "Please enter a last name";
export const LESS_100_SYMBOL = "Should be less than 100 symbol";
export const PLEASE_ENTER_DOMAIN = "Please enter organisation ID";
export const WEAK_PASSWORD = "Password is too weak";
export const PASSWORD_DONT_MATCH = "Password doesn't match";
export const _INVALID_CREDENTIALS = "Invalid login/password";
export const TOO_MANY_REQUESTS =
  "We have blocked all requests from this device due to unusual activity. Try again later.";
export const _CONNECTION_ERROR =
  "Connection error occurred. Please check your connection and try again";
export const _DOMAIN_NOT_SETUP = "Domain not setup to use this login method";
export const ACCOUNT_EXPIRED = "Account expired";
export const ACCOUNT_SUSPENDED = "Account suspended";
export const RESET_TOKEN_EXPIRED = "Reset token is expired";
export const UNABLE_TO_AUTHORISE = "Unable to authorise";
export const FIELD_CANT_BE_EMPTY = "{0} can not be empty";
export const INVALID_POSTCODE = "Invalid postcode";
export const USERNAME_IN_USE =
  "Username already in use, please select a different username";
export const ENTER_VALID_LOGIN = "Please enter a valid login";
export const PASSWORD_MIN_LENGTH = 6;
export const ZERO_WIDTH_NON_JOINER = String.fromCharCode(253);
