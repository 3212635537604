import { change, clearSubmitErrors } from "redux-form";

import {
  ACCOUNT_EXPIRY_DATE_FIELD,
  DEFAULT_PASSWORD,
  NEW_PASSWORD_FIELD,
  PASSWORD_LOCK_FIELD,
  RE_ENTER_PASSWORD_FIELD,
  USE_EMAIL_AS_LOGIN_FIELD,
  USERNAME_FIELD,
} from "./userDetails.constants.js";
import { getConfirmPassword, getNewPassword } from "./userDetails.selectors.js";
import { USER_FORMS } from "../../../constants/forms.js";
import {
  isDefaultPassword,
  isEmailValid,
} from "../../../utils/commonValidation.js";

export const changeUserDetailsForm = (field, value) =>
  change(USER_FORMS.DPD_USER_DETAILS, field, value);

export const updateLoginFieldWithEmailValue = (email) => (dispatch) => {
  dispatch(changeUserDetailsForm(USERNAME_FIELD, email));
};

export const onChangeUsername = (value) => (dispatch, getState) => {
  const state = getState();
  const newPassword = getNewPassword(state);
  const confirmPassword = getConfirmPassword(state);
  if (value && !newPassword) {
    dispatch(setDefaultPassword());
    dispatch(deselectPasswordLock());
  } else if (
    !value &&
    isDefaultPassword(newPassword) &&
    isDefaultPassword(confirmPassword)
  ) {
    dispatch(clearPasswordSection());
  }
};

export const enableUseEmailAsLogin = (value, email) => (dispatch) => {
  if (isEmailValid(email)) {
    dispatch(updateLoginFieldWithEmailValue(email));
  }
  if (value) {
    dispatch(clearPasswordSection());
  }

  !value && dispatch(clearSubmitErrors(USER_FORMS.DPD_USER_DETAILS));
};

export const setDefaultPassword = () => (dispatch) => {
  dispatch(changeUserDetailsForm(NEW_PASSWORD_FIELD, DEFAULT_PASSWORD));
  dispatch(changeUserDetailsForm(RE_ENTER_PASSWORD_FIELD, DEFAULT_PASSWORD));
};

export const clearPasswords = () => (dispatch) => {
  dispatch(changeUserDetailsForm(NEW_PASSWORD_FIELD, ""));
  dispatch(changeUserDetailsForm(RE_ENTER_PASSWORD_FIELD, ""));
};

export const deselectPasswordLock = () => (dispatch) =>
  dispatch(changeUserDetailsForm(PASSWORD_LOCK_FIELD, false));

export const clearUserExpiryDate = () => (dispatch) =>
  dispatch(changeUserDetailsForm(ACCOUNT_EXPIRY_DATE_FIELD, null));

export const triggerUseEmailAsLogin = (value) => (dispatch) =>
  dispatch(changeUserDetailsForm(USE_EMAIL_AS_LOGIN_FIELD, !!value));

export const clearPasswordSection = () => (dispatch) => {
  dispatch(clearPasswords());
  dispatch(deselectPasswordLock());
};
